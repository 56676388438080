import React from 'react';
import css from './LandingPageHowItsWorksSection.css';
import { FormattedMessage } from '../../util/reactIntl';
import HireNowImage from 'assets/Landing_Pages/HowItsWorks/Hire_now_girl.png';
import InstallAppImage from 'assets/Landing_Pages/HowItsWorks/Installapp_girl.png';
import BookingsImage from 'assets/Landing_Pages/HowItsWorks/Bookings_girl.png';

const LandingPageHowItsWorksSection = props => {
  const researchServiceList = [
    {
      id: 'Browse',
      title: 'HowItsWorksSection.browseTitle',
      description: 'HowItsWorksSection.browseText',
    },
    {
      id: 'Hire & Pay',
      title: 'HowItsWorksSection.HirePayTitle',
      description: 'HowItsWorksSection.HirePayText',
    },
    {
      id: 'Receive Delivery',
      title: 'HowItsWorksSection.ReceiveDeliveryTitle',
      description: 'HowItsWorksSection.ReceiveDeliveryText',
    },
  ];
  const bookingServiceList = [
    {
      id: 'Book',
      title: 'HowItsWorksSection.BookTitle',
      description: 'HowItsWorksSection.BookText',
    },
    {
      id: 'Pay',
      title: 'HowItsWorksSection.PayTitle',
      description: 'HowItsWorksSection.PayText',
    },
    {
      id: 'Support',
      title: 'HowItsWorksSection.SupportTitle',
      description: 'HowItsWorksSection.SupportText',
    },
  ];

  return (
    <div>
      <h2 className={css.title}>
        <FormattedMessage id="HowItsWorksSection.title" />
      </h2>
      <div className={css.sectionContentWrapper}>
        <section className={css.sectionClass}>
          <div className={css.titleContainer}>

            <h3><FormattedMessage id="HowItsWorksSection.subTitle1" /></h3>
          </div>
          <div className={css.threeColumnsContainer}>
            <figure className={css.startFigureClass}>
              <img src={HireNowImage} alt='Hire Now' width={270} />
            </figure>
            <dl>
              {researchServiceList.slice(0, 2).map(i => (<>
                <dt>
                  <FormattedMessage id={i.title} />
                </dt>
                <dd>
                  <FormattedMessage id={i.description} />
                </dd>
              </>))}
            </dl>
            <div className={css.optionalImageContainerClass}>
              <dl>
                {researchServiceList.slice(2, 3).map(i => (<>
                  <dt>
                    <FormattedMessage id={i.title} />
                  </dt>
                  <dd>
                    <FormattedMessage id={i.description} />
                  </dd>
                </>))}
              </dl>
              <figure className={css.optionalFigureClass}>
                <img src={InstallAppImage} alt='Install App' width={220} />
              </figure>
            </div>
          </div>
        </section>
        <section className={css.sectionClass}>
          <div className={css.titleContainer}>

            <h3><FormattedMessage id="HowItsWorksSection.subTitle2" values={{ normal: chunks => <span className={css.normalTextClass}>{chunks}</span> }} /></h3>
          </div>
          <figure className={css.mobileFigureClass}>
            <img src={BookingsImage} alt='Bookings' width={270} />
          </figure>
          <div className={css.twoColumnsContainer}>
            <dl>
              {bookingServiceList.slice(0, 2).map(i => (<>
                <dt>
                  <FormattedMessage id={i.title} />
                </dt>
                <dd>
                  <FormattedMessage id={i.description} />
                </dd>
              </>))}
            </dl>
            <div>
              <dl>
                {bookingServiceList.slice(2, 3).map(i => (<>
                  <dt>
                    <FormattedMessage id={i.title} />
                  </dt>
                  <dd>
                    <FormattedMessage id={i.description} />
                  </dd>
                </>))}
              </dl>
              <figure className={css.endFigureClass}>
                <img src={BookingsImage} alt='Bookings' width={150} />
              </figure>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

LandingPageHowItsWorksSection.defaultProps = {};

LandingPageHowItsWorksSection.propTypes = {};

export default LandingPageHowItsWorksSection;
