export const removeDuplicates = data => {
  return [...new Set(data)];
};


export const sortByRating = (listings = [], reviews = {}, getRatingFromReviews) => {
  const reviewsByRatingMap = Object.keys(reviews).reduce((acc, id) => {
    acc[id] = getRatingFromReviews(reviews[id]);
    return acc
  }, {});

  const sortedListings = listings.sort((a, b) => {
    const ratingA = reviewsByRatingMap[a.id.uuid] ?? 0;
    const ratingB = reviewsByRatingMap[b.id.uuid] ?? 0;
    return ratingB - ratingA;
  })

  return sortedListings;
}