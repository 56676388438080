import React from 'react';
import css from '../ListingDetailsSectionMainTab.css';
import SubTabSectionDetailsContent from './SubTabSectionDetailsContent';
import { PACKAGE_TYPES } from '../../../util/constants';

const ListingDetailsSectionSubTabs = props => {
  const { authorDetails = {}, featureImage, ...rest } = props;
  const { currentListing } = rest;
  authorDetails.profileImage = featureImage;

  return (
    <div className={css.subDetailsStickyContainer}>
      <SubTabSectionDetailsContent avatarDetails={authorDetails} tabName={PACKAGE_TYPES.BASIC} tabIndex={0} {...rest} />
    </div>
  );
};
export default ListingDetailsSectionSubTabs;
