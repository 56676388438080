import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ensureOwnListing } from '../../util/data';
import { EditListingLocationForm } from '../../forms';

import css from './EditListingLocationPanel.css';
import { getCombinedLocationSearchString } from 'util/general';
import { getCountryCode, getCountryData, continents } from 'lib/countries-list';
import { removeDuplicates } from 'util/arrayHelper';

class EditListingLocationPanel extends Component {
  constructor(props) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);

    this.state = {
      initialValues: this.getInitialValues(),
    };
  }

  getInitialValues() {
    const { listing } = this.props;
    const currentListing = ensureOwnListing(listing);
    const { geolocation, publicData } = currentListing.attributes;

    const locationFieldsPresent =
      publicData && publicData.location && publicData.location.address && geolocation;
    const { nationality, countries, places, location } = publicData;

    const { address, context, timezone } = location ?? {};

    return {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin: geolocation, timezone, context },
          }
        : null,
      nationality,
      countries,
      places: places ? places.split(':') : null,
    };
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      listingType,
      panelUpdated,
      updateInProgress,
      errors,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);

    return (
      // <div className={classes}>
      <div className={css.scope}>
        <EditListingLocationForm
          className={css.form}
          initialValues={this.state.initialValues}
          onSubmit={values => {
            const { location, nationality, countries, places } = values;

            const countryCodeList = countries.map(country => getCountryCode(country));
            const countryDataList = countryCodeList.map(country => getCountryData(country));
            const continentList = countryDataList.map(country => country.continent);

            const continentSetList = removeDuplicates(continentList);

            const continent = continentSetList.map(iso2 => continents[iso2]).join(',');

            const {
              selectedPlace: { address, origin, timezone, context },
            } = location;

            const combinedLocationSearch = getCombinedLocationSearchString(currentListing);
            const updateValues = {
              geolocation: origin,
              publicData: {
                location: {
                  address,
                  timezone,
                  context,
                },
                nationality,
                continent,
                countries: countries,
                places: places.join(':'),
                combinedLocationSearch,
              },
            };
            const updateState = {
              location: {
                search: address,
                selectedPlace: { address, origin, timezone, context },
              },
              nationality,
              continent,
              countries,
              places,
            };

            this.setState({
              initialValues: updateState,
            });
            onSubmit(updateValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          listingType={listingType}
          fetchErrors={errors}
        />
      </div>
    );
  }
}

const { func, object, string, bool } = PropTypes;

EditListingLocationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingLocationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingLocationPanel;
