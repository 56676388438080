import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconMembership.css';

const IconMembership = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="68" height="57" viewBox="0 0 68 57" fill="none">
      <path d="M57.4616 30.755H52.2676C52.8107 32.243 53.088 33.8149 53.0866 35.399V55.028C53.087 55.6886 52.9744 56.3444 52.7536 56.967H61.3396C62.8819 56.9654 64.3605 56.352 65.4511 55.2615C66.5416 54.1709 67.155 52.6923 67.1566 51.15V40.45C67.1539 37.8796 66.1316 35.4151 64.3141 33.5975C62.4965 31.7799 60.032 30.7577 57.4616 30.755Z" fill="#232750" />
      <path d="M14.069 35.4C14.0676 33.8159 14.3448 32.244 14.888 30.756H9.694C7.12389 30.7589 4.65987 31.7812 2.84252 33.5985C1.02518 35.4159 0.00291141 37.8799 0 40.45L0 51.15C0.00158834 52.6923 0.614959 54.1709 1.70551 55.2615C2.79607 56.352 4.27472 56.9654 5.817 56.967H14.4C14.1789 56.3445 14.0659 55.6886 14.066 55.028L14.069 35.4Z" fill="#232750" />
      <path d="M39.5183 25.705H27.6413C25.0712 25.7079 22.6071 26.7302 20.7898 28.5475C18.9724 30.3648 17.9502 32.8289 17.9473 35.399V55.028C17.9473 55.5422 18.1516 56.0354 18.5152 56.3991C18.8788 56.7627 19.372 56.967 19.8863 56.967H47.2703C47.7845 56.967 48.2777 56.7627 48.6414 56.3991C49.005 56.0354 49.2093 55.5422 49.2093 55.028V35.399C49.2064 32.8294 48.1845 30.3658 46.3678 28.5486C44.5511 26.7313 42.0879 25.7087 39.5183 25.705Z" fill="#232750" />
      <path d="M33.5779 0C31.2725 0.000197843 29.019 0.683961 27.1022 1.96484C25.1854 3.24571 23.6914 5.06618 22.8092 7.19606C21.927 9.32594 21.6961 11.6696 22.1458 13.9307C22.5954 16.1918 23.7054 18.2688 25.3354 19.899C26.9654 21.5293 29.0422 22.6397 31.3033 23.0897C33.5643 23.5398 35.9079 23.3093 38.038 22.4274C40.168 21.5456 41.9887 20.0519 43.2699 18.1354C44.5511 16.2188 45.2353 13.9654 45.2359 11.66C45.2327 8.5689 44.0035 5.60528 41.8179 3.41935C39.6324 1.23342 36.669 0.003706 33.5779 0Z" fill="#232750" />
      <path d="M13.1055 10.867C11.3822 10.8678 9.69781 11.3795 8.26525 12.3374C6.83269 13.2953 5.71628 14.6565 5.05716 16.2488C4.39804 17.8411 4.22579 19.593 4.56219 21.2832C4.89858 22.9734 5.72853 24.5259 6.9471 25.7444C8.16568 26.963 9.71817 27.793 11.4083 28.1294C13.0985 28.4658 14.8505 28.2935 16.4428 27.6344C18.0351 26.9753 19.3962 25.8589 20.3541 24.4263C21.312 22.9937 21.8237 21.3093 21.8245 19.586C21.8219 17.2744 20.9024 15.0582 19.2679 13.4237C17.6333 11.7891 15.4171 10.8697 13.1055 10.867Z" fill="#232750" />
      <path d="M54.05 10.867C52.3256 10.867 50.6399 11.3784 49.2061 12.3364C47.7722 13.2945 46.6547 14.6562 45.9947 16.2494C45.3348 17.8426 45.1622 19.5957 45.4986 21.287C45.835 22.9783 46.6654 24.5319 47.8848 25.7513C49.1042 26.9706 50.6577 27.8011 52.3491 28.1375C54.0404 28.4739 55.7935 28.3012 57.3867 27.6413C58.9799 26.9814 60.3416 25.8638 61.2996 24.43C62.2577 22.9962 62.7691 21.3105 62.7691 19.586C62.7664 17.2744 61.847 15.0582 60.2124 13.4237C58.5778 11.7891 56.3617 10.8697 54.05 10.867Z" fill="#232750" />
    </svg>
  );
};

IconMembership.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconMembership.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconMembership;
