import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  customDescriptionValidators,
  autocompleteSearchRequired,
  autocompletePlaceSelected,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldSelect,
  HeadingFormFieldWrapper,
  LocationAutocompleteInputField,
} from '../../components';
import config from '../../config';

import css from './EditBusinessAdminForm.css';
import { ADMIN, LISTING_TYPES } from 'util/constants';
import Cross_Icon from '../../assets/TripExpertPage/Cross_Icon.svg';

const identity = v => v;

const TITLE_MAX_LENGTH = 60;
const PROVIDER_MAX_LENGTH = 30;
const YEARS_MAX_LENGTH = 3;

const titleMessages = {
  [LISTING_TYPES.LISTING]: 'EditBusinessFinancialForm.title',
  [LISTING_TYPES.ENQUIRY]: 'EditBusinessFinancialForm.enquiryTitle',
  [LISTING_TYPES.COMPANY]: 'EditBusinessFinancialForm.companyTitle',
  [LISTING_TYPES.CLASS]: 'EditBusinessFinancialForm.classTitle',
  [LISTING_TYPES.FACILITY]: 'EditBusinessFinancialForm.facilityTitle',
};

const titlePlaceholderMessages = {
  [LISTING_TYPES.LISTING]: 'EditBusinessFinancialForm.titlePlaceholder',
  [LISTING_TYPES.ENQUIRY]: 'EditBusinessFinancialForm.enquiryTitlePlaceholder',
  [LISTING_TYPES.COMPANY]: 'EditBusinessFinancialForm.companyTitlePlaceholder',
  [LISTING_TYPES.CLASS]: 'EditBusinessFinancialForm.classTitlePlaceholder',
  [LISTING_TYPES.FACILITY]: 'EditBusinessFinancialForm.facilityTitlePlaceholder',
};

const providerMessages = {
  [LISTING_TYPES.LISTING]: 'EditBusinessFinancialForm.provider',
  [LISTING_TYPES.ENQUIRY]: 'EditBusinessFinancialForm.enquiryProvider',
  [LISTING_TYPES.COMPANY]: 'EditBusinessFinancialForm.enquiryProvider',
  [LISTING_TYPES.CLASS]: 'EditBusinessFinancialForm.enquiryProvider',
  [LISTING_TYPES.FACILITY]: 'EditBusinessFinancialForm.enquiryProvider',
};

const providerPlaceholderMessages = {
  [LISTING_TYPES.LISTING]: 'EditBusinessFinancialForm.providerPlaceholder',
  [LISTING_TYPES.ENQUIRY]: 'EditBusinessFinancialForm.enquiryProviderPlaceholder',
  [LISTING_TYPES.COMPANY]: 'EditBusinessFinancialForm.enquiryProviderPlaceholder',
  [LISTING_TYPES.CLASS]: 'EditBusinessFinancialForm.providerPlaceholder',
  [LISTING_TYPES.FACILITY]: 'EditBusinessFinancialForm.providerPlaceholder',
};

const descriptionMessages = {
  [LISTING_TYPES.LISTING]: 'EditBusinessFinancialForm.description',
  [LISTING_TYPES.ENQUIRY]: 'EditBusinessFinancialForm.enquiryDescription',
  [LISTING_TYPES.COMPANY]: 'EditBusinessFinancialForm.companyDescription',
  [LISTING_TYPES.CLASS]: 'EditBusinessFinancialForm.description',
  [LISTING_TYPES.FACILITY]: 'EditBusinessFinancialForm.facilityDescription',
};

const descriptionPlaceholderMessages = {
  [LISTING_TYPES.LISTING]: 'EditBusinessFinancialForm.descriptionPlaceholder',
  [LISTING_TYPES.ENQUIRY]: 'EditBusinessFinancialForm.enquiryDescriptionPlaceholder',
  [LISTING_TYPES.COMPANY]: 'EditBusinessFinancialForm.companyDescriptionPlaceholder',
  [LISTING_TYPES.CLASS]: 'EditBusinessFinancialForm.classDescriptionPlaceholder',
  [LISTING_TYPES.FACILITY]: 'EditBusinessFinancialForm.facilityDescriptionPlaceholder',
};

class EditBusinessAdminFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: props.category,
      subCategories: [],
      categoryBasedCustomFields: [],
    };
  }

  componentDidMount() {
    this.handleCategoryChange(this.props.category);
  }

  handleCategoryChange = category => {
    const categoriesConfig = config.custom.categories;
    const categoryBasedCustomFieldsConfig = config.custom.categoryBasedFields;
    const subCategoryConfig = category ? categoriesConfig.find(cc => cc.key === category) : [];
    const subCategories = (subCategoryConfig && subCategoryConfig.subCategories) || [];
    const CustomFieldsConfig =
      category !== undefined && category !== '' && subCategories.length
        ? categoryBasedCustomFieldsConfig.find(cc => cc.key === category).fields
        : [];
    this.setState({
      category: category,
      subCategories: subCategories,
      categoryBasedCustomFields: CustomFieldsConfig,
    });
  };

  render() {
    const { listingType } = this.props;
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            subCategory,
            className,
            disabled,
            ready,
            handleSubmit,
            intl,
            invalid,
            pristine,
            saveActionMsg,
            updated,
            updateInProgress,
            fetchErrors,
            values,
          } = formRenderProps;

          const { updateListingError, createListingDraftError, showListingsError } =
            fetchErrors || {};
          const errorMessageUpdateListing = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
            </p>
          ) : null;

          // This error happens only on first tab (of EditListingWizard)
          const errorMessageCreateListingDraft = createListingDraftError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
            </p>
          ) : null;

          // //Business_bank_account
          // const businessBankAccountMessage = intl.formatMessage({
          //   id: 'EditBusinessAdminForm.bankAccount',
          // });

          // const businessBankAccountPlaceholderMessage = intl.formatMessage({
          //   id: 'EditBusinessAdminForm.bankAccountPlaceholder',
          // });

          // const businessBankAccountSupportMessage = intl.formatMessage({
          //   id: 'EditBusinessFinancialForm.bankAccountSupport',
          // });

          // const businessBankAccountRequiredMessage = intl.formatMessage({
          //   id: 'EditBusinessFinancialForm.bankAccountRequired',
          // });

          const contact1FirstNameLabel = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1FirstName',
          });

          const contact1FirstNamePlaceholderMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1FirstNamePlaceholder',
          });

          const contact1FirstNameSupportMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1FirstNameSupport',
          });

          const contact2FirstNameSupportMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact2FirstNameSupport',
          });
          
          const contact1FirstNameRequiredMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1FirstNameRequired',
          });
          
          const contact1LastNameLabel = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1LastName',
          });
          

          const contact1LastNamePlaceholderMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1LastNamePlaceholder',
          });

          const contact1LastNameSupportMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1LastNameSupport',
          });

          const contact2LastNameSupportMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact2LastNameSupport',
          });

          const contact1LastNameRequiredMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1LastNameRequired',
          });

          // Travel_business_Admin_Contact_Role
          const contact1RoleLabel = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1Role',
          });

          const contact1RolePlaceholderMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1RolePlaceholder',
          });

          const contact1RoleSupportMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1RoleSupport',
          });

          const contact1RoleRequiredMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1RoleRequired',
          });

          // Travel_business_Admin_Contact_Email
          const contact1EmailLabel = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1Email',
          });

          const contact1EmailPlaceholderMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1EmailPlaceholder',
          });

          const contact1EmailSupportMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1EmailSupport',
          });

          const contact1EmailRequiredMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1EmailRequired',
          });

          // Travel_business_Admin_Contact_Country_Code
          const contact1CountryCodeLabel = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1CountryCode',
          });

          const contact1CountryCodePlaceholderMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1CountryCodePlaceholder',
          });

          const contact1CountryCodeSupportMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1CountryCodeSupport',
          });

          const contact1CountryCodeRequiredMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1CountryCodeRequired',
          });

          // Travel_business_Admin_Contact_Role
          const contact1PhoneNoLabel = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1PhoneNo',
          });

          const contact1PhoneNoPlaceholderMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1PhoneNoPlaceholder',
          });

          const contact1PhoneNoSupportMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1PhoneNoSupport',
          });

          const contact1PhoneNoRequiredMessage = intl.formatMessage({
            id: 'EditBusinessAdminForm.contact1PhoneNoRequired',
          });

          const classes = classNames(css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const {
            Contact_1_first_name,
            Contact_1_last_name,
            Contact_1_business_role,
            Contact_1_email,
            Contact_1_country_code,
            Contact_1_phone_no,
          } = values;
          const contact1Ready = !!Contact_1_first_name && !!Contact_1_last_name && !!Contact_1_business_role && !!Contact_1_email && !!Contact_1_country_code && !!Contact_1_phone_no;
          const submitDisabled = invalid || disabled || submitInProgress || !contact1Ready;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.btnWrapper}>
                <div>
                  <h1 className={css.headingTitle}>
                    {ADMIN}
                  </h1>
                </div>
                <Button
                  rootClassName={css.topSaveButton}
                  type="submit"
                  inProgress={false}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </Button>
              </div>
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}

              <div className={css.formGroup}>
                <p className={css.paymentsInfo}>
                  <FormattedMessage
                    id="EditBusinessAdminForm.introductionText"
                    values={{
                      bold: header => <span className={css.boldText}>{header}</span>,
                    }}
                  />
                </p>

                <div className={css.sectionTitle}>
                  <strong>Authorised Contact 1</strong>
                </div>

                <FieldTextInput
                  id="Contact_1_first_name"
                  name="Contact_1_first_name"
                  className={css.title}
                  type="text"
                  label={contact1FirstNameLabel}
                  supportText={contact1FirstNameSupportMessage}
                  placeholder={contact1FirstNamePlaceholderMessage}
                  validate={composeValidators(required(contact1FirstNameRequiredMessage))}
                />

                <FieldTextInput
                  id="Contact_1_last_name"
                  name="Contact_1_last_name"
                  className={css.title}
                  type="text"
                  label={contact1LastNameLabel}
                  supportText={contact1LastNameSupportMessage}
                  placeholder={contact1LastNamePlaceholderMessage}
                  validate={composeValidators(required(contact1LastNameRequiredMessage))}
                />

                <FieldTextInput
                  id="Contact_1_business_role"
                  name="Contact_1_business_role"
                  className={css.title}
                  type="text"
                  label={contact1RoleLabel}
                  supportText={contact1RoleSupportMessage}
                  placeholder={contact1RolePlaceholderMessage}
                  validate={composeValidators(required(contact1RoleRequiredMessage))}
                />

                <FieldTextInput
                  id="Contact_1_email"
                  name="Contact_1_email"
                  className={css.title}
                  type="text"
                  label={contact1EmailLabel}
                  supportText={contact1EmailSupportMessage}
                  placeholder={contact1EmailPlaceholderMessage}
                  validate={composeValidators(required(contact1EmailRequiredMessage))}
                />

                <div className={css.phonenoContainer}>
                  <FieldTextInput
                    id="Contact_1_country_code"
                    name="Contact_1_country_code"
                    className={css.title}
                    type="text"
                    label={contact1CountryCodeLabel}
                    supportText={contact1CountryCodeSupportMessage}
                    placeholder={contact1CountryCodePlaceholderMessage}
                    validate={composeValidators(required(contact1CountryCodeRequiredMessage))}
                  />

                  <FieldTextInput
                    id="Contact_1_phone_no"
                    name="Contact_1_phone_no"
                    className={css.title}
                    type="text"
                    label={contact1PhoneNoLabel}
                    supportText={contact1PhoneNoSupportMessage}
                    placeholder={contact1PhoneNoPlaceholderMessage}
                    validate={composeValidators(required(contact1PhoneNoRequiredMessage))}
                  />
                </div>

                <div className={css.sectionTitle}>
                  <strong>Authorised Contact 2</strong>
                </div>

                <FieldTextInput
                  id="Contact_2_first_name"
                  name="Contact_2_first_name"
                  className={css.title}
                  type="text"
                  label={contact1FirstNameLabel}
                  supportText={contact2FirstNameSupportMessage}
                  placeholder={contact1FirstNamePlaceholderMessage}
                />

                <FieldTextInput
                  id="Contact_2_last_name"
                  name="Contact_2_last_name"
                  className={css.title}
                  type="text"
                  label={contact1LastNameLabel}
                  supportText={contact2LastNameSupportMessage}
                  placeholder={contact1LastNamePlaceholderMessage}
                />

                <FieldTextInput
                  id="Contact_2_business_role"
                  name="Contact_2_business_role"
                  className={css.title}
                  type="text"
                  label={contact1RoleLabel}
                  supportText={contact1RoleSupportMessage}
                  placeholder={contact1RolePlaceholderMessage}
                />

                <FieldTextInput
                  id="Contact_2_email"
                  name="Contact_2_email"
                  className={css.title}
                  type="text"
                  label={contact1EmailLabel}
                  supportText={contact1EmailSupportMessage}
                  placeholder={contact1EmailPlaceholderMessage}
                />

                <div className={css.phonenoContainer}>
                  <FieldTextInput
                    id="Contact_2_country_code"
                    name="Contact_2_country_code"
                    className={css.title}
                    type="text"
                    label={contact1CountryCodeLabel}
                    supportText={contact1CountryCodeSupportMessage}
                    placeholder={contact1CountryCodePlaceholderMessage}
                  />

                  <FieldTextInput
                    id="Contact_2_phone_no"
                    name="Contact_2_phone_no"
                    className={css.title}
                    type="text"
                    label={contact1PhoneNoLabel}
                    supportText={contact1PhoneNoSupportMessage}
                    placeholder={contact1PhoneNoPlaceholderMessage}
                  />
                </div>

                <Button
                  rootClassName={css.submitButton}
                  type="submit"
                  inProgress={false}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditBusinessAdminFormComponent.defaultProps = { className: null, fetchErrors: null };

EditBusinessAdminFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  listingType: string,
};

export default compose(injectIntl)(EditBusinessAdminFormComponent);
