import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCheckedShield.css';

const IconCheckedShield = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
        <svg className={classes} width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.8315 4.61849C30.7723 4.19301 30.4661 3.84303 30.0523 3.72799L16.789 0.0394706C16.5999 -0.0131569 16.4002 -0.0131569 16.211 0.0394706L2.94772 3.72799C2.53389 3.84303 2.22776 4.19287 2.16852 4.61849C2.09159 5.17165 0.336626 18.2417 4.838 24.7436C9.33405 31.2378 15.9667 32.902 16.2468 32.9697C16.33 32.9899 16.4149 32.9998 16.5 32.9998C16.5851 32.9998 16.67 32.9897 16.7532 32.9697C17.0335 32.902 23.6661 31.2378 28.162 24.7436C32.6634 18.2418 30.9084 5.1718 30.8315 4.61849ZM25.0531 12.2489L16.0062 21.2958C15.7957 21.5063 15.5196 21.6117 15.2437 21.6117C14.9678 21.6117 14.6917 21.5064 14.4812 21.2958L8.88758 15.7022C8.68527 15.5 8.57167 15.2257 8.57167 14.9397C8.57167 14.6537 8.68541 14.3793 8.88758 14.1771L9.99822 13.0665C10.4194 12.6455 11.1022 12.6453 11.5233 13.0665L15.2437 16.7869L22.4174 9.61307C22.6196 9.41075 22.894 9.29716 23.18 9.29716C23.466 9.29716 23.7403 9.41075 23.9425 9.61307L25.0531 10.7237C25.4743 11.1449 25.4743 11.8277 25.0531 12.2489Z" fill="#03c1f4"/>
    </svg>

  );
};

IconCheckedShield.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCheckedShield.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCheckedShield;
