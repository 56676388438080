import React, { useState, useEffect } from 'react';
import { slice } from 'lodash/array';
import { orderBy } from 'lodash'
import { uid } from 'react-uid';
import cns from 'classnames';
import css from './RecentTable.css';
import { ensurePrice } from 'util/data';
import { formatMoney } from 'util/currency';

function RecentTableRow({ intl, entry, onSelect, isSelected }) {
  const name =
    entry.transactions[0].attributes.protectedData?.kidsName ||
    entry.customer.attributes.profile.displayName;

  const userName = entry.customer.attributes.profile.publicData.userName;
  const countOfOrders = entry.transactions.length;

  const totalSpent = entry.transactions.reduce((acc, transaction) => {
    return acc + transaction.attributes.payinTotal.amount;
  }, 0);
  const currency = entry.transactions[0].attributes.payinTotal.currency;
  const totalMoney = ensurePrice({amount: totalSpent, currency: currency});

  return (
    <div className={css.row}>

      <div className={cns(css.cell, css.name, css.whatsappIconSection)}>
        {name}
      </div>
      <div className={cns(css.cell, css.phone)}>
        {userName}
      </div>
      <div className={cns(css.cell, css.orderNumberCol)}>
        <span className={css.listingNameContent}>{countOfOrders}</span>
      </div>
      <div className={cns(css.cell, css.totalSpendCol)}>
        <span className={css.listingNameContent}>{formatMoney(intl, totalMoney, 0, 2)}</span>
      </div>
    </div>
  );
}

export default function RecentTable({ intl, data, onAllSelectedClient, selectedBookings, onSelect, urlQueryParams }) {
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState(data);
  const perPage = 30;

  // const totalPages = Math.ceil(data.length / perPage);

  useEffect(() => {
    let sortedData = data
    if (urlQueryParams && data && urlQueryParams.sort && urlQueryParams.sort === "desc") {
      sortedData = orderBy(data, [entry => entry.listing.attributes.title !== null ? entry.listing.attributes.title.toLowerCase() : null], 'desc')
    }
    else if (urlQueryParams && data && urlQueryParams.sort && urlQueryParams.sort === "asc") {
      sortedData = orderBy(data, [entry => entry.listing.attributes.title !== null ? entry.listing.attributes.title.toLowerCase() : null], 'asc')
    }

    if (urlQueryParams && data && urlQueryParams.keywords) {
      let pattern = new RegExp(urlQueryParams.keywords, "i");
      const searchClients = sortedData.filter((entry) => {
        return entry.customer.attributes.profile.displayName.match(pattern);
      })
      sortedData = searchClients;
    }
    setFilterData(sortedData)
    /* eslint-disable-next-line */
  }, [data])

  const paginatedData = data => {
    const offset = (page - 1) * perPage;
    return slice(data, offset, offset + perPage);
  };

  return (
    <div className={css.wrapper}>
      <div className={css.table}>
        <div className={css.head}>
          <div className={cns(css.cell, css.nameCol)}>Name</div>
          <div className={cns(css.cell, css.phoneCol)}>Username</div>
          <div className={cns(css.cell, css.orderNumberCol)}>Number of Orders</div>
          <div className={cns(css.cell, css.totalSpendCol)}>Total Spend</div>
        </div>

        {paginatedData(filterData).map(entry => (
          <RecentTableRow
            intl={intl}
            key={uid(entry)}
            isSelected={selectedBookings.some(selectedTx => selectedTx.id.uuid === entry.transactions[0].id.uuid)}
            onSelect={onSelect}
            entry={entry} />
        ))}
      </div>

      {/* <PaginationButtons page={page} totalPages={totalPages} onPageClick={setPage} /> */}
    </div>
  );
}
