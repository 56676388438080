import React from 'react';
import cns from 'classnames';
import PropTypes from 'prop-types';
import css from 'components/PackagesModal/PackagesModal.css';
import config from 'config';
import { FormattedMessage, intlShape } from 'util/reactIntl';
import { PrimaryButton, HorizontalScroll } from 'components';

export const USAGE = {
  listingPage: 'listingPage',
};

const Package = ({
  numberOfCredits,
  unformattedPrice,
  description,
  handlePackageBuy,
  inProgress,
  disabled,
  index,
}) => {
  const currencyConfig = config.currencyConfig;
  const params = {
      amount: unformattedPrice,
      credits: numberOfCredits,
    };
  return (
    <div className={css.standoutInlinePackage}>
      <div className={cns(css.card, { [css.whiteCard]: (index % 2) === 1 })}
        onClick={ typeof window !== "undefined" && window.innerWidth < 550 ? () => {
          handlePackageBuy(params);
        } : null}
      >
        <div className={css.productWrap}>
          <div className={css.timeInfoContainer}>
            <div className={css.svgWrap}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                <path d="M18.7991 3.58963C18.5825 3.39682 18.2769 3.3562 18.0208 3.48622L14.1023 5.47584L10.4254 1.05439C10.2894 0.890829 10.092 0.796875 9.88445 0.796875C9.67686 0.796875 9.47951 0.890829 9.34348 1.05439L5.66665 5.47581L1.74807 3.48619C1.49196 3.3562 1.18641 3.39679 0.969789 3.58959C0.753172 3.78239 0.666669 4.09076 0.749485 4.37494L3.47062 13.7106C3.56232 14.0252 3.84048 14.2403 4.15574 14.2403H15.6132C15.9284 14.2403 16.2066 14.0252 16.2982 13.7107L19.0194 4.37498C19.1022 4.0908 19.0157 3.78243 18.7991 3.58963Z" fill="white" />
              </svg>
            </div>
            <div className={css.pacakageHour}>
              <span >{numberOfCredits} <FormattedMessage id="PackagesModal.hours" /></span>
            </div>
          </div>
          <div className={css.packagePrice}>
            <span>{unformattedPrice / 100} {currencyConfig.currency}</span>
          </div>



          <ul className={css.product}>
            <li className={css.main}><div className={css.description}>{description}</div>
            </li>
          </ul>
          <div className={css.productBtn}>
            <PrimaryButton
              className={css.packagePurchase}
              spinnerClassName={css.spinner}
              params={params}
              inProgress={inProgress}
              disabled={disabled}
              onClick={() => {
                handlePackageBuy(params);
              }}
            >
              <FormattedMessage id="PackagesModal.startNow" />
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Packages = ({
  usage,
  packages,
  packageAttr,
  withPayment,
  sellerId,
  buyerId,
  intl,
  listing,
  handleBuy,
  creditRequestInProgress,
  showInline,
}) => {
  const containerClasses = cns({
    [css.packagesContainer]: showInline !== true,
    [css.inlinePackagesContainer]: showInline === true,
    [css.listingPackages]: usage === USAGE.listingPage,
  });

  return (
    <div className={containerClasses}>
      <HorizontalScroll isSmallScrollArrow={true} className={css.horizontalScroll} scrollClassName={css.scrollControl}>
        {packages && packages.length > 0
          ? packages.map((p, index) => {
            const price = p.price;
            const disabled =
              packageAttr && (packageAttr.amount === price) && packageAttr.credits === p.sessions && creditRequestInProgress;

            return (
              <Package
                intl={intl}
                index={index}
                key={`Package${index}`}
                numberOfCredits={p.sessions}
                unformattedPrice={price}
                description={p.description}
                listing={listing}
                sellerId={sellerId}
                buyerId={buyerId}
                standOut={index === 1}
                handlePackageBuy={handleBuy}
                disabled={disabled}
                inProgress={disabled}
                inline={showInline}
                withPayment={withPayment}
              />
            );
          })
          : null}
      </HorizontalScroll>
    </div>
  );
};

Packages.propTypes = {
  intl: intlShape,
  listing: PropTypes.object,
  packages: PropTypes.array,
  packageAttr: PropTypes.array,
  withPayment: PropTypes.bool,
  sellerId: PropTypes.string,
  buyerId: PropTypes.string,
  handleBuy: PropTypes.func,
  showInline: PropTypes.bool,
};
