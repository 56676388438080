import React from 'react';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import css from './CompanyPage.css';
import ActionBarMaybe from 'containers/ListingPage/ActionBarMaybe';
import CompanyPageCommonSection from './CompanyPageCommonSection';

function CompanyPageContent(props) {
  const {
    intl,
    topbar,
    isOwnListing,
    currentListing,
    listingId,
    listingSlug,
    listingType,
    listingTab,
    title,
    otherAuthorsListings,
    authorsProductListings,
    allListingReviews,
    listingVariant,
    currencyConversionRate,
  } = props;

  const { publicData, description, metadata } = currentListing.attributes;

  const reviews = []; //TODO get the reviews of the associated users

  //TODO this is a temporary fix, the safe solution is rewrite the .duck file to follow the original ST structure ( big job )
  if (currentListing?.images?.length === 0) {
    currentListing.images = currentListing?.attributes?.images || [];
  }
  // --------------------------

  //TODO this is a temporary fix, the safe solution is rewrite the
  //EditListingDescriptionPanel file to follow the original ST listing structure ( big job )
  const noLocation = !currentListing?.attributes?.geolocation;
  const noAddress = !publicData?.location;
  const publicDataGeolocation = publicData?.geolocation;
  const publicDataAddress = publicData?.Travel_business_address?.selectedPlace;
  if (
    currentListing?.attributes &&
    noLocation &&
    noAddress &&
    publicDataGeolocation &&
    publicDataAddress
  ) {
    currentListing.attributes.geolocation = publicDataGeolocation;
    currentListing.attributes.publicData.location = publicDataAddress;
  }

  const backgroundImage = currentListing.images[0];
  const profileImage = currentListing.images[1];

  return (
    <LayoutSingleColumn className={css.pageRoot}>
      <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
      <LayoutWrapperMain>
        <div>
          <div className={css.actionBarWrapper}>
            <ActionBarMaybe
              isOwnListing={isOwnListing}
              listing={currentListing}
              routeName="EditCompanyPage"
              editParams={{
                id: listingId.uuid,
                slug: listingSlug,
                type: listingType,
                tab: listingTab,
              }}
            />
          </div>
          <CompanyPageCommonSection
            currentListing={currentListing}
            backgroundImage={backgroundImage}
            profileImage={profileImage}
            intl={intl}
            listingVariant={listingVariant}
            title={title}
            review={reviews}
            authorsProductListings={authorsProductListings}
            otherAuthorsListings={otherAuthorsListings}
            allListingReviews={allListingReviews}
            currencyConversionRate={currencyConversionRate}
          />
        </div>
      </LayoutWrapperMain>
      <LayoutWrapperFooter>
        <Footer />
      </LayoutWrapperFooter>
    </LayoutSingleColumn>
  );
}

export default CompanyPageContent;
