import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from 'components/IconQuotes/IconQuotes';

const IconQuotes = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg color="Turquoise"
     xmlns="http://www.w3.org/2000/svg" className={classes} width="27" height="29" viewBox="0 0 24 24">
        <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z"/>
    </svg>
  );
};

IconQuotes.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconQuotes.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconQuotes;
