import {
  denormalisedEntities,
  denormalisedResponseEntities,
  ensureOwnListing,
  entityRefs,
  updatedEntities,
} from '../util/data';
import moment from 'moment';
import { storableError } from '../util/errors';
import {
  TRANSITION_CANCEL,
  TRANSITION_CANCEL_READY_TO_COMPLETE,
  TRANSITION_DECLINE,
  TRANSITION_ENQUIRE,
  TRANSITION_REVIEW_1_BY_PROVIDER,
  TRANSITION_SKIP_1_BY_PROVIDER,
  TRANSITIONS,
  transitionsToRequested,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_SET_PRICE,
  TRANSITION_UPDATE_PRICE,
} from '../util/transaction';
import { LISTING_STATE_DRAFT } from '../util/types';
import * as log from '../util/log';
import * as analyticsUtils from '../analytics/utils';
import { authInfo } from './Auth.duck';
import { stripeAccountCreateSuccess } from './stripeConnectAccount.duck';
import config from 'config';
import axios from 'axios';
import { wasTransactionCreatedBeforeTimestamp } from 'util/transaction';
import { addMarketplaceEntities } from 'ducks/marketplaceData.duck';
import { get } from 'util/api';

// ================ Action types ================ //

export const CURRENT_USER_SHOW_REQUEST = 'app/user/CURRENT_USER_SHOW_REQUEST';
export const CURRENT_USER_SHOW_SUCCESS = 'app/user/CURRENT_USER_SHOW_SUCCESS';
export const CURRENT_USER_SHOW_ERROR = 'app/user/CURRENT_USER_SHOW_ERROR';

export const CLEAR_CURRENT_USER = 'app/user/CLEAR_CURRENT_USER';

export const FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_HAS_COMPANY_LISTINGS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_COMPANY_LISTINGS_REQUEST';
export const FETCH_CURRENT_USER_HAS_COMPANY_LISTINGS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_COMPANY_LISTINGS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_COMPANY_LISTINGS_ERROR =
  'app/user/FETCH_CURRENT_USER_HAS_COMPANY_LISTINGS_ERROR';

export const FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST';
export const FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_NOTIFICATIONS_ERROR =
  'app/user/FETCH_CURRENT_USER_NOTIFICATIONS_ERROR';

export const FETCH_CURRENT_USER_HAS_ORDERS_REQUEST =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_REQUEST';
export const FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS =
  'app/user/FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS';
export const FETCH_CURRENT_USER_HAS_ORDERS_ERROR = 'app/user/FETCH_CURRENT_USER_HAS_ORDERS_ERROR';

export const SEND_VERIFICATION_EMAIL_REQUEST = 'app/user/SEND_VERIFICATION_EMAIL_REQUEST';
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'app/user/SEND_VERIFICATION_EMAIL_SUCCESS';
export const SEND_VERIFICATION_EMAIL_ERROR = 'app/user/SEND_VERIFICATION_EMAIL_ERROR';

export const FETCH_COMPLETED_LISTINGS_STARTED = 'app/user/FETCH_COMPLETED_LISTINGS_STARTED';
export const FETCH_COMPLETED_LISTINGS_SUCCESS = 'app/user/FETCH_COMPLETED_LISTINGS_SUCCESS';
export const FETCH_COMPLETED_LISTINGS_FAILED = 'app/user/FETCH_COMPLETED_LISTINGS_FAILED';

export const FETCH_ACCEPTED_PACKAGE_STARTED = 'app/user/FETCH_ACCEPTED_PACKAGE_STARTED';
export const FETCH_ACCEPTED_PACKAGE_SUCCESS = 'app/user/FETCH_ACCEPTED_PACKAGE_SUCCESS';
export const FETCH_ACCEPTED_PACKAGE_FAILED = 'app/user/FETCH_ACCEPTED_PACKAGE_FAILED';

export const LAST_BOOKING_REVIEWED = 'app/user/LAST_BOOKING_REVIEWED';
export const ACCEPTED_PACKAGE_NOTE_SEEN = 'app/user/ACCEPTED_PACKAGE_NOTE_SEEN';

export const FETCH_RECENT_TRANSACTIONS_STARTED = 'app/user/FETCH_RECENT_TRANSACTIONS_STARTED';
export const FETCH_RECENT_TRANSACTIONS_SUCCESS = 'app/user/FETCH_RECENT_TRANSACTIONS_SUCCESS';
export const FETCH_RECENT_TRANSACTIONS_FAILED = 'app/user/FETCH_RECENT_TRANSACTIONS_FAILED';

export const FETCH_COMPANY_STARTED = 'app/user/FETCH_COMPANY_STARTED';
export const FETCH_COMPANY_SUCCESS = 'app/user/FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAILED = 'app/user/FETCH_COMPANY_FAILED';

export const SAVE_PREFERRED_CURRENCY_REQUEST = 'app/user/SAVE_PREFERRED_CURRENCY_REQUEST';
export const SAVE_PREFERRED_CURRENCY_SUCCESS = 'app/user/SAVE_PREFERRED_CURRENCY_SUCCESS';
export const SAVE_PREFERRED_CURRENCY_ERROR = 'app/user/SAVE_PREFERRED_CURRENCY_ERROR';

export const FETCH_CURRENCY_CONVERSION_RATE_REQUEST = 'app/user/FETCH_CONVERSION_RATE_REQUEST';
export const FETCH_CURRENCY_CONVERSION_RATE_SUCCESS = 'app/user/FETCH_CONVERSION_RATE_SUCCESS';
export const FETCH_CURRENCY_CONVERSION_RATE_ERROR = 'app/user/FETCH_CONVERSION_RATE_ERROR';

// ================ Reducer ================ //

const mergeCurrentUser = (oldCurrentUser, newCurrentUser) => {
  const { id: oId, type: oType, attributes: oAttr, ...oldRelationships } = oldCurrentUser || {};
  const { id, type, attributes, ...relationships } = newCurrentUser || {};

  // Passing null will remove currentUser entity.
  // Only relationships are merged.
  // TODO figure out if sparse fields handling needs a better handling.
  return newCurrentUser === null
    ? null
    : oldCurrentUser === null
    ? newCurrentUser
    : { id, type, attributes, ...oldRelationships, ...relationships };
};
const PREFERRED_CURRENCY = 'preferredCurrency';

const initialState = {
  currentUser: null,
  currentUserLoaded: false,
  currentUserShowError: null,
  currentUserHasListings: false,
  currentUserHasListingsError: null,
  currentUserHasCompanyListings: false,
  currentUserHasCompanyListingsError: null,
  currentUserNotificationCount: 0,
  currentUserSaleEnquiriesNotificationCount: 0,
  currentUserOrderEnquiriesNotificationCount: 0,
  currentUserPackagesNotificationCount: 0,
  currentUserNotificationCountError: null,
  currentUserHasOrders: null, // This is not fetched unless unverified emails exist
  currentUserHasOrdersError: null,
  sendVerificationEmailInProgress: false,
  sendVerificationEmailError: null,
  currentUserListing: null,
  currentUserListingFetched: false,

  // last booking review
  lastCompleteTransaction: null,
  lastBookingReviewDone: false,
  sendLastBookingReviewInProgress: false,
  sendLastBookingReviewError: null,

  // last accepted package
  lastAcceptedPackage: null,
  lastAcceptedPackageNoteSeen: false,

  //latest transactions
  recentTransactions: [],
  recentTransactionsLoading: false,

  //user company
  createdCompanyRef: [],
  preferredCurrency: 'USD',
  preferredCurrencySaving: false,
  preferredCurrencyError: null,
  currencyConversionRate: 1,
  conversionRateSuccess: false,
  conversionRateLoading: false,
  conversionRateError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CURRENT_USER_SHOW_REQUEST:
      return { ...state, currentUserShowError: null };
    case CURRENT_USER_SHOW_SUCCESS:
      return {
        ...state,
        currentUser: mergeCurrentUser(state.currentUser, payload),
        currentUserLoaded: true,
      };
    case CURRENT_USER_SHOW_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        currentUserShowError: payload,
        currentUserLoaded: false,
      };

    case CLEAR_CURRENT_USER:
      return {
        ...state,
        currentUser: null,
        currentUserLoaded: false,
        currentUserShowError: null,
        currentUserHasListings: false,
        currentUserHasListingsError: null,
        currentUserHasCompanyListings: false,
        currentUserHasCompanyListingsError: null,
        currentUserNotificationCount: 0,
        currentUserNotificationCountError: null,
        currentUserListing: null,
        currentUserListingFetched: false,
        currentUserCompanyListing: null,
        currentUserCompanyListingFetched: false,
      };

    case FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST:
      return { ...state, currentUserHasListingsError: null };
    case FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS:
      return {
        ...state,
        currentUserHasListings: payload.hasListings,
        currentUserListing: payload.listing,
        currentUserListingFetched: true,
      };
    case FETCH_CURRENT_USER_HAS_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasListingsError: payload };

    case FETCH_CURRENT_USER_HAS_COMPANY_LISTINGS_REQUEST:
      return { ...state, currentUserHasCompanyListingsError: null };
    case FETCH_CURRENT_USER_HAS_COMPANY_LISTINGS_SUCCESS:
      return {
        ...state,
        currentUserHasCompanyListings: payload.hasPublishedCompanyListings,
        currentUserCompanyListing: payload.companyListing,
        currentUserCompanyListingFetched: true,
      };
    case FETCH_CURRENT_USER_HAS_COMPANY_LISTINGS_ERROR:
      return { ...state, currentUserHasCompanyListingsError: payload };

    case FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST:
      return { ...state, currentUserNotificationCountError: null };
    case FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        currentUserNotificationCount: payload.transactions.length,
        currentUserSaleEnquiriesNotificationCount:
          payload.saleEnquiries.length < 100 ? payload.saleEnquiries.length : 100,
        currentUserOrderEnquiriesNotificationCount:
          payload.orderEnquiries.length < 100 ? payload.orderEnquiries.length : 100,
        currentUserPackagesNotificationCount:
          payload.packages.length < 100 ? payload.packages.length : 100,
      };
    case FETCH_CURRENT_USER_NOTIFICATIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserNotificationCountError: payload };

    case FETCH_CURRENT_USER_HAS_ORDERS_REQUEST:
      return { ...state, currentUserHasOrdersError: null };
    case FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS:
      return { ...state, currentUserHasOrders: payload.hasOrders };
    case FETCH_CURRENT_USER_HAS_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, currentUserHasOrdersError: payload };

    case SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        sendVerificationEmailInProgress: true,
        sendVerificationEmailError: null,
      };
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
      };
    case SEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        sendVerificationEmailInProgress: false,
        sendVerificationEmailError: payload,
      };

    case FETCH_COMPLETED_LISTINGS_SUCCESS:
      return { ...state, lastCompleteTransaction: payload };
    case LAST_BOOKING_REVIEWED:
      return { ...state, lastBookingReviewDone: true };

    case FETCH_ACCEPTED_PACKAGE_SUCCESS:
      return { ...state, lastAcceptedPackage: payload };
    case ACCEPTED_PACKAGE_NOTE_SEEN:
      return { ...state, lastAcceptedPackageNoteSeen: true };

    case FETCH_RECENT_TRANSACTIONS_STARTED:
      return { ...state, recentTransactionsLoading: true };
    case FETCH_RECENT_TRANSACTIONS_SUCCESS:
      return { ...state, recentTransactions: payload, recentTransactionsLoading: false };
    case FETCH_RECENT_TRANSACTIONS_FAILED:
      return { ...state, recentTransactionsLoading: false };

    case FETCH_COMPANY_SUCCESS:
      return { ...state, createdCompanyRef: payload };

    case SAVE_PREFERRED_CURRENCY_REQUEST:
      return { ...state, preferredCurrencySaving: true };
    case SAVE_PREFERRED_CURRENCY_SUCCESS:
      return { ...state, preferredCurrencySaving: false, preferredCurrency: payload };
    case SAVE_PREFERRED_CURRENCY_ERROR:
      return { ...state, preferredCurrencySaving: false, preferredCurrencyError: payload };

    case FETCH_CURRENCY_CONVERSION_RATE_REQUEST:
      return { ...state, conversionRateLoading: true };
    case FETCH_CURRENCY_CONVERSION_RATE_SUCCESS:
      return {
        ...state,
        conversionRateLoading: false,
        currencyConversionRate: payload,
        conversionRateSuccess: true,
      };
    case FETCH_CURRENCY_CONVERSION_RATE_ERROR:
      return { ...state, conversionRateLoading: false, conversionRateError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const hasCurrentUserErrors = state => {
  const { user } = state;
  return (
    user.currentUserShowError ||
    user.currentUserHasListingsError ||
    user.currentUserNotificationCountError ||
    user.currentUserHasOrdersError
  );
};

export const verificationSendingInProgress = state => {
  return state.user.sendVerificationEmailInProgress;
};

// ================ Action creators ================ //

export const currentUserShowRequest = () => ({ type: CURRENT_USER_SHOW_REQUEST });

export const currentUserShowSuccess = user => ({
  type: CURRENT_USER_SHOW_SUCCESS,
  payload: user,
});

export const currentUserShowError = e => ({
  type: CURRENT_USER_SHOW_ERROR,
  payload: e,
  error: true,
});

export const clearCurrentUser = () => ({ type: CLEAR_CURRENT_USER });

const fetchCurrentUserHasListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasListingsSuccess = (hasListings, listing) => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_SUCCESS,
  payload: { hasListings, listing },
});

const fetchCurrentUserHasListingsError = e => ({
  type: FETCH_CURRENT_USER_HAS_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasCompanyListingsRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_COMPANY_LISTINGS_REQUEST,
});

export const fetchCurrentUserHasCompanyListingsSuccess = (
  hasPublishedCompanyListings,
  companyListing
) => ({
  type: FETCH_CURRENT_USER_HAS_COMPANY_LISTINGS_SUCCESS,
  payload: { hasPublishedCompanyListings, companyListing },
});

const fetchCurrentUserHasCompanyListingsError = e => ({
  type: FETCH_CURRENT_USER_HAS_COMPANY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserNotificationsRequest = () => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_REQUEST,
});

export const fetchCurrentUserNotificationsSuccess = payload => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_SUCCESS,
  payload: payload,
});

const fetchCurrentUserNotificationsError = e => ({
  type: FETCH_CURRENT_USER_NOTIFICATIONS_ERROR,
  error: true,
  payload: e,
});

const fetchCurrentUserHasOrdersRequest = () => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_REQUEST,
});

export const fetchCurrentUserHasOrdersSuccess = hasOrders => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_SUCCESS,
  payload: { hasOrders },
});

const fetchCurrentUserHasOrdersError = e => ({
  type: FETCH_CURRENT_USER_HAS_ORDERS_ERROR,
  error: true,
  payload: e,
});

export const sendVerificationEmailRequest = () => ({
  type: SEND_VERIFICATION_EMAIL_REQUEST,
});

export const sendVerificationEmailSuccess = () => ({
  type: SEND_VERIFICATION_EMAIL_SUCCESS,
});

export const sendVerificationEmailError = e => ({
  type: SEND_VERIFICATION_EMAIL_ERROR,
  error: true,
  payload: e,
});

export const fetchCompletedBookingsStart = () => ({ type: FETCH_COMPLETED_LISTINGS_STARTED });
export const fetchCompletedBookingsSuccess = payload => ({
  type: FETCH_COMPLETED_LISTINGS_SUCCESS,
  payload,
});
export const fetchCompleteBookingsError = error => ({
  type: FETCH_COMPLETED_LISTINGS_FAILED,
  error,
});

export const setLastBookingReviewed = () => ({ type: LAST_BOOKING_REVIEWED });

export const fetchAcceptedPackageStart = () => ({ type: FETCH_ACCEPTED_PACKAGE_STARTED });
export const fetchAcceptedPackageSuccess = payload => ({
  type: FETCH_ACCEPTED_PACKAGE_SUCCESS,
  payload,
});
export const fetchAcceptedPackageError = error => ({
  type: FETCH_ACCEPTED_PACKAGE_FAILED,
  error,
});

export const setAcceptedPackageNoteSeen = () => ({ type: ACCEPTED_PACKAGE_NOTE_SEEN });

export const fetchRecentTransactionsStart = () => ({ type: FETCH_RECENT_TRANSACTIONS_STARTED });
export const fetchRecentTransactionsSuccess = payload => ({
  type: FETCH_RECENT_TRANSACTIONS_SUCCESS,
  payload,
});
export const fetchRecentTransactionsFailed = () => ({ type: FETCH_RECENT_TRANSACTIONS_FAILED });

export const fetchCompanySuccess = payload => ({
  type: FETCH_COMPANY_SUCCESS,
  payload,
});

export const savePreferredCurrencyRequest = () => ({ type: SAVE_PREFERRED_CURRENCY_REQUEST });
export const savePreferredCurrencySuccess = payload => ({
  type: SAVE_PREFERRED_CURRENCY_SUCCESS,
  payload,
});
export const savePreferredCurrencyError = payload => ({
  type: SAVE_PREFERRED_CURRENCY_ERROR,
  payload,
});

export const fetchCurrencyConversionRateRequest = () => ({
  type: FETCH_CURRENCY_CONVERSION_RATE_REQUEST,
});
export const fetchCurrencyConversionRateSuccess = payload => ({
  type: FETCH_CURRENCY_CONVERSION_RATE_SUCCESS,
  payload,
});
export const fetchCurrencyConversionRateFailed = payload => ({
  type: FETCH_CURRENCY_CONVERSION_RATE_ERROR,
  payload,
});

// ================ Thunks ================ //

export const setCurrencyConversionRate = preferredCurrency => async (dispatch, getState, sdk) => {
  dispatch(fetchCurrencyConversionRateRequest());
  try {
    const conversionRate = await get('/api/currencies/conversion-rate', {
      from: 'USD',
      to: preferredCurrency,
    });
    if (localStorage.getItem(PREFERRED_CURRENCY)) {
      sessionStorage.setItem('rate', JSON.stringify(conversionRate));
    }
    dispatch(fetchCurrencyConversionRateSuccess(conversionRate));
  } catch (error) {
    dispatch(fetchCurrencyConversionRateFailed(error));
  }
};

export const savePreferredCurrency = preferredCurrency => (dispatch, getState, sdk) => {
  dispatch(savePreferredCurrencyRequest());
  try {
    dispatch(savePreferredCurrencySuccess(preferredCurrency));
    localStorage.setItem(PREFERRED_CURRENCY, preferredCurrency);
  } catch (error) {
    console.log('Failed to save the preferred currency.');
    dispatch(savePreferredCurrencyError(error));
  }
};

export const fetchCurrentUserHasListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasListingsRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasListingsSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has
    // listings, we only need at most one result.
    authorId: currentUser.id.uuid,
    pub_listing_type: 'listing',
    page: 1,
    per_page: 1,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      const hasListings = response.data.data && response.data.data.length > 0;
      const listing = hasListings ? response.data.data[0] : null;

      const hasPublishedListings =
        hasListings &&
        ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;
      dispatch(fetchCurrentUserHasListingsSuccess(!!hasPublishedListings, listing));
    })
    .catch(e => dispatch(fetchCurrentUserHasListingsError(storableError(e))));
};

export const fetchCurrentUserHasCompanyListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasCompanyListingsRequest());
  const { currentUser } = getState().user;

  if (!currentUser) {
    dispatch(fetchCurrentUserHasCompanyListingsSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    // Since we are only interested in if the user has
    // listings, we only need at most one result.
    authorId: currentUser.id.uuid,
    pub_listing_type: 'company',
    page: 1,
    per_page: 1,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      const hasCompanyListings = response.data.data && response.data.data.length > 0;
      const companyListing = hasCompanyListings ? response.data.data[0] : null;

      const hasPublishedCompanyListings =
        hasCompanyListings &&
        ensureOwnListing(response.data.data[0]).attributes.state !== LISTING_STATE_DRAFT;
      dispatch(
        fetchCurrentUserHasCompanyListingsSuccess(!!hasPublishedCompanyListings, companyListing)
      );
    })
    .catch(e => dispatch(fetchCurrentUserHasCompanyListingsError(storableError(e))));
};

export const fetchCurrentUserHasOrders = () => (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserHasOrdersRequest());

  if (!getState().user.currentUser) {
    dispatch(fetchCurrentUserHasOrdersSuccess(false));
    return Promise.resolve(null);
  }

  const params = {
    only: 'order',
    page: 1,
    per_page: 1,
  };

  return sdk.transactions
    .query(params)
    .then(response => {
      const hasOrders = response.data.data && response.data.data.length > 0;
      dispatch(fetchCurrentUserHasOrdersSuccess(!!hasOrders));
    })
    .catch(e => dispatch(fetchCurrentUserHasOrdersError(storableError(e))));
};

// Notificaiton page size is max (100 items on page)
const NOTIFICATION_PAGE_SIZE = 100;

export const fetchCurrentUserNotifications = userUuid => async (dispatch, getState, sdk) => {
  dispatch(fetchCurrentUserNotificationsRequest());

  const apiQueryParams = {
    only: 'sale',
    last_transitions: transitionsToRequested,
    page: 1,
    per_page: NOTIFICATION_PAGE_SIZE,
  };

  const packagesUrl =
    config.serverBaseUrl +
    config.creditRequestEndPoint +
    '/' +
    userUuid +
    '/' +
    config.sellerPackageRequestsEndPoint;
  const packagesHeaders = {
    headers: {
      Authorization: `Token token=${config.serverToken}`,
    },
  };

  const saleEnquiriesQueryParams = {
    only: 'sale',
    lastTransitions: [TRANSITION_ENQUIRE, TRANSITION_CONFIRM_PAYMENT],
    include: [],
    'fields.transaction': ['lastTransition', 'lastTransitionedAt'],
    page: 1,
    per_page: 100,
  };

  const orderEnquiriesQueryParams = {
    only: 'order',
    lastTransitions: [
      TRANSITION_SET_PRICE,
      TRANSITION_UPDATE_PRICE,
    ],
    include: [],
    'fields.transaction': ['lastTransition', 'lastTransitionedAt'],
    page: 1,
    per_page: 100,
  };

  try {
    const [packagesRes, transactionsRes] = await Promise.allSettled([
      // axios.get(packagesUrl, packagesHeaders),
      sdk.transactions.query(apiQueryParams),
    ]);

    const saleEnquiriesresponse = await sdk.transactions.query(saleEnquiriesQueryParams);
    const saleEnquiries = saleEnquiriesresponse.data.data.filter(
      enq =>
        moment().format('YYYY-MM-DD') ===
        moment(enq.attributes.lastTransitionedAt).format('YYYY-MM-DD')
    );

    const orderEnquiriesresponse = await sdk.transactions.query(orderEnquiriesQueryParams);
    const orderEnquiries = orderEnquiriesresponse.data.data.filter(
      enq =>
        moment().format('YYYY-MM-DD') ===
        moment(enq.attributes.lastTransitionedAt).format('YYYY-MM-DD')
    );

    const packages = packagesRes?.value?.data?.length > 0
        ? packagesRes.value.data.filter(p => p.status === 'pending')
        : [];
    const transactions = transactionsRes?.value?.data ?? [];

    dispatch(
      fetchCurrentUserNotificationsSuccess({
        packages,
        transactions,
        saleEnquiries,
        orderEnquiries,
      })
    );
  } catch (e) {
    dispatch(fetchCurrentUserNotificationsError(storableError(e)));
  }
};

export const fetchCompletedBookings = () => async (dispatch, getState, sdk) => {
  dispatch(fetchCompletedBookingsStart());
  try {
    const response = await sdk.transactions.query({
      only: 'order',
      lastTransitions: [TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_SKIP_1_BY_PROVIDER],
      include: ['listing', 'provider', 'customer'],
    });

    if (response.data.data && response.data.data.length) {
      const lastItem = response.data.data[0];

      const stateEntities = updatedEntities({}, response.data);
      const denormalizedTx = denormalisedEntities(stateEntities, [
        {
          id: lastItem.id,
          type: lastItem.type,
        },
      ]);

      const tx = denormalizedTx[0];
      const isTxOld = wasTransactionCreatedBeforeTimestamp(tx, '2020-09-24 15:30:00');
      const isValidTx =
        !tx.listing.attributes.deleted && !tx.provider.attributes.deleted && !isTxOld;
      if (isValidTx) dispatch(fetchCompletedBookingsSuccess(denormalizedTx[0]));
    }
  } catch (e) {
    console.error(e);
    dispatch(fetchCompleteBookingsError(e));
  }
};

export const fetchAcceptedPackage = buyerId => async (dispatch, getState, sdk) => {
  dispatch(fetchAcceptedPackageStart());
  try {
    const url =
      config.serverBaseUrl +
      config.creditRequestEndPoint +
      '/' +
      buyerId +
      '/' +
      config.buyerPackageRequestsEndPoint;
    const headers = {
      headers: {
        Authorization: `Token token=${config.serverToken}`,
      },
    };
    const response = await axios.get(url, headers);

    const lastBoughtPackage = response.data[0];
    const isValidPackage =
      !!lastBoughtPackage &&
      lastBoughtPackage.status === 'accepted' &&
      lastBoughtPackage.used_credits === 0;
    if (isValidPackage) dispatch(fetchAcceptedPackageSuccess(lastBoughtPackage));
  } catch (e) {
    console.error(e);
  }
};

export const fetchRecentTransactions = () => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchRecentTransactionsStart());
    const excludeTransition = [
      TRANSITION_ENQUIRE,
      TRANSITION_DECLINE,
      TRANSITION_CANCEL_READY_TO_COMPLETE,
      TRANSITION_CANCEL,
    ];

    const response = await sdk.transactions.query({
      only: 'order',
      lastTransitions: TRANSITIONS.filter(t => !excludeTransition.includes(t)),
      include: ['listing', 'booking'],
      page: 1,
      per_page: 10,
    });

    const refs = response.data.data.map(({ id, type }) => ({ id, type }));
    const stateEntities = updatedEntities({}, response.data);
    const recentTransactions = denormalisedEntities(stateEntities, refs);

    dispatch(fetchRecentTransactionsSuccess(recentTransactions));
  } catch (e) {
    console.error(e);
    dispatch(fetchRecentTransactionsFailed(e));
  }
};

export const fetchUserCompany = userUuid => async (dispatch, getState, sdk) => {
  try {
    const response = await sdk.listings.query({
      authorId: userUuid,
      pub_listing_type: 'company',
      per_page: 1,
    });

    const companyRef = entityRefs(response.data.data);
    dispatch(fetchCompanySuccess(companyRef));
    dispatch(addMarketplaceEntities(response));
  } catch (e) {
    console.error(e);
  }
};

export const updateListingStatus = listingId => async (dispatch, getState, sdk) => {
  try {
    const bookings = await sdk.bookings.query({
      listingId: listingId,
      start: moment()
        .subtract(90, 'days')
        .toISOString(),
      end: moment().toISOString(),
      state: 'accepted',
      perPage: 1,
    });

    const listingReviews = await sdk.reviews.query({
      listingId: listingId,
      type: 'ofProvider',
      state: 'public',
    });

    const totalBookings = bookings.data.meta.totalItems;
    const totalReviews = listingReviews.data.meta.totalItems;
    const totalRating = listingReviews.data.data.reduce(
      (acc, review) => acc + review.attributes.rating,
      0
    );
    const rating = totalRating / totalReviews;

    const params = {
      id: listingId,
      publicData: {
        total_bookings: totalBookings,
        total_reviewers: totalReviews,
        rating: isNaN(rating) ? 0 : rating,
        total_ratings: totalRating,
      },
    };
    await sdk.ownListings.update(params);
  } catch (e) {
    console.error(e);
  }
};

export const updateOwnListingStatus = () => async (dispatch, getState, sdk) => {
  const state = getState();
  const currentUserId = state.user.currentUser ? state.user.currentUser.id.uuid : '';
  if (currentUserId === '') return;

  try {
    const responseListings = await sdk.listings.query({
      authorId: currentUserId,
      pub_listing_type: ['listing', 'class', 'facility'],
      include: ['author'],
    });
    const owningListings = responseListings.data.data;
    owningListings.forEach(listing => {
      dispatch(updateListingStatus(listing.id.uuid));
    });
  } catch (e) {
    console.error(e);
  }
};

export const fetchCurrentUser = (params = null) => (dispatch, getState, sdk) => {
  dispatch(currentUserShowRequest());
  const { isAuthenticated } = getState().Auth;

  if (!isAuthenticated) {
    // Make sure current user is null
    dispatch(currentUserShowSuccess(null));
    return Promise.resolve({});
  }

  const parameters = params || {
    include: ['profileImage', 'stripeAccount'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  };

  return sdk.currentUser
    .show(parameters)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.show response');
      }
      const currentUser = entities[0];

      // Save stripeAccount to store.stripe.stripeAccount if it exists
      if (currentUser.stripeAccount) {
        dispatch(stripeAccountCreateSuccess(currentUser.stripeAccount));
      }

      // Set current user id to the logger and analytics.
      try {
        log.setUserId(currentUser.id.uuid);
        // Note: analytics events, such as initial "page view", may have
        // already been sent before we could set the user ID.
        analyticsUtils.setUserId(
          currentUser
            ?.attributes
            ?.profile
            ?.metadata
            ?.lyfshortUserId
        );
      } catch(error) {
        console.log(error);
      }

      dispatch(currentUserShowSuccess(currentUser));
      return currentUser;
    })
    .then(currentUser => {
      dispatch(fetchCurrentUserHasListings());
      dispatch(fetchCurrentUserHasCompanyListings());
      dispatch(fetchCurrentUserNotifications(currentUser.id.uuid));
      dispatch(fetchUserCompany(currentUser.id.uuid));
      if (!currentUser.attributes.emailVerified) {
        dispatch(fetchCurrentUserHasOrders());
      }
      dispatch(fetchCompletedBookings());
      // dispatch(fetchAcceptedPackage(currentUser.id.uuid));
      dispatch(fetchRecentTransactions());

      // Make sure auth info is up to date
      dispatch(authInfo());
    })
    .catch(e => {
      // Make sure auth info is up to date
      dispatch(authInfo());
      log.error(e, 'fetch-current-user-failed');
      dispatch(currentUserShowError(storableError(e)));
    });
};

export const sendVerificationEmail = () => (dispatch, getState, sdk) => {
  if (verificationSendingInProgress(getState())) {
    return Promise.reject(new Error('Verification email sending already in progress'));
  }
  dispatch(sendVerificationEmailRequest());
  return sdk.currentUser
    .sendVerificationEmail()
    .then(() => dispatch(sendVerificationEmailSuccess()))
    .catch(e => dispatch(sendVerificationEmailError(storableError(e))));
};
