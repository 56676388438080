import React, { useState } from 'react';
import css from './AutocompleteMultiselectField.css';
import { Field } from 'react-final-form';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const AutocompleteMultiselectField = props => {
  const { value, id, options, handleSubmitSearch, selectedInterests } = props;

  const initialValue = value
    ? value.map(v => {
        return {
          key: v,
          label: options.find(o => o.key === v)?.label,
        };
      })
    : [];

  const [customOptions, setCustomOptions] = useState(initialValue);

  return (
    <div className={css.wrapper}>
      <Autocomplete
        multiple
        id={id}
        limitTags={1}
        options={options.filter(o => !customOptions.find(g => g.key === o.key))}
        getOptionLabel={option => option.label}
        defaultValue={[]}
        renderInput={params => (
          <TextField {...params} variant="standard" placeholder={'Interests'} />
        )}
        value={customOptions}
        onChange={(event, newValue) => {
          setCustomOptions(newValue);
          const convertedValue = newValue.map(v => v.key);
          handleSubmitSearch('interests', convertedValue);
        }}
      />
    </div>
  );
};

export default AutocompleteMultiselectField;
