import React from 'react';
import css from './LandingPageCategoryListSection.css';
import { SectionPromotedListings } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

const LandingPageCategoryListSection = props => {
  const { intl, listingData, listingAllReviews, currencyConversionRate, fetchPromotedListingReviewInProgress } = props;

  return (
    <>
      <div className={css.title}>
        <FormattedMessage id="CategoryListSection.title" />
      </div>
      <div className={css.description}>
        <FormattedMessage id="CategoryListSection.description" />
      </div>

      <SectionPromotedListings
        intl={intl}
        promotedListings={listingData}
        allListingReview={listingAllReviews}
        fetchPromotedListingReviewInProgress={fetchPromotedListingReviewInProgress}
        currencyConversionRate={currencyConversionRate}
      />
    </>
  );
};
export default LandingPageCategoryListSection;
