import { txState } from 'components/InboxItem/helpers';
import React, { useState } from 'react';
import css from './table.module.css';
import GroupRow from 'containers/InboxPage/components/GroupableRow/GroupRow';
import { TransactionStateButton } from 'components';
import { FormattedMessage } from 'react-intl';
import { FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';

const VisibleSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#00ab99' : '#00ab99',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#00ab99',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? 'grey' : 'grey',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const Table = ({ transactions, intl }) => {
  const [filter, setFilter] = useState('all');
  const [expertFilter, setExpertFilter] = useState('');
  const [actionRequired, setActionRequired] = useState(false);

  transactions = transactions.filter(tx => tx !== null);

  const filteredTransactions =
    filter === 'all'
      ? transactions
      : filter === 'Agetn Y/N'
      ? transactions.filter(tx => txState(intl, tx, 'sale')?.state === 'Yes Agent' || txState(intl, tx, 'sale')?.state === 'No Agent')
      : transactions.filter(tx => txState(intl, tx, 'sale')?.state === filter);

  const STATE_BTNS = [
    {
      state: 'all',
      label: <FormattedMessage id={'InboxPage.stateLabelAll'} />,
    },
    {
      state: 'pending',
      label: <FormattedMessage id={'InboxPage.stateLabelRequested'} />,
    },
    {
      state: 'accepted',
      label: <FormattedMessage id={'InboxPage.stateLabelAccepted'} />,
    },
    {
      state: 'delivered',
      label: <FormattedMessage id={'InboxPage.stateLabelCollaborating'} />,
    },
    {
      state: 'completed',
      label: <FormattedMessage id={'InboxPage.stateLabelCompleted'} />,
    },
    {
      state: 'agent',
      label: <FormattedMessage id={'InboxPage.stateLabelAgent'} />,
    },
    {
      state: 'canceled',
      label: <FormattedMessage id={'InboxPage.stateLabelCanceled'} />,
    },
  ];

  const expertDisplayNames = Array.from(new Set(transactions.map(tx => tx?.provider.attributes.profile.displayName)));

  const filteredTransactionsByDisplayName = expertFilter
    ? filteredTransactions
        .filter(tx => tx?.provider.attributes.profile.displayName === expertFilter)
        .sort((a, b) => a?.provider.attributes.profile.displayName.localeCompare(b?.provider.attributes.profile.displayName))
    : filteredTransactions;

  const filterTransactions = status => {
    setFilter(status);
  };

  const filterExpertTransactions = displayName => {
    setExpertFilter(displayName);
  };

  if (!transactions || transactions?.length === 0) {
    return <p>Your experts don't have any orders yet</p>;
  }

  const headContent = (
    <>
      <div className={css.weekdayRow}>
        <div className={css.weekdayName}>
          <span className={css.bookingNumber}>
            bookings <span>-</span> {moment({}).format('dddd DD MMM')}{' '}
          </span>
        </div>
        <FormControlLabel
          control={
            <VisibleSwitch
              sx={{ mx: 2 }}
              defaultChecked={actionRequired}
              onChange={(e) => {
                const checkStatus = e.target.checked;
                setActionRequired(prevState => ({
                  ...prevState,
                  actionRequired: checkStatus,
                }))
              }}
            />
          }
          className={css.formControlClass}
          label="Action Required"
          labelPlacement='start'
          style={{ width: '300px', color: 'black' }}
        />
      </div>
    </>
  );

  return (
    <div>
      <h2 className={css.title}>
        <div className={css.expersSelectField}>
          <select value={expertFilter} onChange={e => filterExpertTransactions(e.target.value)}>
            <option value="">All experts</option>
            {expertDisplayNames.map((displayName, index) => (
              <option key={index} value={displayName}>
                {displayName}
              </option>
            ))}
          </select>
        </div>
      </h2>
      <div className={css.stateBtnsWrap}>
        {STATE_BTNS.map(btn => (
          <TransactionStateButton
            key={btn.state}
            onClick={() => filterTransactions(btn.state)}
            isActive={btn.state === filter}
            {...btn}
          />
        ))}
      </div>
      <ul className={css.itemList}>
        <div className={css.weekday}>
          {headContent}
          <GroupRow
            type={'admin'}
            entries={transactions}
            intl={intl}
          />
        </div>
      </ul>
    </div>
  );
};

export default Table;
