import moment from 'moment';
import { uid } from 'react-uid';
import { memoize } from 'lodash/function';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  TRANSITION_ACCEPT,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_EXPIRE_PAYMENT,
  TRANSITION_SET_DELIVERED,
} from 'util/transaction';

const { UUID } = sdkTypes;

export const getTransactions = (bookings, listing, date) => {
  if (!listing || !date) {
    return [];
  }

  const transactionsToShow = bookings
    .filter(booking => {
      const isTrue = moment(booking.attributes.start).isBetween(
        moment(date).subtract(1, 'days'),
        moment(date).add(8, 'weeks'),
        'day'
      );
      return isTrue;
    })
    .map(booking => ({
      ...booking.transaction,
      booking: {
        attributes: booking.attributes,
        id: booking.id,
        type: booking.type,
      },
      listing: listing,
    }));

  return transactionsToShow;
};

export const normalizeListingTransactionsResponse = res => {
  const normalisedData = res.data.data.map(t => ({
    ...t,
    id: new UUID(t.id),
    relationships: {
      transaction: {
        ...t.relationships.transaction,
        data: {
          ...t.relationships.transaction.data,
          id: new UUID(t.relationships.transaction.data.id),
        },
      },
    },
  }));
  const normalisedIncluded = res.data.included
    ? res.data.included.map(ti => {
        return ti.relationships &&
          ti.relationships.profileImage &&
          ti.relationships.profileImage.data
          ? {
              ...ti,
              id: new UUID(ti.id),
              relationships: {
                profileImage: {
                  data: {
                    ...ti.relationships.profileImage.data,
                    id: new UUID(ti.relationships.profileImage.data.id),
                  },
                },
              },
            }
          : ti.relationships &&
            ti.relationships.customer &&
            ti.relationships.customer.data &&
            ti.relationships.listing &&
            ti.relationships.listing.data &&
            ti.relationships.provider &&
            ti.relationships.provider.data
          ? {
              ...ti,
              id: new UUID(ti.id),
              relationships: {
                customer: {
                  ...ti.relationships.customer,
                  data: {
                    ...ti.relationships.customer.data,
                    id: new UUID(ti.relationships.customer.data.id),
                  },
                },
                listing: {
                  ...ti.relationships.listing,
                  data: {
                    ...ti.relationships.listing.data,
                    id: new UUID(ti.relationships.listing.data.id),
                  },
                },
                provider: {
                  ...ti.relationships.provider,
                  data: {
                    ...ti.relationships.provider.data,
                    id: new UUID(ti.relationships.provider.data.id),
                  },
                },
              },
            }
          : { ...ti, id: new UUID(ti.id) };
      })
    : [];
  const normalisedRes = {
    ...res,
    data: { ...res.data, data: normalisedData, included: normalisedIncluded },
  };
  return normalisedRes;
};

export const doesDayHaveBookings = (date, bookings) => {
  if (!date || !bookings || !bookings.length) {
    return false;
  }

  return bookings.some(booking => {
    return moment(booking.attributes.start).isSame(date, 'day');
  });
};

export const getDateAvailabilityPlan = (availabilityPlan, date) => {
  if (!availabilityPlan || !availabilityPlan.entries) {
    return [];
  }

  const currentDatePlan = availabilityPlan.entries.find(entry => {
    const currentDateName = moment(date)
      .format('ddd')
      .toLowerCase();
    return entry.dayOfWeek === currentDateName;
  });

  return currentDatePlan || null;
};

export const sortTransactionsByDay = memoize(transactions => {
  const uniqueDates = new Set();
  transactions.forEach(item => {
    const date = moment(item.booking.attributes.start).format('YYYY-MM-DD');
    uniqueDates.add(date);
  });

  const arrayUniqueDates = Array.from(uniqueDates).sort();

  return arrayUniqueDates.map(date => {
    const txs = transactions.filter(tx => {
      return moment(date).isSame(tx.booking.attributes.start, 'day');
    });

    return {
      id: uid(date),
      date: date,
      transactions: txs,
    };
  });
});

export const addAvailabilityPlanForDays = memoize((transactionsByDay, listing) => {
  if (!transactionsByDay || !transactionsByDay.length) {
    return [];
  }

  return transactionsByDay.map(tx => {
    return {
      ...tx,
      availabilityPlan: getDateAvailabilityPlan(listing.attributes.availabilityPlan, tx.date),
    };
  });
});

export const filterBookings = bookings => {
  const filteredBookings = bookings.filter(booking => {
    const lastTransition = booking.transaction.attributes.lastTransition;

    return [
      TRANSITION_EXPIRE_PAYMENT,
      TRANSITION_CONFIRM_PAYMENT,
      TRANSITION_ACCEPT,
      TRANSITION_SET_DELIVERED,
    ].includes(lastTransition);
  });

  const filteredBookingsRefs = filteredBookings.map(({ id, type }) => ({
    type,
    id,
  }));

  return [filteredBookings, filteredBookingsRefs];
};
