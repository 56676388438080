import React from 'react';
import { withRouter } from 'react-router-dom';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { orderBy } from 'lodash';
import { loadData } from './PartnerMemberSalesPage.duck';
import {
  Page,
  PaginationLinks,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
  UserDisplayName,
  AvatarMedium,
} from 'components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import config from '../../config';
import css from './PartnerMemberSalesPage.css';
import { withViewport } from 'util/contextHelpers';
import moment from 'moment';
import GroupRow from 'containers/InboxPage/components/GroupableRow/GroupRow';
import { isScrollingDisabled } from 'ducks/UI.duck';

export class PartnerMemberSalesPageComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      unitType,
      currentUser,
      fetchInProgress,
      fetchPartnerMemberSalesError,
      intl,
      pagination,
      params,
      scrollingDisabled,
      transactions,
      manageDisableScrolling,
    } = this.props;
    const { provider } = transactions[0] ?? {};

    const { state } = params;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);

    const toGroupableTxItem = () => {
      let entries = [];
      const { history } = this.props;
      const urlQueryParams = parse(history.location.search) || {};
      const sortBy = urlQueryParams.sort ? urlQueryParams.sort : 'default';
      const groupBy = urlQueryParams.group ? urlQueryParams.group : 'listing-name';

      entries = orderBy(transactions, 'booking.attributes.displayStart', 'desc').reduce(
        (acc, item) => {
          const index = acc.findIndex(txArr => {
            return txArr.some(txItem => {
              return (
                moment(
                  txItem && txItem.booking ? txItem.booking.attributes.displayStart : null
                ).format('dddd MMMM Do') ===
                moment(item && item.booking ? item.booking.attributes.displayStart : null).format(
                  'dddd MMMM Do'
                )
              );
            });
          });
          index >= 0 ? acc[index].push(item) : acc.push([item]);
          return acc;
        },
        []
      );

      return (
        entries &&
        entries.map((entry, index) => (
          <GroupRow
            key={`M${index}`}
            unitType={unitType}
            state={state}
            type={'admin'}
            entries={entry}
            intl={intl}
            index={index}
            onManageDisableScrolling={manageDisableScrolling}
            currentUser={ensuredCurrentUser}
            sortBy={sortBy}
            groupBy={groupBy}
          />
        ))
      );
    };

    const error = fetchPartnerMemberSalesError ? (
      <p className={css.error}>
        {intl.formatMessage({ id: 'PartnerMemberSalesPage.fetchFailed' })}
      </p>
    ) : null;

    const noResults = !fetchInProgress ? (
      <li key="noResults" className={css.noResults}>
        {intl.formatMessage({ id: 'PartnerMemberSalesPage.noItems' })}
      </li>
    ) : null;

    const headContent = provider && (
      <div className={css.providerInfoHeading}>
        <AvatarMedium className={css.avatarUser} user={provider} />
        <UserDisplayName user={provider} intl={intl} className={css.userDisplayName} />
        <p className={css.userDisplayName}>Orders</p>
      </div>
    );

    const pagingLinks =
      pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="PartnerMemberSalesPage"
          pagePathParams={params}
          pagination={pagination}
        />
      ) : null;

    return (
      <Page title={'PartnerMemberSalesPage'} scrollingDisabled={scrollingDisabled}>
        <LayoutSideNavigation containerClassName={css.LayoutSideContainer}>
          <LayoutWrapperTopbar>
            <TopbarContainer
              className={css.topbar}
              mobileRootClassName={css.mobileTopbar}
              desktopClassName={css.desktopTopbar}
              currentPage={'PartnerMemberSalesPage'}
            />
          </LayoutWrapperTopbar>

          <LayoutWrapperMain className={css.rightPanel}>
            {error}
            <ul className={css.itemList}>
              <div className={css.weekday}>
                {headContent}
                {!fetchInProgress ? (
                  toGroupableTxItem()
                ) : (
                  <li className={css.listItemsLoading}>
                    <IconSpinner />
                  </li>
                )}
                {transactions?.length === 0 && noResults}
              </div>
            </ul>
            {pagingLinks}
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSideNavigation>
      </Page>
    );
  }
}

PartnerMemberSalesPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserListing: null,
  currentUserHasOrders: null,
  fetchPartnerMemberSalesError: null,
  pagination: null,
  sendVerificationEmailError: null,
};

PartnerMemberSalesPageComponent.propTypes = {
  unitType: propTypes.bookingUnitType,
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.listing,
  fetchInProgress: bool.isRequired,
  fetchPartnerMemberSalesError: propTypes.error,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    transactionRefs,
    isLoading,
  } = state.PartnerMemberSalesPage;

  const { currentUser, currentUserListing } = state.user;

  return {
    currentUser,
    currentUserListing,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, transactionRefs),
    isLoading,
  };
};

const PartnerMemberSalesPage = compose(
  withRouter,
  connect(mapStateToProps),
  withViewport,
  injectIntl
)(PartnerMemberSalesPageComponent);

PartnerMemberSalesPage.loadData = loadData;

export default PartnerMemberSalesPage;
