import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingOverviewForm } from '../../forms';
import config from '../../config';
import { CurrentUserContext } from 'contexts/currentUserContext';

import css from './EditListingOverviewPanel.css';
import { getCombinedLocationSearchString } from 'util/general';

const EditListingOverviewPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    listingType,
    currentUser,
  } = props;

  const authorDisplayName = currentUser.attributes.profile.displayName;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const {
    tagline,
    detailed,
    interests,
    suggestion_category,
    other_languages,
    primary_language,
  } = (publicData ?? {});

  return (
    // <div className={classes}>
    <div className={css.scope}>
      <CurrentUserContext.Consumer>
        {currentUser => {
          return (
            <EditListingOverviewForm
              className={css.form}
              initialValues={{
                title,
                summary: description,
                tagline,
                detailed,
                interests,
                suggestion_category,
                other_languages : other_languages?.filter(Boolean),
                primary_language,
              }}
              saveActionMsg={submitButtonText}
              onSubmit={values => {
                const {
                  tagline,
                  summary,
                  detailed,
                  interests,
                  suggestion_category,
                  other_languages,
                  primary_language,
                } = values;
                const otherLangArray = other_languages
                  ? other_languages.filter(Boolean)
                  : [];
                const search_languages = [...otherLangArray, primary_language];
                const combinedLocationSearch = getCombinedLocationSearchString(listing);
                const updateValues = {
                  title: authorDisplayName.trim(),
                  description: summary.trim(),
                  publicData: {
                    tagline,
                    detailed,
                    interests,
                    suggestion_category: suggestion_category
                      ? suggestion_category.filter(s => !Array.isArray(s))
                      : [],
                    other_languages: other_languages
                      ? other_languages.filter(Boolean)
                      : [],
                    primary_language,
                    //values saved only for search purposes
                    search_languages,
                    combinedLocationSearch,
                  },
                };

                onSubmit(updateValues);
              }}
              onChange={onChange}
              disabled={disabled}
              ready={ready}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              category={publicData.category}
              subCategory={publicData.subCategory}
              activityLevel={config.custom.activityLevel}
              currentUser={currentUser}
              listingType={listingType}
            />
          );
        }}
      </CurrentUserContext.Consumer>
    </div>
  );
};

EditListingOverviewPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingOverviewPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  listingType: string,
};

export default EditListingOverviewPanel;
