import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconMessage.css';

const IconMessage = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.4898 4.11888e-06C9.57997 -0.00282404 7.70246 0.493315 6.04288 1.43937C4.38331 2.38543 2.99915 3.74865 2.02723 5.39425C1.05531 7.03986 0.529299 8.91087 0.501189 10.8223C0.473079 12.7338 0.943847 14.6195 1.86695 16.293L0.54221 20.713C0.497519 20.8623 0.488315 21.02 0.515335 21.1735C0.542354 21.327 0.604848 21.4721 0.697829 21.5971C0.79081 21.7221 0.911703 21.8237 1.05086 21.8936C1.19002 21.9636 1.34358 22 1.4993 22C1.59672 21.9999 1.69362 21.9857 1.78703 21.958L6.20283 20.632C7.67168 21.4429 9.3065 21.9065 10.982 21.9873C12.6575 22.068 14.3293 21.7637 15.8692 21.0978C17.409 20.4318 18.7762 19.4219 19.8658 18.1453C20.9554 16.8687 21.7386 15.3594 22.1554 13.733C22.5722 12.1066 22.6115 10.4062 22.2702 8.76229C21.929 7.11834 21.2163 5.57441 20.1867 4.24879C19.1572 2.92317 17.8382 1.85105 16.3307 1.11458C14.8232 0.378118 13.1673 -0.00314724 11.4898 4.11888e-06ZM11.4898 20C9.85177 19.9978 8.24567 19.5461 6.84622 18.694C6.68964 18.5998 6.5104 18.55 6.32772 18.55C6.23058 18.5496 6.13393 18.5637 6.04099 18.592L2.98989 19.509L3.90502 16.454C3.94474 16.321 3.95638 16.1812 3.93918 16.0435C3.92198 15.9057 3.87632 15.7731 3.80511 15.654C2.77934 13.9526 2.34552 11.959 2.57145 9.98475C2.79737 8.01046 3.6703 6.1667 5.05385 4.74154C6.43739 3.31637 8.25362 2.3901 10.2187 2.10744C12.1839 1.82478 14.1872 2.20165 15.9157 3.17919C17.6442 4.15672 19.0005 5.67983 19.7726 7.51055C20.5448 9.34126 20.6894 11.3764 20.1838 13.2981C19.6782 15.2198 18.5508 16.9197 16.9779 18.1322C15.405 19.3447 13.4751 20.0015 11.4898 20Z" />
    </svg>
  );
};

IconMessage.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconMessage.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconMessage;
