import React, { Component } from 'react';
import { array, bool, func, number, oneOf, object, shape, string } from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { isAnyFilterActive, isMainSearchTypeKeywords } from '../../util/search';
import { connect } from 'react-redux';
import omit from 'lodash/omit';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import unionWith from 'lodash/unionWith';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';
import { TopbarContainer } from '../../containers';
import {
  searchListings,
  searchMapListings,
  setActiveListing,
  fetchAllListingReviews,
} from './SearchPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';
import MainPanel from './MainPanel';
import css from './SearchPage.css';
import { LISTING_TYPES } from 'util/constants';
import FiltersSection from './FiltersSection/FiltersSection';
// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 50;
const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const DRAG_LAYOUT_BREAKPOINT = 1024;
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

const LISTING_DRAG_MAP_SHOW_OFFSET = 550;
const LISTING_DRAG_LIMIT_OFFSET = 120;

const validUrlQueryParamsFromProps = props => {
  const { location, productFilterConfig } = props;
  // eslint-disable-next-line no-unused-vars
  const { mapSearch, page, ...searchInURL } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  return validURLParamsForExtendedData(searchInURL, productFilterConfig);
};

const cleanSearchFromConflictingParams = (searchParams, sortConfig, productFilterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    productFilterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: false,
      isMobileModalOpen: false,
      currentQueryParams: validUrlQueryParamsFromProps(props),
      listingDrawerPos: { x: 0, y: 0 },
      isMapIconVisible: false,
      dataCurrentPageResultIds: [],
      keyWordValue: '',
    };

    this.searchMapListingsInProgress = false;

    this.filters = this.filters.bind(this);
    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);

    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.initialValues = this.initialValues.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);
  }

  handleResetAll(e) {
    this.resetAll(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  scrollFunction = () => {
    if (typeof window !== 'undefined') {
      const { isMapIconVisible, listingDrawerPos } = this.state;
      const { y } = listingDrawerPos;
      const scrolled = window.scrollY;
      const totalHeight = window.innerHeight;
      const isScrolledEnough = scrolled > totalHeight;
      const dragLimit = totalHeight - LISTING_DRAG_MAP_SHOW_OFFSET;

      if (!isScrolledEnough && y > dragLimit && isMapIconVisible) {
        this.setState({ isMapIconVisible: false });
      } else if (isScrolledEnough && !isMapIconVisible) {
        this.setState({ isMapIconVisible: true });
      }
    }
  };

  componentDidMount() {
    typeof window !== 'undefined' && window.addEventListener('scroll', this.scrollFunction);
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    if (urlQueryParams && urlQueryParams.keywords && this.state.keyWordValue === '') {
      this.setState({ keyWordValue: urlQueryParams.keywords });
    }

    const {currentPageResultIds, otherCurrentPageResultIds} = this.props;

    this.props.onFetchAllListingReviews([...currentPageResultIds, ...otherCurrentPageResultIds]);
  }

  componentDidUpdate() {
    const { currentPageResultIds, otherCurrentPageResultIds } = this.props;
    if (
      this.props.currentPageResultIds.length !== 0 &&
      JSON.stringify(this.state.dataCurrentPageResultIds) !==
      JSON.stringify(this.props.currentPageResultIds)
    ) {
      this.props.onFetchAllListingReviews([...currentPageResultIds, ...otherCurrentPageResultIds]);
      this.setState({ dataCurrentPageResultIds: this.props.currentPageResultIds });
    }
  }

  componentWillUnmount() {
    typeof window !== 'undefined' && window.removeEventListener('scroll', this.scrollFunction);
  }

  filters() {
    const {
      priceFilterConfig,
      keywordFilterConfig,
      listingTypeConfig,
    } = this.props;

    // Note: "category" filters are not actually filtering anything by default.
    // Currently, if you want to use them, we need to manually configure them to be available
    // for search queries. Read more from extended data document:
    // https://www.sharetribe.com/docs/references/extended-data/#data-schema

    return {
      typeFilter: {
        paramName: 'pub_listing_type',
        options: listingTypeConfig,
      },
      priceFilter: {
        paramName: 'price',
        config: priceFilterConfig,
      },
      keywordFilter: {
        paramName: 'keywords',
        config: keywordFilterConfig,
      },
    };
  }

  onClickDraggableHandler = () => {
    if (this.state.isMapOpen === true) {
      this.setState({ listingDrawerPos: { x: 0, y: 0 }, isMapOpen: false });
    }
  };

  renderDraggableHandler = isMobileLayout =>
    isMobileLayout ? (
      <div className={css.draggableHandler}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="9"
          viewBox="0 0 16 9"
          fill="none"
        >
          <path
            d="M1.61133 7.36719H14.387"
            stroke="#233045"
            strokeWidth="1.68101"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.61133 1.65137H14.387"
            stroke="#233045"
            strokeWidth="1.68101"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ) : null;

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath = this.props.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location } = this.props;

      // parse query parameters, including a custom attribute named certificate
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...rest,
        ...validFilterParams(rest, this.filters()),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
      var mapElement = document.getElementById('search-map');
      const y = mapElement.getBoundingClientRect().top;
      if (typeof window !== 'undefined') window.scrollTo({ top: y - 150 });
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  onControlledDragStop = (e, position) => {
    const { y } = position;
    this.setState({ listingDrawerPos: { x: 0, y } });
  };
  onControlledDrag = (e, position) => {
    if (typeof window !== 'undefined') {
      const { isMapIconVisible } = this.state;
      const { y } = position;
      const dragLimit = window.innerHeight - LISTING_DRAG_MAP_SHOW_OFFSET;

      if (y > dragLimit && isMapIconVisible) {
        this.setState({ isMapIconVisible: false });
      } else if (y <= dragLimit && !isMapIconVisible) {
        this.setState({ isMapIconVisible: true });
      }
    }
  };

  onMapButtonClick = () => {
    if (typeof window !== 'undefined') {
      const yPos = window.innerHeight - LISTING_DRAG_LIMIT_OFFSET;
      window.scrollTo(0, 0);
      this.setState({
        listingDrawerPos: { x: 0, y: yPos },
        isMapOpen: true,
        isMapIconVisible: false,
      });
    }
  };

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, sortConfig, productFilterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(searchParams, sortConfig, productFilterConfig);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, productFilterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = productFilterConfig.map(f => f.queryParamNames);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  initialValues(queryParamNames, isLiveEdit) {
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    // Query parameters that are in state (user might have not yet clicked "Apply")
    const currentQueryParams = this.state.currentQueryParams;

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = paramName => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
      return hasQueryParamInState && !isLiveEdit ? currentQueryParam : urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce((acc, paramName) => {
        return { ...acc, [paramName]: getInitialValue(paramName) };
      }, {})
      : {};
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, sortConfig, productFilterConfig } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, keywords } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };
        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            ...keywordsMaybe,
            address,
            bounds,
          },
        };
      };
      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(
            searchParams,
            sortConfig,
            productFilterConfig
          );
          search.pub_listing_type = 'product';
          history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  onClickModeItemHandler = value => {
    const { history } = this.props;
    const urlParams = parse(history.location.search);
    let queryParams = { ...urlParams, pub_listing_mode: value };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };

  render() {
    const {
      intl,
      listings,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      onActivateListing,
      listingAllReviews,
      fetchReviewsInProgress,
      allAuthorNumberOfListings,
      history,
      otherListings,
      currencyConversionRate,
    } = this.props;

    if (typeof window === 'undefined') {
      return null;
    }
    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, sort, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const filters = this.filters();
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filters);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(pickSearchParamsOnly(searchParams, filters));
    const searchParamsAreInSync = urlQueryString === paramsQueryString;
    let validQueryParams = validURLParamsForExtendedData(searchInURL, filters);

    const { address, bounds, origin } = searchInURL || {};
    const { title, description, schema } = createSearchResultSchema(listings, address, intl);

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync;
    const hasPaginationInfo = !!pagination && !pagination.paginationUnsupported;
    const listingsLength = listings ? listings.length : 0;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : listingsLength;

    const hasNoResult = listingsAreLoaded && totalItems === 0;
    const hasSearchParams = location.search?.length > 0;
    const noResultsInfo = hasNoResult ? (
      <div className={css.noSearchResults}>
        <FormattedMessage id="SearchPage.noResults" />
        <br />
        {hasSearchParams ? (
          <button className={css.resetAllFiltersButton} onClick={e => this.resetAll(e)}>
            <FormattedMessage id={'SearchPage.resetAllFilters'} />
          </button>
        ) : null}
      </div>
    ) : null;

    const isPartnersSearch = urlQueryParams?.pub_listing_type === LISTING_TYPES.COMPANY;
    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer
              className={topbarClasses}
              currentPage="SearchPage"
              currentSearchParams={urlQueryParams}
            />
          </LayoutWrapperTopbar>

          <LayoutWrapperMain>
            <div className={css.pageWrapper}>
              {!isPartnersSearch && (
                <FiltersSection
                  currentQueryParams={validUrlQueryParamsFromProps(this.props)}
                  history={history}
                />
              )}
              <section className={css.sectionMapContainer}>
                <div className={css.subContainer}>
                  <div className={css.draggableWrapper}>
                    <MainPanel
                      urlQueryParams={validQueryParams}
                      sort={sort}
                      listings={listings.filter(l => {
                        if (isPartnersSearch) {
                          return true;
                        } else {
                          return l?.attributes?.price?.amount > 0;
                        }
                      })}
                      otherListings={otherListings}
                      searchInProgress={searchInProgress}
                      fetchReviewsInProgress={fetchReviewsInProgress}
                      searchListingsError={searchListingsError}
                      searchParamsAreInSync={searchParamsAreInSync}
                      onActivateListing={onActivateListing}
                      onManageDisableScrolling={onManageDisableScrolling}
                      onOpenModal={this.onOpenMobileModal}
                      onCloseModal={this.onCloseMobileModal}
                      pagination={pagination}
                      searchParamsForPagination={parse(location.search)}
                      showAsModalMaxWidth={MODAL_BREAKPOINT}
                      history={history}
                      allListingReview={listingAllReviews}
                      allAuthorNumberOfListings={allAuthorNumberOfListings}
                      currencyConversionRate={currencyConversionRate}
                    />

                    {validQueryParams.pub_listing_type === LISTING_TYPES.COMPANY ? (
                      <div className={css.partnershipWrapper}>
                        <p>
                          <FormattedMessage
                            id="PartnerTravelAgencies.supportText"
                            values={{
                              supported: chunks => (
                                <NamedLink name="GlobalAvailabilityPage" target="_blank">
                                  {chunks}
                                </NamedLink>
                              ),
                            }}
                          />
                        </p>
                        <div className={css.partnershipButtonWrapper}>
                          <NamedLink
                            name="BecomeExpertOrPartnerPage"
                            className={css.partnershipButton}
                          >
                            Apply for Partnership
                          </NamedLink>
                        </div>
                      </div>
                    ) : null}
                    {validQueryParams.pub_listing_type === LISTING_TYPES.COMPANY ? (
                      <div className={css.businessText}>
                        <h3>
                          <FormattedMessage id="PartnerTravelAgencies.backgroudTitle" />
                        </h3>
                        <p className={css.topSpan}>
                          <FormattedMessage id="PartnerTravelAgencies.backgroundInfo" />
                        </p>
                        <h3>
                          <FormattedMessage id="PartnerTravelAgencies.leadFeeTitle" />
                        </h3>
                        <p className={css.topSpan}>
                          <FormattedMessage id="PartnerTravelAgencies.leadFeeInfo1" />
                        </p>
                        <p className={css.topSpan}>
                          <FormattedMessage id="PartnerTravelAgencies.leadFeeInfo2" />
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </section>
            </div>
          </LayoutWrapperMain>

          <LayoutWrapperFooter>
            <Footer className={css.footer} />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  categoryConfig: config.custom.categories,
  subcategoryConfig: config.custom.subCategoriesFilterOptions,
  languageConfig: config.custom.languageFilterConfig,
  priceFilterConfig: config.custom.priceFilterConfig,
  keywordFilterConfig: config.custom.keywordFilterConfig,
  dateRangeLengthFilterConfig: config.custom.dateRangeLengthFilterConfig,
  listingTypeConfig: config.custom.listingTypeFilterConfig,
  productFilterConfig: config.custom.productFilters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  categoryConfig: array,
  subcategoryConfig: array,
  languageConfig: array,
  priceFilterConfig: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),
  dateRangeLengthFilterConfig: object,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    otherCurrentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
    listingAllReviews,
    fetchReviewsInProgress,
    allAuthorNumberOfListings,
  } = state.SearchPage;
  const { currencyConversionRate } = state.user;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  const otherListings = getListingsById(state, otherCurrentPageResultIds);
  return {
    listings: pageListings,
    otherListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    currentPageResultIds,
    otherCurrentPageResultIds,
    listingAllReviews,
    fetchReviewsInProgress,
    allAuthorNumberOfListings,
    currencyConversionRate,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onFetchAllListingReviews: listingRef => dispatch(fetchAllListingReviews(listingRef)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SearchPageComponent);

SearchPage.loadData = (params, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  return searchListings({
    ...rest,
    ...originMaybe,
    address,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.listing': ['title', 'description', 'geolocation', 'price', 'publicData', 'metadata'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],

    // 'limit.images': 1,
  });
};

export default SearchPage;
