import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconArrowDown.css';

const IconArrowDown = props => {
  const { selected, className } = props;
  const classes = classNames({
    [css.iconArrowDown]: true,
    [css.selected]: !!selected,
    [className]: !!className,
  });
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
      <path d="M5.38411 5.53907L9.31659 0.820092C9.58798 0.494429 9.3564 0 8.93248 0H1.06752C0.643602 0 0.412023 0.494428 0.683409 0.820091L4.61589 5.53907C4.81579 5.77894 5.18421 5.77894 5.38411 5.53907Z" fill="#C4C4C4" />
    </svg>
  );
};

const { string } = PropTypes;

IconArrowDown.defaultProps = {
  className: null,
  rootClassName: null,
};

IconArrowDown.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconArrowDown;
