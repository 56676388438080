import { memoize } from 'lodash/function';
import { pick } from 'lodash/object';
import moment from 'moment';
import { getMarketplaceEntities } from 'ducks/marketplaceData.duck';
import { getDefaultTimeZoneOnBrowser } from 'util/dates';

export const PERIODS = {
  ACTIVE: 'active',
  RECENT: 'recent',
};

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

export const getData = transactions => {
  if (!transactions.length) return [];

  const sortedByBookingStart = transactions.sort(
    (a, b) => a.booking.attributes.start - b.booking.attributes.start
  );

  //need to get an index
  const byClient = sortedByBookingStart.reduce((acc, tx) => {
    if (!tx.listing) return acc;

    const clientId = tx.customer.id.uuid;
    const kidsName = tx.attributes.protectedData.kidsName;
    const listingId = tx.listing.id.uuid;
    const txTimezone = defaultTimeZone();
    const weekday = moment(tx.booking.attributes.start).isoWeekday();
    const formatedWeekday = moment(tx.booking.attributes.start).format('dddd DD MMM');
    const startTime = moment(tx.booking.attributes.start)
      .tz(txTimezone)
      .format('kk:mm');
    const endTime = moment(tx.booking.attributes.end)
      .tz(txTimezone)
      .format('kk:mm');

    const index = acc.findIndex(accItem => {
      const accItemClientId = accItem.customer.id.uuid;
      const accItemKidsName = accItem.kidsName;
      const accItemListingId = accItem.listing.id.uuid;
      const accWeekday = accItem.weekday;
      const accItemStartTime = accItem.bookingStart;
      const accItemEndTime = accItem.bookingEnd;

      const isClientIdSame = clientId === accItemClientId;
      const isKidsNameSame = kidsName === accItemKidsName;
      const isListingIdSame = listingId === accItemListingId;
      const isWeekdaySame = weekday === accWeekday;
      const isStartTimeSame = startTime === accItemStartTime;
      const isEndTimeSame = endTime === accItemEndTime;

      return (
        isClientIdSame &&
        isKidsNameSame &&
        isListingIdSame &&
        isWeekdaySame &&
        isStartTimeSame &&
        isEndTimeSame
      );
    });

    // if acc array has accumulated entity with the same data
    if (index >= 0) {
      acc[index].transactions = [...acc[index].transactions, tx];
      return acc;
    } else {
      //acc array doesnt have accumulated entity
      acc.push({
        customer: tx.customer,
        listing: tx.listing,
        provider: tx.provider,
        weekday: weekday,
        formatedWeekday: formatedWeekday,
        bookingStart: startTime,
        bookingEnd: endTime,
        transactions: [tx],
        kidsName: kidsName,
      });
      return acc;
    }
  }, []);

  const withGroupedClients = byClient.reduce((acc, entry, i, arr) => {
    const entryItemListingId = entry.listing.id.uuid;
    const entryWeekday = entry.weekday;
    const entryItemStartTime = entry.bookingStart;
    const entryItemEndTime = entry.bookingEnd;

    const index = acc.findIndex(compareEntry => {
      const isListingIdSame = entryItemListingId === compareEntry.listing.id.uuid;
      const isWeekdaySame = entryWeekday === compareEntry.weekday;
      const isStartTimeSame = entryItemStartTime === compareEntry.bookingStart;
      const isEndTimeSame = entryItemEndTime === compareEntry.bookingEnd;

      return isListingIdSame && isWeekdaySame && isStartTimeSame && isEndTimeSame;
    });

    // if acc array has accumulated entity with the same data
    if (index >= 0) {
      const clientsArrayEntryFields = [
        'customer',
        'listing',
        'transactions',
        'provider',
        'kidsName',
      ];
      const parentEntry = pick(acc[index], clientsArrayEntryFields);
      const insertEntry = pick(entry, clientsArrayEntryFields);
      acc[index].multipleClients = true;
      acc[index].clients = [
        ...(acc[index].clients ? [...acc[index].clients] : [parentEntry]),
        insertEntry,
      ];
      return acc;
    } else {
      //acc array doesnt have accumulated entity
      acc.push({
        ...entry,
        multipleClients: false,
      });
      return acc;
    }
  }, []);

  return withGroupedClients.sort((a, b) => a.weekday - b.weekday);
};

export const getMarketplaceEntitiesMemo = memoize(getMarketplaceEntities, (...args) => {
  const [state, refs] = args;
  const stateKey = JSON.stringify(state.marketplaceData.entities.transaction);
  const refKey = JSON.stringify(refs);
  return [stateKey, refKey].join('_');
});

export const transformRecentTransactions = transactions => {
  if (!transactions.length) return [];

  // const sortedByBookingStart = transactions.sort(
  //   (a, b) => a.booking.attributes.start - b.booking.attributes.start
  // );

  //need to get an index
  const transformedEntity = transactions.reduce((acc, tx) => {
    if (!tx.listing) return acc;

    const clientId = tx.customer.id.uuid;
    const kidsName = tx.attributes.protectedData.kidsName;
    const listingId = tx.listing.id.uuid;

    const index = acc.findIndex(accItem => {
      const accItemClientId = accItem.customer.id.uuid;
      const accItemKidsName = accItem.kidsName;
      const accItemListingId = accItem.listing.id.uuid;

      const isClientIdSame = clientId === accItemClientId;
      const isKidsNameSame = kidsName === accItemKidsName;
      const isListingIdSame = listingId === accItemListingId;

      return isClientIdSame && isKidsNameSame && isListingIdSame;
    });

    // if acc array has accumulated entity with the same data
    if (index >= 0) {
      acc[index].transactions = [...acc[index].transactions, tx];
      return acc;
    } else {
      //acc array doesnt have accumulated entity
      acc.push({
        customer: tx.customer,
        listing: tx.listing,
        provider: tx.provider,
        transactions: [tx],
        kidsName,
      });
      return acc;
    }
  }, []);

  // remove entities where customer user was deleted
  const transformedEntityWithoutDeletedCustomers = transformedEntity.filter(
    e => !e.customer.attributes.deleted
  );

  // sort in alphabetical order
  return transformedEntityWithoutDeletedCustomers.sort((a, b) => {
    const aName =
      a.transactions[0].attributes.protectedData?.kidsName ||
      a.customer.attributes.profile.displayName;
    const bName =
      b.transactions[0].attributes.protectedData?.kidsName ||
      b.customer.attributes.profile.displayName;
    return aName.localeCompare(bName);
  });
};
