import React from 'react';
import { string } from 'prop-types';

import css from './SectionBanner.css';

const SectionBanner = props => {
  const { className } = props;

  const classes = className === 'trainersBanner' ? css.trainersBanner :
                  className === 'healthCareBanner' ? css.healthCareBanner :
                  className === 'kidsBanner' ? css.kidsBanner :
                  className === 'onlineBanner' ? css.onlineBanner :
                  className === 'groupActivitiesBanner' ? css.groupActivitiesBanner :
                  className === 'facilitiesBanner' ? css.facilitiesBanner :
                  className === 'sportsProductsBanner' ? css.sportsProductsBanner :
                  className === 'membershipsBanner' ? css.membershipsBanner : null

  return (
    <div className={css.bannerWrapper}>
      <div className={classes}>
      </div>
    </div>
  );
};

SectionBanner.defaultProps = { rootClassName: null, className: null };

SectionBanner.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionBanner;
