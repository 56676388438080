import React, { Component, Fragment, useState, useRef } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import omit from 'lodash/omit';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { minImages, composeValidators } from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import { GALLERY, LISTING_TYPES, PHOTOS } from '../../util/constants';
import {
  AddImages,
  Button,
  Form,
  ValidationError,
  HeadingFormFieldWrapper,
  Modal,
  GenericMessage,
} from '../../components';
import { ThumbnailWrapper } from '../../components/AddImages/AddImages';
import { default as RemoveVideoButton } from '../../components/AddImages/RemoveImageButton';
import { getExtensionFromUrl } from '../../util/urlHelpers';
import SectionMapMaybe from '../../containers/ListingPage/SectionMapMaybe';

import css from './EditListingPhotosForm.css';

// -----------------------------  crop START -----------------------------
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
// import 'react-image-crop/dist/ReactCrop.css';
import Compress from 'compress.js';
import { post } from 'util/api';
import axios from 'axios';

const compress = new Compress();
// -----------------------------  crop END -----------------------------

const ACCEPT_IMAGES = '.jpg, .jpeg, .png, .gif';
const MAX_FIELDS = 5;
const MAX_VIDEO_SIZE = 100 * 1024 * 1024; // 100 MB
const MAX_IMAGE_SIZE = 10 * 1024 * 1024; // 10 MB

// Field component that uses file-input to allow user to select images.
const FieldAddImage = props => {
  const {
    id,
    name,
    label,
    disabled,
    form,
    onImageUploadHandler,
    listingType,
    rootClassName,
    promptNotification,
  } = props;

  const isBackgroundImage = id === 'backgroundImage';
  const isProfileImage = id === 'profileImage';
  const isLibraryImage = id === 'addImage';

  const formApi = form;
  // -----------------------------  crop START -----------------------------
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  let initialAspect = 19 / 15;
  let workingWidth = 380;
  let workingHeight = 300;
  let previewHeight = 150;

  if (isBackgroundImage) {
    initialAspect = 15 / 4;
    workingWidth = 1440;
    workingHeight = 384;
    previewHeight = 80;
  }

  if (isProfileImage) {
    initialAspect = 19 / 15;
    workingWidth = 380;
    workingHeight = 300;
    previewHeight = 150;
  }

  if (isLibraryImage) {
    initialAspect = 19 / 15;
    workingWidth = 380;
    workingHeight = 300;
    previewHeight = 150;
  }

  const [imgSrc, setImgSrc] = useState('');
  const previewCanvasRef = useRef(null);
  // const finalCanvas = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: '%', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
    aspect: initialAspect,
  });
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(initialAspect);
  const [blobFile, setBlobFile] = useState(null);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [loadingCrop, setLoadingCrop] = useState(false);

  // -----------------------------  crop END -----------------------------
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
          // finalCanvas.current
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );
  return (
    <Field
      id={id}
      name={name}
      accept={ACCEPT_IMAGES}
      form={null}
      label={label}
      type="file"
      disabled={disabled}
    >
      {fieldprops => {
        const { accept, input, label, disabled: fieldDisabled } = fieldprops;
        const { name, type } = input;
        const onChange = async e => {
          const file = e.target.files[0];
          if (!file) {
            return;
          }

          if (file.size > MAX_IMAGE_SIZE) {
            promptNotification('imageFileIsLarge');
            return;
          }

          if (!['image/jpeg', 'image/jpg', 'image/png', 'image/gif'].includes(file.type)) {
            promptNotification('notSupportedFormat');
            return;
          }
          // formApi.change(`addImage`, file);
          // formApi.blur(`addImage`);
          // onImageUploadHandler(file);

          {
            /* -----------------------------  crop START  ----------------------------- */
          }

          try {
            const compressedFiles = await compress.compress([file], {
              size: 1, // the max size in MB, defaults to 2MB
              quality: 0.9, // the quality of the image, max is 1,
              maxWidth: 1920, // the max width of the output image, defaults to 1920px
              maxHeight: 1920, // the max height of the output image, defaults to 1920px
              resize: true, // defaults to true, set false if you do not want to resize the image width and height
              rotate: false, // See the rotation section below    
            });
            const base64str = compressedFiles[0].data;
            const imgExt = compressedFiles[0].ext;
            const convertedFile = Compress.convertBase64ToFile(base64str, imgExt);
            setCrop(undefined); // Makes crop preview update between images.
            const reader = new FileReader();
            reader.addEventListener('load', () => setImgSrc(reader.result.toString() || ''));
            reader.readAsDataURL(convertedFile);
  
            setCropModalOpen(true);
          } catch (error) {
           console.error('Error compressing file:', error); 
          }

          {
            /* -----------------------------  crop END ----------------------------- */
          }
        };

        {
          /* -----------------------------  crop START ----------------------------- */
        }

        function onImageLoad(e) {
          if (aspect) {
            const { width, height } = e.currentTarget;

            setCrop({
              unit: 'px', // Can be 'px' or '%'
              x: 10,
              y: 10,
              width: previewHeight * initialAspect,
              height: previewHeight,
              aspect: initialAspect,
            });

            setTimeout(() => {
              if (previewCanvasRef.current) {
                var canvas = document.getElementById('previewCanvas');

                canvas.toBlob(function(blob) {
                  setBlobFile(blob);
                });
              }
            }, 1000);
          }
        }

        const handleCompletedCrop = c => {
          setCompletedCrop(c);

          setTimeout(() => {
            if (previewCanvasRef.current) {
              var canvas = document.getElementById('previewCanvas');

              canvas.toBlob(function(blob) {
                setBlobFile(blob);
                setLoadingCrop(false);
              });
            }
          }, 1000);
        };

        const onSave = () => {
          const newImage = new File([blobFile], 'newImage', { type: blobFile.type });
          // const link = document.createElement('a');
          // link.href = window.URL.createObjectURL(blobFile);
          // link.download = 'image file name here';
          // document.body.appendChild(link);
          // link.click();
          // document.body.removeChild(link);
          formApi.change(`addImage`, newImage);
          formApi.blur(`addImage`);
          onImageUploadHandler(newImage, name);
          setCropModalOpen(false);
        };

        {
          /* -----------------------------  crop END ----------------------------- */
        }

        const inputProps = { accept, id: name, name, onChange, type };
        return (
          <>
            <div className={rootClassName}>
              <div className={css.aspectRatioWrapper}>
                {fieldDisabled ? null : <input {...inputProps} className={css.addImageInput} />}
                {fieldDisabled && !!blobFile ? (
                  <div className={css.loadingIndicator}></div>
                ) : (
                  <label htmlFor={name} className={css.addImage}>
                    {label}
                  </label>
                )}
              </div>
              {/* {id === 'backgroundImage' && (
                <span className={css.tooltiptext}>
                  {
                    'Background image displayed behind Profile, Action & Map Images (1440 w X 384 h Pixels)'
                  }
                </span>
              )} */}
            </div>
            {/* -----------------------------  crop START ----------------------------- */}

            <Modal
              id="cropModal"
              isOpen={cropModalOpen}
              onClose={() => {
                setCropModalOpen(false);
              }}
              onManageDisableScrolling={() => {}}
            >
              <div className={css.modalContentWrapper}>
                <div className={css.modalContentTopSection}>
                  <div className={css.croppingToolWrapper}>
                    <h2>Edit</h2>

                    <ReactCrop
                      crop={crop}
                      onChange={(_, percentCrop) => {
                        setLoadingCrop(true);
                        setCrop(percentCrop);
                      }}
                      onComplete={handleCompletedCrop}
                      aspect={aspect}
                    >
                      <img
                        ref={imgRef}
                        alt="Crop me"
                        src={imgSrc}
                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                        onLoad={onImageLoad}
                        // style={{
                        //   border: '1px solid black',
                        //   objectFit: 'contain',
                        //   width: '300px', //completedCrop.width,
                        //   height: 'auto', //completedCrop.height,
                        // }}
                      />
                    </ReactCrop>
                  </div>

                  <div className={css.previewWrapper}>
                    <h2 className={css.previewCanvas}>Preview</h2>

                    {completedCrop && (
                      <canvas
                        id="previewCanvas"
                        ref={previewCanvasRef}
                        style={{
                          border: '1px solid black',
                          objectFit: 'contain',
                          width: `${previewHeight * initialAspect}px`, //completedCrop.width,
                          height: `${previewHeight}px`, //completedCrop.height,
                        }}
                        className={css.previewCanvas}
                      />
                    )}

                    {/* <canvas
                      id="finalCanvas"
                      ref={finalCanvas}
                      style={{
                        border: '1px solid black',
                        // width: '300px', //completedCrop.width,
                        // height: '300px', //completedCrop.height,
                      }}
                      className={css.finalCanvas}
                    /> */}
                  </div>
                </div>

                <div
                  className={blobFile && !loadingCrop ? css.saveButton : css.saveButtonDisabled}
                  onClick={blobFile && !loadingCrop ? onSave : () => {}}
                >
                  Add
                </div>
              </div>
            </Modal>

            {/* -----------------------------  crop END ----------------------------- */}
          </>
        );
      }}
    </Field>
  );
};

export class EditListingPhotosFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUploadRequested: false,
      assets: {},
      imageInOrder: [],
      replacedImageId: null,
      uploadVideoInProgress: false,
      errorUploadVideo: false,
      serverFailed: false,
      notSupportedFormat: false,
      videoFileIsLarge: false,
      imageFileIsLarge: false,
    };
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.promptNotification = this.promptNotification.bind(this);
    this.submittedImages = [];

    this.backgroundImageRef = React.createRef();
    this.profileImageRef = React.createRef();
    this.actionImageRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.listingVideos && this.props.listingVideos.length) {
      const assetsObj = {};
      this.props.listingVideos.forEach(item => {
        assetsObj[item.id] = item.asset;
      });
      this.setState({ assets: assetsObj });
    }

    if (this.props.images) {
      this.setState({ imageInOrder: this.props.images });
    }
  }

  componentDidUpdate() {
    const imageLength = this.props.images.length;
    const stateImageLength = this.state.imageInOrder.length;
    if (
      imageLength !== stateImageLength &&
      !this.props.uploadImageInProgress &&
      !this.props.updateInProgress
    ) {
      if (imageLength < stateImageLength) {
        const imageIds = this.props.images.map(image => {
          if (image.id.uuid) return image.id.uuid;
          else return image.id;
        });
        const updatedImages = this.state.imageInOrder.filter(image => {
          return imageIds.includes(image.id) || imageIds.includes(image.id?.uuid);
        });
        this.setState({ imageInOrder: updatedImages });
      } else {
        const lastImage = this.props.images[imageLength - 1];
        this.setState({ imageInOrder: [...this.state.imageInOrder, lastImage] });
      }
    }

    if (
      (this.state.replacedImageId || this.state.replacedImageId === 0) &&
      !this.props.uploadImageInProgress
    ) {
      const images = [...this.state.imageInOrder];
      const lastImage = this.props.images[imageLength - 1];
      const updatedImages = images.map((image, index) => {
        if (this.state.replacedImageId === index) {
          this.props.onRemoveImage(image.id);
          return lastImage;
        } else {
          return image;
        }
      });
      this.setState({ replacedImageId: null, imageInOrder: updatedImages });
    }
  }

  onImageUploadHandler(file, uploadAs) {
    if (file) {
      this.setState({ imageUploadRequested: true });
      this.props
        .onImageUpload({ id: `${file.name}_${Date.now()}`, file, uploadAs })
        .then(() => {
          this.setState({ imageUploadRequested: false });
        })
        .catch(() => {
          this.setState({ imageUploadRequested: false });
        });
    }
  }

  promptNotification(type) {
    this.setState({ [type]: true });
    setTimeout(() => {
      this.setState({ [type]: false });
    }, 7000);
  }

  render() {
    const { listingType, listingVideos, listing } = this.props;
    const { assets } = this.state;
    const submitHandler = values => {
      const { listingVideos, images, ...restValues } = values;
      const normalizedListingVideos = listingVideos
        ? listingVideos.map(item => {
            return {
              ...item,
              asset: assets[item.id],
            };
          })
        : [];

      if (normalizedListingVideos.some(item => !item.asset)) {
        // setPictureError(true);
        return;
      }

      const updatePublicData = {
        publicData: {
          listingVideos: normalizedListingVideos,
        },
      };

      this.props.onSubmit({ ...restValues, images: this.state.imageInOrder, ...updatePublicData });
    };

    const omitPictureOnPop = values => {
      if (!values || !values.length) {
        return;
      }

      const lastItem = values[values.length - 1];
      this.setState({ assets: { ...omit(assets, lastItem.id) } });
    };

    const replaceFeatureImage = (e, imageId, uploadAs) => {
      this.onImageUploadHandler(e.target.files[0], uploadAs);
      this.props.onRemoveImage(imageId);
    };

    return (
      <FinalForm
        {...this.props}
        mutators={{
          ...arrayMutators,
        }}
        onImageUploadHandler={this.onImageUploadHandler}
        promptNotification={this.promptNotification}
        imageUploadRequested={this.state.imageUploadRequested}
        onSubmit={submitHandler}
        initialValues={{
          backgroundImage: this.props.backgroundImage,
          profileImage: this.props.profileImage,
          actionImage: this.props.actionImage,
          images: this.props.images,
          listingVideos: this.props.listingVideos,
        }}
        render={formRenderProps => {
          const {
            form,
            className,
            fetchErrors,
            handleSubmit,
            images,
            backgroundImage,
            profileImage,
            actionImage,
            imageUploadRequested,
            intl,
            invalid,
            onImageUploadHandler,
            promptNotification,
            onRemoveImage,
            disabled,
            ready,
            saveActionMsg,
            updated,
            updateInProgress,
            values,
          } = formRenderProps;

          const {
            mutators: { push, pop },
          } = formRenderProps.form;

          const chooseImageText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImage}>
                <FormattedMessage
                  id="EditListingPhotosForm.chooseImage"
                  values={{ count: this.state.imageInOrder.length + 1 }}
                />
              </span>
              <span className={css.imageDimension}>
                <FormattedMessage id="EditListingPhotosForm.featureImagesDimension" />
              </span>
              <span className={css.imageTypes}>
                <FormattedMessage id="EditListingPhotosForm.imageTypes" />
              </span>
            </span>
          );

          const backgroundImageText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImage}>
                <FormattedMessage id="EditListingPhotosForm.chooseBackgroundLandscapeImage" />
              </span>
              <span className={css.requiredTitle}>
                <FormattedMessage id="EditListingPhotosForm.requiredTitle" />
              </span>
              <span className={css.imageDimension}>
                <FormattedMessage id="EditListingPhotosForm.backgroundImagesDimension" />
              </span>
              <span className={css.imageTypes}>
                <FormattedMessage id="EditListingPhotosForm.imageTypesSmall" />
              </span>
            </span>
          );

          const backgroundLargeImageText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImage}>
                <FormattedMessage id="EditListingPhotosForm.chooseBackgroundLandscapeImage" />
              </span>
              <span className={css.requiredTitle}>
                <FormattedMessage id="EditListingPhotosForm.requiredTitle" />
              </span>
              <span className={css.imageDimension}>
                <FormattedMessage id="EditListingPhotosForm.backgroundLargeImagesDimension" />
              </span>
              <span className={css.imageTypes}>
                <FormattedMessage id="EditListingPhotosForm.imageTypesSmall" />
              </span>
            </span>
          );

          const profileImageText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImage}>
                <FormattedMessage id="EditListingPhotosForm.chooseProfileLandscapeImage" />
              </span>
              <span className={css.requiredTitle}>
                <FormattedMessage id="EditListingPhotosForm.requiredTitle" />
              </span>
              <span className={css.imageDimension}>
                <FormattedMessage id="EditListingPhotosForm.featureImagesDimension" />
              </span>
              <span className={css.imageTypes}>
                <FormattedMessage id="EditListingPhotosForm.imageTypesSmall" />
              </span>
            </span>
          );

          const brandImageText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImage}>
                <FormattedMessage id="EditListingPhotosForm.chooseBrandLandscapeImage" />
              </span>
              <span className={css.requiredTitle}>
                <FormattedMessage id="EditListingPhotosForm.requiredTitle" />
              </span>
              <span className={css.imageDimension}>
                <FormattedMessage id="EditListingPhotosForm.featureImagesDimension" />
              </span>
              <span className={css.imageTypes}>
                <FormattedMessage id="EditListingPhotosForm.imageTypesSmall" />
              </span>
            </span>
          );

          const actionImageText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImage}>
                <FormattedMessage id="EditListingPhotosForm.chooseActionLandscapeImage" />
              </span>
              <span className={css.requiredTitle}>
                <FormattedMessage id="EditListingPhotosForm.requiredTitle" />
              </span>
              <span className={css.imageDimension}>
                <FormattedMessage id="EditListingPhotosForm.featureImagesDimension" />
              </span>
              <span className={css.imageTypes}>
                <FormattedMessage id="EditListingPhotosForm.imageTypesSmall" />
              </span>
            </span>
          );

          const videoClipsText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImage}>
                <FormattedMessage
                  id="EditListingPhotosForm.chooseVideoClip"
                  values={{ count: Object.keys(this.state.assets).length + 1 }}
                />
              </span>
              <span className={css.imageDimension}>
                <FormattedMessage id="EditListingPhotosForm.videoClipDimension" />
              </span>
              <span className={css.imageTypes}>
                <FormattedMessage id="EditListingPhotosForm.videoClipTypes" />
              </span>
            </span>
          );

          const { publishListingError, showListingsError, updateListingError, uploadImageError } =
            fetchErrors || {};
          const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

          let uploadImageFailed = null;

          if (uploadOverLimit) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
              </p>
            );
          } else if (uploadImageError) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
              </p>
            );
          }

          // NOTE: These error messages are here since Photos panel is the last visible panel
          // before creating a new listing. If that order is changed, these should be changed too.
          // Create and show listing errors are shown above submit button
          const publishListingFailed = publishListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
            </p>
          ) : null;
          const showListingFailed = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
            </p>
          ) : null;

          const submittedOnce = this.submittedImages.length > 0;
          // imgs can contain added images (with temp ids) and submitted images with uniq ids.
          const arrayOfImgIds = imgs =>
            imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
          const imageIdsFromProps = arrayOfImgIds(images);
          const imageIdsFromPreviousSubmit = arrayOfImgIds(this.submittedImages);
          const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
          const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

          const submitReady = (updated && pristineSinceLastSubmit) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled =
            invalid ||
            disabled ||
            submitInProgress ||
            imageUploadRequested ||
            ready ||
            !values.backgroundImage?.id ||
            !values.profileImage?.id ||
            !values.actionImage?.id;

          const submitCompanyDisabled =
            disabled ||
            submitInProgress ||
            imageUploadRequested ||
            ready ||
            !values.profileImage?.id ||
            !values.backgroundImage?.id;

          const classes = classNames(css.root, className);

          const uploadVideoWidget = async (e, form) => {
            e.preventDefault();
            try {
              // Simulate getting the file from an input field
              const fileInput = document.createElement('input');
              fileInput.type = 'file';
              fileInput.accept = 'video/mp4';
              fileInput.click();
              fileInput.addEventListener('change', async changeEvent => {
                const file = changeEvent.target.files[0];
                if (file.size > MAX_VIDEO_SIZE) {
                  this.promptNotification('videoFileIsLarge');
                  return;
                }
                if (file.type !== 'video/mp4') {
                  this.promptNotification('notSupportedFormat');
                  return;
                }
                this.setState({ uploadVideoInProgress: true });

                const uniqueId = `video${Object.keys(assets)?.length + 1}`;
                const body = {
                  listingId: listing.id.uuid,
                  name: uniqueId,
                  contentType: file.type,
                  contentLength: file.size,
                };

                const { signedUploadURL, downloadBasePath } = await post(
                  '/api/listings-videos/create-upload',
                  body
                );

                // Upload the file to the signed URL
                try {
                  await axios.put(signedUploadURL, file, {
                    headers: {
                      'Content-Type': file.type,
                    },
                  });
                } catch (error) {
                  this.promptNotification('serverFailed');
                  this.setState({ uploadVideoInProgress: false });
                }

                // Perform any additional actions after successful upload
                console.log('Video uploaded successfully!');
                const listingVideoIds = Object.keys(assets).map((_, index) => ({
                  id: `video${index + 1}`,
                }));
                form.change('listingVideos', [...listingVideoIds, { id: uniqueId }]);
                const downloadURL = `${downloadBasePath}/${uniqueId}`;

                // Update the form state
                this.setState(prevState => ({
                  assets: {
                    ...prevState.assets,
                    [uniqueId]: { url: downloadURL, type: 'video' },
                  },
                  uploadVideoInProgress: false,
                }));
              });
            } catch (error) {
              this.promptNotification('errorUploadVideo');
              this.setState({ uploadVideoInProgress: false });
              console.error('Error uploading video', error);
            }
          };

          const required = value => (value ? undefined : 'Required');
          const filedArrayRequired = value => {
            if (value && value.length < 1) {
              return 'Require at least one entry';
            }

            return {};
          };

          const onImageReplace = (file, arrayIndex) => {
            this.setState({ replacedImageId: arrayIndex });
            onImageUploadHandler(file);
          };

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedImages = images;
                handleSubmit(e);
              }}
            >
              <div className={css.btnWrapper}>
                <div>
                  <h1 className={css.headingTitle}>{GALLERY}</h1>
                </div>
                <Button
                  rootClassName={css.topSaveButton}
                  type="submit"
                  inProgress={false}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </Button>
              </div>
              <GenericMessage
                message={'Video File is too large'}
                show={this.state.videoFileIsLarge}
                errorFeedback
              />
              <GenericMessage
                message={'Image file is too large'}
                show={this.state.imageFileIsLarge}
                errorFeedback
              />
              <GenericMessage
                message={'Error happened during upload process'}
                show={this.state.errorUploadVideo}
                errorFeedback
              />
              <GenericMessage
                message={'Failed to upload (Server failure)'}
                show={this.state.serverFailed}
                errorFeedback
              />
              <GenericMessage
                message={'File format is not supported'}
                show={this.state.notSupportedFormat}
                errorFeedback
              />
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingPhotosForm.updateFailed" />
                </p>
              ) : null}

              {uploadImageFailed}

              <div className={css.formGroup}>
                <HeadingFormFieldWrapper
                  label={'Feature Images'} //TODO dafs
                  className={css.subHeading}
                  supportText={
                    'Feature image to connect with your customers (380 w X 300 h pixels)'
                  }
                />
                {listingType === 'company' ? (
                  <div className={css.companyProfileImageWrapper}>
                    {!profileImage.id ? (
                      <FieldAddImage
                        id="profileImage"
                        name="profileImage"
                        label={brandImageText}
                        disabled={imageUploadRequested}
                        listingType={listingType}
                        form={form}
                        onImageUploadHandler={onImageUploadHandler}
                        rootClassName={css.addSingleImageWrapper}
                        promptNotification={promptNotification}
                      />
                    ) : (
                      // <Field
                      //   id="profileImage"
                      //   name="profileImage"
                      //   accept={ACCEPT_IMAGES}
                      //   form={null}
                      //   label={brandImageText}
                      //   type="file"
                      //   disabled={imageUploadRequested}
                      // >
                      //   {fieldprops => {
                      //     const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                      //     const { name, type } = input;
                      //     const onChange = e => {
                      //       const file = e.target.files[0];
                      //       form.change(`profileImage`, file);
                      //       form.blur(`profileImage`);
                      //       onImageUploadHandler(file, `profileImage`);
                      //     };
                      //     const inputProps = { accept, id: name, name, onChange, type };
                      //     return (
                      //       <div className={css.addSingleImageWrapper}>
                      //         <div className={css.aspectRatioWrapper}>
                      //           {fieldDisabled ? null : (
                      //             <input {...inputProps} className={css.addImageInput} />
                      //           )}
                      //           <label htmlFor={name} className={css.addImage}>
                      //             {label}
                      //           </label>
                      //         </div>
                      //       </div>
                      //     );
                      //   }}
                      // </Field>
                      <div onClick={() => this.profileImageRef.current.click()}>
                        <input
                          type="file"
                          id="file"
                          onChange={e => replaceFeatureImage(e, profileImage.id, `profileImage`)}
                          ref={this.profileImageRef}
                          style={{ display: 'none' }}
                        />
                        <ThumbnailWrapper
                          image={profileImage}
                          index={'profileImageThum'}
                          key={profileImage.id.uuid || profileImage.id}
                          className={css.thumbnail}
                          savedImageAltText={intl.formatMessage({
                            id: 'EditListingPhotosForm.savedImageAltText',
                          })}
                          onRemoveImage={onRemoveImage}
                        />
                      </div>
                    )}

                    <div className={css.mapWrapper}>
                      <SectionMapMaybe
                        geolocation={listing.attributes.publicData.geolocation}
                        publicData={listing.attributes.publicData}
                        listingId={listing.id}
                        className={css.mapThumbnail}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={css.profileImageWrapper}>
                    {!profileImage.id ? (
                      <div>
                        <FieldAddImage
                          id="profileImage"
                          name="profileImage"
                          label={profileImageText}
                          disabled={imageUploadRequested}
                          listingType={listingType}
                          form={form}
                          onImageUploadHandler={onImageUploadHandler}
                          rootClassName={css.addSingleImageWrapper}
                          promptNotification={promptNotification}
                        />
                        <div className={css.picDescription}>"I would hire them" face photo</div>
                      </div>
                    ) : (
                      <div onClick={() => this.profileImageRef.current.click()}>
                        <input
                          type="file"
                          id="file"
                          onChange={e => replaceFeatureImage(e, profileImage.id, `profileImage`)}
                          ref={this.profileImageRef}
                          style={{ display: 'none' }}
                        />
                        <ThumbnailWrapper
                          image={profileImage}
                          index={'profileImageThum'}
                          key={profileImage.id.uuid || profileImage.id}
                          className={css.thumbnail}
                          savedImageAltText={intl.formatMessage({
                            id: 'EditListingPhotosForm.savedImageAltText',
                          })}
                          onRemoveImage={onRemoveImage}
                        />
                        <div className={css.picDescription}>"I would hire them" face photo</div>
                      </div>
                    )}
                    {!actionImage.id ? (
                      <div>
                        <FieldAddImage
                          id="actionImage"
                          name="actionImage"
                          label={actionImageText}
                          disabled={imageUploadRequested}
                          listingType={listingType}
                          form={form}
                          onImageUploadHandler={onImageUploadHandler}
                          rootClassName={css.addSingleImageWrapper}
                          promptNotification={promptNotification}
                        />
                        <div className={css.picDescription}>
                          Picture of me doing something I like
                        </div>
                      </div>
                    ) : (
                      <div onClick={() => this.actionImageRef.current.click()}>
                        <input
                          type="file"
                          id="file"
                          onChange={e => replaceFeatureImage(e, actionImage.id, `actionImage`)}
                          ref={this.actionImageRef}
                          style={{ display: 'none' }}
                        />
                        <ThumbnailWrapper
                          image={actionImage}
                          index={'actionImageThum'}
                          key={actionImage.id.uuid || actionImage.id}
                          className={css.thumbnail}
                          savedImageAltText={intl.formatMessage({
                            id: 'EditListingPhotosForm.savedImageAltText',
                          })}
                          onRemoveImage={onRemoveImage}
                        />
                        <div className={css.picDescription}>
                          Picture of me doing something I like
                        </div>
                      </div>
                    )}

                    <div className={css.mapWrapper}>
                      <SectionMapMaybe
                        geolocation={listing.attributes.geolocation}
                        publicData={listing.attributes.publicData}
                        listingId={listing.id}
                        className={css.mapThumbnail}
                      />
                    </div>
                  </div>
                )}
                <HeadingFormFieldWrapper
                  label={'Background Image'}
                  className={css.subHeading}
                  supportText={'Background image displayed behind Profile (1440 w X 384 h Pixels)'}
                />
                <div style={{ marginBottom: '30px' }}>
                  {!backgroundImage.id ? (
                    <FieldAddImage
                      id="backgroundImage"
                      name="backgroundImage"
                      label={
                        listingType === 'company' ? backgroundLargeImageText : backgroundImageText
                      }
                      disabled={imageUploadRequested}
                      listingType={listingType}
                      form={form}
                      onImageUploadHandler={onImageUploadHandler}
                      rootClassName={
                        listingType === 'company'
                          ? css.addLargeBackgroundImageWrapper
                          : css.addBackgroundImageWrapper
                      }
                      promptNotification={promptNotification}
                    />
                  ) : (
                    // <Field
                    //   id="backgroundImage"
                    //   name="backgroundImage"
                    //   accept={ACCEPT_IMAGES}
                    //   form={null}
                    //   label={
                    //     listingType === 'company' ? backgroundLargeImageText : backgroundImageText
                    //   }
                    //   type="file"
                    //   disabled={imageUploadRequested}
                    //   listingType={listingType}
                    // >
                    //   {fieldprops => {
                    //     const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                    //     const { name, type } = input;
                    //     const onChange = e => {
                    //       const file = e.target.files[0];
                    //       form.change(`backgroundImage`, file);
                    //       form.blur(`backgroundImage`);
                    //       onImageUploadHandler(file, `backgroundImage`);
                    //     };
                    //     const inputProps = { accept, id: name, name, onChange, type };
                    //     return (
                    //       <div
                    //         className={
                    //           listingType === 'company'
                    //             ? css.addLargeBackgroundImageWrapper
                    //             : css.addBackgroundImageWrapper
                    //         }
                    //       >
                    //         <div className={css.aspectRatioWrapper}>
                    //           {fieldDisabled ? null : (
                    //             <input {...inputProps} className={css.addImageInput} />
                    //           )}
                    //           <label htmlFor={name} className={css.addImage}>
                    //             {label}
                    //           </label>
                    //         </div>
                    //         <span className={css.tooltiptext}>
                    //           {
                    //             'Background image displayed behind Profile, Action & Map Images (1440 w X 384 h Pixels)'
                    //           }
                    //         </span>
                    //       </div>
                    //     );
                    //   }}
                    // </Field>
                    <div onClick={() => this.backgroundImageRef.current.click()}>
                      <input
                        type="file"
                        id="file"
                        onChange={e =>
                          replaceFeatureImage(e, backgroundImage.id, `backgroundImage`)
                        }
                        ref={this.backgroundImageRef}
                        style={{ display: 'none' }}
                      />
                      <ThumbnailWrapper
                        image={backgroundImage}
                        index={'backgroundImageThum'}
                        className={css.backgroundThumbnail}
                        savedImageAltText={intl.formatMessage({
                          id: 'EditListingPhotosForm.savedImageAltText',
                        })}
                        isLandscape={true}
                        onRemoveImage={onRemoveImage}
                      />
                    </div>
                  )}
                </div>
                {listingType !== 'company' && (
                  <Fragment>
                    <HeadingFormFieldWrapper
                      label={'Image Library (Up to 20 )'} //TODO sdfsdf
                      className={css.subHeading}
                      supportText={
                        'Optional - Provide up to 20 display images to encourage potential customers to select you as their Trip Expert'
                      }
                    />
                    <AddImages
                      className={css.imagesFieldLong}
                      images={this.state.imageInOrder}
                      thumbnailClassName={css.thumbnail}
                      savedImageAltText={intl.formatMessage({
                        id: 'EditListingPhotosForm.savedImageAltText',
                      })}
                      onImageReplaceHandler={onImageReplace}
                      onRemoveImage={onRemoveImage}
                      fullWidth={true}
                    >
                      {images.length < 20 && (
                        <FieldAddImage
                          id="addImage"
                          name="addImage"
                          label={chooseImageText}
                          disabled={imageUploadRequested}
                          listingType={listingType}
                          form={form}
                          onImageUploadHandler={onImageUploadHandler}
                          rootClassName={css.addSingleImageWrapper}
                          promptNotification={promptNotification}
                        />
                      )}

                      <Field
                        component={props => {
                          const { input, meta } = props;
                          return (
                            <div className={css.imageRequiredWrapper}>
                              <input {...input} />
                              <ValidationError fieldMeta={meta} />
                            </div>
                          );
                        }}
                        name="images"
                        type="hidden"
                        // validate={composeValidators(validatorImages(imageRequiredMessage))}
                      />
                    </AddImages>
                  </Fragment>
                )}
                {publishListingFailed}
                {showListingFailed}

                {listingType !== 'company' && (
                  <div className={css.imageArrayField}>
                    <HeadingFormFieldWrapper
                      label={'Video Library (Up to 5 ) '}
                      className={css.subHeading}
                      supportText={'Video Library (Up to 5 )'}
                    />
                    <div>
                      <div className={css.videoClipWrapper}>
                        {assets
                          ? Object.keys(assets).map((item, index) => (
                              <div key={index}>
                                <div className={css.pictureContainer}>
                                  <video
                                    src={assets[item].url}
                                    controls
                                    width="100%"
                                    style={{ objectFit: 'contain', aspectRatio: '1920 / 1080' }}
                                  >
                                    <source
                                      src={assets[item].url}
                                      type={`video/${getExtensionFromUrl(assets[item].url)}`}
                                    />
                                  </video>

                                  <RemoveVideoButton
                                    onClick={e => {
                                      e.preventDefault();
                                      const updatedAssets = omit(this.state.assets, item);
                                      const squenceAssets = Object.keys(updatedAssets).map(
                                        (item, index) => updatedAssets[item]
                                      );
                                      const upAssets = squenceAssets.reduce(
                                        (obj, item, index) => ({
                                          ...obj,
                                          [`video${index + 1}`]: item,
                                        }),
                                        {}
                                      );
                                      const listingVideoIds = Object.keys(
                                        updatedAssets
                                      ).map((_, index) => ({ id: `video${index + 1}` }));
                                      form.change('listingVideos', [...listingVideoIds]);
                                      this.setState({
                                        assets: upAssets,
                                      });
                                      // omitPictureOnPop(formRenderProps.values.listingVideos);
                                    }}
                                  />
                                </div>
                              </div>
                            ))
                          : null}

                        {Object.keys(assets).length < 5 &&
                          (this.state.uploadVideoInProgress ? (
                            <div className={css.addVideoLoadingWrapper}>
                              <div className={css.loadingIndicator}></div>
                            </div>
                          ) : (
                            <div
                              onClick={e => {
                                uploadVideoWidget(e, form);
                              }}
                              className={css.addVideoWrapper}
                            >
                              <div className={css.aspectRatioWrapper}>
                                <label className={css.addImage}>{videoClipsText}</label>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                )}

                <Button
                  rootClassName={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={listingType === 'company' ? submitCompanyDisabled : submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingPhotosFormComponent.defaultProps = {
  fetchErrors: null,
  backgroundImage: {},
  profileImage: {},
  actionImage: {},
  images: [],
};

EditListingPhotosFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default compose(injectIntl)(EditListingPhotosFormComponent);
