import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaFacebook.css';

const IconSocialMediaFacebook = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clipIconSocialMediaFacebook)">
    <path d="M34.6601 8.63417H39.4073V0.366167C38.5883 0.2535 35.7717 0 32.4913 0C25.6468 0 20.9582 4.30517 20.9582 12.2178V19.5H13.4052V28.743H20.9582V52H30.2185V28.7452H37.466L38.6165 19.5022H30.2163V13.1343C30.2185 10.4628 30.9378 8.63417 34.6601 8.63417Z" fill="#03c1f4"/>
    </g>
    <defs>
    <clipPath id="clipIconSocialMediaFacebook">
    <rect width="52" height="52" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
  );
};

IconSocialMediaFacebook.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaFacebook.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaFacebook;
