import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingProfileForm } from '../../forms';
import config from '../../config';
import { CurrentUserContext } from 'contexts/currentUserContext';

import css from './EditListingProfilePanel.css';

const EditListingProfilePanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    listingType,
    handleStripeConnected,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { title, publicData } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const {
    accreditations,
  } = publicData;

  return (
    // <div className={classes}>
    <div className={css.scope}>
      {/* <div>
        <div>
          <h1 className={css.title}>{panelTitle} </h1>
        </div>
      </div> */}
      <CurrentUserContext.Consumer>
        {currentUser => {
          return (
            <EditListingProfileForm
              // className={css.form}
              className={css.scope}
              initialValues={{
                accreditations,
              }}
              saveActionMsg={submitButtonText}
              handleStripeConnected={handleStripeConnected}
              onSubmit={values => {
                const {
                  accreditations,
                } = values;
                const updateValues = {
                  publicData: {
                    accreditations,
                  },
                };

                onSubmit(updateValues);
              }}
              onChange={onChange}
              disabled={disabled}
              ready={ready}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              category={publicData.category}
              subCategory={publicData.subCategory}
              activityLevel={config.custom.activityLevel}
              currentUser={currentUser}
              currentListingId={currentListing.id}
              listingType={listingType}
            />
          );
        }}
      </CurrentUserContext.Consumer>
    </div>
  );
};

EditListingProfilePanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingProfilePanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  listingType: string,
};

export default EditListingProfilePanel;
