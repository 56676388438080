import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, NamedLink } from '../../components';
import config from '../../config';

import css from './EditBusinessIntroductionForm.css';
import { INTRODUCTION } from 'util/constants';

class EditBusinessIntroductionFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: props.category,
      subCategories: [],
      categoryBasedCustomFields: [],
    };
  }

  componentDidMount() {
    this.handleCategoryChange(this.props.category);
  }

  handleCategoryChange = category => {
    const categoriesConfig = config.custom.categories;
    const categoryBasedCustomFieldsConfig = config.custom.categoryBasedFields;
    const subCategoryConfig = category ? categoriesConfig.find(cc => cc.key === category) : [];
    const subCategories = (subCategoryConfig && subCategoryConfig.subCategories) || [];
    const CustomFieldsConfig =
      category !== undefined && category !== '' && subCategories.length
        ? categoryBasedCustomFieldsConfig.find(cc => cc.key === category).fields
        : [];
    this.setState({
      category: category,
      subCategories: subCategories,
      categoryBasedCustomFields: CustomFieldsConfig,
    });
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            className,
            disabled,
            ready,
            handleSubmit,
            intl,
            invalid,
            pristine,
            saveActionMsg,
            updateInProgress,
            fetchErrors,
          } = formRenderProps;

          const { updateListingError, createListingDraftError, showListingsError } =
            fetchErrors || {};
          const errorMessageUpdateListing = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
            </p>
          ) : null;

          // This error happens only on first tab (of EditListingWizard)
          const errorMessageCreateListingDraft = createListingDraftError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
            </p>
          ) : null;

          const title = intl.formatMessage({
            id: 'EditBusinessIntroductionForm.title',
          });

          const classes = classNames(css.root, className);
          const submitReady = pristine || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.btnWrapper}>
                <div>
                  <h1 className={css.headingTitle}>{INTRODUCTION}</h1>
                </div>
                <Button
                  rootClassName={css.topSaveButton}
                  type="submit"
                  inProgress={false}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </Button>
              </div>
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}

              <div className={css.formGroup}>
                <div className={css.titleSection}>{title}</div>
                <p className={css.subTitle}>
                  <FormattedMessage id="EditBusinessIntroductionForm.subTitle0" />
                </p>
                <ul>
                  <li className={css.summaryItem}>
                    <FormattedMessage id="EditBusinessIntroductionForm.summary0" />
                  </li>
                  <li className={css.summaryItem}>
                    <FormattedMessage id="EditBusinessIntroductionForm.summary1" />
                  </li>
                  <li className={css.summaryItem}>
                    <FormattedMessage id="EditBusinessIntroductionForm.summary2" />
                  </li>
                  <li className={css.summaryItem}>
                    <FormattedMessage id="EditBusinessIntroductionForm.summary3" />
                  </li>
                </ul>

                <p className={css.descriptionSection}>
                  <FormattedMessage
                    id="EditBusinessIntroductionForm.description0"
                    values={{
                      bold: chunks => <strong>{chunks}</strong>,
                      br: <br />,
                      a: (...chunks) => (
                        <NamedLink name="BusinessGettingStartedPage" target="_blank">
                          {chunks}
                        </NamedLink>
                      ),
                    }}
                  />
                </p>
                <p className={css.subTitle}>
                  <FormattedMessage id="EditBusinessIntroductionForm.subTitle1" />
                </p>

                <p className={css.descriptionSection}>
                  <FormattedMessage
                    id="EditBusinessIntroductionForm.description1"
                    values={{
                      bold: chunks => <strong>{chunks}</strong>,
                      br: <br />,
                    }}
                  />
                </p>

                <Button
                  rootClassName={css.submitButton}
                  type="submit"
                  inProgress={false}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditBusinessIntroductionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditBusinessIntroductionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  listingType: string,
};

export default compose(injectIntl)(EditBusinessIntroductionFormComponent);
