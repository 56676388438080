import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import css from './PriceFilterOnPopup.css';
import { RangeSlider } from 'components';
import { debounce } from 'lodash';

const PriceFilterOnPopup = props => {
  const { handleFiltersChange, currentQueryParams } = props;

  //slider logic
  const priceFromQueryString = currentQueryParams?.price;
  const rangeInitialValue = priceFromQueryString ? priceFromQueryString.split(',').map(Number) : [10, 500];
  const [range, setRange] = useState(rangeInitialValue);

  const handleDebouncedChange = useCallback(
    debounce(value => {
      handleSubmit(value);
    }, 500),
    [handleFiltersChange]
  );

  const handleChange = value => {
    setRange(value);
    handleDebouncedChange(value);
  };

  const handleSubmit = value => {
    const newValue = {
      price: `${value[0]},${value[1]}`,
    };
    handleFiltersChange(newValue);
  };

  function valuetext(value) {
    return `$${value}`;
  }

  //---

  return (
    <div className={classNames(css.wrapper, css.disableSelect)}>
      <p className={css.popupLabel}>
        Price range{' '}
        <span className={css.rangeValue}>
          ${range[0]} - ${range[1]}
        </span>
      </p>
      <div className={css.sliderWrapper}>
        <RangeSlider handles={range} min={10} max={500} onChange={handleChange} />
      </div>
    </div>
  );
};

export default PriceFilterOnPopup;
