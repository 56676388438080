/**
 * Creates a sortable image grid with children added to the end of the created grid.
 *
 * Example:
 * // images = [{ id: 'tempId', imageId: 'realIdFromAPI', file: File }];
 * <AddImages images={images}>
 *   <input type="file" accept="images/*" onChange={handleChange} />
 * </AddImages>
 */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ImageFromFile, ResponsiveImage, IconSpinner } from '../../components';

import css from './AddImages.css';
import RemoveImageButton from './RemoveImageButton';

export const ThumbnailWrapper = props => {
  const { className, image, savedImageAltText, onRemoveImage, isLandscape = false } = props;
  const handleRemoveClick = e => {
    e.stopPropagation();
    onRemoveImage(image.id);
  };

  if (image.file) {
    // Add remove button only when the image has been uploaded and can be removed
    const removeButton = image.imageId ? <RemoveImageButton onClick={handleRemoveClick} /> : null;

    // While image is uploading we show overlay on top of thumbnail
    const uploadingOverlay = !image.imageId ? (
      <div className={css.thumbnailLoading}>
        <IconSpinner />
      </div>
    ) : null;

    return (
      <ImageFromFile
        id={image.id}
        className={className}
        rootClassName={css.thumbnail}
        file={image.file}
      >
        {removeButton}
        {uploadingOverlay}
      </ImageFromFile>
    );
  } else {
    const classes = classNames(css.thumbnail, className);
    return (
      <div className={classes}>
        <div className={css.threeToTwoWrapper}>
          <div className={css.aspectWrapper}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              image={image}
              alt={savedImageAltText}
              variants={isLandscape ? ['landscape-background-crop'] : ['feature-Image-crop']}
            />
          </div>
          <RemoveImageButton onClick={handleRemoveClick} />
        </div>
      </div>
    );
  }
};

ThumbnailWrapper.defaultProps = { className: null };

const { array, func, node, string, object } = PropTypes;

ThumbnailWrapper.propTypes = {
  className: string,
  image: object.isRequired,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
};

const AddImages = props => {
  const {
    children,
    className,
    thumbnailClassName,
    images,
    savedImageAltText,
    onRemoveImage,
    onImageReplaceHandler,
    fullWidth,
  } = props;
  const classes = classNames(css.root, className);
  const inputFile = useRef([]);

  const onOpenFileSystem = index => {
    inputFile.current[index].click();
  };

  const onChangeImage = (e, replaceIndex) => {
    const file = e.target.files[0];
    onImageReplaceHandler(file, replaceIndex);
  };
  return (
    <div className={classes}>
      {images.map((image, index) => {
        return (
          <div
            key={index}
            className={fullWidth ? css.longThumbnailWrapper : css.noStyle}
            onClick={() => onOpenFileSystem(index)}
          >
            <input
              type="file"
              id="file"
              onChange={e => onChangeImage(e, index)}
              ref={el => (inputFile.current[index] = el)}
              style={{ display: 'none' }}
            />
            <ThumbnailWrapper
              image={image}
              index={index}
              key={image.id.uuid || image.id}
              className={thumbnailClassName}
              savedImageAltText={savedImageAltText}
              onRemoveImage={onRemoveImage}
            />
          </div>
        );
      })}
      {children}
    </div>
  );
};

AddImages.defaultProps = { className: null, thumbnailClassName: null, images: [] };

AddImages.propTypes = {
  images: array,
  children: node.isRequired,
  className: string,
  thumbnailClassName: string,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
};

export default AddImages;
