import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError, Button } from '../../components';

import css from './FieldStandardTime.css';

const CONTENT_MAX_LENGTH = 5000;

class FieldStandardTimeComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSupport: false,
      counterValue: 0,
      standardTime: 0,
    }
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const { input, standardTime } = this.props;
    if (input.value) {
      this.setState({ counterValue: input.value || 0, standardTime })
    }
  }
  componentDidUpdate() {
    const { standardTime } = this.props;
    if (this.state.standardTime !== standardTime) {
      this.setState({ counterValue: standardTime, standardTime })
    }
  }
  render() {
    /* eslint-disable no-unused-vars */
    const {
      rootClassName,
      className,
      inputRootClass,
      customErrorText,
      id,
      label,
      supportText,
      input,
      meta,
      onUnmount,
      isUncontrolled,
      inputRef,
      form,
      extraLabel,
      standardTime,
      discout24hrs,
      discout48hrs,
      premium24hrs,
      premium48hrs,
      ...rest
    } = this.props;
    /* eslint-enable no-unused-vars */

    if (label && !id) {
      throw new Error('id required when a label is given');
    }

    const { valid, invalid, touched, error } = meta;

    const errorText = customErrorText || error;

    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.
    const hasError = !!customErrorText || !!(touched && invalid && error);

    const fieldMeta = { touched: hasError, error: errorText };

    // Textarea doesn't need type.
    const { type, ...inputWithoutType } = input;
    // Uncontrolled input uses defaultValue instead of value.
    const { value: defaultValue, ...inputWithoutValue } = input;
    // Use inputRef if it is passed as prop.
    const refMaybe = inputRef ? { ref: inputRef } : {};

    const inputClasses =
      inputRootClass ||
      classNames(css.input, {
        [css.inputSuccess]: valid,
        [css.inputError]: hasError,
      });
    const maxLength = CONTENT_MAX_LENGTH;
    const inputProps = { className: inputClasses, id, type, ...refMaybe, ...input, ...rest };

    const classes = classNames(rootClassName || css.root, className);

    const { counterValue } = this.state
    const diffStandardTime = counterValue - standardTime;

    const getLabel = () => {
      if (input.name === "collaborationTime") {
        if (diffStandardTime <= -48)
          return `${counterValue}hrs (LessTime) - ${discout48hrs}% discount`;
        else if (diffStandardTime <= -24)
          return `${counterValue}hrs (LessTime) - ${discout24hrs}% discount`;
        else if (diffStandardTime >= 48)
          return `${counterValue}hrs (MoreTime) + ${premium48hrs}% premium`;
        else if (diffStandardTime >= 24)
          return `${counterValue}hrs (MoreTime) + ${premium24hrs}% premium`;
        else
          return `${counterValue}hrs (Standard)`;
      }
      else {
        if (diffStandardTime <= -48)
          return `${counterValue}hrs (Express) + ${premium48hrs}% premium`;
        else if (diffStandardTime <= -24)
          return `${counterValue}hrs (Express) + ${premium24hrs}% premium`;
        else if (diffStandardTime >= 48)
          return `${counterValue}hrs (No Rush) - ${discout48hrs}% discount`;
        else if (diffStandardTime >= 24)
          return `${counterValue}hrs (No Rush) - ${discout24hrs}% discount`;
        else
          return `${counterValue}hrs (Standard)`;
      }

    }

    const getDiscountOrPremium = (value) => {
      const diffStandardTime = value - standardTime
      let percentage = null
      if (input.name === "collaborationTime") {
        if (diffStandardTime <= -48)
          percentage = -discout48hrs;
        else if (diffStandardTime <= -24)
          percentage = -discout24hrs;
        else if (diffStandardTime >= 48)
          percentage = premium48hrs;
        else if (diffStandardTime >= 24)
          percentage = premium24hrs;
        else
          percentage = null;
      }
      else {
        if (diffStandardTime <= -48)
          percentage = premium48hrs;
        else if (diffStandardTime <= -24)
          percentage = premium24hrs;
        else if (diffStandardTime >= 48)
          percentage = -discout48hrs;
        else if (diffStandardTime >= 24)
          percentage = -discout24hrs;
        else
          percentage = null;
      }

      form.change(input.name, { hours: value, percentage })
    }

    return (
      <div className={classes}>
        {label ? <label htmlFor={id}>{label}</label> : null}

        <div className={css.headingWrapper}>
          <span className={css.fieldValue}>{getLabel()}</span>
          <div className={css.diffStandardTime}>
            {diffStandardTime ? `${diffStandardTime}hrs` : ""}
            <div className={css.buttonWrapper}>
              <Button
                type="button"
                disabled={this.state.counterValue - standardTime >= 48}
                onClick={() => {
                  getDiscountOrPremium((this.state.counterValue + 24));
                  this.setState((prevState) => ({ counterValue: prevState.counterValue + 24 }))
                }}
              >+
              </Button>

              <Button
                type="button"
                disabled={this.state.counterValue - standardTime <= -48 || this.state.counterValue <= 24}
                onClick={() => {
                  getDiscountOrPremium((this.state.counterValue - 24));
                  this.setState((prevState) => ({ counterValue: prevState.counterValue - 24 }))
                }}
              >-
              </Button>
            </div>
          </div>
        </div>
        <input {...inputProps} ref={this.inputRef} type='hidden' value={this.state.counterValue} />
        <ValidationError fieldMeta={fieldMeta} />
      </div>
    );
  }
}

FieldStandardTimeComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
  inputRef: null,
};

FieldStandardTimeComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,
  // a ref object passed for input element.
  inputRef: object,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
    // Either 'textarea' or something that is passed to the input element
    type: string.isRequired,
  }).isRequired,
  meta: object.isRequired,
};

class FieldStandardTime extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldStandardTimeComponent} {...this.props} />;
  }
}

export default FieldStandardTime;
