import React, { Component } from 'react';
import classNames from 'classnames';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  NamedLink,
  Footer,
} from 'components';
import { FormattedMessage } from 'react-intl';
import { StaticPage, TopbarContainer } from 'containers/index';
import css from './OverviewPage.css';
import Collapsible from 'react-collapsible';

import processDiagram from '../../assets/TripExpertPage/Presonalised Trip Planning diagram.png';
import diagram1 from '../../assets/Overview_Page/Hire_Trip_Expert_Flow.svg';
import diagram2 from '../../assets/Overview_Page/Become_Trip_Expert_Flow.svg';
import diagram3 from '../../assets/Overview_Page/Become_Partner_Flow.svg';

const scrollToSection = (id) => {
  const element = document.getElementById(id);
  if (element) {
    const yOffset = -100;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  }
}

const userTypeList = [
  {
    id: "traveler",
    label: "Traveler (Customer)",
    summary: [
      "Save time, money and improve trip planning quality, hire a Trip Expert.  Conducting research, building a plan from high level requirements, then sharing as an Interactive Itinerary within a purpose built App available on your device, collaborating with direct messaging.",
      "Customers initially browse Trip Experts from all around the world with varying array of skills, location experience and specializations then hire them by Personalised Trip Planning “Service as a Product” (SaaP). Once itinerary delivery is complete customers can also choose to book some or all of the items directly with the Trip Expert in their capacity as a Travel Agent, or book with suppliers themselves.",
    ],
    details: [
      "Browse Trip Expert profiles and select one you like based on criteria such as location or trip specialization, price, interests, experience.",
      "Start en enquiry including completing the background trip information details that will be delivered to assist the Trip Expert with your Personalised Trip Planning quote.",
      "Create a Lyfshort profile.  (Required to place an order on the Marketplace).",
      "Send the enquiry and wait for a return quotation or clarification questions from the Expert on marketplace messaging.",
      "If the quote is acceptable, place the order and hire your Expert.",
      "Ensure you have downloaded the free Lyfshort Trip Organiser App from the App or Play stores to receive your interactive itinerary, check delivery status and communicate with your Trip Expert. (Must log in to Lyfshort Organiser App with the same userID as the order was placed on the Marketplace.  To check compare profile email addresses in the Lyfshort App and on the marketplace when logged in).",
      "Upon Credit Card payment, research is conducted and an Interactive Itinerary is created and shared via the Lyfshort App.",
      "Using messaging in the Lyfshort App, changes to the itinerary can then be requested by the customer and made by the Expert.",
      "Following the Expert sharing the Itinerary the customer now has the option to book some or all of the itinerary items directly with the Trip Expert in their capacity as a Travel Agent (or book directly with suppliers themselves).",
      "For booked items, the conversation simply continues via messaging within the interactive itinerary leading up to and during the trip.",
      "If optional Travel related products and services such as flights, accommodation, activities, tours or any other type requiring booking and payment are offered by the Travel Agent, to the Customer, following delivery of a Trip Expert SaaP, they are negotiated and contracted directly between the Customer and the Travel Agent.  Lyfshort is in no way involved in these direct Travel Agent transactions outside of providing a secure and efficient communication medium and a marketplace to facilitate initial introductions created when the Trip Expert SaaP's are purchased. <a>Full Terms and conditions here</a>.",
    ],
  },
  {
    id: "expert",
    label: "Travel Agent (Trip Expert)",
    summary: [
      "Are you a Travel Agent that would like to receive qualified booking leads while also getting paid directly for the value you deliver when creating Itineraries?  Would you like to set your own \"Service as a Product\" (SaaP) pricing, access new global markets and simplify communication with your customers?",
      "Lyfshort's Assisted Trip Planning marketplace enables Travelers to hire Trip Experts, then collaboratively plan trips together.  Setup a profile and sales page quickly, focus on specialized trips or locations you love, continue to use existing internal IT and booking systems, access a purpose built Interactive Itinerary App to communicate (Instead of a spaghetti of emails, phone calls and SMS's etc).",
      "No up front or monthly costs, no commissions or revenue splits on Travel bookings.  SaaP hire charges are allocated 70% - 90% to the Trip Expert, minus a small Travel Agency lead fee, with the remainding % contributing to marketplace operation. <a>More details here</a>. Any direct customer booking revenue related to the trip (Flights, Accommodation, Activities etc.) is 100% retained.  Travel Agencies can apply for marketplace partnership, and, on approval, invite their Travel Agents to set up Trip Expert profiles, start taking order requests from new customers from around the world."
    ],
    details: [
      "To become a Trip Expert and offer services on the marketplace you must be both a registered Travel Agent and work for or be affiliated with a Partner Travel Agency.",
      "Lyfshort is looking for Partner Travel Agencies so suggest contacting the owner or administrator of your Travel Agency to complete a Partnership Request, and get approved.",
      "Once Approved, Partner Travel Business administrators send invites to their Travel Agents via email or a unique link from the marketplace.",
      "The email/link will guide you to first create a Lyfshort profile.  (If you already have a profile, log in to the Marketplace).",
      "Navigate to the top right hand Avatar menu and you should now see a second \"Trip Expert\" section in the drop down menu, the sub-menu options under this heading are where you manage customers and work on the marketplace.",
      "Sections include \"Sales Listing Page\", \"Order Management\",\"Reporting\" and \"Messages\".",
      "Start by completing all sections in the sales listing page. They include \"Overview\", \"Locations\", \"Gallery\", \"Pricing\"",
      "A draft view is available before publishing live to the Marketplace.  Once you have \"Published\", your Trip Expert sales page should be visible in marketplace searches.  (Suggest testing).",
      "All Personalised Trip Planning prices are quoted in USD however may be viewed by marketplace browsers in their preferred local currency.",
      "After completing all the sections the Sales Page Listing can now be \"Published\" from the Draft View screen.",
      "Once published, customers can now submit order enquiries for your Personalised Trip Planning hire on the marketplace, email notifications will be sent when orders are received.",
      "Enquiries will contain a package of completed information from the customer with enough detail to both provide a quote for the service and subsequently start researching and constructing the itinerary within the Lyfshort App upon receiving an order, including dates, locations, budgets, transport and accommodation requirements and any other details the customer wants considered.",
      "Trip Experts can visit the \"Order Management\" menu (when logged into the marketplace, top right hand corner) to view the  enquiry and order details.",
      "Upon the Customer accepting the Personalised Trip Planning quote as an order (by paying for the service) it is now time to start creating and collaborating around the interactive itinerary in the Lyfshort App.  A placeholder Trip visible to both you and the customer will have been automatically created with the Trip name provided in the initial enquiry, ready for you to add relevant itinerary items after researching details relating to the requirements.",
      "At this stage nothing besides the itinerary name has been shared with the customer however they know you are working on it.",
      "Once you have added itinerary items and are ready to “reveal” to the customer the full details of the first draft of the itinerary, this next step can be completed.",
      "The customer will get a notification that their itinerary is available to review.",
      "At this stage the customer can also invite other people going on the trip to join the interactive itinerary and participate in the group messaging conversations with the Trip Expert.",
      "Collaboration now continues over messaging and within the interactive itinerary until the Trip details are looking good.",
      "The customer can also choose to book itinerary items through the Trip Expert in their capacity as a Travel Agent (or not). If they do, the messaging conversation continues within the shared itinerary and booking and payment details are sorted out directly between the 2 parties (As they normally would over email/phone/F2F but in 1 place over InApp messaging instead.).",
      `If optional Travel related products and services such as flights, accommodation, activities, tours or any other type requiring booking and payment are offered by the Travel Agent, to the Customer, following delivery and completion of a Trip Expert SaaP, they are negotiated and contracted directly between the Customer and the Travel Agent.  Lyfshort is in no way involved in these direct Travel Agent transactions outside of providing a secure and efficient communication medium and a marketplace to facilitate initial introductions created when the Trip Expert SaaP's are purchased. <a>Full Terms and conditions here</a>.`,
    ],
  },
  {
    id: "agency",
    label: "Partner Travel Business (Travel Agency)",
    summary: [
      "Becoming a Lyfshort Marketplace Business Partner provides existing Travel Agencies the opportunity of new customers and revenue in wide Geographical markets and is quickly scalable across teams of Travel Agents.",
      "Once approved, a Travel Agency administrator can create and send invite links to Travel Agents associated with their business, who in turn create individual \"Trip Expert\" multimedia sales listing pages for customer enquiries on the marketplace.",
      "Following purchase the \"Trip Expert\" conducts personalized research and creates an Interactive Itinerary via the Lyfshort Trip Organiser App.  Changes can be requested using direct messaging (removing the need for a spaghetti of emails, phone calls, SMS's etc.).   The customer now also has the option to book itinerary items directly with the Trip Expert in their capacity as a Travel Agent (or book directly with suppliers themselves).  For booked items, the conversation simply continues via messaging within the Lyfshort Interactive itinerary App leading up to and during the trip.  The Travel Agency administrator has central access to all linked Trip Expert transactions, reporting and sales proceeds.  It is the Travel Agency Administrators responsibility to transfer SaaP order funds to the individual Travel Agents."
    ],
    details: [
      `A Travel Agency owner or Manager applies to become a "Partner Travel Business" on the Lyfshort Marketplace.`,
      `Travel Business Partnerships are only available in countries supported by Stripe Connect payments (at this stage). They included Australia, Austria, Belgium, Brazil, Bulgaria, Canada, Cyprus, the Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hong Kong, Hungary, India, Ireland, Italy, Japan, Latvia, Lithuania, Luxembourg, Malta, Mexico, the Netherlands, New Zealand, Norway, Poland, Portugal, Romania, Singapore, Slovakia, Slovenia, Spain, Sweden, Switzerland, Thailand, the United Kingdom, and the United States. Additional countries are being evaluated, for unsupported countries please contact Lyfshort support.`,
      `Trip Expert (Travel Agent) sales listings linked to Partner Travel Businesses offering SaaP packages can be located anywhere.`,
      `Application information required includes Business, Financial and administrative details along with brand imagery for a <business>Business Listing Page</business>.`,
      `The application goes through a financial and commercial diligence process, including Stripe Bank Account registration, and is manually reviewed by Lyfshort.`,
      `Upon approval, the Travel Business Listing page is published and additional administration menu access is enabled (Available in the top right Avatar Menu drop down).`,
      `The Travel Agency Business Admin can now invite Travel Agent staff or associates (Via email or unique link) to create a Lyfshort profile and Trip Expert listing page, linked to their Partner Business listing page.`,
      `Each Trip Expert sets up their own sales listing page in the "Trip Expert" menu section, (available in the top right corner after a business invite is accepted form the Travel Agency administrator).`,
      `All sales for Trip Expert Personalised Trip Planning SaaP's on the Marketplace will be deposited in the registered bank account of the Partner Travel Business (Via Stripe).`,
      `It is the Partner Travel Businesses responsibility to manage distribution and payment of proceeds to Travel Agents for their SaaP work. Relevant supporting transaction reporting is available.`,
      `The Partner Travel Business will receive the following payment on successful SaaP delivery :
      (USD Total SaaP Customer Charge) - (USD SaaP *Marketplace % fee + USD **lead fee) = (Travel Business Retained Payment amount) paid out in linked account currency, based on Stripe’s standard terms and conditions for the region.

      *Marketplace % fee on SaaP transactions includes but is not limited to :
      Prospect marketing, Travel Business and Trip Expert sales listing, order process management and organizer App integration, transaction reporting, credit card processing fees, currency conversion fees and support.

      **Lead fee covers : The introduction to a qualified opportunity to directly sell Travel Agent products and services to (Eg. Flights, accommodation, car hire, activities etc.) following a SaaP completion. <c>More details here</c>.`,
      `If the customer chooses to use the Trip Expert in their capacity as a Travel Agent, communication continues directly through the Interactive Itinerary and messaging.`,
      `The Travel Agency / Travel Agents continue using the same internal booking systems, processes, and IT systems however, instead of communicating via emails, phone calls, SMS's etc. with customers, Interactive Itineraries and messaging are now centrally used with customers instead.`,
      `Lyfshort does not charge any commission % or revenue splits related to travel bookings, only the small transactional lead fee associated with the marketplace connection made.`,
      `If optional Travel related products and services such as flights, accommodation, activities, tours or any other type requiring booking and payment are offered by the Travel Agent, to the Customer, following delivery and completion of a Trip Expert SaaP, they are negotiated and contracted directly between the Customer and the Travel Agent.  Lyfshort is in no way involved in these direct Travel Agent transactions outside of providing a secure and efficient communication medium and a marketplace to facilitate initial introductions created when the Trip Expert SaaP's are purchased. <a>Full Terms and conditions here</a>.`,
    ]
  },
]

const selectDiagram = (i) => {
  switch (i) {
    case 0:
      return diagram1;
    case 1:
      return diagram2;
    case 2:
      return diagram3;
    default:
      return
  }
}
export default class OverviewPage extends Component {

  render() {
    return (
      <StaticPage title="Overview Page">
        <LayoutSingleColumn>
          <LayoutWrapperTopbar >
            <TopbarContainer isDarkMode={true} />
          </LayoutWrapperTopbar>

          <LayoutWrapperMain >
            <div className={css.overviewSection}>
              <div className={css.containerWrapper}>
                <h2>Assisted Trip Planning Marketplace</h2>
                <h2 className={css.redFontClass}>Overview</h2>
                <div className={css.processDiagramWrapper}>
                  <img src={processDiagram} alt="process Diagram" width="100%" />
                </div>
                <h3>User Types</h3>
                {userTypeList.map((type, index) => (
                  <div className={css.bookmarkLink}>
                    <a key={index} onClick={() => scrollToSection(type.id)}>{index + 1}. {type.label}</a>
                  </div>
                ))}

                {/* main content */}
                {userTypeList.map((type, index) => (
                  <div className={css.sectionContainer}>
                    <h3 id={type.id} className={classNames(css.bookmarkLink, css.redFontClass)}>
                      {index + 1}. {type.label}
                    </h3>
                    <h3>Service Summary</h3>
                    {type.summary.map((summary, index) => (
                      <p key={index}>
                        <FormattedMessage
                          id={"summary" + index}
                          defaultMessage={summary}
                          values={{
                            a: chunks => <NamedLink name="CommercialModelPage" target='_blank'>
                              {chunks}
                            </NamedLink>,
                          }}
                        />
                      </p>
                    ))}
                    <Collapsible
                      trigger={<div className={css.expandableBar}>
                        <span>Getting Started &gt;&gt;</span>
                        <span className={css.accordionArrow}></span>
                      </div>}
                      triggerWhenOpen={<div className={css.expandableBar}>
                        <span>Getting Started &gt;&gt;</span>
                        <span className={css.accordionArrowOpen}></span>
                      </div>
                      }
                    >
                      <div className={css.sectionDiagramWrapper}>
                        <img src={selectDiagram(index)} alt={"diagram" + index} />
                      </div>
                      <ol style={{ listStyleType: "disc" }}>
                        {type.details.map((sentence, index) => (
                          <li key={index}>
                            <p>
                              <FormattedMessage
                                id={"sentence" + index}
                                defaultMessage={sentence}
                                values={{
                                  business: chunks => <NamedLink name="SearchPage" to={{ search: `pub_listing_type=company` }}>
                                    {chunks}
                                  </NamedLink>,
                                  a: chunks => <NamedLink name="TravelAgentTnCPage">
                                    {chunks}
                                  </NamedLink>,
                                  b: (...chunks) => <b>{chunks}</b>,
                                  c: chunks => <NamedLink name="CommercialModelPage" target="_blank">
                                    {chunks}
                                  </NamedLink>
                                }}
                              />
                            </p>
                          </li>
                        ))}
                      </ol>
                    </Collapsible>
                  </div>
                ))}
              </div>
            </div>
          </LayoutWrapperMain>

          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </StaticPage>
    )
  };
}
