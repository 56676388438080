import React, { Component } from 'react';
import { func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import { ValidationError } from '../../components';
import css from './LocationAutocompleteInput.css'
import LocationAutocompleteInputImpl from './LocationAutocompleteInputImpl.js';
import classNames from 'classnames';

class LocationAutocompleteInputComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSupport: false,
    }
  }
  render() {
    /* eslint-disable no-unused-vars */
    const { rootClassName, labelClassName, supportText, locationTypes, ...restProps } = this.props;
    const { input, label, meta, valueFromForm, ...otherProps } = restProps;
    /* eslint-enable no-unused-vars */

    const value = typeof valueFromForm !== 'undefined' ? valueFromForm : input.value;
    const locationAutocompleteProps = { label, meta, locationTypes, ...otherProps, input: { ...input, value } };
    const labelInfo = label ? (
      <label className={labelClassName} htmlFor={input.name}>
        {label}
      </label>
    ) : null;

    return (
      <div className={rootClassName}>
        <div className={css.headingWrapper}>
          {labelInfo}
          {supportText ? (<div
            onMouseLeave={() => this.setState({ showSupport: false })}
            className={css.support}>
            <svg xmlns="http://www.w3.org/2000/svg" onClick={() =>
              this.setState((prevState) => ({ showSupport: !prevState.showSupport }))
            }
              width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clip-path="url(#clip0_906_753)">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.08984 8.99959C9.32495 8.33126 9.789 7.7677 10.3998 7.40873C11.0106 7.04975 11.7287 6.91853 12.427 7.0383C13.1253 7.15808 13.7587 7.52112 14.2149 8.06312C14.6712 8.60512 14.9209 9.29112 14.9198 9.99959C14.9198 11.9996 11.9198 12.9996 11.9198 12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <circle cx="12" cy="17" r="1" fill="black" />
              </g>
              <defs>
                <clipPath id="clip0_906_753">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className={classNames(css.supportText, { [css.hideSupportText]: !this.state.showSupport })}>
              {supportText}
            </span>
          </div>) : null}
        </div>
        <LocationAutocompleteInputImpl {...locationAutocompleteProps} />
        <ValidationError fieldMeta={meta} />
      </div>
    );
  }
}

LocationAutocompleteInputComponent.defaultProps = {
  rootClassName: null,
  labelClassName: null,
  type: null,
  label: null,
};

LocationAutocompleteInputComponent.propTypes = {
  rootClassName: string,
  labelClassName: string,
  input: shape({
    onChange: func.isRequired,
    name: string.isRequired,
  }).isRequired,
  label: string,
  meta: object.isRequired,
};

export default LocationAutocompleteInputImpl;

export const LocationAutocompleteInputField = props => {
  return <Field component={LocationAutocompleteInputComponent} {...props} />;
};
