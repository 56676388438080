import React from 'react';
import { string } from 'prop-types';

const IconPro = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="36" viewBox="0 0 60 36" fill="none">
    <path d="M0 17.5769C0 8.46421 7.3873 1.0769 16.5 1.0769H43.5C52.6127 1.0769 60 8.46421 60 17.5769C60 26.6896 52.6127 34.0769 43.5 34.0769H0V17.5769Z" fill="#FFAA00"/>
    <path d="M15.9824 24.0769H19.1024V19.7569H20.6424C23.3824 19.7569 25.7624 18.3969 25.7624 15.2769C25.7624 12.0769 23.4224 11.0569 20.5624 11.0569H15.9824V24.0769ZM19.1024 17.2969V13.5169H20.3824C21.9024 13.5169 22.7224 13.9769 22.7224 15.2769C22.7224 16.5769 22.0024 17.2969 20.4624 17.2969H19.1024ZM27.7346 24.0769H30.8146V18.3969C31.3346 17.0769 32.2346 16.5969 32.9746 16.5969C33.4146 16.5969 33.6346 16.6369 33.9946 16.7169L34.5746 14.0969C34.2946 13.9569 33.9546 13.8969 33.3746 13.8969C32.3746 13.8969 31.2746 14.5169 30.5546 15.8569H30.4746L30.2546 14.1369H27.7346V24.0769ZM39.9448 24.3169C42.4848 24.3169 44.8248 22.3969 44.8248 19.0969C44.8248 15.7969 42.4848 13.8969 39.9448 13.8969C37.4248 13.8969 35.0848 15.7969 35.0848 19.0969C35.0848 22.3969 37.4248 24.3169 39.9448 24.3169ZM39.9448 21.8169C38.7848 21.8169 38.2448 20.7569 38.2448 19.0969C38.2448 17.4569 38.7848 16.3769 39.9448 16.3769C41.1048 16.3769 41.6448 17.4569 41.6448 19.0969C41.6448 20.7569 41.1048 21.8169 39.9448 21.8169Z" fill="white"/>
    </svg>
  );
};

IconPro.defaultProps = {
  rootClassName: null,
  className: null,
  fillColor: null,
};

IconPro.propTypes = {
  rootClassName: string,
  className: string,
  fillColor: string,
};

export default IconPro;
