import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { IconReviewUser, Modal } from '../../components';
import { ReviewForm } from '../../forms';

import css from './ReviewModal.css';

export const ReviewModalContent = ({
  revieweeName,
  tx,
  onSubmitReview,
  reviewSent,
  sendReviewInProgress,
  sendReviewError,
  // onSkip = () => {},
}) => {
  const reviewee = <span className={css.reviewee}>{revieweeName}</span>;
  return (
    <>
      <IconReviewUser className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage id="ReviewModal.title" values={{ revieweeName: reviewee }} />
      </p>

      {tx && (
        <div className={css.listingDetails}>
          <p className={css.listingPre}>Listing:</p>
          <p className={css.listingTitle}>{tx.listing.attributes.title}</p>
        </div>
      )}

      <p className={css.modalMessage}>
        <FormattedMessage id="ReviewModal.description" />
      </p>
      <ReviewForm
        onSubmit={onSubmitReview}
        reviewSent={reviewSent}
        sendReviewInProgress={sendReviewInProgress}
        sendReviewError={sendReviewError}
        // onSkip={onSkip}
      />
    </>
  );
};

const ReviewModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    // onSkip = () => {},
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'ReviewModal.later' });

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={() => {
        // onSkip();
        onCloseModal();
      }}
      onManageDisableScrolling={onManageDisableScrolling}
      closeButtonMessage={closeButtonMessage}
    >
      <ReviewModalContent {...rest} />
    </Modal>
  );
};

const { bool, string, func } = PropTypes;

ReviewModal.defaultProps = {
  className: null,
  rootClassName: null,
  reviewSent: false,
  sendReviewInProgress: false,
  sendReviewError: null,
};

ReviewModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  reviewSent: bool,
  sendReviewInProgress: bool,
  sendReviewError: propTypes.error,
  tx: propTypes.transaction,
  onSkip: func,
};

export default injectIntl(ReviewModal);
