import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { PaginationLinks, NamedLink } from '../../components';
import ListingResults from './components/ListingResults/ListingResults.js';
import CompanyResults from './components/CompanyResults/CompanyResults';
import css from './SearchResultsPanel.css';
import { LISTING_TYPES } from 'util/constants';

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    pageName = 'SearchPage',
    setActiveListing,
    allListingReview = {},
    allAuthorNumberOfListings = {},
    params = {},
    currencyConversionRate,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const content = useMemo(() => {
    if (!listings.length) return null;

    const type = search.pub_listing_type || 'listing';

    switch (type) {
      case LISTING_TYPES.LISTING:
        return (
          <ListingResults
            listings={listings}
            setActiveListing={setActiveListing}
            allListingReview={allListingReview}
            currencyConversionRate={currencyConversionRate}
          />
        );
      case LISTING_TYPES.COMPANY:
        return <CompanyResults listings={listings} setActiveListing={setActiveListing} />;
      default:
        return 'Unknown listing type';
    }
    /* eslint-disable-next-line */
  }, [listings, search.pub_listing_type]);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName={pageName}
        pageSearchParams={search}
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  return (
    <div className={classes}>
      <div>
        {content}
        {props.children}
      </div>
      {paginationLinks}
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  allListingReview: {},
  params: {},
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  urlQueryParams: object,
  allListingReview: object,
  params: object,
};

export default SearchResultsPanel;
