import { memoize } from 'lodash/function';

export const getRatingFromReviews = memoize(reviews => {
  if (!reviews || !reviews.length) {
    return 0;
  }

  const reviewsSum = reviews.reduce((acc, review) => acc + review.attributes.rating, 0);
  const reviewsAverage = reviewsSum / reviews.length;
  return Math.round(reviewsAverage * 10) / 10;
});

export const getRatingFromMetadata = metadata => {
  const rating = metadata && metadata.rating;
  if (rating && typeof rating == 'number' && rating > 0) {
    return metadata.rating;
  } else {
    return 0;
  }
};

export const linkImagesToListing = listing => {
  const includedArray = listing.data.included || [];
  const imageRelationshipsArray = listing.data.data.relationships.images.data;

  const imagesArray = includedArray.filter(x =>
    imageRelationshipsArray.find(y => y.id.uuid === x.id.uuid)
  );
  return imagesArray;
};

export const validateStringLength = (str, maxLength = 30) => {
  if (typeof str === 'string' && str.length < maxLength) {
    return str;
  } else {
    return '';
  }
};
