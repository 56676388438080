import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconShare.css';

const IconShare = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1875 10.375C11.2614 10.375 10.4385 10.8251 9.92569 11.5178L5.511 9.26175C5.58506 9.01013 5.625 8.74403 5.625 8.46875C5.625 8.09591 5.55191 7.73987 5.41956 7.41397L10.0464 4.63403C10.5627 5.23997 11.3309 5.625 12.1875 5.625C13.7383 5.625 15 4.36331 15 2.8125C15 1.26169 13.7383 0 12.1875 0C10.6367 0 9.375 1.26169 9.375 2.8125C9.375 3.17094 9.44253 3.51384 9.56531 3.82938L4.92688 6.61625C4.411 6.02819 3.65441 5.65625 2.8125 5.65625C1.26169 5.65625 0 6.91794 0 8.46875C0 10.0196 1.26169 11.2812 2.8125 11.2812C3.75381 11.2812 4.5885 10.8164 5.09928 10.1042L9.50116 12.3537C9.41919 12.6173 9.375 12.8973 9.375 13.1875C9.375 14.7383 10.6367 16 12.1875 16C13.7383 16 15 14.7383 15 13.1875C15 11.6367 13.7383 10.375 12.1875 10.375ZM12.1875 0.9375C13.2214 0.9375 14.0625 1.77863 14.0625 2.8125C14.0625 3.84637 13.2214 4.6875 12.1875 4.6875C11.1536 4.6875 10.3125 3.84637 10.3125 2.8125C10.3125 1.77863 11.1536 0.9375 12.1875 0.9375ZM2.8125 10.3438C1.77863 10.3438 0.9375 9.50262 0.9375 8.46875C0.9375 7.43488 1.77863 6.59375 2.8125 6.59375C3.84637 6.59375 4.6875 7.43488 4.6875 8.46875C4.6875 9.50262 3.84637 10.3438 2.8125 10.3438ZM12.1875 15.0625C11.1536 15.0625 10.3125 14.2214 10.3125 13.1875C10.3125 12.1536 11.1536 11.3125 12.1875 11.3125C13.2214 11.3125 14.0625 12.1536 14.0625 13.1875C14.0625 14.2214 13.2214 15.0625 12.1875 15.0625Z"
        fill="#006A80"
      />
    </svg>
  );
};

IconShare.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconShare.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconShare;
