import React, { useState, useCallback, useEffect } from 'react';
import Slider from 'react-slick';
import ImageViewer from 'react-simple-image-viewer';

import css from './ListingPage.css';
import { ResponsiveImage, Button } from 'components';
import NoImageIcon from 'components/ResponsiveImage/NoImageIcon';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  swipeToSlide: true,
  swipe: true,
  autoplay: true,
  autoplaySpeed: 4000,
  dots: false,
  dotsClass: 'slick-dots slick-thumb',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  nextArrow: <></>,
  prevArrow: <></>,
};

function SectionPhotos(props) {
  const { rootClassName, images, isDraftView } = props;

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);


  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  if (images === null) {
    const noImageClasses = classNames(rootClassName || css.root, css.noImageContainer);

    // NoImageMessage is needed for listing images on top the map (those component lose context)
    // https://github.com/tomchentw/react-google-maps/issues/578
    const noImageMessageText = <FormattedMessage id="ResponsiveImage.noImage" />;
    /* eslint-disable jsx-a11y/img-redundant-alt */

    return (
      <div className={noImageClasses}>
        <div className={css.noImageWrapper}>
          <NoImageIcon className={css.noImageIcon} />
          <div className={css.noImageText}>{noImageMessageText}</div>
        </div>
      </div>
    );
  }

  // const imagesUrl = images.map(photo => photo.attributes.variants['scaled-large']?.url);
  const imagesUrl = images.map(photo => !isDraftView
    ? photo.attributes.variants['scaled-large']?.url
    : photo.attributes.variants['default']?.url);


  return (
    <div className={css.mainContentWrapper}>
      <h2>Photos</h2>
      <div className={rootClassName || css.photoSliderWrapper}>
        <Slider className={css.carousel} {...settings}>
          {imagesUrl.map((imageUrl, index) => (
            <div className={css.photoSingleImageContainer} key={index}>
              <img
                src={imageUrl}
                onClick={() => openImageViewer(index)}
                width="100%"
                height="auto"
                key={index}
                style={{ margin: '2px', padding: '2px' }}
                alt=""
              />
            </div>
          ))}
        </Slider>

        {isViewerOpen && (
          <ImageViewer
            src={imagesUrl}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              zIndex: '90000',
              backgroundColor: 'rgba(0,0,0,0.7)',
            }}
            closeOnClickOutside={true}
          />
        )}
      </div>
    </div>
  );
}

export default SectionPhotos;
