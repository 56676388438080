/**
 * Use the CSS tab above to style your Element's container.
 */
import React from 'react';
import { useStripe, useElements, AddressElement } from '@stripe/react-stripe-js';
const ADDRESS_ELEMENT_OPTIONS = {
  mode: 'billing',
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"sofiapro", Helvetica, Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '18px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};
function BillingDetails(props) {
  const stripe = useStripe();
  const elements = useElements();

  const handleChange = event => {
    return props.handleStripeAddress(event, stripe, elements);
  };

  return (
    <AddressElement
      options={ADDRESS_ELEMENT_OPTIONS}
      onChange={handleChange}
    />
  );
}
export default BillingDetails;
