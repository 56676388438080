import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldSelect } from '../../components';
import countryList from 'react-select-country-list';

import css from './SignupForm.css';

const KEY_CODE_ENTER = 13;
const MIN_AGE = 13;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    initialValues={{ email: props.queryEmail }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfService,
        values,
        adminId,
        queryEmail,
      } = fieldRenderProps;
      const countriesOptions = countryList().getData();

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      // userName
      const userNameLabel = intl.formatMessage({
        id: 'SignupForm.userNameLabel',
      });
      const userNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.userNamePlaceholder',
      });
      const userNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.userNameRequired',
      });
      const userNameRequired = validators.required(userNameRequiredMessage);

      // lyfshortAccountNumber
      const lyfshortAccountNumberLabel = intl.formatMessage({
        id: 'SignupForm.lyfshortAccountNumberLabel',
      });
      const lyfshortAccountNumberPlaceholder = intl.formatMessage({
        id: 'SignupForm.lyfshortAccountNumberPlaceholder',
      });
      const lyfshortAccountNumberRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lyfshortAccountNumberRequired',
      });
      const lyfshortAccountNumberRequired = validators.required(
        lyfshortAccountNumberRequiredMessage
      );

      //Country
      const countryLabel = intl.formatMessage({
        id: 'SignupForm.countryLabel',
      });
      const countryRequiredMessage = intl.formatMessage({
        id: 'SignupForm.countryRequired',
      });
      const countryRequired = validators.required(countryRequiredMessage);

      //Month of Birth
      const monthOfBirthLabel = intl.formatMessage({
        id: 'SignupForm.monthOfBirthLabel',
      });
      const monthOfBirthRequiredMessage = intl.formatMessage({
        id: 'SignupForm.monthOfBirthRequired',
      });
      const monthOfBirthRequired = validators.required(monthOfBirthRequiredMessage);

      //Year of Birth
      const yearOfBirthLabel = intl.formatMessage({
        id: 'SignupForm.yearOfBirthLabel',
      });
      const yearOfBirthRequiredMessage = intl.formatMessage({
        id: 'SignupForm.yearOfBirthRequired',
      });
      const yearOfBirthRequired = validators.required(yearOfBirthRequiredMessage);

      const ageAtLeast = (message, minYears, birth_month = '01') => value => {
        if (value.length === 4) {
          const ageInYears = moment().diff(moment([value, birth_month - 1]), 'years');
          return ageInYears >= minYears ? undefined : message;
        }

        return 'Please 4 digit for year';
      };

      //Gender
      const genderLabel = intl.formatMessage({
        id: 'SignupForm.genderLabel',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            {adminId && (
              <center>
                <h2>Become a trip expert</h2>
              </center>
            )}

            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={lastNameRequired}
              />
            </div>

            <FieldTextInput
              className={css.userNameRoot}
              type="text"
              id={formId ? `${formId}.userName` : 'userName'}
              name="userName"
              label={userNameLabel}
              placeholder={userNamePlaceholder}
              validate={userNameRequired}
            />

            {/* <FieldTextInput
              className={css.userNameRoot}
              type="number"
              id={formId ? `${formId}.lyfshortAccountNumber` : 'lyfshortAccountNumber'}
              name="lyfshortAccountNumber"
              label={lyfshortAccountNumberLabel}
              placeholder={lyfshortAccountNumberPlaceholder}
              validate={lyfshortAccountNumberRequired}
            /> */}

            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordValidators}
            />

            <FieldSelect
              className={css.country}
              type="select"
              name="country"
              id="country"
              label={countryLabel}
              validate={countryRequired}
            >
              <option value="">Select Country</option>
              {countriesOptions.map(country => (
                <option key={country.value} value={country.value}>
                  {country.label}
                </option>
              ))}
            </FieldSelect>

            <h3>Confirm Your Age</h3>
            <div className={css.name}>
              <FieldSelect
                className={css.firstNameRoot}
                type="select"
                name="birth_month"
                id="birth_month"
                label={monthOfBirthLabel}
                validate={monthOfBirthRequired}
              >
                <option value="">Select</option>
                <option value="01">January</option>
                <option value="02">February</option>
                <option value="03">March</option>
                <option value="04">April</option>
                <option value="05">May</option>
                <option value="06">June</option>
                <option value="07">July</option>
                <option value="08">August</option>
                <option value="09">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </FieldSelect>

              <FieldTextInput
                className={css.lastNameRoot}
                type="number"
                id={'birth_year'}
                name="birth_year"
                label={yearOfBirthLabel}
                placeholder={yearOfBirthLabel}
                validate={validators.composeValidators(
                  yearOfBirthRequired,
                  ageAtLeast(`Age should be at least ${MIN_AGE}`, MIN_AGE, values.birth_month)
                )}
              />
            </div>

            <FieldSelect
              className={css.country}
              type="select"
              name="gender"
              id="gender"
              label={genderLabel}
            >
              <option value="">Select</option>
              <option value="female">Female</option>
              <option value="male">Male</option>
              <option value="non binary">Non Binary</option>
            </FieldSelect>
          </div>

          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                />
              </span>
            </p>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
