import React from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { FormattedMessage } from 'react-intl';
import css from './GlobalAvailabilityPage.css';

const supportedCountryList = [
  {
    code: 'au',
    name: 'Australia',
  },
  {
    code: 'at',
    name: 'Austria',
  },
  {
    code: 'be',
    name: 'Belgium',
  },
  {
    code: 'bg',
    name: 'Bulgaria',
  },
  {
    code: 'ca',
    name: 'Canada',
  },
  {
    code: 'hr',
    name: 'Croatia',
  },
  {
    code: 'dk',
    name: 'Denmark',
  },
  {
    code: 'ee',
    name: 'Estonia',
  },
  {
    code: 'fi',
    name: 'Finland',
  },
  {
    code: 'fr',
    name: 'France',
  },
  {
    code: 'de',
    name: 'Germany',
  },
  {
    code: 'gi',
    name: 'Gibraltar',
  },
  {
    code: 'gr',
    name: 'Greece',
  },
  {
    code: 'hk',
    name: 'Hong Kong',
  },
  {
    code: 'hu',
    name: 'Hungary',
  },
  {
    code: 'ie',
    name: 'Ireland',
  },
  {
    code: 'it',
    name: 'Italy',
  },
  {
    code: 'jp',
    name: 'Japan',
  },
  {
    code: 'lv',
    name: 'Latvia',
  },
  {
    code: 'li',
    name: 'Liechtenstein',
  },
  {
    code: 'lt',
    name: 'Lithuania',
  },
  {
    code: 'lu',
    name: 'Luxembourg',
  },
  {
    code: 'mt',
    name: 'Malta',
  },
  {
    code: 'nl',
    name: 'Netherlands',
  },
  {
    code: 'nz',
    name: 'New Zealand',
  },
  {
    code: 'no',
    name: 'Norway',
  },
  {
    code: 'pl',
    name: 'Poland',
  },
  {
    code: 'pt',
    name: 'Portugal',
  },
  {
    code: 'ro',
    name: 'Romania',
  },
  {
    code: 'sg',
    name: 'Singapore',
  },
  {
    code: 'sk',
    name: 'Slovakia',
  },
  {
    code: 'si',
    name: 'Slovenia',
  },
  {
    code: 'es',
    name: 'Spain',
  },
  {
    code: 'se',
    name: 'Sweden',
  },
  {
    code: 'ch',
    name: 'Switzerland',
  },
  {
    code: 'ae',
    name: 'United Arab Emirates',
  },
  {
    code: 'gb',
    name: 'United Kingdom',
  },
  {
    code: 'us',
    name: 'United States',
  },
];

export default function GlobalAvailabilityPage() {
  return (
    <StaticPage
      title="Global Availability"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Global Availability Page',
        description: 'Global Availability',
        name: 'Global Availability Page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <section className={css.sectionWrapper}>
            <div className={css.title}>
              <FormattedMessage id="GlobalAvailabilityPage.partnerTravelBusinessTitle" />
            </div>
            <ul className={css.countryListSection}>
              {supportedCountryList.map((country, i) => (
                <li key={i} className={css.countryListItem}>
                  <span className={`fi fi-${country.code}`}></span>
                  <span className={css.countryNameTitle}>{country.name}</span>
                </li>
              ))}
            </ul>
            <div className={css.descriptionTextContainer}>
              <p>
                <FormattedMessage
                  id="GlobalAvailabilityPage.partnerTravelBusinessContent"
                  values={{
                    email: chunks => <a href={`mailto:${chunks}`}>{chunks}</a>,
                  }}
                />
              </p>
            </div>
            <div className={css.title}>
              <FormattedMessage id="GlobalAvailabilityPage.tripExpertLocationTitle" />
            </div>
            <div className={css.descriptionTextContainer}>
              <p>
                <FormattedMessage id="GlobalAvailabilityPage.tripExpertLocationContent" />
              </p>
            </div>
            <div className={css.title}>
              <FormattedMessage id="GlobalAvailabilityPage.receivingPaymentsTitle" />
            </div>
            <div className={css.descriptionTextContainer}>
              <p>
                <FormattedMessage
                  id="GlobalAvailabilityPage.receivingPaymentsContent"
                  values={{
                    support: chunks => (
                      <a
                        href="https://stripe.com/docs/payments/cards/supported-card-brands"
                        target="_blank"
                      >
                        {chunks}
                      </a>
                    ),
                    currency: chunks => (
                      <a href="https://stripe.com/docs/currencies" target="_blank">
                        {chunks}
                      </a>
                    ),
                    stripe: chunks => (
                      <a href="https://stripe.com/in/global" target="_blank">
                        {chunks}
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          </section>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
}
