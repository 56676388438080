import React from 'react';
import { bool } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney, getRefCurrencyValue } from '../../util/currency';
import { txIsCanceled, txIsCompleted } from '../../util/transaction';
import { propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemTotalPrice = props => {
  const { transaction, isProvider, intl, currencyConversionRate } = props;
  const { to = 'USD', rate = 1 } = currencyConversionRate ?? {};
  const refCurrencyShow = to !== 'USD';

  let providerTotalMessageId = 'OrderBreakdown.providerTotalDefault';
  if (txIsCompleted(transaction)) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalReceived';
  } else if (txIsCanceled(transaction)) {
    providerTotalMessageId = 'OrderBreakdown.providerTotalCanceled';
  }

  const totalLabel = isProvider ? (
    <FormattedMessage id={providerTotalMessageId} />
  ) : (
    <FormattedMessage id="OrderBreakdown.total" />
  );

  const totalPrice = isProvider
    ? transaction.attributes.payoutTotal
    : transaction.attributes.payinTotal;
  const formattedTotalPrice = formatMoney(intl, totalPrice);
  const refFormattedTotalPrice = getRefCurrencyValue(intl, rate, totalPrice);

  return (
    <>
      <hr className={css.totalDivider} />
      <div className={css.lineItemTotal}>
        <div className={css.totalLabel}>{totalLabel}</div>
        <div className={css.totalPrice}>{formattedTotalPrice}</div>
      </div>
      {refCurrencyShow && (
        <div className={css.lineItemRefTotal}>
          <div className={css.refTotalPrice}>{`Est. ~${to} ${refFormattedTotalPrice}`}</div>
        </div>
      )}
    </>
  );
};

// LineItemTotalPrice.propTypes = {
//   transaction: propTypes.transaction.isRequired,
//   isProvider: bool.isRequired,
//   intl: intlShape.isRequired,
// };

export default LineItemTotalPrice;
