import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cns from 'classnames';

import css from './SmallScrollArrow.css';
import RightLightArrow from '../../assets/Landing_Pages/RightLightArrow.svg';
import RightDarkArrow from '../../assets/Landing_Pages/RightDarkArrow.svg';

function SmallScrollArrow(props) {
  const { onClick, className, direction, customStyles, disable = false } = props;
  const rootClass = cns({
    [css.root]: true,
    [className]: !!className,
    [css.left]: direction === 'left',
    [css.right]: direction === 'right'
  });

  const imgClass = cns({
    [css.img]: !disable,
    [css.disableImg]: disable,
  });

  const handleClick = e => {
    onClick(e);
  };

  return (
    <div style={{ ...customStyles }} className={rootClass} onClick={handleClick}>
      <div className={imgClass}>
        <img src={disable ? RightDarkArrow : RightLightArrow} alt='arrow' />
      </div>
    </div>
  );
}

SmallScrollArrow.propTypes = {
  onClick: PropTypes.func,
  direction: PropTypes.oneOf(['left', 'right']),
  customStyles: PropTypes.object,
  className: PropTypes.string,
};

export default memo(SmallScrollArrow);
