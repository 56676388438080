import React from 'react';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from 'components';
import { TopbarContainer } from 'containers/index';
import css from './ProcessesPage.css';

import diagram1 from '../../assets/Overview_Page/Hire_Trip_Expert_Flow.svg';
import diagram2 from '../../assets/Overview_Page/Become_Trip_Expert_Flow.svg';
import diagram3 from '../../assets/Overview_Page/Become_Partner_Flow.svg';

export default function ProcessesPage() {
  return (
    <Page title="Processes">
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer isDarkMode={true} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.processesSection}>
            <div className={css.containerWrapper}>
              <h1>Processes</h1>
              <div className={css.processDiagramWrapper}>
                <img src={diagram1} alt='process diagram 1' />
              </div>
              <div className={css.processDiagramWrapper}>
                <img src={diagram2} alt='process diagram 1' />
              </div>
              <div className={css.processDiagramWrapper}>
                <img src={diagram3} alt='process diagram 1' />
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  )
}
