import React from 'react';
import css from './LandingPageExtraordinarySection.css';
import { FormattedMessage } from '../../util/reactIntl';
import Extraordinary_Image1 from '../../assets/Landing_Pages/Extraordinary/Extraordinary_Image1.png';
import Extraordinary_Image2 from '../../assets/Landing_Pages/Extraordinary/Extraordinary_Image2.png';
import Extraordinary_Image3 from '../../assets/Landing_Pages/Extraordinary/Extraordinary_Image3.png';
import Extraordinary_Image4 from '../../assets/Landing_Pages/Extraordinary/Extraordinary_Image4.png';
import Extraordinary_Image5 from '../../assets/Landing_Pages/Extraordinary/Extraordinary_Image5.png';
import Extraordinary_Image6 from '../../assets/Landing_Pages/Extraordinary/Extraordinary_Image6.png';

const LandingPageExtraordinarySection = props => {
  const ExtraordinaryList = [{
    id: 'extraordinary1',
    src: Extraordinary_Image1,
    title: "ExtraordinarySection.extraordinaryTitle1",
    description: "ExtraordinarySection.extraordinaryDescription1"
  }, {
    id: 'extraordinary2',
    src: Extraordinary_Image2,
    title: "ExtraordinarySection.extraordinaryTitle2",
    description: "ExtraordinarySection.extraordinaryDescription2"
  }, {
    id: 'extraordinary3',
    src: Extraordinary_Image3,
    title: "ExtraordinarySection.extraordinaryTitle3",
    description: "ExtraordinarySection.extraordinaryDescription3"
  }, {
    id: 'extraordinary4',
    src: Extraordinary_Image4,
    title: "ExtraordinarySection.extraordinaryTitle4",
    description: "ExtraordinarySection.extraordinaryDescription4"
  }, {
    id: 'extraordinary5',
    src: Extraordinary_Image5,
    title: "ExtraordinarySection.extraordinaryTitle5",
    description: "ExtraordinarySection.extraordinaryDescription5"
  }, {
    id: 'extraordinary6',
    src: Extraordinary_Image6,
    title: "ExtraordinarySection.extraordinaryTitle6",
    description: "ExtraordinarySection.extraordinaryDescription6"
  }];

  return (
    <div className={css.extraordinarySectionContainer}>
      <div className={css.title}>
        <FormattedMessage id="ExtraordinarySection.title" />
      </div>
      <div className={css.listContainer}>
        {
          ExtraordinaryList.map((extraordinary) => (
            <div id={extraordinary.id} className={css.listWrapper}>
              <img src={extraordinary.src} alt={extraordinary.title} />
              <div className={css.listContentWrap}>
                <FormattedMessage id={extraordinary.title} />
                <FormattedMessage id={extraordinary.description} />
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

LandingPageExtraordinarySection.defaultProps = {};

LandingPageExtraordinarySection.propTypes = {

};

export default LandingPageExtraordinarySection;
