import React, { useEffect, useState } from 'react';

import css from './TaxJarInfo.css';
import { estimatedTotalPrice, estimatedTransaction, getTaxInfo } from './utils';

const showTaxInfo = process.env.REACT_APP_SHOW_TAX_INFO === 'true';

const TaxJarInfo = props => {
  const { lineItems, listing } = props;

  const [taxInfo, setTaxInfo] = useState(null);
  useEffect(() => {
    if (lineItems && listing && showTaxInfo) {
      getTaxInfo(setTaxInfo, lineItems, listing);
    }
  }, [lineItems]);

  if (!showTaxInfo) {
    return null;
  }

  const totalPayin =
    lineItems &&
    estimatedTotalPrice(lineItems.filter(item => item.includeFor.includes('customer')));

  if (!taxInfo || !lineItems || !totalPayin) {
    return null;
  }
  console.log(taxInfo);
  return (
    <div className={css.wrapper}>
      <h2 className={css.title}>Tax info</h2>
      <p className={css.field}>
        <b>Tax amount:</b> ${taxInfo?.amount_to_collect}
      </p>
      <p className={css.field}>
        <b>Freight taxable:</b> {taxInfo?.freight_taxable ? 'yes' : 'no'}
      </p>
      <p className={css.field}>
        <b>Has nexus:</b> {taxInfo?.has_nexus ? 'yes' : 'no'}
      </p>
      <p className={css.field}>
        <b>Juristiction:</b> {taxInfo?.jurisdictions?.country}
      </p>
      <p className={css.field}>
        <b>Order total amount:</b> ${taxInfo?.order_total_amount}
      </p>
      <p className={css.field}>
        <b>Tax rate:</b> {taxInfo?.rate}
      </p>
      <p className={css.field}>
        <b>Shipping:</b> ${taxInfo?.shipping}
      </p>

      <p className={css.field}>
        <b>Taxable Amount:</b> ${taxInfo?.taxable_amount}
      </p>
    </div>
  );
};

export default TaxJarInfo;
