import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from 'util/types';
import { intlShape } from 'util/reactIntl';
import moment from 'moment';
import classNames from 'classnames';

import { BookingInfoMaybe, UserDisplayName } from 'components';
import css from './PartnerMemberTransactionItem.css';
import { formatMoney } from 'util/currency';
import { ensurePrice } from 'util/data';
import { txIsRequested, txIsExtendRequested, txIsPending, txIsAccepted } from 'util/transaction';
const _ = require('lodash');

const removeUnit = inputText => {
  return _.trimStart(inputText, 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
};

function PartnerMemberTransactionItem(props) {
  const { tx, intl, stateData } = props;
  const { customer, provider, listing, id } = tx;

  const tripName =
    tx.attributes.protectedData && tx.attributes.protectedData?.tripDetails?.tripName;
  const tripLocation =
    tx.attributes.protectedData?.tripDetails?.tripLocation &&
    tx.attributes.protectedData?.tripDetails?.tripLocation.search;
  const targetStartDate =
    tx.attributes.protectedData && tx.attributes.protectedData?.tripDetails?.targetStartDate;
  const minBudget =
    tx.attributes.protectedData &&
    ensurePrice(tx.attributes.protectedData?.tripDetails?.estimatedMinBudget);
  const maxBudget =
    tx.attributes.protectedData &&
    ensurePrice(tx.attributes.protectedData?.tripDetails?.estimatedMaxBudget);
  const minBudgetChip =
    minBudget?.currency && minBudget?.amount && formatMoney(intl, minBudget, 0, 2);
  const maxBudgetChip =
    maxBudget?.currency && maxBudget?.amount && formatMoney(intl, maxBudget, 0, 2);
  const maxBudgetChipInUse = removeUnit(maxBudgetChip);
  const totalPrice = ensurePrice(tx.attributes.payinTotal);

  const otherUserDisplayName = <UserDisplayName user={customer} intl={intl} />;
  const rowNotificationDot = txIsRequested(tx) ? <div className={css.notificationDot} /> : null;

  return (
    <div className={css.item}>
      <div className={css.itemLink}>
        <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
        <div className={css.itemInfo}>
          <div>
            <div className={css.priceContainer}>{formatMoney(intl, totalPrice, 0, 2)}</div>
            <div className={css.itemUsername}>
              <div className={css.clientBookListingWrapper}>
                <span className={css.userDisplayNameAdminWrapper}>
                  {otherUserDisplayName} - {tripName}
                </span>
                <span className={css.listingNameWrapper}>
                  <div className={classNames(css.chip, css.mobileMaybe)}>
                    {`Budget: ${minBudgetChip} - ${maxBudgetChipInUse}`}
                  </div>
                  <div className={classNames(css.chip, css.mobileMaybe)}>{tripLocation}</div>
                  <div className={classNames(css.chip, css.mobileMaybe)}>
                    {moment(new Date(targetStartDate)).format('ddd Do MMM')}
                  </div>
                </span>
              </div>
            </div>
            <div className={css.transactionId}>{`TransID - ${id?.uuid}`}</div>
            <div className={css.stateDetail}>
              <BookingInfoMaybe bookingClassName={css.bookingNoActionNeeded} intl={intl} tx={tx} />
            </div>
          </div>
          <div
            className={classNames(
              css.stateName,
              {
                [css.stateDelivered]: stateData.state === 'Delivered / Collaborate',
                [css.stateAccepted]: stateData.state === 'Accepted',
                [css.stateCancelled]: stateData.state === 'Cancelled',
                [css.stateSuccess]: stateData.state === 'Completed',
              },
              stateData.stateClassName
            )}
          >
            <div className={css.completeLabelWrapper}>{stateData.state}</div>
            {(txIsRequested(tx) ||
              (txIsAccepted(tx) && !txIsExtendRequested(tx)) ||
              txIsPending(tx)) && <div className={css.actionRequiredMark}>Action Required</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

PartnerMemberTransactionItem.propTypes = {
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
  stateData: PropTypes.object,
};

export default PartnerMemberTransactionItem;
