import config from '../../../config';
import { convertArrayToSearchString } from 'util/urlHelpers';

const convertLabelsToSuggestionsKeysSearchString = labels => {
  const suggestionsOptions = config.custom.suggestionCategoryOptions;
  const filtered = labels.filter(l => suggestionsOptions.find(x => x.label === l));
  const converted = filtered.map(f => suggestionsOptions.find(x => x.label === f).key);

  const type = 'has_any';
  const searchString = convertArrayToSearchString(converted, type);
  return searchString;
};

export const getPredictionsFromCategory = category => {
  switch (category) {
    case 'learn_&_explore':
      return convertLabelsToSuggestionsKeysSearchString([
        'Backpacking',
        'Camping',
        'Clubs & Pubs',
        'Historical Tours',
        'Long Weekend',
        'Nature Parks',
        'Photography',
        'Religion',
        'Road Trip',
        'Sightseeing',
        'Volunteering',
      ]);
    case 'relax_&_recharge':
      return convertLabelsToSuggestionsKeysSearchString([
        'Beach',
        'Cruise',
        'Health & Fitness',
        'Long Weekend',
        'Photography',
        'Resort',
        'Road Trip',
        'Shopping',
        'Snowsports',
        'Spa & Massage',
        'Sporting Events',
        'Watersports',
      ]);

    case 'escape_&_Reflect':
      return convertLabelsToSuggestionsKeysSearchString([
        'Backpacking',
        'Beach',
        'Cruise',
        'Festivals',
        'Long Weekend',
        'Nature Parks',
        'Resort',
        'Shopping',
        'Spa & Massage',
        'Volunteering',
      ]);

    case 'have_an_adventure':
      return convertLabelsToSuggestionsKeysSearchString([
        'Adrenaline Sports',
        'Backpacking',
        'Festivals',
        'Group Sports',
        'Hiking & Climbing',
        'Individual Sports',
        'Music Events',
        'Road Trip',
        'Snowsports',
        'Sporting Events',
        'Watersports',
      ]);
    case 'celebratration_event':
      return convertLabelsToSuggestionsKeysSearchString([
        'Birthday',
        'Wedding',
        'Buck & Hens',
        'Anniversary',
        'Romance',
        'New Year',
        'Restaurants & Dining',
        'Clubs & Pubs',
      ]);
    case 'socialise_&_experience':
      return convertLabelsToSuggestionsKeysSearchString([
        'Clubs & Pubs',
        'Restaurants & Dining',
        'Gambling',
        'Volunteering',
        'Religion',
        'Group Sports',
        'Music Events',
        'Long Weekend',
      ]);

    case 'work':
      return convertLabelsToSuggestionsKeysSearchString([
        'Long Weekend',
        'Resort',
        'Spa & Massage',
        'Sightseeing',
        'Historical',
        'Health & Fitness',
        'Shopping',
        'Restaurants & Dining',
      ]);
    case 'visit_Someone':
      return convertLabelsToSuggestionsKeysSearchString([
        'Road Trip',
        'Long Weekend',
        'Clubs & Pubs',
        'Restaurants & Dining',
      ]);
    case 'family_getaway':
      return convertLabelsToSuggestionsKeysSearchString([
        'Backpacking',
        'Birthday',
        'Health & Fitness',
        'Hiking & Climbing',
        'Historical Tours',
        'Long Weekend',
        'Nature Parks',
        'Resort',
        'Road Trip',
        'Snowsports',
        'Watersports',
      ]);

    default:
      return null;
  }
};

export const convertSuggestionsStringTotripReasonValue = currentSuggestionsParams => {
  const tripReasonsKeys = config.custom.tripReasonOptions;
  const predictions = tripReasonsKeys.map(t => {
    return {
      key: t.key,
      suggentionsString: getPredictionsFromCategory(t.key),
    };
  });

  const foundTripReason = predictions.find(s => s.suggentionsString === currentSuggestionsParams);
  return foundTripReason?.key;
};

export const convertMultipleSelectQueryStringToArray = queryString => {
  if (!queryString) {
    return null;
  }
  const types = ['has_all:', 'has_any:'];
  types.forEach(t => {
    queryString = queryString.replace(t, '');
  });
  const finalArray = queryString.split(',');
  return finalArray;
};

export const convertSelectQueryStringToValue = queryString => {
  if (!queryString) {
    return null;
  }
  const types = ['has_all:', 'has_any:'];
  types.forEach(t => {
    queryString = queryString.replace(t, '');
  });
  return queryString;
};

export const convertQueryParamsToChips = (queryParams, allOptions) => {
  // TODO improve this to work for single select filters too
  const entries = Object.entries(queryParams);
  const chips = entries.map(e => {
    const mainKey = e[0];
    const mainValue = e[1];
    const types = ['has_all:', 'has_any:'];

    //multiple select case
    if (typeof mainValue === 'string' && types.find(t => mainValue.includes(t))) {
      const arrayOfValues = convertMultipleSelectQueryStringToArray(mainValue);
      const subArrayOfChips = arrayOfValues.map(x => {
        const label = allOptions.find(l => l.key === x)?.label;
        return {
          searchKey: mainKey,
          value: x,
          label: label,
        };
      });

      return subArrayOfChips;
    } else if (typeof mainValue === 'string' && mainKey === 'price') {
      const priceValues = mainValue.split(',');
      const label = `$${priceValues[0]} - $${priceValues[1]}`;
      return {
        searchKey: mainKey,
        value: mainValue,
        label: label,
      };
    } else if (typeof mainValue === 'string' && mainKey === 'address') {
      const boundsParam = entries.find(e => e[0] === 'bounds');
      const label = mainValue;
      return {
        searchKey: mainKey,
        value: mainValue,
        label,
        boundsParam,
      };
    }
  });

  const flattenChips = chips.flat();
  return flattenChips;
};
