import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  maxLength,
  required,
  composeValidators,
  autocompleteSearchRequired,
  autocompletePlaceSelected,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  HeadingFormFieldWrapper,
  LocationAutocompleteInputField,
  FieldMultipleSelection,
  NamedLink,
  FieldSelect,
} from '../../components';
import config from '../../config';

import css from './EditListingDescriptionForm.css';
import { accreditationTypes } from 'marketplace-custom-config';

const identity = v => v;

const TITLE_MAX_LENGTH = 60;
const PROVIDER_MAX_LENGTH = 30;
const YEARS_MAX_LENGTH = 3;

class EditListingDescriptionFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: props.category,
      subCategories: [],
      categoryBasedCustomFields: [],
    };
  }

  componentDidMount() {
    this.handleCategoryChange(this.props.category);
  }

  handleCategoryChange = category => {
    const categoriesConfig = config.custom.categories;
    const categoryBasedCustomFieldsConfig = config.custom.categoryBasedFields;
    const subCategoryConfig = category ? categoriesConfig.find(cc => cc.key === category) : [];
    const subCategories = (subCategoryConfig && subCategoryConfig.subCategories) || [];
    const CustomFieldsConfig =
      category !== undefined && category !== '' && subCategories.length
        ? categoryBasedCustomFieldsConfig.find(cc => cc.key === category).fields
        : [];
    this.setState({
      category: category,
      subCategories: subCategories,
      categoryBasedCustomFields: CustomFieldsConfig,
    });
  };

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            className,
            disabled,
            ready,
            handleSubmit,
            intl,
            invalid,
            pristine,
            saveActionMsg,
            updated,
            updateInProgress,
            fetchErrors,
            values,
          } = formRenderProps;

          const { updateListingError, createListingDraftError, showListingsError } =
            fetchErrors || {};
          const errorMessageUpdateListing = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
            </p>
          ) : null;

          // This error happens only on first tab (of EditListingWizard)
          const errorMessageCreateListingDraft = createListingDraftError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
            </p>
          ) : null;

          //Travel_business
          const travelBusinessMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.travelBusiness',
          });

          const travelBusinessPlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.travelBusinessPlaceholder',
          });

          const travelBusinessSupportMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.travelBusinessSupport',
          });

          const travelBusinessRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.travelBusinessRequired',
          });

          //Travel_business_busnumber
          const businessNumberMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.businessNumber',
          });

          const businessNumberPlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.businessNumberPlaceholder',
          });

          const businessNumberSupportMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.businessNumberSupport',
          });

          const businessNumberRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.businessNumberRequired',
          });

          //Travel_business_address
          const registeredAddressMessage = intl.formatMessage(
            {
              id: 'EditListingDescriptionForm.registeredAddress',
            },
            {
              i: chunks => <i style={{fontWeight: 300}}>{chunks}</i>,
            }
          );
          const registeredAddressPlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.registeredAddressPlaceholder',
          });
          const registeredAddressSupportMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.registeredAddressSupport',
          });

          //Business Location
          const businessLocationMessage = intl.formatMessage(
            {
              id: 'EditListingDescriptionForm.businessLocation',
            },
            {
              i: chunks => <i style={{fontWeight: 300}}>{chunks}</i>,
            }
          );
          const businessLocationPlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.businessLocationPlaceholder',
          });
          const businessLocationSupportMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.businessLocationSupport',
          });

          const registeredAddressRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.registeredAddressRequiredMessage',
          });

          const businessLocationRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.businessLocationRequiredMessage',
          });

          const registeredAddressNotRecognizedMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.registeredAddressNotRecognizedMessage',
          });

          //Travel_business_buyers
          const yearsInBusinessMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.yearsInBusinessNumber',
          });

          const yearsInBusinessPlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.yearsInBusinessPlaceholder',
          });

          const yearsInBusinessSupportMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.yearsInBusinessSupport',
          });

          const yearsInBusinessRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.yearsInBusinessRequired',
          });

          const yearsLengthMessage = intl.formatMessage(
            { id: 'EditListingDescriptionForm.maxLength' },
            {
              maxLength: YEARS_MAX_LENGTH,
            }
          );

          const yearsLength3Message = maxLength(yearsLengthMessage, YEARS_MAX_LENGTH);

          //Travel_business_acreditation
          const accreditationMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.accreditation',
          });
          const accreditationSupportMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.accreditationSupport',
          });

          const accreditationPlaceholderMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.accreditationPlaceholder',
          });

          const accreditationRequiredMessage = intl.formatMessage({
            id: 'EditListingDescriptionForm.accreditationRequired',
          })

          const classes = classNames(css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const { Travel_business_acreditation } = values;
          const noAccreditation = !Travel_business_acreditation || Travel_business_acreditation?.filter(Boolean)?.length === 0;
          const submitDisabled = invalid || disabled || submitInProgress || noAccreditation;

          //Travel_business_payment_method
          const paymentMethodLabel = intl.formatMessage({
            id: 'EditBusinessFinancialForm.paymentMethod',
          });

          const paymentMethodSupportMessage = intl.formatMessage({
            id: 'EditBusinessFinancialForm.paymentMethodSupport',
          });

          const paymentMethodRequiredMessage = intl.formatMessage({
            id: 'EditBusinessFinancialForm.paymentMethodRequired',
          });

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.btnWrapper}>
                <div>
                  <h1 className={css.headingTitle}>{'Business'}</h1>
                </div>
                <div className={css.topButtons}>
                  <div className={css.guideButton}>
                    <NamedLink
                      name="BusinessGettingStartedPage"
                      className={css.link}
                      target="_blank"
                    >
                      Help Getting Started
                    </NamedLink>
                  </div>
                  <Button
                    rootClassName={css.topSaveButton}
                    type="submit"
                    inProgress={false}
                    disabled={submitDisabled}
                    ready={submitReady}
                  >
                    {saveActionMsg}
                  </Button>
                </div>
              </div>
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}

              <div className={css.formGroup}>
                <FieldTextInput
                  key="Travel_business"
                  id="Travel_business"
                  name="Travel_business"
                  className={css.title}
                  type="text"
                  label={travelBusinessMessage}
                  supportText={travelBusinessSupportMessage}
                  placeholder={travelBusinessPlaceholderMessage}
                  validate={composeValidators(required(travelBusinessRequiredMessage))}
                />

                <FieldTextInput
                  key="Travel_business_busnumber"
                  id="Travel_business_busnumber"
                  name="Travel_business_busnumber"
                  className={css.title}
                  type="text"
                  label={businessNumberMessage}
                  supportText={businessNumberSupportMessage}
                  placeholder={businessNumberPlaceholderMessage}
                  validate={composeValidators(required(businessNumberRequiredMessage))}
                />

                <LocationAutocompleteInputField
                  className={css.locationAddress}
                  inputClassName={css.locationAutocompleteInput}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.desktopPredictions}
                  validClassName={css.validLocation}
                  autoFocus
                  autocomplete="off"
                  name="Travel_business_address"
                  locationTypes={['address']}
                  needContext={true}
                  id="Travel_business_address"
                  label={registeredAddressMessage}
                  supportText={registeredAddressSupportMessage}
                  placeholder={registeredAddressPlaceholderMessage}
                  useDefaultPredictions={false}
                  format={identity}
                  // valueFromForm={values.location}
                  validate={composeValidators(
                    autocompleteSearchRequired(registeredAddressRequiredMessage),
                    autocompletePlaceSelected(registeredAddressNotRecognizedMessage)
                  )}
                />

                <LocationAutocompleteInputField
                  className={css.locationAddress}
                  inputClassName={css.locationAutocompleteInput}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.desktopPredictions}
                  validClassName={css.validLocation}
                  autoFocus
                  autocomplete="off"
                  name="Travel_business_location"
                  locationTypes={['country', 'region', 'locality', 'place', 'district']}
                  needContext={true}
                  id="Travel_business_location"
                  label={businessLocationMessage}
                  supportText={businessLocationSupportMessage}
                  placeholder={businessLocationPlaceholderMessage}
                  useDefaultPredictions={false}
                  format={identity}
                  // valueFromForm={values.location}
                  validate={composeValidators(
                    autocompleteSearchRequired(businessLocationRequiredMessage),
                    autocompletePlaceSelected(registeredAddressNotRecognizedMessage)
                  )}
                />

                <FieldTextInput
                  key="Travel_business_busyears"
                  id="Travel_business_busyears"
                  name="Travel_business_busyears"
                  className={css.title}
                  type="number"
                  label={yearsInBusinessMessage}
                  supportText={yearsInBusinessSupportMessage}
                  placeholder={yearsInBusinessPlaceholderMessage}
                  validate={composeValidators(
                    required(yearsInBusinessRequiredMessage),
                    yearsLength3Message
                  )}
                />

                <HeadingFormFieldWrapper
                  label={accreditationMessage}
                  supportText={accreditationSupportMessage}
                >
                  <FieldSelect
                    key="Travel_business_acreditation_0"
                    className={css.customField}
                    type="select"
                    name="Travel_business_acreditation[0]"
                    id="Travel_business_acreditation_0"
                    validate={composeValidators(required(accreditationRequiredMessage))}
                  >
                    <option value="">{accreditationPlaceholderMessage}</option>
                    {accreditationTypes.map(acc => (
                      <option key={acc} value={acc} label={acc} />
                    ))}
                  </FieldSelect>

                  {[...Array(4)].map((_, index) => (
                    <FieldSelect
                      key={`Travel_business_acreditation_${index + 1}`}
                      className={css.title}
                      type="select"
                      name={`Travel_business_acreditation[${index + 1}]`}
                      id={`Travel_business_acreditation_${index + 1}`}
                    >
                      <option value="">
                        {intl.formatMessage(
                          { id: 'EditListingDescriptionForm.accreditationLabel' },
                          {
                            count: index + 1,
                          }
                        )}
                      </option>
                      {accreditationTypes.map(acc => (
                        <option key={acc} value={acc} label={acc} />
                      ))}
                    </FieldSelect>
                  ))}
                </HeadingFormFieldWrapper>

                <div className={css.sectionTitle}>
                  <strong>Travel Agent Payments</strong>
                </div>

                <FieldMultipleSelection
                  name="Travel_business_payment_method"
                  id="Travel_business_payment_method"
                  className={css.title}
                  label={paymentMethodLabel}
                  supportText={paymentMethodSupportMessage}
                  options={config.custom.PaymentMethodsConfig}
                  validate={composeValidators(required(paymentMethodRequiredMessage))}
                  limit={5}
                />
                <Button
                  rootClassName={css.submitButton}
                  type="submit"
                  inProgress={false}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  listingType: string,
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
