import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { array, bool, func, number, object, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { getEditListingPageName } from 'util/urlHelpers';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { withViewport } from '../../util/contextHelpers';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import {
  ADMIN,
  AGREEMENT,
  LISTING_TYPES,
  VIEWDRAFTBUSINESS,
  VIEWDRAFTLISTING,
  PAYMENTS,
  DESCRIPTION,
  OVERVIEW,
  PROFILE,
  LOCATION,
  PRICING,
  PHOTOS,
  INTRODUCTION,
} from 'util/constants';
import { ensureCurrentUser, ensureListing } from '../../util/data';
import { Modal, NamedRedirect, Tabs, StripeConnectAccountStatusBox } from '../../components';
import { StripeConnectAccountForm } from '../../forms';
import EditListingWizardTab from './EditListingWizardTab';
import css from './EditListingWizard.css';
import { COMPANY_TABS, getTabs, TABS } from 'components/EditListingWizard/helpers';

// Tabs are horizontal in small screens
const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

////////////
// Portal //
////////////

// TODO: change all the modals to use portals at some point.
// Portal is used here to circumvent the problems that rise
// from different levels of z-indexes in DOM tree.
// Note: React Portal didn't exist when we originally created modals.

class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    this.props.portalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    this.props.portalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

const tabLabel = (intl, tab, type) => {
  let key = null;
  if (tab === DESCRIPTION) {
    key = 'EditListingWizard.tabLabelBusiness';
  } else if (tab === INTRODUCTION) {
    key = 'EditListingWizard.tabLabelIntroduction';
  } else if (tab === OVERVIEW) {
    key = 'EditListingWizard.tabLabelOverview';
  } else if (tab === PROFILE) {
    key = 'EditListingWizard.tabLabelProfile';
  } else if (tab === LOCATION) {
    key = 'EditListingWizard.tabLabelLocation';
  } else if (tab === PRICING) {
    key = 'EditListingWizard.tabLabelPricing';
  } else if (tab === PHOTOS) {
    key = 'EditListingWizard.tabLabelPhotos';
  } else if (tab === AGREEMENT) {
    key = 'EditListingWizard.tabLabelAgreement';
  } else if (tab === ADMIN) {
    key = 'EditListingWizard.tabLabelAdmin';
  } else if (tab === PAYMENTS) {
    key = 'EditListingWizard.tabLabelPayments';
  } else if (tab === VIEWDRAFTLISTING) {
    key = 'EditListingWizard.tabLabelViewDraftListings';
  } else if (tab === VIEWDRAFTBUSINESS) {
    key = 'EditListingWizard.tabLabelViewDraft';
  }

  return intl.formatMessage({ id: key });
};

/**
 * Check if a wizard tab is completed.
 *
 * @param tab wizard's tab
 * @param listing is contains some specific data if tab is completed
 *
 * @return true if tab / step is completed.
 */
const tabCompleted = (tab, listing) => {
  const { description, geolocation, price, title, publicData, privateData } = listing.attributes;

  const { stripeConnected, stripePayoutEnabled } = listing?.author?.attributes ?? {};

  const listingVideos = publicData && publicData.listingVideos;
  switch (tab) {
    case INTRODUCTION:
      return true;
    case OVERVIEW:
      return !!(description && title && description !== 'I will');
    case PROFILE:
      return !!(publicData && publicData.nationality);
    case DESCRIPTION:
      return !!(title && publicData?.Travel_business_payment_method?.length > 0);
    case LOCATION:
      return !!(geolocation && publicData && publicData.location && publicData.location.address);
    case PRICING:
      return !!price;
    case PHOTOS:
      return listingVideos && listingVideos.length >= 0;
    case AGREEMENT:
      return !!privateData?.agreementDate && privateData?.agreementVersionDate;
    case ADMIN:
      return !!privateData && Object.keys(privateData).length !== 0;
    case PAYMENTS:
      return !!stripeConnected;
    case VIEWDRAFTLISTING:
      return !!privateData;
    case VIEWDRAFTBUSINESS:
      return !!privateData;
    default:
      return false;
  }
};

/**
 * Check which wizard tabs are active and which are not yet available. Tab is active if previous
 * tab is completed. In edit mode all tabs are active.
 *
 * @param isNew flag if a new listing is being created or an old one being edited
 * @param listing data to be checked
 *
 * @return object containing activity / editability of different tabs of this wizard
 */
const tabsActive = (isNew, listing, tabs) => {
  return tabs.reduce((acc, tab) => {
    const previousTabIndex = tabs.findIndex(t => t === tab) - 1;
    const isActive =
      previousTabIndex >= 0 ? !isNew || tabCompleted(tabs[previousTabIndex], listing) : true;
    return { ...acc, [tab]: isActive };
  }, {});
};

const scrollToTab = (tabPrefix, tabId, type) => {
  if (LISTING_TYPES.ENQUIRY === type && tabId === DESCRIPTION) return;

  const el = document.querySelector(`#${tabPrefix}_${tabId}`);
  if (el) {
    el.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  }
};

// Create return URL for the Stripe onboarding form
const createReturnURL = (returnURLType, rootURL, routes, pathParams) => {
  const path = createResourceLocatorString(
    'EditListingStripeOnboardingPage',
    routes,
    { ...pathParams, returnURLType },
    {}
  );
  const root = rootURL.replace(/\/$/, '');
  return `${root}${path}`;
};

// Get attribute: stripeAccountData
const getStripeAccountData = stripeAccount => stripeAccount.attributes.stripeAccountData || null;

// Get last 4 digits of bank account returned in Stripe account
const getBankAccountLast4Digits = stripeAccountData =>
  stripeAccountData && stripeAccountData.external_accounts.data.length > 0
    ? stripeAccountData.external_accounts.data[0].last4
    : null;

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

// Redirect user to Stripe's hosted Connect account onboarding form
const handleGetStripeConnectAccountLinkFn = (getLinkFn, commonParams) => type => () => {
  commonParams.successURL = typeof window !== 'undefined' && window.location.href;
  commonParams.failureURL = typeof window !== 'undefined' && window.location.href;

  return getLinkFn({ type, ...commonParams })
    .then(url => {
      if (typeof window !== 'undefined') window.location.href = url;
    })
    .catch(err => console.error(err));
};

// Create a new or edit listing through EditListingWizard
class EditListingWizard extends Component {
  constructor(props) {
    super(props);

    // Having this info in state would trigger unnecessary rerendering
    this.hasScrolledToTab = false;

    this.state = {
      draftId: null,
      showPayoutDetails: false,
      portalRoot: null,
    };
    this.postRequestGifRef = React.createRef();
    this.handleCreateFlowTabScrolling = this.handleCreateFlowTabScrolling.bind(this);
    this.handlePublishListing = this.handlePublishListing.bind(this);
    this.handleCloseListing = this.handleCloseListing.bind(this);
    this.handleOpenListing = this.handleOpenListing.bind(this);
    this.handleStripeConnected = this.handleStripeConnected.bind(this);
    this.handlePaymentVerification = this.handlePaymentVerification.bind(this);
    this.handlePayoutModalClose = this.handlePayoutModalClose.bind(this);
    this.handlePayoutSubmit = this.handlePayoutSubmit.bind(this);
  }

  componentDidMount() {
    const { stripeOnboardingReturnURL } = this.props;

    if (stripeOnboardingReturnURL != null) {
      this.setState({ showPayoutDetails: true });
    }

    if (this.postRequestGifRef.current) {
      this.postRequestGifRef.current.src =
        '/static/images/landingpage/landingpage_postRequestGif.gif';
    }
  }

  handleCreateFlowTabScrolling(shouldScroll) {
    this.hasScrolledToTab = shouldScroll;
  }

  handleStripeConnected(id) {
    const { type, currentUser, stripeAccount } = this.props;

    const stripeConnected =
      currentUser && currentUser.stripeAccount && !!currentUser.stripeAccount.id;

    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;

    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due'));

    // Enable if you want to verify strip account before publish Listing
    if ((!stripeConnected && !requirementsMissing) || type === LISTING_TYPES.ENQUIRY) {
    } else {
      this.setState({
        draftId: id,
        showPayoutDetails: true,
      });
    }
  }

  handlePublishListing(listing) {
    const { onPublishListingDraft } = this.props;
    onPublishListingDraft(listing);
  }

  handleCloseListing(listing) {
    const { onCloseListing } = this.props;
    onCloseListing(listing);
  }

  handleOpenListing(listing) {
    const { onOpenListing } = this.props;
    onOpenListing(listing);
  }

  handlePaymentVerification() {
    const { currentUser, stripeAccount } = this.props;
    const stripeConnected =
      currentUser && currentUser.stripeAccount && !!currentUser.stripeAccount.id;

    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;
    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due'));

    if (stripeConnected && !requirementsMissing) {
    } else {
      this.setState({
        showPayoutDetails: true,
      });
    }
  }

  handlePayoutModalClose() {
    this.setState({ showPayoutDetails: false });
  }

  handlePayoutSubmit(values) {
    this.props
      .onPayoutDetailsSubmit(values)
      .then(response => {
        this.props.onManageDisableScrolling('EditListingWizard.payoutModal', false);
      })
      .catch(() => {
        // do nothing
      });
  }

  render() {
    const {
      id,
      type,
      className,
      rootClassName,
      params,
      listing,
      viewport,
      intl,
      errors,
      fetchInProgress,
      payoutDetailsSaveInProgress,
      payoutDetailsSaved,
      onManageDisableScrolling,
      onPayoutDetailsFormChange,
      onGetStripeConnectAccountLink,
      getAccountLinkInProgress,
      createStripeAccountError,
      updateStripeAccountError,
      fetchStripeAccountError,
      stripeAccountFetched,
      stripeAccount,
      currentUser,
      ...rest
    } = this.props;
    const tabs = getTabs(type);

    const selectedTab = params.tab;
    const isNewListingFlow = [LISTING_PAGE_PARAM_TYPE_NEW, LISTING_PAGE_PARAM_TYPE_DRAFT].includes(
      params.type
    );
    const rootClasses = rootClassName || css.root;
    const classes = classNames(rootClasses, className);
    const currentListing = ensureListing(listing);
    const tabsStatus = tabsActive(isNewListingFlow, currentListing, tabs);

    // If selectedTab is not active, redirect to the beginning of wizard
    if (!tabsStatus[selectedTab]) {
      const currentTabIndex = tabs.indexOf(selectedTab);
      const nearestActiveTab = tabs
        .slice(0, currentTabIndex)
        .reverse()
        .find(t => tabsStatus[t]);

      return (
        <NamedRedirect
          name={getEditListingPageName(type)}
          params={{ ...params, tab: nearestActiveTab }}
        />
      );
    }

    const { width } = viewport;
    const hasViewport = width > 0;
    const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasFontsLoaded =
      hasViewport && document.documentElement.classList.contains('fontsLoaded');

    // Check if scrollToTab call is needed (tab is not visible on mobile)
    if (hasVerticalTabLayout) {
      this.hasScrolledToTab = true;
    } else if (hasHorizontalTabLayout && !this.hasScrolledToTab && hasFontsLoaded) {
      const tabPrefix = id;
      scrollToTab(tabPrefix, selectedTab, type);
      this.hasScrolledToTab = true;
    }

    const tabLink = tab => {
      const links = {
        [LISTING_TYPES.LISTING]: 'EditListingPage',
        [LISTING_TYPES.COMPANY]: 'EditCompanyPage',
      };
      return {
        name: links[type],
        params: { ...params, tab },
      };
    };

    const setPortalRootAfterInitialRender = () => {
      if (!this.state.portalRoot) {
        this.setState({ portalRoot: document.getElementById('portal-root') });
      }
    };
    const formDisabled = getAccountLinkInProgress;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!ensuredCurrentUser.id;
    const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;

    const rootURL = config.canonicalRootURL;
    const routes = routeConfiguration();
    const { returnURLType, ...pathParams } = params;
    const successURL = createReturnURL('success', rootURL, routes, pathParams);
    const failureURL = createReturnURL('failure', rootURL, routes, pathParams);

    const accountId = stripeConnected ? stripeAccount.id : null;
    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;

    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due'));

    const savedCountry = stripeAccountData ? stripeAccountData.country : null;

    const handleGetStripeConnectAccountLink = handleGetStripeConnectAccountLinkFn(
      onGetStripeConnectAccountLink,
      {
        accountId,
        successURL,
        failureURL,
      }
    );

    const returnedNormallyFromStripe = returnURLType === 'success';
    const showVerificationError = returnURLType === 'failure';
    const showVerificationNeeded = stripeConnected && requirementsMissing;

    const getTabIcon = tab => {
      switch (tab) {
        case INTRODUCTION:
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-info"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="16" x2="12" y2="12"></line>
              <line x1="12" y1="8" x2="12.01" y2="8"></line>
            </svg>
          );
        case OVERVIEW:
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
            >
              <path
                d="M9 9C11.2091 9 13 7.20914 13 5C13 2.79086 11.2091 1 9 1C6.79086 1 5 2.79086 5 5C5 7.20914 6.79086 9 9 9Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17 19V17C17 15.9391 16.5786 14.9217 15.8284 14.1716C15.0783 13.4214 14.0609 13 13 13H5C3.93913 13 2.92172 13.4214 2.17157 14.1716C1.42143 14.9217 1 15.9391 1 17V19"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          );
        case PROFILE:
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="26"
              viewBox="0 0 21 26"
              fill="none"
            >
              <path
                d="M11.5582 16.514H14.1137M1.33594 24.1807V3.73622C1.33594 3.05844 1.60518 2.40843 2.08444 1.92917C2.5637 1.44991 3.21372 1.18066 3.89149 1.18066H16.6693C17.347 1.18066 17.9971 1.44991 18.4763 1.92917C18.9556 2.40843 19.2248 3.05844 19.2248 3.73622V24.1807L15.3915 21.6251L12.8359 24.1807L10.2804 21.6251L7.72483 24.1807L5.16927 21.6251L1.33594 24.1807ZM6.44705 6.29178H14.1137H6.44705ZM6.44705 11.4029H14.1137H6.44705Z"
                stroke="#6B7B95"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          );
        case DESCRIPTION:
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-file-text"
            >
              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
              <polyline points="14 2 14 8 20 8"></polyline>
              <line x1="16" y1="13" x2="8" y2="13"></line>
              <line x1="16" y1="17" x2="8" y2="17"></line>
              <polyline points="10 9 9 9 8 9"></polyline>
            </svg>
          );
        case LOCATION:
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="24"
              viewBox="0 0 20 24"
              fill="none"
            >
              <path
                d="M19 10C19 17 10 23 10 23C10 23 1 17 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          );
        case PRICING:
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="23"
              viewBox="0 0 24 23"
              fill="none"
            >
              <path
                d="M9 22C9.55228 22 10 21.5523 10 21C10 20.4477 9.55228 20 9 20C8.44772 20 8 20.4477 8 21C8 21.5523 8.44772 22 9 22Z"
                stroke="#C4C4C4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20 22C20.5523 22 21 21.5523 21 21C21 20.4477 20.5523 20 20 20C19.4477 20 19 20.4477 19 21C19 21.5523 19.4477 22 20 22Z"
                stroke="#C4C4C4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 1H5L7.68 14.39C7.77144 14.8504 8.02191 15.264 8.38755 15.5583C8.75318 15.8526 9.2107 16.009 9.68 16H19.4C19.8693 16.009 20.3268 15.8526 20.6925 15.5583C21.0581 15.264 21.3086 14.8504 21.4 14.39L23 6H6"
                stroke="#C4C4C4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          );
        case PHOTOS:
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
                stroke="#C4C4C4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z"
                stroke="#C4C4C4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21 15L16 10L5 21"
                stroke="#C4C4C4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          );
        case AGREEMENT:
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-check-square"
            >
              <polyline points="9 11 12 14 22 4"></polyline>
              <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
            </svg>
          );
        case ADMIN:
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-user"
            >
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
          );
        case PAYMENTS:
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-shopping-cart"
            >
              <circle cx="9" cy="21" r="1"></circle>
              <circle cx="20" cy="21" r="1"></circle>
              <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
            </svg>
          );
        case VIEWDRAFTBUSINESS:
          return (
            <svg
              width="24"
              height="18"
              viewBox="0 0 24 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 9C1 9 5 1 12 1C19 1 23 9 23 9C23 9 19 17 12 17C5 17 1 9 1 9Z"
                stroke="#00AB99"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z"
                stroke="#00AB99"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          );
        case VIEWDRAFTLISTING:
          return (
            <svg
              width="24"
              height="18"
              viewBox="0 0 24 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 9C1 9 5 1 12 1C19 1 23 9 23 9C23 9 19 17 12 17C5 17 1 9 1 9Z"
                stroke="#00AB99"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z"
                stroke="#00AB99"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          );
        default:
          return null;
      }
    };

    // Redirect from success URL to basic path for StripePayoutPage
    if (returnedNormallyFromStripe && stripeConnected && !requirementsMissing) {
      return <NamedRedirect name="EditListingPage" params={pathParams} />;
    }

    return (
      <div className={classes} ref={setPortalRootAfterInitialRender}>
        <Tabs
          rootClassName={css.tabsContainer}
          navRootClassName={css.nav}
          tabRootClassName={css.tab}
          title={'create'}
        >
          {tabs.map(tab => {
            return (
              <EditListingWizardTab
                {...rest}
                key={tab}
                currentUser={ensuredCurrentUser}
                svgIcon={getTabIcon(tab)}
                listingType={type}
                tabId={`${id}_${tab}`}
                tabLabel={tabLabel(intl, tab, type)}
                tabLinkProps={tabLink(tab)}
                selected={selectedTab === tab}
                disabled={isNewListingFlow && !tabsStatus[tab]}
                tab={tab}
                intl={intl}
                params={params}
                listing={listing}
                marketplaceTabs={tabs}
                errors={errors}
                handleCreateFlowTabScrolling={this.handleCreateFlowTabScrolling}
                handlePublishListing={this.handlePublishListing}
                handleCloseListing={this.handleCloseListing}
                handleOpenListing={this.handleOpenListing}
                handlePaymentVerification={this.handlePaymentVerification}
                handleStripeConnected={this.handleStripeConnected}
                fetchInProgress={fetchInProgress}
                onManageDisableScrolling={onManageDisableScrolling}
                isTabCompleted={tabCompleted(tab, listing)}
                viewport={viewport}
                listingBackgroundImages={this.props.listingBackgroundImages}
              />
            );
          })}
        </Tabs>
        {this.state.portalRoot && onManageDisableScrolling ? (
          <Portal portalRoot={this.state.portalRoot}>
            <Modal
              id="EditListingWizard.payoutModal"
              isOpen={this.state.showPayoutDetails}
              onClose={this.handlePayoutModalClose}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.modalPayoutDetailsWrapper}>
                <h1 className={css.modalTitle}>
                  <FormattedMessage id="EditListingPhotosPanel.payoutModalTitleOneMoreThing" />
                  {/* <br />
                  <FormattedMessage id="EditListingPhotosPanel.cardPayoutPreferences" /> */}
                </h1>
                <p className={css.modalMessage}>
                  <FormattedMessage id="EditListingPhotosPanel.payoutModalInfo" />
                </p>
                <div className={css.payoutModalInfoNote}>
                  <span className={css.noteFont}>
                    <FormattedMessage id="EditListingPhotosPanel.note" />
                  </span>
                  <FormattedMessage id="EditListingPhotosPanel.payoutModalInfoNote" />
                </div>
                {!currentUserLoaded ? (
                  <FormattedMessage id="StripePayoutPage.loadingData" />
                ) : (
                  <StripeConnectAccountForm
                    disabled={formDisabled}
                    inProgress={payoutDetailsSaveInProgress}
                    ready={payoutDetailsSaved}
                    stripeBankAccountLastDigits={getBankAccountLast4Digits(stripeAccountData)}
                    savedCountry={savedCountry}
                    submitButtonText={intl.formatMessage({
                      id: 'StripePayoutPage.submitButtonText',
                    })}
                    stripeAccountError={
                      createStripeAccountError ||
                      updateStripeAccountError ||
                      fetchStripeAccountError
                    }
                    stripeAccountFetched={stripeAccountFetched}
                    onChange={onPayoutDetailsFormChange}
                    onSubmit={rest.onPayoutDetailsSubmit}
                    onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink}
                    stripeConnected={stripeConnected}
                  >
                    {stripeConnected && (showVerificationError || showVerificationNeeded) ? (
                      <StripeConnectAccountStatusBox
                        type={showVerificationError ? 'verificationError' : 'verificationNeeded'}
                        inProgress={getAccountLinkInProgress}
                        onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                          'custom_account_verification'
                        )}
                      />
                    ) : stripeConnected && savedCountry ? (
                      <StripeConnectAccountStatusBox
                        type="verificationSuccess"
                        inProgress={getAccountLinkInProgress}
                        disabled={payoutDetailsSaveInProgress}
                        onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                          'custom_account_update'
                        )}
                      />
                    ) : null}
                  </StripeConnectAccountForm>
                )}
              </div>
            </Modal>
          </Portal>
        ) : null}
      </div>
    );
  }
}

EditListingWizard.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  updateInProgress: false,
};

EditListingWizard.propTypes = {
  id: string.isRequired,
  type: oneOf([
    LISTING_TYPES.LISTING,
    LISTING_TYPES.ENQUIRY,
    LISTING_TYPES.COMPANY,
    LISTING_TYPES.PRODUCT,
    LISTING_TYPES.FACILITY,
    LISTING_TYPES.CLASS,
  ]).isRequired,
  className: string,
  rootClassName: string,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf([...TABS, ...COMPANY_TABS]).isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  errors: shape({
    createListingDraftError: object,
    updateListingError: object,
    publishListingError: object,
    showListingsError: object,
    uploadImageError: object,
    createStripeAccountError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  payoutDetailsSaveInProgress: bool.isRequired,
  payoutDetailsSaved: bool.isRequired,
  onPayoutDetailsFormChange: func.isRequired,
  onPayoutDetailsSubmit: func.isRequired,
  onGetStripeConnectAccountLink: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  updateInProgress: bool,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withViewport, injectIntl)(EditListingWizard);
