import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, Button } from '../../components';
import { BookingTimeForm } from '../../forms';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './BookingPanel.css';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const { Money } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if ((isOwnListing || isClosed) &&  typeof window !== 'undefined' ) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    currentUser,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    location,
    intl,
    handlePackagesModalOpen,
    isProduct,
  } = props;

  const [isCookieMessageShow, setCookieMessageShow] = useState(false)
  useEffect(() => {
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});

    if (cookies.euCookiesAccepted !== '1') {
      setCookieMessageShow(true)
    }
  }, [])


  const priceAddons = listing.attributes.publicData
    ? listing.attributes.publicData.priceAddons
    : [];

  const formattedPriceAddons =
    priceAddons &&
    priceAddons.map(i => ({
      label: i.label,
      price: new Money(i.amount, i.currency),
    }));

  const handleSubmit = values => {
    if (values.withPayment && listing.attributes.publicData) {
      values.payment_method = listing.attributes.publicData.payment_method || []
    }

    if (values.withCredits && !values.seats) {
      values.seats = 1;
    }
    const selectedPriceAddons =
      values &&
      values.additionalItems &&
      priceAddons.filter(p => values.additionalItems.find(i => i === p.label));

    onSubmit({
      ...values,
      priceAddons: selectedPriceAddons,
    });
  };

  const price = listing.attributes.price;
  const timeZone =
    listing.attributes.availabilityPlan && listing.attributes.availabilityPlan.timezone;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingTimeForm = hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isBook = !!parse(location.search).book;

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
      ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
      : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
      ? 'BookingPanel.perDay'
      : 'BookingPanel.perUnit';

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);
  return (
    <div>
      <div className={css.stickyContainer}>
        <div className={classes}>
          <ModalInMobile
            containerClassName={css.modalContainer}
            id="BookingTimeFormInModal"
            isModalOpenOnMobile={isBook}
            onClose={() => closeBookModal(history, location)}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.modalHeading}>
              <h1 className={css.title}>{title}</h1>
            </div>
            <div className={css.bookingHeading}>
              <div className={css.desktopPriceContainer}>
                <div className={css.desktopPriceValue} title={priceTitle}>
                  {formattedPrice}
                </div>
                <div className={css.desktopPerUnit}>
                  {/* <FormattedMessage id={unitTranslationKey} /> */}
                  <FormattedMessage id={'BookingPanel.perNight'} />
                </div>
              </div>
              <div className={css.bookingHeadingContainer}>
                <h2 className={titleClasses}>{title}</h2>
                {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
              </div>
            </div>

            {showBookingTimeForm && !isProduct ? (
              <BookingTimeForm
                className={css.bookingForm}
                formId="BookingPanel"
                submitButtonWrapperClassName={css.submitButtonWrapper}
                unitType={unitType}
                onSubmit={handleSubmit}
                price={price}
                isOwnListing={isOwnListing}
                listingId={listing.id}
                listing={listing}
                currentUser={currentUser}
                monthlyTimeSlots={monthlyTimeSlots}
                onFetchTimeSlots={onFetchTimeSlots}
                startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
                timeZone={timeZone}
                handlePackagesModalOpen={handlePackagesModalOpen}
                showTopup={true}
                priceAddons={formattedPriceAddons}
                isProduct={isProduct}
                onManageDisableScrolling={onManageDisableScrolling}
                history={history}
              />
            ) : null}
          </ModalInMobile>
        </div>
      </div>

      <div className={classNames(css.openBookingForm, { [css.openBookingFormLiftUp]: isCookieMessageShow })}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>

        {showBookingTimeForm ? (
          <Button
            rootClassName={css.bookButton}
            onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
          >
            <FormattedMessage id="BookingPanel.viewSchedule" />
          </Button>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  monthlyTimeSlots: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  isProduct: bool,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);
