/*
 * Marketplace specific configuration.
 */

export const tripReasonOptions = [
  { key: 'anything', label: 'Open to Anything' },
  { key: 'learn_&_explore', label: 'Learn & Explore' },
  { key: 'relax_&_recharge', label: 'Relax & Recharge' },
  { key: 'escape_&_Reflect', label: 'Escape & Reflect' },
  { key: 'have_an_adventure', label: 'Have an Adventure' },
  { key: 'celebratration_event', label: 'Celebration Event' },
  { key: 'socialise_&_experience', label: 'Socialise & Experience' },
  { key: 'work', label: 'Work' },
  { key: 'visit_Someone', label: 'Visit Someone' },
  { key: 'family_getaway', label: 'Family Getaway' },
];

export const suggestionCategoryOptions = [
  { key: 'accessibility_travel', label: 'Accessibility Travel' },
  { key: 'backpacking', label: 'Backpacking' },
  { key: 'beach', label: 'Beach' },
  { key: 'birthday', label: 'Birthday' },
  { key: 'buck_&_hens', label: 'Buck & Hens' },
  { key: 'camping', label: 'Camping' },
  { key: 'clubs_&_pubs', label: 'Clubs & Pubs' },
  { key: 'cruise', label: 'Cruise' },
  { key: 'festivals', label: 'Festivals' },
  { key: 'gambling', label: 'Gambling' },
  { key: 'health_&_fitness', label: 'Health & Fitness' },
  { key: 'hiking_&_climbing', label: 'Hiking & Climbing' },
  { key: 'historical', label: 'Historical' },
  { key: 'honeymoon', label: 'Honeymoon' },
  { key: 'long_weekend', label: 'Long Weekend' },
  { key: 'music_events', label: 'Music Events' },
  { key: 'nature_parks', label: 'Nature Parks' },
  { key: 'new_year', label: 'New Year' },
  { key: 'photography', label: 'Photography' },
  { key: 'religion', label: 'Religion' },
  { key: 'resort', label: 'Resort' },
  { key: 'restaurants_&_dining', label: 'Restaurants & Dining' },
  { key: 'road_trip', label: 'Road Trip' },
  { key: 'romance', label: 'Romance' },
  { key: 'shopping', label: 'Shopping' },
  { key: 'sightseeing', label: 'Sightseeing' },
  { key: 'spa_&_massage', label: 'Spa & Massage' },
  { key: 'sporting_events', label: 'Sporting Events' },
  { key: 'sports_adrenaline ', label: 'Sports - Adrenaline ' },
  { key: 'sports_group', label: 'Sports - Group' },
  { key: 'sports_individual', label: 'Sports - Individual' },
  { key: 'watersports', label: 'Watersports' },
  { key: 'snowsports', label: 'Snowsports' },
  { key: 'volunteering', label: 'Volunteering' },
  { key: 'wedding', label: 'Wedding' },
];

export const subCategoriesFilterOptions = [
  { key: 'gym', label: 'Gym' },
  { key: 'tennis', label: 'Tennis' },
  { key: 'yoga', label: 'Yoga' },
  { key: 'boxing', label: 'Boxing' },
  { key: 'swimming', label: 'Swimming' },
  { key: 'olympic lifting', label: 'Olympic Lifting' },
  { key: 'crossfit', label: 'Crossfit' },
  { key: 'pilates', label: 'Pilates' },
  { key: 'golf', label: 'Golf' },
  { key: 'martial_arts', label: 'Martial Arts' },
  { key: 'triathlon', label: 'Triathlon' },
  { key: 'dance', label: 'Dance' },
  { key: 'physio', label: 'Physio' },
  { key: 'karate', label: 'Karate' },
  { key: 'jiu-jitsu', label: 'Jiu-jitsu' },
  { key: 'wakeboarding', label: 'Wakeboarding' },
  { key: 'basketball', label: 'Basketball' },
  { key: 'football', label: 'Football' },
  { key: 'badminton', label: 'Badminton' },
  { key: 'rugby', label: 'Rugby' },
  { key: 'barre', label: 'Barre' },
  { key: 'kite surfing', label: 'Kite Surfing' },
  { key: 'surfing', label: 'Surfing' },
  { key: 'bootcamp', label: 'Bootcamp' },
  { key: 'personal training', label: 'Personal Training' },
  { key: 'biking', label: 'Biking' },
  { key: 'cycling', label: 'Cycling' },
  { key: 'running', label: 'Running' },
  { key: 'obstacle sports', label: 'Obstacle Sports' },
  { key: 'rock climbing', label: 'Rock Climbing' },
  { key: 'skateboarding', label: 'Skateboarding' },
  { key: 'acupuncture', label: 'Acupuncture' },
  { key: 'chiropractor', label: 'Chiropractor' },
  { key: 'cryotherapy', label: 'Cryotherapy' },
  { key: 'massage', label: 'Massage' },
  { key: 'meditation', label: 'Meditation' },
  { key: 'nutrition', label: 'Nutrition' },
  { key: 'physical Therapy', label: 'Physical Therapy' },
  { key: 'prenatal', label: 'Prenatal' },
  { key: 'TCM practise', label: 'TCM Practise' },
  { key: 'paddle tennis', label: 'Paddle Tennis' },
  { key: 'pickle ball', label: 'Pickle Ball' },
  { key: 'squash', label: 'Squash' },
  { key: 'martial arts', label: 'Martial Arts' },
  { key: 'wrestling', label: 'Wrestling' },
  { key: 'kick boxing', label: 'Kick Boxing' },
  { key: 'baseball', label: 'Baseball' },
  { key: 'cricket', label: 'Cricket' },
];

export const continents = [
  { key: 'africa', label: 'Africa' },
  { key: 'antarctica', label: 'Antarctica' },
  { key: 'asia', label: 'Asia' },
  { key: 'europe', label: 'Europe' },
  { key: 'north america', label: 'North America' },
  { key: 'australia oceania', label: 'Australia / Oceania' },
  { key: 'south america', label: 'South America' },
];

export const languageFilterConfig = [
  { key: 'ab', label: 'Abkhazian' },
  { key: 'aa', label: 'Afar' },
  { key: 'af', label: 'Afrikaans' },
  { key: 'ak', label: 'Akan' },
  { key: 'sq', label: 'Albanian' },
  { key: 'am', label: 'Amharic' },
  { key: 'ar', label: 'Arabic' },
  { key: 'an', label: 'Aragonese' },
  { key: 'hy', label: 'Armenian' },
  { key: 'as', label: 'Assamese' },
  { key: 'av', label: 'Avaric' },
  { key: 'ae', label: 'Avestan' },
  { key: 'ay', label: 'Aymara' },
  { key: 'az', label: 'Azerbaijani' },
  { key: 'bm', label: 'Bambara' },
  { key: 'ba', label: 'Bashkir' },
  { key: 'eu', label: 'Basque' },
  { key: 'be', label: 'Belarusian' },
  { key: 'bn', label: 'Bengali' },
  { key: 'bh', label: 'Bihari' },
  { key: 'bi', label: 'Bislama' },
  { key: 'bs', label: 'Bosnian' },
  { key: 'br', label: 'Breton' },
  { key: 'bg', label: 'Bulgarian' },
  { key: 'my', label: 'Burmese' },
  { key: 'ca', label: 'Catalan' },
  { key: 'km', label: 'Central Khmer' },
  { key: 'ch', label: 'Chamorro' },
  { key: 'ce', label: 'Chechen' },
  { key: 'ny', label: 'Chichewa' },
  { key: 'zh', label: 'Chinese' },
  { key: 'cv', label: 'Chuvash' },
  { key: 'kw', label: 'Cornish' },
  { key: 'co', label: 'Corsican' },
  { key: 'cr', label: 'Cree' },
  { key: 'hr', label: 'Croatian' },
  { key: 'cs', label: 'Czech' },
  { key: 'da', label: 'Danish' },
  { key: 'dv', label: 'Divehi' },
  { key: 'nl', label: 'Dutch' },
  { key: 'dz', label: 'Dzongkha' },
  { key: 'en', label: 'English' },
  { key: 'eo', label: 'Esperanto' },
  { key: 'et', label: 'Estonian' },
  { key: 'ee', label: 'Ewe' },
  { key: 'fo', label: 'Faroese' },
  { key: 'fj', label: 'Fijian' },
  { key: 'fi', label: 'Finnish' },
  { key: 'fr', label: 'French' },
  { key: 'ff', label: 'Fulah' },
  { key: 'gl', label: 'Galician' },
  { key: 'lg', label: 'Ganda' },
  { key: 'ka', label: 'Georgian' },
  { key: 'de', label: 'German' },
  { key: 'el', label: 'Greek' },
  { key: 'kl', label: 'Greenlandic' },
  { key: 'gn', label: 'Guarani' },
  { key: 'gu', label: 'Gujarati' },
  { key: 'ht', label: 'Haitian' },
  { key: 'ha', label: 'Hausa' },
  { key: 'he', label: 'Hebrew' },
  { key: 'hz', label: 'Herero' },
  { key: 'hi', label: 'Hindi' },
  { key: 'ho', label: 'Hiri Motu' },
  { key: 'hu', label: 'Hungarian' },
  { key: 'is', label: 'Icelandic' },
  { key: 'io', label: 'Ido' },
  { key: 'ig', label: 'Igbo' },
  { key: 'id', label: 'Indonesian' },
  { key: 'ia', label: 'Interlingua' },
  { key: 'ie', label: 'Interlingue' },
  { key: 'iu', label: 'Inuktitut' },
  { key: 'ik', label: 'Inupiaq' },
  { key: 'ga', label: 'Irish' },
  { key: 'it', label: 'Italian' },
  { key: 'ja', label: 'Japanese' },
  { key: 'jv', label: 'Javanese' },
  { key: 'kn', label: 'Kannada' },
  { key: 'kr', label: 'Kanuri' },
  { key: 'ks', label: 'Kashmiri' },
  { key: 'kk', label: 'Kazakh' },
  { key: 'ki', label: 'Kikuyu' },
  { key: 'rw', label: 'Kinyarwanda' },
  { key: 'kv', label: 'Komi' },
  { key: 'kg', label: 'Kongo' },
  { key: 'ko', label: 'Korean' },
  { key: 'kj', label: 'Kuanyama' },
  { key: 'ku', label: 'Kurdish' },
  { key: 'ky', label: 'Kyrgyz' },
  { key: 'lo', label: 'Lao' },
  { key: 'la', label: 'Latin' },
  { key: 'lv', label: 'Latvian' },
  { key: 'li', label: 'Limburgan' },
  { key: 'ln', label: 'Lingala' },
  { key: 'lt', label: 'Lithuanian' },
  { key: 'lu', label: 'Luba-Katanga' },
  { key: 'lb', label: 'Luxembourgish' },
  { key: 'mk', label: 'Macedonian' },
  { key: 'mg', label: 'Malagasy' },
  { key: 'ms', label: 'Malay' },
  { key: 'ml', label: 'Malayalam' },
  { key: 'mt', label: 'Maltese' },
  { key: 'gv', label: 'Manx' },
  { key: 'mi', label: 'Maori' },
  { key: 'mr', label: 'Marathi' },
  { key: 'mh', label: 'Marshallese' },
  { key: 'mn', label: 'Mongolian' },
  { key: 'na', label: 'Nauru' },
  { key: 'nv', label: 'Navajo' },
  { key: 'ng', label: 'Ndonga' },
  { key: 'ne', label: 'Nepali' },
  { key: 'nd', label: 'North Ndebele' },
  { key: 'se', label: 'Northern Sami' },
  { key: 'no', label: 'Norwegian' },
  { key: 'nb', label: 'Norwegian Bokmål' },
  { key: 'nn', label: 'Norwegian Nynorsk' },
  { key: 'oc', label: 'Occitan' },
  { key: 'oj', label: 'Ojibwa' },
  { key: 'cu', label: 'Old Church Slavonic' },
  { key: 'or', label: 'Oriya' },
  { key: 'om', label: 'Oromo' },
  { key: 'os', label: 'Ossetian / Ossetic' },
  { key: 'pi', label: 'Pali' },
  { key: 'pa', label: 'Panjabi / Punjabi' },
  { key: 'ps', label: 'Pashto' },
  { key: 'fa', label: 'Persian' },
  { key: 'pl', label: 'Polish' },
  { key: 'pt', label: 'Portuguese' },
  { key: 'qu', label: 'Quechua' },
  { key: 'ro', label: 'Romanian' },
  { key: 'rm', label: 'Romansh' },
  { key: 'rn', label: 'Rundi' },
  { key: 'ru', label: 'Russian' },
  { key: 'sm', label: 'Samoan' },
  { key: 'sg', label: 'Sango' },
  { key: 'sa', label: 'Sanskrit' },
  { key: 'sc', label: 'Sardinian' },
  { key: 'gd', label: 'Scottish Gaelic' },
  { key: 'sr', label: 'Serbian' },
  { key: 'sn', label: 'Shona' },
  { key: 'ii', label: 'Sichuan Yi' },
  { key: 'sd', label: 'Sindhi' },
  { key: 'si', label: 'Sinhalese' },
  { key: 'sk', label: 'Slovak' },
  { key: 'sl', label: 'Slovenian' },
  { key: 'so', label: 'Somali' },
  { key: 'nr', label: 'South Ndebele' },
  { key: 'st', label: 'Southern Sotho' },
  { key: 'es', label: 'Spanish' },
  { key: 'su', label: 'Sundanese' },
  { key: 'sw', label: 'Swahili' },
  { key: 'ss', label: 'Swati' },
  { key: 'sv', label: 'Swedish' },
  { key: 'tl', label: 'Tagalog' },
  { key: 'ty', label: 'Tahitian' },
  { key: 'tg', label: 'Tajik' },
  { key: 'ta', label: 'Tamil' },
  { key: 'tt', label: 'Tatar' },
  { key: 'te', label: 'Telugu' },
  { key: 'th', label: 'Thai' },
  { key: 'bo', label: 'Tibetan' },
  { key: 'ti', label: 'Tigrinya' },
  { key: 'to', label: 'Tonga' },
  { key: 'ts', label: 'Tsonga' },
  { key: 'tn', label: 'Tswana' },
  { key: 'tr', label: 'Turkish' },
  { key: 'tk', label: 'Turkmen' },
  { key: 'tw', label: 'Twi' },
  { key: 'uk', label: 'Ukrainian' },
  { key: 'ur', label: 'Urdu' },
  { key: 'ug', label: 'Uyghur' },
  { key: 'uz', label: 'Uzbek' },
  { key: 've', label: 'Venda' },
  { key: 'vi', label: 'Vietnamese' },
  { key: 'vo', label: 'Volapük' },
  { key: 'wa', label: 'Walloon' },
  { key: 'cy', label: 'Welsh' },
  { key: 'fy', label: 'Western Frisian' },
  { key: 'wo', label: 'Wolof' },
  { key: 'xh', label: 'Xhosa' },
  { key: 'yi', label: 'Yiddish' },
  { key: 'yo', label: 'Yoruba' },
  { key: 'za', label: 'Zhuang' },
  { key: 'zu', label: 'Zulu' },
];

export const listingTypeFilterConfig = [
  { key: 'class', label: 'Classes' },
  { key: 'listing', label: "Pro's" },
  // { key: 'enquiry', label: 'Enquiry' },
  { key: 'facility', label: 'Facilities' },
  { key: 'company', label: 'Companies' },
  { key: 'product', label: 'Product' },
];

export const categories = [
  { key: '', label: 'None', hideFromFilters: true, hideFromListingInfo: true },

  {
    key: 'sports',
    label: 'Sports',
    subCategories: [
      {
        group: 'Rockett/Ball sports',
        options: [
          { key: 'tennis', label: 'Tennis' },
          { key: 'table tennis', label: 'Table Tennis' },
          { key: 'badminton', label: 'Badminton' },
          { key: 'squash', label: 'Squash' },
          { key: 'paddle tennis', label: 'Paddle Tennis' },
          { key: 'pickle ball', label: 'Pickle Ball' },
        ],
      },
      {
        group: 'Movement and Mind',
        options: [
          { key: 'yoga', label: 'Yoga' },
          { key: 'pilates', label: 'Pilates' },
          { key: 'dance', label: 'Dance' },
          { key: 'barre', label: 'Barre' },
          { key: 'meditation', label: 'Meditation' },
        ],
      },
      {
        group: 'Strenght Focussed',
        options: [
          { key: 'personal training', label: 'Personal Training' },
          { key: 'crossfit', label: 'Crossfit' },
          { key: 'swimming', label: 'Swimming' },
          { key: 'olympic lifting', label: 'Olympic Lifting' },
          { key: 'running', label: 'Running' },
        ],
      },
      {
        group: 'Adventure/Outdoor',
        options: [
          { key: 'cycling', label: 'Cycling' },
          { key: 'biking', label: 'Biking' },
          { key: 'rock climbing', label: 'Rock Climbing' },
          { key: 'bootcamp', label: 'Bootcamp' },
          { key: 'wake boarding', label: 'Wake Boarding' },
          { key: 'obstacle sports', label: 'Obstacle Sports' },
          { key: 'surfing', label: 'Surfing' },
          { key: 'kite surfing', label: 'Kite Surfing' },
          { key: 'skateboarding', label: 'Skateboarding' },
        ],
      },
      {
        group: 'Combat Sports',
        options: [
          { key: 'martial arts', label: 'Martial Arts' },
          { key: 'wrestling', label: 'Wrestling' },
          { key: 'boxing', label: 'Boxing' },
          { key: 'kick boxing', label: 'Kick Boxing' },
        ],
      },
      {
        group: 'Ball Sports',
        options: [
          { key: 'golf', label: 'Golf' },
          { key: 'cricket', label: 'Cricket' },
          { key: 'basketball', label: 'Basketball' },
          { key: 'netball', label: 'Netball' },
          { key: 'hockey', label: 'Hockey' },
          { key: 'baseball', label: 'Baseball' },
        ],
      },
    ],
  },
  {
    key: 'adventure',
    label: 'Adventure',
    subCategories: [
      {
        group: 'Adventure',
        options: [
          { key: 'cycling', label: 'Cycling' },
          { key: 'running', label: 'Cross Country Running' },
          { key: 'biking', label: 'Biking' },
          { key: 'rock climbing', label: 'Rock Climbing' },
          { key: 'bootcamp', label: 'Bootcamp' },
          { key: 'wake boarding', label: 'Wake Boarding' },
          { key: 'obstacle sports', label: 'Obstacle Sports' },
          { key: 'surfing', label: 'Surfing' },
          { key: 'kite surfing', label: 'Kite Surfing' },
          { key: 'skateboarding', label: 'Skateboarding' },
        ],
      },
    ],
  },
  {
    key: 'wellness',
    label: 'Wellness',
    subCategories: [
      {
        group: 'Wellness',
        options: [
          { key: 'massage', label: 'Massage' },
          { key: 'physical Therapy', label: 'Physical Therapy' },
          { key: 'chiropractor', label: 'Chiropractor' },
          { key: 'reflexology', label: 'Reflexology' },
          { key: 'prenatal', label: 'Prenatal' },
          { key: 'acupuncture', label: 'Acupuncture' },
          { key: 'cryotherapy', label: 'Cryotherapy' },
          { key: 'meditation', label: 'Meditation' },
          { key: 'TCM practise', label: 'TCM Practise' },
          { key: 'nutrition', label: 'Nutrition' },
        ],
      },
      {
        group: 'Beuty',
        options: [
          { key: `spa's`, label: `Spa's` },
          { key: 'face treatments', label: 'Face Treatments' },
          { key: 'hair removal', label: 'Hair Removal' },
          { key: 'hair salon', label: 'Hair Salon' },
          { key: 'nail salon', label: 'Nail Salon' },
          { key: `tattoo's`, label: `Tattoo's` },
          { key: 'tanning', label: 'Tanning' },
        ],
      },
    ],
  },
];

export const categoryBasedFields = [
  {
    // key: 'In-Person Sessions',
    key: 'sports',
    fields: [
      {
        key: 'duration',
        label: 'Duration',
        type: 'text',
        placeholder: '1 day',
        required_message: 'Duration is required',
      },
      {
        key: 'listing_mode',
        label: 'Listing mode',
        type: 'dropdown',
        required_message: 'Listing mode is required',
        options: [
          { key: '', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
          { key: 'in_person', label: 'In-Person' },
          { key: 'online', label: 'Online' },
        ],
      },
      {
        key: 'activity_levels',
        label: 'Activity levels',
        type: 'dropdown',
        multiselect: 'true',
        required_message: 'Activity levels are required',
        options: [
          { key: '', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
          { key: 'all_levels', label: 'All levels' },
          { key: 'beginner', label: 'Beginner' },
          { key: 'intermediate', label: 'Intermediate' },
          { key: 'advanced', label: 'Advanced' },
        ],
      },
      {
        key: 'hosted_in',
        label: 'Hosted in which language?',
        type: 'dropdown',
        multiselect: 'true',
        required_message: 'Hosted language is required',
        options: languageFilterConfig,
      },
    ],
  },
  {
    // key: 'Wellness and Beauty',
    key: 'wellness',
    fields: [
      {
        key: 'duration',
        label: 'Duration',
        type: 'text',
        placeholder: '1 day',
        required_message: 'Duration is required',
      },
      {
        key: 'listing_mode',
        label: 'Listing mode',
        type: 'dropdown',
        required_message: 'Listing mode is required',
        options: [
          { key: '', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
          { key: 'in_person', label: 'In-Person' },
          { key: 'online', label: 'Online' },
        ],
      },
      {
        key: 'activity_levels',
        label: 'Activity levels',
        type: 'dropdown',
        multiselect: 'true',
        required_message: 'Activity levels are required',
        options: [
          { key: '', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
          { key: 'all_levels', label: 'All levels' },
          { key: 'beginner', label: 'Beginner' },
          { key: 'intermediate', label: 'Intermediate' },
          { key: 'advanced', label: 'Advanced' },
        ],
      },
      {
        key: 'hosted_in',
        label: 'Hosted in which language?',
        type: 'dropdown',
        multiselect: 'true',
        required_message: 'Hosted language is required',
        options: languageFilterConfig,
      },
    ],
  },
  {
    key: 'adventure',
    fields: [
      {
        key: 'duration',
        label: 'Duration',
        type: 'text',
        placeholder: '2 days',
        required_message: 'Duration is required',
      },
      {
        key: 'listing_mode',
        label: 'Listing mode',
        type: 'dropdown',
        required_message: 'Listing mode is required',
        options: [
          { key: '', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
          { key: 'in_person', label: 'In-Person' },
          { key: 'online', label: 'Online' },
        ],
      },
      {
        key: 'activity_levels',
        label: 'Activity levels',
        type: 'dropdown',
        multiselect: 'true',
        required_message: 'Activity levels are required',
        options: [
          { key: '', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
          { key: 'all_levels', label: 'All levels' },
          { key: 'beginner', label: 'Beginner' },
          { key: 'intermediate', label: 'Intermediate' },
          { key: 'advanced', label: 'Advanced' },
        ],
      },
      {
        key: 'hosted_in',
        label: 'Hosted in which language?',
        type: 'dropdown',
        multiselect: 'true',
        required_message: 'Hosted in language is required',
        options: languageFilterConfig,
      },
    ],
  },
];

export const activityLevel = [
  { key: 'all_levels', label: 'All levels' },
  { key: 'beginner', label: 'Beginner' },
  { key: 'intermediate', label: 'Intermediate' },
  { key: 'advanced', label: 'Advanced' },
];

export const treatmentRoomOptions = [
  { key: 'private_room', label: 'Private room' },
  { key: 'separator', label: 'Separator' },
  { key: 'open_space', label: 'Open space' },
];

export const deliveryOptions = [
  { key: 'home_delivery', label: 'Home delivery' },
  { key: 'meetup', label: 'Meetup' },
  { key: 'self_pick_up', label: 'Self pick up' },
];

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 1000,
  step: 5,
};

// Activate booking dates filter on search page
export const dateRangeLengthFilterConfig = {
  active: true,

  // A global time zone to use in availability searches. As listings
  // can be in various time zones, we must decide what time zone we
  // use in search when looking for available listings within a
  // certain time interval.
  //
  // If you have all/most listings in a certain time zone, change this
  // config value to that.
  //
  // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  searchTimeZone: 'Etc/UTC',

  // Options for the minimum duration of the booking
  options: [
    { key: '0', label: 'Any length' },
    { key: '60', label: '1 hour', shortLabel: '1h' },
    { key: '120', label: '2 hours', shortLabel: '2h' },
  ],
};

const relevanceKey = 'relevance';

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  relevanceKey,
  conflictingFilters: [],
  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: relevanceKey, label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: true,
};

export const productFilters = [
  {
    id: 'category',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      options: [
        { key: 'tennis_rackets', label: 'Tennis Rackets' },
        { key: 'tennis_unique_products', label: 'Tennis Unique Products' },
        { key: 'shoes', label: 'Shoes' },
        { key: 'tennis_bags', label: 'Tennis Bags' },
        { key: 'exercise_unique_equipment', label: 'Exercise Unique Equipment' },
      ],
    },
  },
  {
    key: 'shoes',
    fields: [
      {
        id: 'size',
        label: 'Size (US)',
        type: 'SelectMultipleFilter',
        group: 'primary',
        queryParamNames: ['pub_size'],
        config: {
          options: [
            { key: '4', label: '4' },
            { key: '5', label: '5' },
            { key: '6', label: '6' },
            { key: '7', label: '7' },
            { key: '8', label: '8' },
            { key: '9', label: '9' },
            { key: '10', label: '10' },
            { key: '11', label: '11' },
            { key: '12', label: '12' },
          ],
        },
      },
      {
        id: 'brand',
        label: 'Brand',
        type: 'SelectMultipleFilter',
        group: 'primary',
        queryParamNames: ['pub_brand'],
        config: {
          options: [
            { key: 'adidas', label: 'Adidas' },
            { key: 'air_jordan', label: 'Air Jordan' },
            { key: 'converse', label: 'Converse' },
            { key: 'new_balance', label: 'New Balance' },
            { key: 'nike', label: 'Nike' },
            { key: 'puma', label: 'Puma' },
            { key: 'ultraboost', label: 'Ultraboost' },
            { key: 'vans', label: 'Vans' },
            { key: 'yeezy', label: 'Yeezy' },
            { key: 'other', label: 'Other' },
          ],
        },
      },
    ],
  },
  {
    key: 'tennis_rackets',
    fields: [
      {
        id: 'level',
        label: 'Level',
        type: 'SelectSingleFilter',
        group: 'primary',
        queryParamNames: ['meta_level'],
        config: {
          options: [
            { key: 'pro', label: 'Pro' },
            { key: 'intermediate', label: 'Intermediate' },
            { key: 'junior', label: 'Junior ' },
          ],
        },
      },
      {
        id: 'brand',
        label: 'Brand',
        type: 'SelectMultipleFilter',
        group: 'primary',
        queryParamNames: ['pub_brand'],
        config: {
          options: [
            { key: 'yonex', label: 'Yonex' },
            { key: 'head', label: 'Head' },
          ],
        },
      },
      {
        id: 'size',
        label: 'Grip size',
        type: 'SelectMultipleFilter',
        group: 'primary',
        queryParamNames: ['pub_size'],
        config: {
          options: [
            { key: '2', label: '2' },
            { key: '3', label: '3' },
            { key: '4', label: '4' },
          ],
        },
      },
    ],
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    queryParamNames: ['keywords'],
    config: {},
  },
];

export const PaymentMethodsConfig = [
  { key: 'visa', label: 'Visa' },
  { key: 'mastercard', label: 'Mastercard' },
  { key: 'amex', label: 'Amex' },
  { key: 'paypal', label: 'Paypal' },
  { key: 'directDeposit', label: 'Direct Deposit' },
];

export const interestsOptions = [
  {
    key: '3D printing',
    label: '3D printing',
  },
  {
    key: 'Acroyoga',
    label: 'Acroyoga',
  },
  {
    key: 'Acting',
    label: 'Acting',
  },
  {
    key: 'Action figure',
    label: 'Action figure',
  },
  {
    key: 'Aerospace',
    label: 'Aerospace',
  },
  {
    key: 'Air hockey',
    label: 'Air hockey',
  },
  {
    key: 'Air sports',
    label: 'Air sports',
  },
  {
    key: 'Aircraft spotting',
    label: 'Aircraft spotting',
  },
  {
    key: 'Airsoft',
    label: 'Airsoft',
  },
  {
    key: 'Amateur astronomy',
    label: 'Amateur astronomy',
  },
  {
    key: 'Amateur geology',
    label: 'Amateur geology',
  },
  {
    key: 'Amateur Radio',
    label: 'Amateur Radio',
  },
  {
    key: 'Amusement park visiting',
    label: 'Amusement park visiting',
  },
  {
    key: 'Animal fancy',
    label: 'Animal fancy',
  },
  {
    key: 'Animation',
    label: 'Animation',
  },
  {
    key: 'Anime',
    label: 'Anime',
  },
  {
    key: 'Ant-keeping',
    label: 'Ant-keeping',
  },
  {
    key: 'Antiquities',
    label: 'Antiquities',
  },
  {
    key: 'Aquascaping',
    label: 'Aquascaping',
  },
  {
    key: 'Archaeology',
    label: 'Archaeology',
  },
  {
    key: 'Archery',
    label: 'Archery',
  },
  {
    key: 'Architecture',
    label: 'Architecture',
  },
  {
    key: 'Art',
    label: 'Art',
  },
  {
    key: 'Art collecting',
    label: 'Art collecting',
  },
  {
    key: 'Astrology',
    label: 'Astrology',
  },
  {
    key: 'Astronomy',
    label: 'Astronomy',
  },
  {
    key: 'Audiophile',
    label: 'Audiophile',
  },
  {
    key: 'Australian Rules Football (AFL)',
    label: 'Australian Rules Football (AFL)',
  },
  {
    key: 'Auto audiophilia',
    label: 'Auto audiophilia',
  },
  {
    key: 'Auto detailing',
    label: 'Auto detailing',
  },
  {
    key: 'Auto racing',
    label: 'Auto racing',
  },
  {
    key: 'Automobilism',
    label: 'Automobilism',
  },
  {
    key: 'Axe throwing',
    label: 'Axe throwing',
  },
  {
    key: 'Backgammon',
    label: 'Backgammon',
  },
  {
    key: 'Backpacking',
    label: 'Backpacking',
  },
  {
    key: 'Badminton',
    label: 'Badminton',
  },
  {
    key: 'Baking',
    label: 'Baking',
  },
  {
    key: 'Ballet dancing',
    label: 'Ballet dancing',
  },
  {
    key: 'Ballroom dancing',
    label: 'Ballroom dancing',
  },
  {
    key: 'Barbershop Music',
    label: 'Barbershop Music',
  },
  {
    key: 'Bartending',
    label: 'Bartending',
  },
  {
    key: 'BASE jumping',
    label: 'BASE jumping',
  },
  {
    key: 'Baseball',
    label: 'Baseball',
  },
  {
    key: 'Basket weaving',
    label: 'Basket weaving',
  },
  {
    key: 'Basketball',
    label: 'Basketball',
  },
  {
    key: 'Baton twirling',
    label: 'Baton twirling',
  },
  {
    key: 'Beach volleyball',
    label: 'Beach volleyball',
  },
  {
    key: 'Beachcombing',
    label: 'Beachcombing',
  },
  {
    key: 'Beatboxing',
    label: 'Beatboxing',
  },
  {
    key: 'Beauty pageants',
    label: 'Beauty pageants',
  },
  {
    key: 'Beekeeping',
    label: 'Beekeeping',
  },
  {
    key: 'Beer tasting',
    label: 'Beer tasting',
  },
  {
    key: 'Bell ringing',
    label: 'Bell ringing',
  },
  {
    key: 'Benchmarking',
    label: 'Benchmarking',
  },
  {
    key: 'Billiards',
    label: 'Billiards',
  },
  {
    key: 'Binge watching',
    label: 'Binge watching',
  },
  {
    key: 'Biology',
    label: 'Biology',
  },
  {
    key: 'Birdwatching',
    label: 'Birdwatching',
  },
  {
    key: 'Blacksmithing',
    label: 'Blacksmithing',
  },
  {
    key: 'Blogging',
    label: 'Blogging',
  },
  {
    key: 'BMX',
    label: 'BMX',
  },
  {
    key: 'Board games',
    label: 'Board games',
  },
  {
    key: 'Board sports',
    label: 'Board sports',
  },
  {
    key: 'Bodybuilding',
    label: 'Bodybuilding',
  },
  {
    key: 'Bonsai',
    label: 'Bonsai',
  },
  {
    key: 'Book collecting',
    label: 'Book collecting',
  },
  {
    key: 'Book discussion clubs',
    label: 'Book discussion clubs',
  },
  {
    key: 'Book folding',
    label: 'Book folding',
  },
  {
    key: 'Book restoration',
    label: 'Book restoration',
  },
  {
    key: 'Botany',
    label: 'Botany',
  },
  {
    key: 'Bowling',
    label: 'Bowling',
  },
  {
    key: 'Boxing',
    label: 'Boxing',
  },
  {
    key: 'Brazilian jiu-jitsu',
    label: 'Brazilian jiu-jitsu',
  },
  {
    key: 'Breadmaking',
    label: 'Breadmaking',
  },
  {
    key: 'Breakdancing',
    label: 'Breakdancing',
  },
  {
    key: 'Bridge',
    label: 'Bridge',
  },
  {
    key: 'Building',
    label: 'Building',
  },
  {
    key: 'Bullet journaling',
    label: 'Bullet journaling',
  },
  {
    key: 'Bus riding',
    label: 'Bus riding',
  },
  {
    key: 'Bus spotting',
    label: 'Bus spotting',
  },
  {
    key: 'Business',
    label: 'Business',
  },
  {
    key: 'Butchering',
    label: 'Butchering',
  },
  {
    key: 'Butterfly watching',
    label: 'Butterfly watching',
  },
  {
    key: 'Button collecting',
    label: 'Button collecting',
  },
  {
    key: 'Calligraphy',
    label: 'Calligraphy',
  },
  {
    key: 'Camping',
    label: 'Camping',
  },
  {
    key: 'Candle making',
    label: 'Candle making',
  },
  {
    key: 'Candy making',
    label: 'Candy making',
  },
  {
    key: 'Canoeing',
    label: 'Canoeing',
  },
  {
    key: 'Canyoning',
    label: 'Canyoning',
  },
  {
    key: 'Car fixing & building',
    label: 'Car fixing & building',
  },
  {
    key: 'Car riding',
    label: 'Car riding',
  },
  {
    key: 'Car spotting',
    label: 'Car spotting',
  },
  {
    key: 'Car tuning',
    label: 'Car tuning',
  },
  {
    key: 'Card games',
    label: 'Card games',
  },
  {
    key: 'Cardistry',
    label: 'Cardistry',
  },
  {
    key: 'Carrier pigeons',
    label: 'Carrier pigeons',
  },
  {
    key: 'Cartophily',
    label: 'Cartophily',
  },
  {
    key: 'Caving',
    label: 'Caving',
  },
  {
    key: 'Ceramics',
    label: 'Ceramics',
  },
  {
    key: 'Chatting',
    label: 'Chatting',
  },
  {
    key: 'Checkers',
    label: 'Checkers',
  },
  {
    key: 'Cheerleading',
    label: 'Cheerleading',
  },
  {
    key: 'Cheesemaking',
    label: 'Cheesemaking',
  },
  {
    key: 'Chemistry',
    label: 'Chemistry',
  },
  {
    key: 'Chess',
    label: 'Chess',
  },
  {
    key: 'City trip',
    label: 'City trip',
  },
  {
    key: 'Cleaning',
    label: 'Cleaning',
  },
  {
    key: 'Climbing',
    label: 'Climbing',
  },
  {
    key: 'Clothesmaking',
    label: 'Clothesmaking',
  },
  {
    key: 'Coffee roasting',
    label: 'Coffee roasting',
  },
  {
    key: 'Coin collecting',
    label: 'Coin collecting',
  },
  {
    key: 'Collecting',
    label: 'Collecting',
  },
  {
    key: 'Color guard',
    label: 'Color guard',
  },
  {
    key: 'Coloring',
    label: 'Coloring',
  },
  {
    key: 'Comic book collecting',
    label: 'Comic book collecting',
  },
  {
    key: 'Communication',
    label: 'Communication',
  },
  {
    key: 'Community activism',
    label: 'Community activism',
  },
  {
    key: 'Community Radio',
    label: 'Community Radio',
  },
  {
    key: 'Compact discs',
    label: 'Compact discs',
  },
  {
    key: 'Composting',
    label: 'Composting',
  },
  {
    key: 'Computer programming',
    label: 'Computer programming',
  },
  {
    key: 'Confectionery',
    label: 'Confectionery',
  },
  {
    key: 'Conlanging',
    label: 'Conlanging',
  },
  {
    key: 'Construction',
    label: 'Construction',
  },
  {
    key: 'Cooking',
    label: 'Cooking',
  },
  {
    key: 'Cornhole',
    label: 'Cornhole',
  },
  {
    key: 'Cosplaying',
    label: 'Cosplaying',
  },
  {
    key: 'Couponing',
    label: 'Couponing',
  },
  {
    key: 'Craft',
    label: 'Craft',
  },
  {
    key: 'Creative writing',
    label: 'Creative writing',
  },
  {
    key: 'Cribbage',
    label: 'Cribbage',
  },
  {
    key: 'Cricket',
    label: 'Cricket',
  },
  {
    key: 'Crocheting',
    label: 'Crocheting',
  },
  {
    key: 'Croquet',
    label: 'Croquet',
  },
  {
    key: 'Cross-stitch',
    label: 'Cross-stitch',
  },
  {
    key: 'cross-fit',
    label: 'Crossfit',
  },
  {
    key: 'Crossword puzzles',
    label: 'Crossword puzzles',
  },
  {
    key: 'Cryptography',
    label: 'Cryptography',
  },
  {
    key: 'Crystals',
    label: 'Crystals',
  },
  {
    key: 'Cue sports',
    label: 'Cue sports',
  },
  {
    key: 'Curling',
    label: 'Curling',
  },
  {
    key: 'Cycling',
    label: 'Cycling',
  },
  {
    key: 'Dairy Farming',
    label: 'Dairy Farming',
  },
  {
    key: 'Dancing',
    label: 'Dancing',
  },
  {
    key: 'Dandyism',
    label: 'Dandyism',
  },
  {
    key: 'Darts',
    label: 'Darts',
  },
  {
    key: 'Debating',
    label: 'Debating',
  },
  {
    key: 'Decorating',
    label: 'Decorating',
  },
  {
    key: 'Decorative birds',
    label: 'Decorative birds',
  },
  {
    key: 'Deltiology',
    label: 'Deltiology',
  },
  {
    key: 'Die-cast toy',
    label: 'Die-cast toy',
  },
  {
    key: 'Digital arts',
    label: 'Digital arts',
  },
  {
    key: 'Digital hoarding',
    label: 'Digital hoarding',
  },
  {
    key: 'Dining',
    label: 'Dining',
  },
  {
    key: 'Diorama',
    label: 'Diorama',
  },
  {
    key: 'Disc golf',
    label: 'Disc golf',
  },
  {
    key: 'Distro Hopping',
    label: 'Distro Hopping',
  },
  {
    key: 'Diving',
    label: 'Diving',
  },
  {
    key: 'Djembe',
    label: 'Djembe',
  },
  {
    key: 'DJing',
    label: 'DJing',
  },
  {
    key: 'Do it yourself',
    label: 'Do it yourself',
  },
  {
    key: 'Dodgeball',
    label: 'Dodgeball',
  },
  {
    key: 'Dog sports',
    label: 'Dog sports',
  },
  {
    key: 'Dog training',
    label: 'Dog training',
  },
  {
    key: 'Dog walking',
    label: 'Dog walking',
  },
  {
    key: 'Dolls',
    label: 'Dolls',
  },
  {
    key: 'Dominoes',
    label: 'Dominoes',
  },
  {
    key: 'Dowsing',
    label: 'Dowsing',
  },
  {
    key: 'Drama',
    label: 'Drama',
  },
  {
    key: 'Drawing',
    label: 'Drawing',
  },
  {
    key: 'Driving',
    label: 'Driving',
  },
  {
    key: 'Eating',
    label: 'Eating',
  },
  {
    key: 'Editing',
    label: 'Editing',
  },
  {
    key: 'Electronic games',
    label: 'Electronic games',
  },
  {
    key: 'Electronics',
    label: 'Electronics',
  },
  {
    key: 'Element collecting',
    label: 'Element collecting',
  },
  {
    key: 'Embroidery',
    label: 'Embroidery',
  },
  {
    key: 'Engraving',
    label: 'Engraving',
  },
  {
    key: 'Entertaining',
    label: 'Entertaining',
  },
  {
    key: 'Entrepreneurship',
    label: 'Entrepreneurship',
  },
  {
    key: 'Ephemera collecting',
    label: 'Ephemera collecting',
  },
  {
    key: 'Equestrianism',
    label: 'Equestrianism',
  },
  {
    key: 'Esports',
    label: 'Esports',
  },
  {
    key: 'Everyday carry',
    label: 'Everyday carry',
  },
  {
    key: 'Exhibition drill',
    label: 'Exhibition drill',
  },
  {
    key: 'Experimenting',
    label: 'Experimenting',
  },
  {
    key: 'Fantasy sports',
    label: 'Fantasy sports',
  },
  {
    key: 'Farming',
    label: 'Farming',
  },
  {
    key: 'Fashion',
    label: 'Fashion',
  },
  {
    key: 'Fashion design',
    label: 'Fashion design',
  },
  {
    key: 'Fencing',
    label: 'Fencing',
  },
  {
    key: 'Feng shui decorating',
    label: 'Feng shui decorating',
  },
  {
    key: 'Field hockey',
    label: 'Field hockey',
  },
  {
    key: 'Figure Skating',
    label: 'Figure Skating',
  },
  {
    key: 'Filmmaking',
    label: 'Filmmaking',
  },
  {
    key: 'Films',
    label: 'Films',
  },
  {
    key: 'Finance',
    label: 'Finance',
  },
  {
    key: 'Fingerpainting',
    label: 'Fingerpainting',
  },
  {
    key: 'Fingerprint collecting',
    label: 'Fingerprint collecting',
  },
  {
    key: 'Fishfarming',
    label: 'Fishfarming',
  },
  {
    key: 'Fishing',
    label: 'Fishing',
  },
  {
    key: 'Fishkeeping',
    label: 'Fishkeeping',
  },
  {
    key: 'Fitness',
    label: 'Fitness',
  },
  {
    key: 'Flag football',
    label: 'Flag football',
  },
  {
    key: 'Flower arranging',
    label: 'Flower arranging',
  },
  {
    key: 'Flower collecting and pressing',
    label: 'Flower collecting and pressing',
  },
  {
    key: 'Flower growing',
    label: 'Flower growing',
  },
  {
    key: 'Fly tying',
    label: 'Fly tying',
  },
  {
    key: 'Flying',
    label: 'Flying',
  },
  {
    key: 'Flying disc',
    label: 'Flying disc',
  },
  {
    key: 'Flying model planes',
    label: 'Flying model planes',
  },
  {
    key: 'Footbag',
    label: 'Footbag',
  },
  {
    key: 'Foraging',
    label: 'Foraging',
  },
  {
    key: 'Foreign language learning',
    label: 'Foreign language learning',
  },
  {
    key: 'Fossicking',
    label: 'Fossicking',
  },
  {
    key: 'Fossil hunting',
    label: 'Fossil hunting',
  },
  {
    key: 'Freestyle football',
    label: 'Freestyle football',
  },
  {
    key: 'Frisbee',
    label: 'Frisbee',
  },
  {
    key: 'Fruit picking',
    label: 'Fruit picking',
  },
  {
    key: 'Furniture building',
    label: 'Furniture building',
  },
  {
    key: 'Fusilately',
    label: 'Fusilately',
  },
  {
    key: 'Gardening',
    label: 'Gardening',
  },
  {
    key: 'Genealogy',
    label: 'Genealogy',
  },
  {
    key: 'Geocaching',
    label: 'Geocaching',
  },
  {
    key: 'Geography',
    label: 'Geography',
  },
  {
    key: 'Ghost hunting',
    label: 'Ghost hunting',
  },
  {
    key: 'Gingerbread house making',
    label: 'Gingerbread house making',
  },
  {
    key: 'Giving advice',
    label: 'Giving advice',
  },
  {
    key: 'Glassblowing',
    label: 'Glassblowing',
  },
  {
    key: 'Go',
    label: 'Go',
  },
  {
    key: 'Gold prospecting',
    label: 'Gold prospecting',
  },
  {
    key: 'Golf',
    label: 'Golf',
  },
  {
    key: 'Gongfu tea',
    label: 'Gongfu tea',
  },
  {
    key: 'Gongoozling',
    label: 'Gongoozling',
  },
  {
    key: 'Graffiti',
    label: 'Graffiti',
  },
  {
    key: 'Graphic design',
    label: 'Graphic design',
  },
  {
    key: 'Groundhopping',
    label: 'Groundhopping',
  },
  {
    key: 'Guerrilla gardening',
    label: 'Guerrilla gardening',
  },
  {
    key: 'Gunsmithing',
    label: 'Gunsmithing',
  },
  {
    key: 'Gymnastics',
    label: 'Gymnastics',
  },
  {
    key: 'Hacking',
    label: 'Hacking',
  },
  {
    key: 'Hairstyle',
    label: 'Hairstyle',
  },
  {
    key: 'Handball',
    label: 'Handball',
  },
  {
    key: 'Hardware',
    label: 'Hardware',
  },
  {
    key: 'Herbalism',
    label: 'Herbalism',
  },
  {
    key: 'Herp keeping',
    label: 'Herp keeping',
  },
  {
    key: 'Herping',
    label: 'Herping',
  },
  {
    key: 'High-power rocketry',
    label: 'High-power rocketry',
  },
  {
    key: 'Hiking',
    label: 'Hiking',
  },
  {
    key: 'Hiking/backpacking',
    label: 'Hiking/backpacking',
  },
  {
    key: 'History',
    label: 'History',
  },
  {
    key: 'Hobby horsing',
    label: 'Hobby horsing',
  },
  {
    key: 'Hobby tunneling',
    label: 'Hobby tunneling',
  },
  {
    key: 'Home improvement',
    label: 'Home improvement',
  },
  {
    key: 'Homebrewing',
    label: 'Homebrewing',
  },
  {
    key: 'Homing pigeons',
    label: 'Homing pigeons',
  },
  {
    key: 'Hooping',
    label: 'Hooping',
  },
  {
    key: 'Horseback riding',
    label: 'Horseback riding',
  },
  {
    key: 'Horsemanship',
    label: 'Horsemanship',
  },
  {
    key: 'Horseshoes',
    label: 'Horseshoes',
  },
  {
    key: 'Houseplant care',
    label: 'Houseplant care',
  },
  {
    key: 'Hula hooping',
    label: 'Hula hooping',
  },
  {
    key: 'Hunting',
    label: 'Hunting',
  },
  {
    key: 'Hydroponics',
    label: 'Hydroponics',
  },
  {
    key: 'Ice hockey',
    label: 'Ice hockey',
  },
  {
    key: 'Ice skating',
    label: 'Ice skating',
  },
  {
    key: 'Iceboat racing',
    label: 'Iceboat racing',
  },
  {
    key: 'Indoors',
    label: 'Indoors',
  },
  {
    key: 'Inline skating',
    label: 'Inline skating',
  },
  {
    key: 'Insect collecting',
    label: 'Insect collecting',
  },
  {
    key: 'Inventing',
    label: 'Inventing',
  },
  {
    key: 'Jewelry making',
    label: 'Jewelry making',
  },
  {
    key: 'Jigsaw puzzles',
    label: 'Jigsaw puzzles',
  },
  {
    key: 'Jogging',
    label: 'Jogging',
  },
  {
    key: 'Journaling',
    label: 'Journaling',
  },
  {
    key: 'Judo',
    label: 'Judo',
  },
  {
    key: 'Juggling',
    label: 'Juggling',
  },
  {
    key: 'Jujitsu',
    label: 'Jujitsu',
  },
  {
    key: 'Jukskei',
    label: 'Jukskei',
  },
  {
    key: 'Jumping rope',
    label: 'Jumping rope',
  },
  {
    key: 'Kabaddi',
    label: 'Kabaddi',
  },
  {
    key: 'Karaoke',
    label: 'Karaoke',
  },
  {
    key: 'Karate',
    label: 'Karate',
  },
  {
    key: 'Kart racing',
    label: 'Kart racing',
  },
  {
    key: 'Karting',
    label: 'Karting',
  },
  {
    key: 'Kayaking',
    label: 'Kayaking',
  },
  {
    key: 'Kendama',
    label: 'Kendama',
  },
  {
    key: 'Kite flying',
    label: 'Kite flying',
  },
  {
    key: 'Kitesurfing',
    label: 'Kitesurfing',
  },
  {
    key: 'Knife collecting',
    label: 'Knife collecting',
  },
  {
    key: 'Knife making',
    label: 'Knife making',
  },
  {
    key: 'Knife throwing',
    label: 'Knife throwing',
  },
  {
    key: 'Knitting',
    label: 'Knitting',
  },
  {
    key: 'Knot tying',
    label: 'Knot tying',
  },
  {
    key: 'Knowledge/word games',
    label: 'Knowledge/word games',
  },
  {
    key: 'Kombucha brewing',
    label: 'Kombucha brewing',
  },
  {
    key: 'Kung fu',
    label: 'Kung fu',
  },
  {
    key: 'Lace making',
    label: 'Lace making',
  },
  {
    key: 'Lacrosse',
    label: 'Lacrosse',
  },
  {
    key: 'Lapel pins',
    label: 'Lapel pins',
  },
  {
    key: 'Lapidary',
    label: 'Lapidary',
  },
  {
    key: 'LARPing',
    label: 'LARPing',
  },
  {
    key: 'Laser tag',
    label: 'Laser tag',
  },
  {
    key: 'Learning',
    label: 'Learning',
  },
  {
    key: 'Leather crafting',
    label: 'Leather crafting',
  },
  {
    key: 'Leaves',
    label: 'Leaves',
  },
  {
    key: 'Lego building',
    label: 'Lego building',
  },
  {
    key: 'Letterboxing',
    label: 'Letterboxing',
  },
  {
    key: 'Life science',
    label: 'Life science',
  },
  {
    key: 'Linguistics',
    label: 'Linguistics',
  },
  {
    key: 'Listening to music',
    label: 'Listening to music',
  },
  {
    key: 'Listening to podcasts',
    label: 'Listening to podcasts',
  },
  {
    key: 'Literature',
    label: 'Literature',
  },
  {
    key: 'Livestreaming',
    label: 'Livestreaming',
  },
  {
    key: 'Lock picking',
    label: 'Lock picking',
  },
  {
    key: 'Lomography',
    label: 'Lomography',
  },
  {
    key: 'Long-distance running',
    label: 'Long-distance running',
  },
  {
    key: 'Longboarding',
    label: 'Longboarding',
  },
  {
    key: 'Lotology (lottery ticket collecting)',
    label: 'Lotology (lottery ticket collecting)',
  },
  {
    key: 'Machining',
    label: 'Machining',
  },
  {
    key: 'Macrame',
    label: 'Macrame',
  },
  {
    key: 'Magic',
    label: 'Magic',
  },
  {
    key: 'Magnet fishing',
    label: 'Magnet fishing',
  },
  {
    key: 'Mahjong',
    label: 'Mahjong',
  },
  {
    key: 'Makeup',
    label: 'Makeup',
  },
  {
    key: 'Manga',
    label: 'Manga',
  },
  {
    key: 'Marbles',
    label: 'Marbles',
  },
  {
    key: 'Marching band',
    label: 'Marching band',
  },
  {
    key: 'Martial arts',
    label: 'Martial arts',
  },
  {
    key: 'Massaging',
    label: 'Massaging',
  },
  {
    key: 'Mathematics',
    label: 'Mathematics',
  },
  {
    key: 'Mazes',
    label: 'Mazes',
  },
  {
    key: 'Mechanics',
    label: 'Mechanics',
  },
  {
    key: 'Medical science',
    label: 'Medical science',
  },
  {
    key: 'Meditation',
    label: 'Meditation',
  },
  {
    key: 'Memory training',
    label: 'Memory training',
  },
  {
    key: 'Metal detecting',
    label: 'Metal detecting',
  },
  {
    key: 'Metalworking',
    label: 'Metalworking',
  },
  {
    key: 'Meteorology',
    label: 'Meteorology',
  },
  {
    key: 'Microbiology',
    label: 'Microbiology',
  },
  {
    key: 'Microscopy',
    label: 'Microscopy',
  },
  {
    key: 'Mineral collecting',
    label: 'Mineral collecting',
  },
  {
    key: 'Mini Golf',
    label: 'Mini Golf',
  },
  {
    key: 'Miniature art',
    label: 'Miniature art',
  },
  {
    key: 'Minimalism',
    label: 'Minimalism',
  },
  {
    key: 'Model aircraft',
    label: 'Model aircraft',
  },
  {
    key: 'Model building',
    label: 'Model building',
  },
  {
    key: 'Model engineering',
    label: 'Model engineering',
  },
  {
    key: 'Model racing',
    label: 'Model racing',
  },
  {
    key: 'Model United Nations',
    label: 'Model United Nations',
  },
  {
    key: 'Modeling',
    label: 'Modeling',
  },
  {
    key: 'Motor sports',
    label: 'Motor sports',
  },
  {
    key: 'Motorcycling',
    label: 'Motorcycling',
  },
  {
    key: 'Mountain biking',
    label: 'Mountain biking',
  },
  {
    key: 'Mountaineering',
    label: 'Mountaineering',
  },
  {
    key: 'Movie memorabilia collecting',
    label: 'Movie memorabilia collecting',
  },
  {
    key: 'Museum visiting',
    label: 'Museum visiting',
  },
  {
    key: 'Mushroom hunting/mycology',
    label: 'Mushroom hunting/mycology',
  },
  {
    key: 'Music',
    label: 'Music',
  },
  {
    key: 'Mycology',
    label: 'Mycology',
  },
  {
    key: 'Nail art',
    label: 'Nail art',
  },
  {
    key: 'Needlepoint',
    label: 'Needlepoint',
  },
  {
    key: 'Netball',
    label: 'Netball',
  },
  {
    key: 'Neuroscience',
    label: 'Neuroscience',
  },
  {
    key: 'Noodling',
    label: 'Noodling',
  },
  {
    key: 'Nordic skating',
    label: 'Nordic skating',
  },
  {
    key: 'Online Gaming ',
    label: 'Online Gaming ',
  },
  {
    key: 'Orienteering',
    label: 'Orienteering',
  },
  {
    key: 'Origami',
    label: 'Origami',
  },
  {
    key: 'Outdoors',
    label: 'Outdoors',
  },
  {
    key: 'Paintball',
    label: 'Paintball',
  },
  {
    key: 'Painting',
    label: 'Painting',
  },
  {
    key: 'Paragliding',
    label: 'Paragliding',
  },
  {
    key: 'Parkour',
    label: 'Parkour',
  },
  {
    key: 'Pen Spinning',
    label: 'Pen Spinning',
  },
  {
    key: 'People-watching',
    label: 'People-watching',
  },
  {
    key: 'Performance',
    label: 'Performance',
  },
  {
    key: 'Perfume',
    label: 'Perfume',
  },
  {
    key: 'Pet sitting',
    label: 'Pet sitting',
  },
  {
    key: 'Philately',
    label: 'Philately',
  },
  {
    key: 'Phillumeny',
    label: 'Phillumeny',
  },
  {
    key: 'Philosophy',
    label: 'Philosophy',
  },
  {
    key: 'Photography',
    label: 'Photography',
  },
  {
    key: 'Physics',
    label: 'Physics',
  },
  {
    key: 'Pickleball',
    label: 'Pickleball',
  },
  {
    key: 'Picnicking',
    label: 'Picnicking',
  },
  {
    key: 'Pilates',
    label: 'Pilates',
  },
  {
    key: 'Pinball',
    label: 'Pinball',
  },
  {
    key: 'Pipes',
    label: 'Pipes',
  },
  {
    key: 'Planning',
    label: 'Planning',
  },
  {
    key: 'Plastic art',
    label: 'Plastic art',
  },
  {
    key: 'Playing musical instruments',
    label: 'Playing musical instruments',
  },
  {
    key: 'Podcast hosting',
    label: 'Podcast hosting',
  },
  {
    key: 'Poetry',
    label: 'Poetry',
  },
  {
    key: 'Poi',
    label: 'Poi',
  },
  {
    key: 'Poker',
    label: 'Poker',
  },
  {
    key: 'Pole dancing',
    label: 'Pole dancing',
  },
  {
    key: 'Polo',
    label: 'Polo',
  },
  {
    key: 'Pool',
    label: 'Pool',
  },
  {
    key: 'Postcrossing',
    label: 'Postcrossing',
  },
  {
    key: 'Pottery',
    label: 'Pottery',
  },
  {
    key: 'Powerboat racing',
    label: 'Powerboat racing',
  },
  {
    key: 'Powerlifting',
    label: 'Powerlifting',
  },
  {
    key: 'Practical jokes',
    label: 'Practical jokes',
  },
  {
    key: 'Pressed flower craft',
    label: 'Pressed flower craft',
  },
  {
    key: 'Proofreading and editing',
    label: 'Proofreading and editing',
  },
  {
    key: 'Proverbs',
    label: 'Proverbs',
  },
  {
    key: 'Psychology',
    label: 'Psychology',
  },
  {
    key: 'Public speaking',
    label: 'Public speaking',
  },
  {
    key: 'Public transport riding',
    label: 'Public transport riding',
  },
  {
    key: 'Puppetry',
    label: 'Puppetry',
  },
  {
    key: 'Puzzles',
    label: 'Puzzles',
  },
  {
    key: 'Pyrography',
    label: 'Pyrography',
  },
  {
    key: 'Qigong',
    label: 'Qigong',
  },
  {
    key: 'Quidditch',
    label: 'Quidditch',
  },
  {
    key: 'Quilling',
    label: 'Quilling',
  },
  {
    key: 'Quilting',
    label: 'Quilting',
  },
  {
    key: 'Quizzes',
    label: 'Quizzes',
  },
  {
    key: 'Race walking',
    label: 'Race walking',
  },
  {
    key: 'Racquetball',
    label: 'Racquetball',
  },
  {
    key: 'Radio-controlled car racing',
    label: 'Radio-controlled car racing',
  },
  {
    key: 'Radio-controlled model collecting',
    label: 'Radio-controlled model collecting',
  },
  {
    key: 'Radio-controlled model playing',
    label: 'Radio-controlled model playing',
  },
  {
    key: 'Rafting',
    label: 'Rafting',
  },
  {
    key: 'Rail transport modeling',
    label: 'Rail transport modeling',
  },
  {
    key: 'Railway journeys',
    label: 'Railway journeys',
  },
  {
    key: 'Railway modelling',
    label: 'Railway modelling',
  },
  {
    key: 'Railway studies',
    label: 'Railway studies',
  },
  {
    key: 'Rappelling',
    label: 'Rappelling',
  },
  {
    key: 'Rapping',
    label: 'Rapping',
  },
  {
    key: 'Reading',
    label: 'Reading',
  },
  {
    key: 'Recipe creation',
    label: 'Recipe creation',
  },
  {
    key: 'Record collecting',
    label: 'Record collecting',
  },
  {
    key: 'Refinishing',
    label: 'Refinishing',
  },
  {
    key: 'Reiki',
    label: 'Reiki',
  },
  {
    key: 'Renaissance fair',
    label: 'Renaissance fair',
  },
  {
    key: 'Renovating',
    label: 'Renovating',
  },
  {
    key: 'Research',
    label: 'Research',
  },
  {
    key: 'Reviewing Gadgets',
    label: 'Reviewing Gadgets',
  },
  {
    key: 'Road biking',
    label: 'Road biking',
  },
  {
    key: 'Robot combat',
    label: 'Robot combat',
  },
  {
    key: 'Rock balancing',
    label: 'Rock balancing',
  },
  {
    key: 'Rock climbing',
    label: 'Rock climbing',
  },
  {
    key: 'Rock painting',
    label: 'Rock painting',
  },
  {
    key: 'Rock tumbling',
    label: 'Rock tumbling',
  },
  {
    key: 'Role-playing games',
    label: 'Role-playing games',
  },
  {
    key: 'Roller derby',
    label: 'Roller derby',
  },
  {
    key: 'Roller skating',
    label: 'Roller skating',
  },
  {
    key: 'Roundnet',
    label: 'Roundnet',
  },
  {
    key: "Rubik's Cube",
    label: "Rubik's Cube",
  },
  {
    key: 'Rugby',
    label: 'Rugby',
  },
  {
    key: 'Rugby league football',
    label: 'Rugby league football',
  },
  {
    key: 'Rughooking',
    label: 'Rughooking',
  },
  {
    key: 'Running',
    label: 'Running',
  },
  {
    key: 'Safari',
    label: 'Safari',
  },
  {
    key: 'Sailing',
    label: 'Sailing',
  },
  {
    key: 'Sand art',
    label: 'Sand art',
  },
  {
    key: 'Satellite watching',
    label: 'Satellite watching',
  },
  {
    key: 'Science and technology studies',
    label: 'Science and technology studies',
  },
  {
    key: 'Scouting',
    label: 'Scouting',
  },
  {
    key: 'Scrapbooking',
    label: 'Scrapbooking',
  },
  {
    key: 'Scuba Diving',
    label: 'Scuba Diving',
  },
  {
    key: 'Sculling or rowing',
    label: 'Sculling or rowing',
  },
  {
    key: 'Sculpting',
    label: 'Sculpting',
  },
  {
    key: 'Scutelliphily',
    label: 'Scutelliphily',
  },
  {
    key: 'Sea glass collecting',
    label: 'Sea glass collecting',
  },
  {
    key: 'Seashell collecting',
    label: 'Seashell collecting',
  },
  {
    key: 'Sewing',
    label: 'Sewing',
  },
  {
    key: 'Shoemaking',
    label: 'Shoemaking',
  },
  {
    key: 'Shoes',
    label: 'Shoes',
  },
  {
    key: 'Shogi',
    label: 'Shogi',
  },
  {
    key: 'Shooting',
    label: 'Shooting',
  },
  {
    key: 'Shooting sports',
    label: 'Shooting sports',
  },
  {
    key: 'Shopping',
    label: 'Shopping',
  },
  {
    key: 'Shortwave listening',
    label: 'Shortwave listening',
  },
  {
    key: 'Shuffleboard',
    label: 'Shuffleboard',
  },
  {
    key: 'Singing',
    label: 'Singing',
  },
  {
    key: 'Skateboarding',
    label: 'Skateboarding',
  },
  {
    key: 'Sketching',
    label: 'Sketching',
  },
  {
    key: 'Skiing',
    label: 'Skiing',
  },
  {
    key: 'Skimboarding',
    label: 'Skimboarding',
  },
  {
    key: 'Skipping rope',
    label: 'Skipping rope',
  },
  {
    key: 'Skydiving',
    label: 'Skydiving',
  },
  {
    key: 'Slacklining',
    label: 'Slacklining',
  },
  {
    key: 'Sled dog racing',
    label: 'Sled dog racing',
  },
  {
    key: 'Sledding',
    label: 'Sledding',
  },
  {
    key: 'Slot cars',
    label: 'Slot cars',
  },
  {
    key: 'Slot car racing',
    label: 'Slot car racing',
  },
  {
    key: 'Snorkeling',
    label: 'Snorkeling',
  },
  {
    key: 'Snowboarding',
    label: 'Snowboarding',
  },
  {
    key: 'Snowmobiling',
    label: 'Snowmobiling',
  },
  {
    key: 'Snowshoeing',
    label: 'Snowshoeing',
  },
  {
    key: 'Soapmaking',
    label: 'Soapmaking',
  },
  {
    key: 'Soccer',
    label: 'Soccer',
  },
  {
    key: 'Social media',
    label: 'Social media',
  },
  {
    key: 'Social studies',
    label: 'Social studies',
  },
  {
    key: 'Sociology',
    label: 'Sociology',
  },
  {
    key: 'Softball',
    label: 'Softball',
  },
  {
    key: 'Speed skating',
    label: 'Speed skating',
  },
  {
    key: 'Speedcubing',
    label: 'Speedcubing',
  },
  {
    key: 'Speedrunning',
    label: 'Speedrunning',
  },
  {
    key: 'Sport stacking',
    label: 'Sport stacking',
  },
  {
    key: 'Sports memorabilia',
    label: 'Sports memorabilia',
  },
  {
    key: 'Sports science',
    label: 'Sports science',
  },
  {
    key: 'Spreadsheets',
    label: 'Spreadsheets',
  },
  {
    key: 'Squash',
    label: 'Squash',
  },
  {
    key: 'Stamp collecting',
    label: 'Stamp collecting',
  },
  {
    key: 'Stand-up comedy',
    label: 'Stand-up comedy',
  },
  {
    key: 'Stone collecting',
    label: 'Stone collecting',
  },
  {
    key: 'Stone skipping',
    label: 'Stone skipping',
  },
  {
    key: 'Storm chasing',
    label: 'Storm chasing',
  },
  {
    key: 'Story writing',
    label: 'Story writing',
  },
  {
    key: 'Storytelling',
    label: 'Storytelling',
  },
  {
    key: 'Stretching',
    label: 'Stretching',
  },
  {
    key: 'String Figures',
    label: 'String Figures',
  },
  {
    key: 'Stripping',
    label: 'Stripping',
  },
  {
    key: 'Stuffed toy collecting',
    label: 'Stuffed toy collecting',
  },
  {
    key: 'Sudoku',
    label: 'Sudoku',
  },
  {
    key: 'Sun bathing',
    label: 'Sun bathing',
  },
  {
    key: 'Surfing',
    label: 'Surfing',
  },
  {
    key: 'Survivalism',
    label: 'Survivalism',
  },
  {
    key: 'Swimming',
    label: 'Swimming',
  },
  {
    key: 'Table football',
    label: 'Table football',
  },
  {
    key: 'Table tennis',
    label: 'Table tennis',
  },
  {
    key: 'Taekwondo',
    label: 'Taekwondo',
  },
  {
    key: 'Tai chi',
    label: 'Tai chi',
  },
  {
    key: 'Talking',
    label: 'Talking',
  },
  {
    key: 'Tapestry',
    label: 'Tapestry',
  },
  {
    key: 'Tarot',
    label: 'Tarot',
  },
  {
    key: 'Tattooing',
    label: 'Tattooing',
  },
  {
    key: 'Taxidermy',
    label: 'Taxidermy',
  },
  {
    key: 'Tea bag collecting',
    label: 'Tea bag collecting',
  },
  {
    key: 'Teaching',
    label: 'Teaching',
  },
  {
    key: 'Telling jokes',
    label: 'Telling jokes',
  },
  {
    key: 'Tennis polo',
    label: 'Tennis polo',
  },
  {
    key: 'Tennis',
    label: 'Tennis',
  },
  {
    key: 'Tether car',
    label: 'Tether car',
  },
  {
    key: 'Thrifting',
    label: 'Thrifting',
  },
  {
    key: 'Thru-hiking',
    label: 'Thru-hiking',
  },
  {
    key: 'Ticket collecting',
    label: 'Ticket collecting',
  },
  {
    key: 'Topiary',
    label: 'Topiary',
  },
  {
    key: 'Tour skating',
    label: 'Tour skating',
  },
  {
    key: 'Tourism',
    label: 'Tourism',
  },
  {
    key: 'Trade fair visiting',
    label: 'Trade fair visiting',
  },
  {
    key: 'Trainspotting',
    label: 'Trainspotting',
  },
  {
    key: 'Transit map collecting',
    label: 'Transit map collecting',
  },
  {
    key: 'Trapshooting',
    label: 'Trapshooting',
  },
  {
    key: 'Travel',
    label: 'Travel',
  },
  {
    key: 'Triathlon',
    label: 'Triathlon',
  },
  {
    key: 'Ultimate frisbee',
    label: 'Ultimate frisbee',
  },
  {
    key: 'Unicycling',
    label: 'Unicycling',
  },
  {
    key: 'Upcycling',
    label: 'Upcycling',
  },
  {
    key: 'Urban exploration',
    label: 'Urban exploration',
  },
  {
    key: 'Vacation',
    label: 'Vacation',
  },
  {
    key: 'Vegetable farming',
    label: 'Vegetable farming',
  },
  {
    key: 'Vehicle restoration',
    label: 'Vehicle restoration',
  },
  {
    key: 'Video editing',
    label: 'Video editing',
  },
  {
    key: 'Video game collecting',
    label: 'Video game collecting',
  },
  {
    key: 'Video game developing',
    label: 'Video game developing',
  },
  {
    key: 'Video gaming',
    label: 'Video gaming',
  },
  {
    key: 'Video making',
    label: 'Video making',
  },
  {
    key: 'Videography',
    label: 'Videography',
  },
  {
    key: 'Vintage cars',
    label: 'Vintage cars',
  },
  {
    key: 'Vintage clothing',
    label: 'Vintage clothing',
  },
  {
    key: 'Vinyl records',
    label: 'Vinyl records',
  },
  {
    key: 'Volleyball',
    label: 'Volleyball',
  },
  {
    key: 'Volunteering',
    label: 'Volunteering',
  },
  {
    key: 'VR gaming',
    label: 'VR gaming',
  },
  {
    key: 'Walking',
    label: 'Walking',
  },
  {
    key: 'Wargaming',
    label: 'Wargaming',
  },
  {
    key: 'Watch making',
    label: 'Watch making',
  },
  {
    key: 'Watching documentaries',
    label: 'Watching documentaries',
  },
  {
    key: 'Watching movies',
    label: 'Watching movies',
  },
  {
    key: 'Watching television',
    label: 'Watching television',
  },
  {
    key: 'Water polo',
    label: 'Water polo',
  },
  {
    key: 'Water sports',
    label: 'Water sports',
  },
  {
    key: 'Wax sealing',
    label: 'Wax sealing',
  },
  {
    key: 'Waxing',
    label: 'Waxing',
  },
  {
    key: 'Weaving',
    label: 'Weaving',
  },
  {
    key: 'Web design',
    label: 'Web design',
  },
  {
    key: 'Webtooning',
    label: 'Webtooning',
  },
  {
    key: 'Weight training',
    label: 'Weight training',
  },
  {
    key: 'Weightlifting',
    label: 'Weightlifting',
  },
  {
    key: 'Welding',
    label: 'Welding',
  },
  {
    key: 'Whale watching',
    label: 'Whale watching',
  },
  {
    key: 'Whittling',
    label: 'Whittling',
  },
  {
    key: 'Wikipedia editing',
    label: 'Wikipedia editing',
  },
  {
    key: 'Wine tasting',
    label: 'Wine tasting',
  },
  {
    key: 'Winemaking',
    label: 'Winemaking',
  },
  {
    key: 'Witchcraft',
    label: 'Witchcraft',
  },
  {
    key: 'Wood carving',
    label: 'Wood carving',
  },
  {
    key: 'Woodworking',
    label: 'Woodworking',
  },
  {
    key: 'Word searches',
    label: 'Word searches',
  },
  {
    key: 'Worldbuilding',
    label: 'Worldbuilding',
  },
  {
    key: 'Wrestling',
    label: 'Wrestling',
  },
  {
    key: 'Writing',
    label: 'Writing',
  },
  {
    key: 'Writing music',
    label: 'Writing music',
  },
  {
    key: 'Yo-yoing',
    label: 'Yo-yoing',
  },
  {
    key: 'Yoga',
    label: 'Yoga',
  },
  {
    key: 'Zoo visiting',
    label: 'Zoo visiting',
  },
  {
    key: 'Zumba',
    label: 'Zumba',
  },
].map(l => {
  return {
    key: l.key.replace(' ', '-'),
    label: l.label,
  };
});

export const accreditationTypes = [
  "ABTA - Association of British Travel Agents",
  "ACTA - Association of Canadian Travel Agencies",
  "ARC - Accredited Agency",
  "ARC - Corporate Travel Department (CTD)",
  "ARC - Verified Travel Consultant (VTC)",
  "ATAS - Australian Travel Accreditation Scheme",
  "ATOL - Air Travel Organisers License",
  "CLIA - Individual Agent Membership (IAM)",
  "CLIA - Premier Agency Membership (TAM)",
  "CLIA - Travel Agency Membership (TAM)",
  "ECTAA - European Travel Agents and Tour Operators Association",
  "GSTC - Global Sustainable Tourism Council",
  "IATA - Accreditation (Ticketing)",
  "IATA - TIDS (Non-Ticketing)",
  "IATAN - Non-Ticketing",
  "IATAN - US Based Ticketing",
  "TICO - Registered Travel Agency",
  "TRU - Accredited Host Agency Membership",
  "TRU - Accredited Travel Agency Membership",
  "VMA - Virtuoso Member Agency",
  "TAANZ - Travel Agent Association of New Zealand",
]
