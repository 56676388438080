import { TABS } from 'containers/InboxPage/InboxPage';

export const isResults = (tab, transactions, enquiries) => {
  const hasTransactions =
    (tab === TABS.sale || tab === TABS.order) && transactions.length && !enquiries.length;
  const hasEnquiries =
    (tab === TABS.enquiry || tab === TABS.enquiryClient) &&
    !transactions.length &&
    enquiries.length;
  const hasEverything = transactions.length && enquiries.length;

  return hasEverything || hasTransactions || hasEnquiries;
};
export const getNoResultsMessageId = (tab, action) => {
  /*eslint default-case: 0*/
  switch (tab) {
    case TABS.schedule:
      return 'InboxPage.noScheduleBookings';
    case TABS.order:
      return action? 'InboxPage.noActionRequiredFound' : 'InboxPage.noOrdersFound';
    case TABS.sale:
      return action? 'InboxPage.noActionRequiredFound' : 'InboxPage.noSalesFound';
    case TABS.proConnectRequests:
      return 'InboxPage.noproConnectRequestsFound';
    case TABS.enquiry:
    case TABS.enquiryClient:
      return 'InboxPage.noEnquiriesFound';
  }
};
