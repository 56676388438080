import React from 'react';
import PropTypes from 'prop-types';

const IconArrowRight = props => {
    const { className } = props;
    const classes = className;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={classes} width="44" height="44" viewBox="0 0 44 44" fill="none">
            <path d="M34.8333 22L9.16658 22" stroke="#0095B3" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M34.8333 22L27.4999 14.6667" stroke="#0095B3" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M34.8333 22L27.4999 29.3333" stroke="#0095B3" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

const { string } = PropTypes;

IconArrowRight.defaultProps = {
    className: null,
    rootClassName: null,
};

IconArrowRight.propTypes = {
    className: string,
    rootClassName: string,
};

export default IconArrowRight;
