import { addMarketplaceEntities, getMarketplaceEntities } from 'ducks/marketplaceData.duck';
import moment from 'moment';
import { storableError } from 'util/errors';
import { denormalisedEntities, denormalisedResponseEntities, updatedEntities } from 'util/data';
import {
  filterBookings,
  getTransactions,
  normalizeListingTransactionsResponse,
} from './scheduleHelpers';
import axios from 'axios';
import config from 'config';
import qs from 'qs';
import { transitionTransaction } from './InboxPage.duck';

const initialState = {
  ownListingsRefs: [],
  companyListingsRefs: [],
  bookingsRefs: [],
  transactions: [],
  fetchBookingsInProgress: false,
  fetchBookingsRequestError: null,
  fetchCompanyListingsInProgress: false,
};

// ================ Action types ================ //
export const FETCH_USER_LISTINGS_REQUEST = 'app/CalendarPage/FETCH_USER_LISTINGS_REQUEST';
export const FETCH_USER_LISTINGS_SUCCESS = 'app/CalendarPage/FETCH_USER_LISTINGS_SUCCESS';
export const FETCH_USER_LISTINGS_ERROR = 'app/CalendarPage/FETCH_USER_LISTINGS_ERROR';

export const FETCH_COMPANY_LISTINGS_REQUEST = 'app/CalendarPage/FETCH_COMPANY_LISTINGS_REQUEST';
export const FETCH_COMPANY_LISTINGS_SUCCESS = 'app/CalendarPage/FETCH_COMPANY_LISTINGS_SUCCESS';
export const FETCH_COMPANY_LISTINGS_ERROR = 'app/CalendarPage/FETCH_COMPANY_LISTINGS_ERROR';

export const FETCH_BOOKINGS_REQUEST = 'app/CalendarPage/FETCH_BOOKINGS_REQUEST';
export const FETCH_BOOKINGS_SUCCESS = 'app/CalendarPage/FETCH_BOOKINGS_SUCCESS';
export const FETCH_BOOKINGS_ERROR = 'app/CalendarPage/FETCH_BOOKINGS_ERROR';

export const GET_TRANSACTIONS_FOR_LISTING = 'app/CalendarPage/GET_TRANSACTIONS_FOR_LISTING';

export const RESET_CALENDAR_PAGE_STATE = 'app/CalendarPage/RESET_CALENDAR_PAGE_STATE';

export const SET_AVAILABILITY_EXCEPTIONS = 'app/CalendarPage/SET_AVAILABILITY_EXCEPTIONS';

export default function scheduleReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_USER_LISTINGS_SUCCESS:
      return { ...state, ownListingsRefs: payload };
    case FETCH_COMPANY_LISTINGS_REQUEST:
      return { ...state, fetchCompanyListingsInProgress: true };
    case FETCH_COMPANY_LISTINGS_SUCCESS:
      return { ...state, companyListingsRefs: payload, fetchCompanyListingsInProgress: false };
    case FETCH_COMPANY_LISTINGS_ERROR:
      return {
        ...state,
        fetchCompanyListingsError: payload,
        fetchCompanyListingsInProgress: false,
      };
    case FETCH_BOOKINGS_REQUEST:
      return { ...state, fetchBookingsInProgress: true, fetchBookingsRequestError: null };
    case FETCH_BOOKINGS_SUCCESS:
      return { ...state, bookingsRefs: payload, fetchBookingsInProgress: false };
    case FETCH_BOOKINGS_ERROR:
      return { ...state, fetchBookingsInProgress: false, fetchBookingsRequestError: payload };

    case GET_TRANSACTIONS_FOR_LISTING:
      return { ...state, transactions: payload };

    case RESET_CALENDAR_PAGE_STATE:
      return initialState;
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const fetchUserListingsRequest = () => ({ type: FETCH_USER_LISTINGS_REQUEST });
export const fetchUserListingsSuccess = payload => ({ type: FETCH_USER_LISTINGS_SUCCESS, payload });
export const fetchUserListingsError = e => ({
  type: FETCH_USER_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchCompanyListingsRequest = () => ({ type: FETCH_COMPANY_LISTINGS_REQUEST });
export const fetchCompanyListingsSuccess = payload => ({
  type: FETCH_COMPANY_LISTINGS_SUCCESS,
  payload,
});
export const fetchCompanyListingsError = e => ({
  type: FETCH_COMPANY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchBookingsRequest = () => ({ type: FETCH_BOOKINGS_REQUEST });
export const fetchBookingsSuccess = payload => ({ type: FETCH_BOOKINGS_SUCCESS, payload });
export const fetchBookingsError = e => ({
  type: FETCH_BOOKINGS_ERROR,
  error: true,
  payload: e,
});

export const getTransactionsForListing = payload => ({
  type: GET_TRANSACTIONS_FOR_LISTING,
  payload,
});

export const resetCalendarPageState = () => ({
  type: RESET_CALENDAR_PAGE_STATE,
});

export const setAvailabilityExceptions = () => ({
  type: SET_AVAILABILITY_EXCEPTIONS,
});

// ================ Thunks ================ //

export const fetchUserListings = () => async (dispatch, getState, sdk) => {
  dispatch(fetchUserListingsRequest());

  try {
    const currentUser = await sdk.currentUser.show();
    const { companyName } = currentUser.data.data.attributes.profile.publicData;
    const { companyOwner } = currentUser.data.data.attributes.profile.metadata;
    let linkedListingsRes = null;
    if (companyOwner && companyName) {
      linkedListingsRes = await sdk.listings.query({
        pub_companyName: companyName,
        include: ['author'],
      });

      if (linkedListingsRes) {
        const linkedListingsRefs = linkedListingsRes.data.data.map(({ id, type }) => ({
          type,
          id,
        }));
        dispatch(addMarketplaceEntities(linkedListingsRes));
        dispatch(fetchCompanyListingsSuccess(linkedListingsRefs));
      }
    }

    const response = await sdk.ownListings.query();
    const refs = response.data.data.map(({ id, type }) => ({ type, id }));

    dispatch(addMarketplaceEntities(response));
    dispatch(fetchUserListingsSuccess(refs));

  } catch (e) {
    dispatch(fetchUserListingsError(storableError(e)));
    console.error(e);
  }
};

export const retrieveTransactions = (listing, date) => async (dispatch, getState, sdk) => {
  dispatch(fetchBookingsRequest());
  const { entities } = getState().marketplaceData;

  try {
    const response = await sdk.bookings.query({
      listingId: listing.id.uuid,
      start: moment()
        .startOf('day')
        .toISOString(),
      end: moment()
        .add(89, 'days')
        .toISOString(),
      include: [
        'transaction',
        'transaction.customer',
        'transaction.customer.profileImage',
        'transaction.provider',
      ],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    });

    const refs = response.data.data.map(({ id, type }) => ({ type, id }));

    const stateEntitiesBookings = updatedEntities(entities, response.data);
    const bookings = denormalisedEntities(stateEntitiesBookings, refs);
    const [pendingAndAcceptedBookings, pendingAndAcceptedBookingsRefs] = filterBookings(bookings);
    const transactions = getTransactions(pendingAndAcceptedBookings, listing, date);

    dispatch(getTransactionsForListing(transactions));
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchBookingsSuccess(pendingAndAcceptedBookingsRefs));
  } catch (e) {
    const is403 = !!e.message.search('403');
    console.log('is403', is403);
    if (is403) {
      const currentUser = await sdk.currentUser.show();
      const res = await axios
        .get(config.serverBaseUrl + config.listingTransactionsEndPoint, {
          headers: {
            Authorization: `Token token=${config.serverToken}`,
          },
          params: {
            booking_params: {
              listingId: listing.id.uuid,
              userId: currentUser.data.data.id.uuid,
              start: moment()
                .startOf('day')
                .toISOString(),
              end: moment()
                .add(89, 'days')
                .toISOString(),
            },
          },
          paramsSerializer: params => {
            return qs.stringify(params);
          },
        })
        .catch(e => {
          dispatch(fetchBookingsError(e));
        });
      if (res && res.data && res.data.data) {
        const normalisedRes = normalizeListingTransactionsResponse(res);
        const bookings = denormalisedResponseEntities(normalisedRes);
        const [pendingAndAcceptedBookings, pendingAndAcceptedBookingsRefs] = filterBookings(
          bookings
        );
        const transactions = getTransactions(pendingAndAcceptedBookings, listing, date);
        dispatch(getTransactionsForListing(transactions));
        dispatch(addMarketplaceEntities(normalisedRes));
        dispatch(fetchBookingsSuccess(pendingAndAcceptedBookingsRefs));
      }
    }
    console.error(e);
  }
};

export const transitionScheduleTx = params => async (dispatch, getState, sdk) => {
  const { date, listing, customerId, sellerId } = params;
  await dispatch(
    transitionTransaction({
      ...params,
      customerId: customerId.uuid,
      sellerId: sellerId.uuid,
      listing: listing.id.uuid,
    })
  );

  const state = getState();
  const { bookingsRefs } = state.Schedule;

  const bookings = getMarketplaceEntities(state, bookingsRefs);
  const transactions = getTransactions(bookings, listing, date);

  dispatch(getTransactionsForListing(transactions));
};
