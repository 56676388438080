import React, { useEffect, useState } from 'react';
import { uid } from 'react-uid';
import PropTypes from 'prop-types';
import { propTypes } from 'util/types';
import Slider from 'react-slick';
import LatestReview from 'components/LastestReview/LastestReview';
import css from 'components/SectionLatestReviews/styles.css';
import classNames from 'classnames';
import StarRatings from 'react-star-ratings';

function SectionLatestReviews(props) {
  const { data, className, title, subCategoryLabel, rating, totalRating } = props;
  const [reviewsData, setReviewsData] = useState(data);

  const sliderSettings = {
    appendDots: dots => (
      <div
        style={{
          backgroundColor: 'transparent',
          borderRadius: '10px',
          padding: '10px',
        }}
      >
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: false,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 3,
    variableWidth: true,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setReviewsData(data);
  }, [data]);

  return (
    <div className={className}>
      <div className={css.LatestReviewHeading}>
        <h2>{title} </h2>
        <div className={css.starContent}>
          <span className={classNames(css.ratingWrapper, { [css.filledRating]: rating })}>
            <StarRatings
              rating={rating}
              starRatedColor="#00bfe6"
              starEmptyColor="#fff"
              numberOfStars={1}
              starDimension="24px"
              starSpacing="0px"
            />
            <span className={css.reviewCount}>{rating}</span>
            <span className={css.reviewCountWrapper}>({totalRating})</span>
          </span>
        </div>
      </div>

      <div className={css.reviews}>
        <Slider {...sliderSettings}>
          {reviewsData.map((review, i) => {
            if (review.attributes.content)
              return (
                <div key={uid(review.attributes.content, i)} style={{ width: '614px' }}>
                  <LatestReview review={review} subCategoryLabel={subCategoryLabel} />
                </div>
              );
          })}
        </Slider>
      </div>
    </div>
  );
}

SectionLatestReviews.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(propTypes.review),
};

export default SectionLatestReviews;
