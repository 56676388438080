import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingViewDraftForm } from '../../forms';
import config from '../../config';
import { CurrentUserContext } from 'contexts/currentUserContext';

import css from '../EditListingDescriptionPanel/EditListingDescriptionPanel.css';

const EditListingViewDraftPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    // submitButtonText,
    publishButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    listingType,
    backgroundImage,
    profileImage,
    actionImage,
    viewport,
    listingBackgroundImages,
    currencyConversionRate,
  } = props;

  const classes = classNames(rootClassName || css.draftRoot, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData, privateData } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  return (
    <div className={classes}>
      <CurrentUserContext.Consumer>
        {currentUser => {
          return (
            <EditListingViewDraftForm
              className={css.form}
              currentListing={listing}
              publishActionMsg={publishButtonText}
              onSubmit={onSubmit}
              backgroundImage={backgroundImage}
              profileImage={profileImage}
              actionImage={actionImage}
              onChange={onChange}
              disabled={disabled}
              ready={ready}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              category={publicData.category}
              subCategory={publicData.subCategory}
              activityLevel={config.custom.activityLevel}
              currentUser={currentUser}
              listingType={listingType}
              viewport={viewport}
              listingBackgroundImages={listingBackgroundImages}
              currencyConversionRate={currencyConversionRate}
            />
          );
        }}
      </CurrentUserContext.Consumer>
    </div>
  );
};

EditListingViewDraftPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingViewDraftPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  // submitButtonText: string.isRequired,
  publishButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  listingType: string,
};

export default EditListingViewDraftPanel;
