import { bool, func, object, string } from 'prop-types';
import React from 'react';
import { CurrentUserContext } from 'contexts/currentUserContext';
import { EditBusinessIntroductionForm } from 'forms';
import css from './EditBusinessIntroductionPanel.css'

const EditBusinessIntroductionPanel = props => {
  const {
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    updateInProgress,
    errors,
    listingType,
  } = props;

  return (
    <div>
      <CurrentUserContext.Consumer>
        {currentUser => {
          return (
            <EditBusinessIntroductionForm
              className={css.form}
              initialValues={{}}
              saveActionMsg={submitButtonText}
              onSubmit={values => {
                const updatedValues = {}
                onSubmit(updatedValues);
              }}
              onChange={onChange}
              disabled={disabled}
              ready={ready}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              currentUser={currentUser}
              listingType={listingType}
            />
          );
        }}
      </CurrentUserContext.Consumer>
    </div>
  );
};

EditBusinessIntroductionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditBusinessIntroductionPanel.propTypes = {
  className: string,
  rootClassName: string,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
};

export default EditBusinessIntroductionPanel;
