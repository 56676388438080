import React from 'react';
// import { uid } from 'react-uid';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
// import { FieldArray } from 'react-final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
// import {
//   maxLength,
//   autocompleteSearchRequired,
//   autocompletePlaceSelected,
//   composeValidators,
// } from '../../util/validators';
import {
  Form,
  Button,
  // FieldTextInput,
  // LocationAutocompleteInputField,
  // SecondaryButton,
  HeadingFormFieldWrapper,
} from '../../components';
import config from '../../config';
import { get } from 'util/api';
import css from './EditListingProfileForm.css';
import { BUSINESS_LINK, LISTING_TYPES } from 'util/constants';
import { types as sdkTypes } from '../../util/sdkLoader';
import { CompanyListingCard } from '../../components';
import { setActiveListing } from 'containers/SearchPage/SearchPage.duck';
const sharetribeSdk = require('sharetribe-flex-sdk');

const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const TITLE_MAX_LENGTH = 60;
const PROVIDER_MAX_LENGTH = 30;
const MAX_FIELDS = 3;
const ACCREDITATIONS_MAX_FIELDS = 5;

const identity = v => v;
const filedArrayRequired = value => {
  if (value && value.length < 1) {
    return 'Require at least one entry';
  }

  return undefined;
};

const titleMessages = {
  [LISTING_TYPES.LISTING]: 'EditListingDescriptionForm.title',
  [LISTING_TYPES.ENQUIRY]: 'EditListingDescriptionForm.enquiryTitle',
  [LISTING_TYPES.COMPANY]: 'EditListingDescriptionForm.companyTitle',
  [LISTING_TYPES.CLASS]: 'EditListingDescriptionForm.classTitle',
  [LISTING_TYPES.FACILITY]: 'EditListingDescriptionForm.facilityTitle',
};

const titlePlaceholderMessages = {
  [LISTING_TYPES.LISTING]: 'EditListingDescriptionForm.titlePlaceholder',
  [LISTING_TYPES.ENQUIRY]: 'EditListingDescriptionForm.enquiryTitlePlaceholder',
  [LISTING_TYPES.COMPANY]: 'EditListingDescriptionForm.companyTitlePlaceholder',
  [LISTING_TYPES.CLASS]: 'EditListingDescriptionForm.classTitlePlaceholder',
  [LISTING_TYPES.FACILITY]: 'EditListingDescriptionForm.facilityTitlePlaceholder',
};

const providerMessages = {
  [LISTING_TYPES.LISTING]: 'EditListingDescriptionForm.provider',
  [LISTING_TYPES.ENQUIRY]: 'EditListingDescriptionForm.enquiryProvider',
  [LISTING_TYPES.COMPANY]: 'EditListingDescriptionForm.enquiryProvider',
  [LISTING_TYPES.CLASS]: 'EditListingDescriptionForm.enquiryProvider',
  [LISTING_TYPES.FACILITY]: 'EditListingDescriptionForm.enquiryProvider',
};

const providerPlaceholderMessages = {
  [LISTING_TYPES.LISTING]: 'EditListingDescriptionForm.providerPlaceholder',
  [LISTING_TYPES.ENQUIRY]: 'EditListingDescriptionForm.enquiryProviderPlaceholder',
  [LISTING_TYPES.COMPANY]: 'EditListingDescriptionForm.enquiryProviderPlaceholder',
  [LISTING_TYPES.CLASS]: 'EditListingDescriptionForm.providerPlaceholder',
  [LISTING_TYPES.FACILITY]: 'EditListingDescriptionForm.providerPlaceholder',
};

const descriptionMessages = {
  [LISTING_TYPES.LISTING]: 'EditListingDescriptionForm.description',
  [LISTING_TYPES.ENQUIRY]: 'EditListingDescriptionForm.enquiryDescription',
  [LISTING_TYPES.COMPANY]: 'EditListingDescriptionForm.companyDescription',
  [LISTING_TYPES.CLASS]: 'EditListingDescriptionForm.description',
  [LISTING_TYPES.FACILITY]: 'EditListingDescriptionForm.facilityDescription',
};

const descriptionPlaceholderMessages = {
  [LISTING_TYPES.LISTING]: 'EditListingDescriptionForm.descriptionPlaceholder',
  [LISTING_TYPES.ENQUIRY]: 'EditListingDescriptionForm.enquiryDescriptionPlaceholder',
  [LISTING_TYPES.COMPANY]: 'EditListingDescriptionForm.companyDescriptionPlaceholder',
  [LISTING_TYPES.CLASS]: 'EditListingDescriptionForm.classDescriptionPlaceholder',
  [LISTING_TYPES.FACILITY]: 'EditListingDescriptionForm.facilityDescriptionPlaceholder',
};
const { UUID } = sdkTypes;
class EditListingProfileFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: props.category,
      subCategories: [],
      categoryBasedCustomFields: [],
      businessAccountInfo: null,
    };
  }

  componentDidMount() {
    this.handleCategoryChange(this.props.category);
    sdk.currentUser
      .show()
      .then(res => {
        const businessId = res.data.data.attributes.profile.metadata.businessId;
        get('/api/provider-business/get-public', { id: businessId }).then(resp => {
          const businessListingId = resp.providerBusiness.sharetribeListingId;
          const id = new UUID(businessListingId);
          return sdk.listings
            .show({
              id: id,
              include: ['images'],
              'fields.image': ['variants.scaled-small', 'variants.scaled-small2x'],
            })
            .then(res => {
              const businessAccountListing = res.data.data;
              const imageIds = businessAccountListing.relationships.images.data.map(
                item => item.id.uuid
              );
              const filteredImages = res.data.included.filter(image =>
                imageIds.includes(image.id.uuid)
              );

              const updatedBusinessAccountListing = {
                ...businessAccountListing,
                images: filteredImages,
                relationships: undefined,
              };

              this.setState(prevState => ({
                ...prevState,
                businessAccountInfo: updatedBusinessAccountListing,
              }));
            });
        });
      })
      .catch(e => console.log(e));
  }

  handleCategoryChange = category => {
    const categoriesConfig = config.custom.categories;
    const categoryBasedCustomFieldsConfig = config.custom.categoryBasedFields;
    const subCategoryConfig = category ? categoriesConfig.find(cc => cc.key === category) : [];
    const subCategories = (subCategoryConfig && subCategoryConfig.subCategories) || [];
    const CustomFieldsConfig =
      category !== undefined && category !== '' && subCategories.length
        ? categoryBasedCustomFieldsConfig.find(cc => cc.key === category).fields
        : [];
    this.setState({
      category: category,
      subCategories: subCategories,
      categoryBasedCustomFields: CustomFieldsConfig,
    });
  };

  render() {
    const { listingType } = this.props;

    const showCompanyName = [LISTING_TYPES.LISTING].some(type => listingType === type);
    const showBankDetails = [
      LISTING_TYPES.LISTING,
      LISTING_TYPES.CLASS,
      LISTING_TYPES.FACILITY,
    ].some(type => listingType === type);

    return (
      <>
        <div className={css.btnWrapper}>
          <div>
            <h1 className={css.headingTitle}>{BUSINESS_LINK}</h1>
          </div>
        </div>
        <div className={css.formGroup}>
          <FinalForm
            {...this.props}
            mutators={{
              ...arrayMutators,
            }}
            render={formRenderProps => {
              const {
                subCategory,
                className,
                disabled,
                ready,
                handleSubmit,
                values,
                intl,
                invalid,
                pristine,
                saveActionMsg,
                updated,
                updateInProgress,
                fetchErrors,
                handleStripeConnected,
                currentListingId,
                currentUser,
              } = formRenderProps;
              const {
                mutators: { push, pop },
              } = formRenderProps.form;

              const stripeConnected =
                currentUser && currentUser.stripeAccount && !!currentUser.stripeAccount.id;

              const { updateListingError, createListingDraftError, showListingsError } =
                fetchErrors || {};
              const errorMessageUpdateListing = updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
                </p>
              ) : null;

              // This error happens only on first tab (of EditListingWizard)
              const errorMessageCreateListingDraft = createListingDraftError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
                </p>
              ) : null;

              const errorMessageShowListing = showListingsError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
                </p>
              ) : null;

              const classes = classNames(css.root, className);
              const submitReady = (updated && pristine) || ready;
              const submitInProgress = updateInProgress;
              const submitDisabled = invalid || disabled || submitInProgress || !stripeConnected;

              //Stripe Button
              const stripeButtonLabel = intl.formatMessage({
                id: 'EditListingProfileForm.stripeButtonLabel',
              });
              const stripeButtonPlaceholderMessage = intl.formatMessage({
                id: 'EditListingProfileForm.stripeButtonPlaceholder',
              });
              const stripeButtonSupportMessage = intl.formatMessage({
                id: 'EditListingProfileForm.stripeButtonSupport',
              });

              //Accreditations
              const accreditationsLabel = intl.formatMessage({
                id: 'EditListingProfileForm.accreditationsLabel',
              });
              const accreditationsPlaceholderMessage = intl.formatMessage({
                id: 'EditListingProfileForm.accreditationsPlaceholder',
              });
              const accreditationsSupportMessage = intl.formatMessage({
                id: 'EditListingProfileForm.accreditationsSupport',
              });
              const businessLinkSupportedMessage = intl.formatMessage({
                id: 'EditListingProfileForm.businessLinkSupport',
              });
              const businessLinkDescriptionMessage = intl.formatMessage({
                id: 'EditListingProfileForm.businessLinkDescriptionMessage',
              });
              const businessAccountInfo = this.state.businessAccountInfo;
              const businessId = businessAccountInfo?.id.uuid;
              const businessAccountInfoPublicData = businessAccountInfo?.attributes?.publicData;

              return (
                <Form className={classes} onSubmit={handleSubmit}>
                  {errorMessageCreateListingDraft}
                  {errorMessageUpdateListing}
                  {errorMessageShowListing}

                  {/* {!stripeConnected && (
                    <HeadingFormFieldWrapper
                      rootClassName={css.stripeButton}
                      label={stripeButtonLabel}
                      supportText={stripeButtonSupportMessage}
                    >
                      <Button type="button" onClick={() => handleStripeConnected(currentListingId)}>
                        {stripeButtonPlaceholderMessage}
                      </Button>
                    </HeadingFormFieldWrapper>
                  )} */}

                  <div className={css.descriptionMessage}>{businessLinkDescriptionMessage}</div>
                  <HeadingFormFieldWrapper label={' '} supportText={businessLinkSupportedMessage}>
                    {!!businessAccountInfoPublicData && (
                      <div className={css.alignCenter}>
                        <div style={{ maxWidth: '230px' }}>
                          <CompanyListingCard
                            key={businessId}
                            listing={businessAccountInfo}
                            setActiveListing={setActiveListing}
                          />
                        </div>
                      </div>
                    )}
                  </HeadingFormFieldWrapper>
                  <Button
                    rootClassName={css.submitButton}
                    type="submit"
                    inProgress={false}
                    disabled={false} //{submitDisabled}
                    ready={submitReady}
                  >
                    {'Next'}
                  </Button>
                </Form>
              );
            }}
          />
        </div>
      </>
    );
  }
}

EditListingProfileFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingProfileFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  listingType: string,
};

export default compose(injectIntl)(EditListingProfileFormComponent);
