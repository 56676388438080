import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';
import { CurrentUserContext } from 'contexts/currentUserContext';

import css from './EditListingDescriptionPanel.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    listingType,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { title, publicData, privateData } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const {
    Travel_business_busnumber,
    Travel_business_location,
    Travel_business_busyears,
    Travel_business_acreditation,
    Travel_business_payment_method,
  } = (publicData ?? {});
  const { Travel_business_address } = (privateData ?? {});

  return (
    // <div className={classes}>
    <div className={css.scope}>
      <CurrentUserContext.Consumer>
        {currentUser => {
          return (
            <EditListingDescriptionForm
              className={css.form}
              initialValues={{
                Travel_business: title,
                Travel_business_busnumber,
                Travel_business_address,
                Travel_business_location,
                Travel_business_busyears,
                Travel_business_acreditation,
                Travel_business_payment_method,
              }}
              saveActionMsg={submitButtonText}
              onSubmit={values => {
                const {
                  Travel_business,
                  Travel_business_busnumber,
                  Travel_business_address,
                  Travel_business_location,
                  Travel_business_busyears,
                  Travel_business_acreditation,
                  Travel_business_payment_method,
                } = values;

                const trimmedAccreditation = Travel_business_acreditation && Travel_business_acreditation.filter(Boolean);

                const {
                  selectedPlace: { address, origin },
                } = Travel_business_location;
                const updateValues = {
                  title: Travel_business,
                  publicData: {
                    geolocation: origin,
                    Travel_business_busnumber,
                    Travel_business_location,
                    Travel_business_busyears,
                    Travel_business_acreditation: trimmedAccreditation ?? [],
                    Travel_business_payment_method,
                  },
                  privateData: {
                    Travel_business_address,
                  },
                };

                onSubmit(updateValues);
              }}
              onChange={onChange}
              disabled={disabled}
              ready={ready}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              category={publicData.category}
              subCategory={publicData.subCategory}
              activityLevel={config.custom.activityLevel}
              currentUser={currentUser}
              listingType={listingType}
            />
          );
        }}
      </CurrentUserContext.Consumer>
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  listingType: string,
};

export default EditListingDescriptionPanel;
