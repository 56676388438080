/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import React from 'react';
import { oneOf, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  propTypes,
  LINE_ITEM_CUSTOMER_COMMISSION,
  LINE_ITEM_PROVIDER_COMMISSION,
  LINE_ITEM_BASIC_PACKAGE_FEE,
  LINE_ITEM_DETAILED_PACKAGE_FEE,
} from '../../util/types';

import LineItemBookingPeriod from './LineItemBookingPeriod';
import LineItemBasePriceMaybe from './LineItemBasePriceMaybe';
import LineItemSubTotalMaybe from './LineItemSubTotalMaybe';
import LineItemShippingFeeMaybe from './LineItemShippingFeeMaybe';
import LineItemPickupFeeMaybe from './LineItemPickupFeeMaybe';
import LineItemCustomerCommissionMaybe from './LineItemCustomerCommissionMaybe';
import LineItemCustomerCommissionRefundMaybe from './LineItemCustomerCommissionRefundMaybe';
import LineItemProviderCommissionMaybe from './LineItemProviderCommissionMaybe';
import LineItemProviderCommissionRefundMaybe from './LineItemProviderCommissionRefundMaybe';
import LineItemRefundMaybe from './LineItemRefundMaybe';
import LineItemTotalPrice from './LineItemTotalPrice';
import LineItemUnknownItemsMaybe from './LineItemUnknownItemsMaybe';
import LineItemCreditCardFeeMaybe from './LineItemCreditCardFeeMaybe';
import LineItemAgentFeeMaybe from './LineItemAgentFeeMaybe';

import css from './OrderBreakdown.module.css';
import {
  TRANSITION_CANCEL_ENQUIRY_BY_CUSTOMER,
  TRANSITION_CANCEL_ENQUIRY_BY_CUSTOMER_2,
  TRANSITION_CANCEL_ENQUIRY_BY_PROVIDER,
  TRANSITION_CANCEL_ENQUIRY_BY_PROVIDER_2,
  TRANSITION_DECLINE,
  TRANSITION_DECLINE_EXTEND_DELIVERY_PERIOD,
  TRANSITION_EXPIRED_FROM_ACCEPTED_1,
  TRANSITION_EXPIRE_ENQUIRY,
  TRANSITION_EXPIRE_ENQUIRY_2,
  TRANSITION_EXPIRE_PAYMENT,
  TRANSITION_OP_CANCEL_ORDER,
  TRANSITION_OP_CANCEL_ORDER_2,
} from '../../util/transaction';

export const OrderBreakdownComponent = props => {
  const {
    rootClassName,
    className,
    userRole,
    unitType,
    transaction,
    listing,
    booking,
    intl,
    dateType,
    taxJar,
    currencyConversionRate,
  } = props;

  const isCustomer = userRole === 'customer';
  const isProvider = userRole === 'provider';
  const isRefunded = transaction.attributes.transitions.some(transition =>
    [
      TRANSITION_DECLINE,
      TRANSITION_EXPIRED_FROM_ACCEPTED_1,
      TRANSITION_DECLINE_EXTEND_DELIVERY_PERIOD,
      TRANSITION_OP_CANCEL_ORDER,
      TRANSITION_OP_CANCEL_ORDER_2,
    ].includes(transition.transition)
  );
  const isCancelled = transaction.attributes.transitions.some(transition =>
    [
      TRANSITION_EXPIRE_PAYMENT,
      TRANSITION_CANCEL_ENQUIRY_BY_CUSTOMER,
      TRANSITION_CANCEL_ENQUIRY_BY_PROVIDER,
      TRANSITION_CANCEL_ENQUIRY_BY_CUSTOMER_2,
      TRANSITION_CANCEL_ENQUIRY_BY_PROVIDER_2,
      TRANSITION_EXPIRE_ENQUIRY,
      TRANSITION_EXPIRE_ENQUIRY_2,
    ].includes(transition.transition)
  );
  const lineItems = transaction.attributes.lineItems;
  const { packages } = listing.attributes.publicData;
  const hasCommissionLineItem = lineItems.find(item => {
    const hasCustomerCommission = isCustomer && item.code === LINE_ITEM_CUSTOMER_COMMISSION;
    const hasProviderCommission = isProvider && item.code === LINE_ITEM_PROVIDER_COMMISSION;
    return (hasCustomerCommission || hasProviderCommission) && !item.reversal;
  });

  const hasPackage = lineItems.find(
    item =>
      item.code === LINE_ITEM_BASIC_PACKAGE_FEE || item.code === LINE_ITEM_DETAILED_PACKAGE_FEE
  );
  const selectPackage = hasPackage
    ? hasPackage.code === LINE_ITEM_BASIC_PACKAGE_FEE
      ? packages[0]
      : hasPackage.code === LINE_ITEM_DETAILED_PACKAGE_FEE
        ? packages[1]
        : null
    : null;
  const classes = classNames(rootClassName || css.root, className);

  const priceSummaryTitle = intl.formatMessage({
    id: "OrderBreakdown.priceSummaryTitle"
  })

  /**
   * OrderBreakdown contains different line items:
   *
   * LineItemBookingPeriod: prints booking start and booking end types. Prop dateType
   * determines if the date and time or only the date is shown
   *
   * LineItemUnitsMaybe: if he unitType is line-item/unit print the name and
   * quantity of the unit (Not used with FTW-product).
   *
   * LineItemShippingFeeMaybe: prints the shipping fee (combining additional fee of
   * additional items into it).
   *
   * LineItemShippingFeeRefundMaybe: prints the amount of refunded shipping fee
   *
   * LineItemBasePriceMaybe: prints the base price calculation for the listing, e.g.
   * "$150.00 * 2 nights $300"
   *
   * LineItemUnitPriceMaybe: prints just the unit price, e.g. "Price per night $32.00".
   * This line item is not used by default in the OrderBreakdown.
   *
   * LineItemUnknownItemsMaybe: prints the line items that are unknown. In ideal case there
   * should not be unknown line items. If you are using custom pricing, you should create
   * new custom line items if you need them.
   *
   * LineItemSubTotalMaybe: prints subtotal of line items before possible
   * commission or refunds
   *
   * LineItemRefundMaybe: prints the amount of refund
   *
   * LineItemCustomerCommissionMaybe: prints the amount of customer commission
   * The default transaction process used by FTW doesn't include the customer commission.
   *
   * LineItemCustomerCommissionRefundMaybe: prints the amount of refunded customer commission
   *
   * LineItemProviderCommissionMaybe: prints the amount of provider commission
   *
   * LineItemProviderCommissionRefundMaybe: prints the amount of refunded provider commission
   *
   * LineItemTotalPrice: prints total price of the transaction
   *
   */

  return (
    <div className={classes}>
      <p className={css.title}>{priceSummaryTitle}</p>
      <hr className={css.totalDivider} />

      <LineItemBookingPeriod booking={booking} unitType={unitType} dateType={dateType} />
      <LineItemBasePriceMaybe lineItems={lineItems} unitType={unitType} intl={intl} />
      <LineItemShippingFeeMaybe lineItems={lineItems} intl={intl} />
      <LineItemPickupFeeMaybe lineItems={lineItems} intl={intl} />
      <LineItemUnknownItemsMaybe lineItems={lineItems} isProvider={isProvider} intl={intl} />
      <LineItemCreditCardFeeMaybe transaction={transaction} intl={intl} />
      <LineItemSubTotalMaybe
        lineItems={lineItems}
        unitType={unitType}
        userRole={userRole}
        intl={intl}
      />
      <LineItemRefundMaybe lineItems={lineItems} intl={intl} beforePayment={isCancelled} />
      {(!isRefunded && !isCancelled) && (
        <LineItemCustomerCommissionMaybe
          lineItems={lineItems}
          isCustomer={isCustomer}
          intl={intl}
        />
      )}
      {/* <LineItemCustomerCommissionRefundMaybe
        lineItems={lineItems}
        isCustomer={isCustomer}
        intl={intl}
      /> */}
      {(!isRefunded && !isCancelled) && (
        <LineItemProviderCommissionMaybe
          lineItems={lineItems}
          isProvider={isProvider}
          intl={intl}
        />
      )}
      {/* <LineItemProviderCommissionRefundMaybe
        lineItems={lineItems}
        isProvider={isProvider}
        intl={intl}
      /> */}
      {(!isRefunded && !isCancelled) && (
        <LineItemAgentFeeMaybe lineItems={lineItems} isProvider={isProvider} intl={intl} />
      )}
      {taxJar}
      <LineItemTotalPrice
        transaction={transaction}
        isProvider={isProvider}
        intl={intl}
        lineItems={lineItems}
        currencyConversionRate={currencyConversionRate}
      />
      {/* {hasCommissionLineItem ? (
        <span className={css.feeInfo}>
          <FormattedMessage id="OrderBreakdown.commissionFeeNote" />
        </span>
      ) : null} */}
    </div>
  );
};

OrderBreakdownComponent.defaultProps = {
  rootClassName: null,
  className: null,
  booking: null,
  dateType: null,
};

// OrderBreakdownComponent.propTypes = {
//   rootClassName: string,
//   className: string,

//   userRole: oneOf(['customer', 'provider']).isRequired,
//   unitType: propTypes.lineItemUnitType.isRequired,
//   transaction: propTypes.transaction.isRequired,
//   booking: propTypes.booking,
//   dateType: propTypes.dateType,

//   // from injectIntl
//   intl: intlShape.isRequired,
// };

const OrderBreakdown = injectIntl(OrderBreakdownComponent);

OrderBreakdown.displayName = 'OrderBreakdown';

export default OrderBreakdown;
