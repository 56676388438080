import React from 'react';
import css from './LandingPageExpertMarketPlaceSection.css';
// import ExpertMarketPlace_Image from '../../assets/Landing_Pages/ExpertMarketPlace/ExpertMarketPlace_Image.png';
// import PlayIcon from '../../assets/Landing_Pages/Play_Icon.svg';
// import ReactPlayer from 'react-player';
// import ExternalLink from 'components/ExternalLink/ExternalLink';

const LandingPageExpertMarketPlaceSection = () => {
  return (
    // <iframe
    //   // width="560"
    //   // height="315"
    //   width={'100%'}
    //   height="500"
    //   src="https://www.youtube.com/embed/pbtfWSCGWbw"
    //   title="YouTube video player"
    //   frameborder="0"
    //   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    //   allowfullscreen
    // ></iframe>
    <video
      poster='https://storage.googleapis.com/lyfsho.appspot.com/public/LyfshortMarketplaceIntro-thumb.jpg'
      controls width="100%"
    >
      <source
        src="https://storage.googleapis.com/lyfsho.appspot.com/public/LyfshortMarketplaceIntro.mp4"
        type="video/mp4"
      />
    </video>
    // <ReactPlayer
    //   url="https://youtu.be/pbtfWSCGWbw"
    //   light={ExpertMarketPlace_Image}
    //   playing
    //   width="85%"
    //   height="600px"
    //   playIcon={<img src={PlayIcon} />}
    //   className={css.player}
    // />
  );
};
export default LandingPageExpertMarketPlaceSection;
