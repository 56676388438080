import React, { Component } from 'react';
import { func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { formatCurrencyMajorUnit } from '../../util/currency';
import { propTypes } from '../../util/types';
import config from '../../config';

import { PlainPriceFilterForm } from '../../forms';

import css from './PriceFilterSimple.css';

class PriceFilterSimpleComponent extends Component {
    constructor(props) {
        super(props);
        // this.state = { isOpen: true };

        this.handleChange = this.handleChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }

    handleChange(values) {
        const { onSubmit, urlParam } = this.props;
        onSubmit(urlParam, values);
    }

    handleClear() {
        const { onSubmit, urlParam } = this.props;
        onSubmit(urlParam, null);
    }


    render() {
        const {
            rootClassName,
            className,
            id,
            initialValues,
            min,
            max,
            step,
            intl,
            currencyConfig,
        } = this.props;
        const classes = classNames(rootClassName || css.root, className);
        const { minPrice, maxPrice } = initialValues || {};

        const hasValue = value => value != null;
        const hasInitialValues = initialValues && hasValue(minPrice) && hasValue(maxPrice);

        const labelText = hasInitialValues
            ? intl.formatMessage(
                { id: 'PriceFilterSimple.labelSelectedPlain' },
                {
                    minPrice: formatCurrencyMajorUnit(intl, currencyConfig.currency, minPrice),
                    maxPrice: formatCurrencyMajorUnit(intl, currencyConfig.currency, maxPrice),
                }
            )
            : intl.formatMessage({ id: 'PriceFilterSimple.label' });

        return (
            <div className={classes}>
                <h2 className={css.filterLabel}>{labelText}</h2>
                <div className={css.formWrapper}>
                    <PlainPriceFilterForm
                        id={id}
                        initialValues={hasInitialValues ? initialValues : { minPrice: min, maxPrice: max }}
                        onChange={this.handleChange}
                        intl={intl}
                        contentRef={node => {
                            this.filterContent = node;
                        }}
                        min={min}
                        max={max}
                        step={step}
                        liveEdit
                        isOpen={true}
                    />
                </div>
            </div>
        );
    }
}

PriceFilterSimpleComponent.defaultProps = {
    rootClassName: null,
    className: null,
    initialValues: null,
    step: number,
    currencyConfig: config.currencyConfig,
};

PriceFilterSimpleComponent.propTypes = {
    rootClassName: string,
    className: string,
    id: string.isRequired,
    urlParam: string.isRequired,
    onSubmit: func.isRequired,
    initialValues: shape({
        minPrice: number.isRequired,
        maxPrice: number.isRequired,
    }),
    min: number.isRequired,
    max: number.isRequired,
    step: number,
    currencyConfig: propTypes.currencyConfig,

    // form injectIntl
    intl: intlShape.isRequired,
};

const PriceFilterSimple = injectIntl(PriceFilterSimpleComponent);

export default PriceFilterSimple;
