import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaTwitter.css';

const IconSocialMediaTwitter = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clipIconSocialMediaTwitter)">
        <path d="M52 9.87675C50.0662 10.725 48.0057 11.2872 45.8575 11.5602C48.0675 10.2407 49.7542 8.16725 50.5473 5.668C48.4868 6.8965 46.2118 7.76425 43.7872 8.2485C41.8307 6.16525 39.0422 4.875 36.0002 4.875C30.0982 4.875 25.3467 9.6655 25.3467 15.5382C25.3467 16.3833 25.4182 17.1957 25.5938 17.9692C16.731 17.537 8.88875 13.2893 3.6205 6.8185C2.70075 8.41425 2.16125 10.2408 2.16125 12.207C2.16125 15.899 4.0625 19.1717 6.8965 21.0665C5.18375 21.034 3.5035 20.5367 2.08 19.7535C2.08 19.786 2.08 19.8282 2.08 19.8705C2.08 25.051 5.77525 29.354 10.621 30.3452C9.75325 30.5825 8.8075 30.6962 7.826 30.6962C7.1435 30.6962 6.4545 30.6572 5.80775 30.5142C7.189 34.736 11.1085 37.8397 15.769 37.9405C12.142 40.7777 7.53675 42.4873 2.55125 42.4873C1.677 42.4873 0.8385 42.4482 0 42.341C4.72225 45.3862 10.3188 47.125 16.354 47.125C35.971 47.125 46.696 30.875 46.696 16.7895C46.696 16.3182 46.6797 15.8633 46.657 15.4115C48.7728 13.91 50.5505 12.0347 52 9.87675Z" fill="#03c1f4" />
      </g>
      <defs>
        <clipPath id="clipIconSocialMediaTwitter">
          <rect width="52" height="52" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

IconSocialMediaTwitter.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaTwitter.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaTwitter;
