import React, { useEffect } from 'react';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import { ensureListing } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import {
  EditListingOverviewPanel,
  EditListingProfilePanel,
  EditBusinessIntroductionPanel,
  EditListingDescriptionPanel,
  EditListingLocationPanel,
  EditListingPhotosPanel,
  EditListingPricingPanel,
  EditBusinessFinancialPanel,
  EditBusinessAdminPanel,
  EditBusinessVerificationPanel,
  EditBusinessViewDraftPanel,
  FormNavigation,
  EditListingViewDraftPanel,
} from '../../components';

import css from './EditListingWizard.css';
import {
  LISTING_TYPES,
  INTRODUCTION,
  DESCRIPTION,
  LOCATION,
  PRICING,
  PHOTOS,
  OVERVIEW,
  PROFILE,
  AGREEMENT,
  ADMIN,
  VIEWDRAFTBUSINESS,
  VIEWDRAFTLISTING,
  PAYMENTS,
} from 'util/constants';
import { TABS, COMPANY_TABS } from './helpers';

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [
  OVERVIEW,
  LOCATION,
  PHOTOS,
  PRICING,
  PROFILE,
  VIEWDRAFTLISTING,
  INTRODUCTION,
  DESCRIPTION,
  AGREEMENT,
  ADMIN,
  PAYMENTS,
  VIEWDRAFTBUSINESS,
];

const pathParamsToNextTab = (params, tab, marketplaceTabs) => {
  const nextTabIndex = marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];
  return { ...params, tab: nextTab };
};

const pathParamsToPrevTab = (params, tab, marketplaceTabs) => {
  const prevTabIndex = marketplaceTabs.findIndex(s => s === tab) - 1;
  const prevTab = prevTabIndex !== 0 ? marketplaceTabs[prevTabIndex] : marketplaceTabs[0];
  return { ...params, tab: prevTab };
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (
  listingId,
  listingType,
  params,
  tab,
  marketplaceTabs,
  history
) => {
  const redirectRoutes = {
    [LISTING_TYPES.LISTING]: 'EditListingPage',
    [LISTING_TYPES.COMPANY]: 'EditCompanyPage',
  };
  const routeName = redirectRoutes[listingType];
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingId,
  };
  const routes = routeConfiguration();
  console.log(params.type)

  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString(routeName, routes, currentPathParams, {});
    history.replace(draftURI);
  }

  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs);
  const to = createResourceLocatorString(routeName, routes, nextPathParams, {});
  history.push(to);
};

const EditListingWizardTab = props => {
  const {
    tab,
    marketplaceTabs,
    params,
    errors,
    fetchInProgress,
    newListingPublished,
    history,
    images,
    backgroundImage,
    profileImage,
    actionImage,
    listingBackgroundImages,
    listing,
    handleCreateFlowTabScrolling,
    handlePublishListing,
    handleCloseListing,
    handleOpenListing,
    handlePaymentVerification,
    handleStripeConnected,
    onUpdateListing,
    onCreateListingDraft,
    onImageUpload,
    uploadImageInProgress,
    onUpdateImageOrder,
    onRemoveImage,
    onChange,
    onManageDisableScrolling,
    updatedTab,
    updateInProgress,
    intl,
    listingType,
    currentUser,
    isTabCompleted,
    viewport,
    currencyConversionRate,
  } = props;

  const { type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const currentListing = ensureListing(listing);
  const imageIds = images => {
    return images ? images.map(img => img.imageId || img.id) : null;
  };

  const onCompleteEditListingWizardTab = (tab, updateValues, passThrownErrors = false) => {
    // Normalize images for API call
    const {
      backgroundImage,
      profileImage,
      images: updatedImages,
      actionImage,
      ...otherValues
    } = updateValues;

    if (currentUser.id === null && updateValues.publicData.listing_type === LISTING_TYPES.ENQUIRY) {
      localStorage.setItem('LandingFormData', JSON.stringify(updateValues));
      history.push('/signup');
    }

    const imageProperty =
      typeof updatedImages !== 'undefined'
        ? listingType === 'company'
          ? { images: imageIds([backgroundImage, profileImage, ...updatedImages]) }
          : { images: imageIds([backgroundImage, profileImage, actionImage, ...updatedImages]) }
        : {};

    const updateValuesWithImages = { ...otherValues, ...imageProperty };

    if (isNewListingFlow) {
      const onUpsertListingDraft = isNewURI
        ? (tab, updateValues) => onCreateListingDraft(updateValues)
        : onUpdateListing;

      const upsertValues = isNewURI
        ? updateValuesWithImages
        : { ...updateValuesWithImages, id: currentListing.id };

      return onUpsertListingDraft(tab, upsertValues)
        .then(r => {
          if (tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
            // Create listing flow: smooth scrolling polyfill to scroll to correct tab
            handleCreateFlowTabScrolling(false);

            // After successful saving of draft data, user should be redirected to next tab
            redirectAfterDraftUpdate(
              r.data.data.id.uuid,
              listingType,
              params,
              tab,
              marketplaceTabs,
              history
            );
          } else if (tab === marketplaceTabs[marketplaceTabs.length - 1]) {
            handlePublishListing(currentListing);
          }
        })
        .catch(e => {
          if (passThrownErrors) {
            throw e;
          }
          // No need for extra actions
          // Error is logged in EditListingPage.duck file.
        });
    } else {
      return onUpdateListing(tab, { ...updateValuesWithImages, id: currentListing.id });
    }
  };

  useEffect(() => {
    if (localStorage.getItem('LandingFormData')) {
      const updateData = JSON.parse(localStorage.getItem('LandingFormData'));
      localStorage.removeItem('LandingFormData');
      onCompleteEditListingWizardTab('description', updateData);
    }
    // eslint-disable-next-line
  }, []);
  const panelProps = tab => {
    return {
      className: css.panel,
      listingType,
      errors,
      listing,
      onChange,
      panelUpdated: updatedTab === tab,
      updateInProgress,
      onManageDisableScrolling,
      // newListingPublished and fetchInProgress are flags for the last wizard tab
      ready: newListingPublished,
      disabled: fetchInProgress,
      currentUser,
    };
  };

  const onRedirectPrevTab = () => {
    const redirectRoutes = {
      [LISTING_TYPES.LISTING]: 'EditListingPage',
      [LISTING_TYPES.COMPANY]: 'EditCompanyPage',
    };
    const routeName = redirectRoutes[listingType];
    const routes = routeConfiguration();
    const prevPathParams = pathParamsToPrevTab(params, tab, marketplaceTabs);
    const to = createResourceLocatorString(routeName, routes, prevPathParams, {});
    history.push(to);
  };

  const onRedirectNextTab = () => {
    const redirectRoutes = {
      [LISTING_TYPES.LISTING]: 'EditListingPage',
      [LISTING_TYPES.COMPANY]: 'EditCompanyPage',
    };
    const routeName = redirectRoutes[listingType];
    const routes = routeConfiguration();
    const prevPathParams = pathParamsToNextTab(params, tab, marketplaceTabs);
    const to = createResourceLocatorString(routeName, routes, prevPathParams, {});
    history.push(to);
  };

  switch (tab) {
    case OVERVIEW: {
      const submitButtonTranslationKey = 'EditListingWizard.saveEditChanges';
      return (
        <div className={css.panelNavWrapper}>
          <EditListingOverviewPanel
            {...panelProps(OVERVIEW)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              const updatedValues = {
                ...values,
                publicData: {
                  ...values.publicData,
                  listing_type: listingType,
                },
              };

              onCompleteEditListingWizardTab(tab, updatedValues);
            }}
          />
          <FormNavigation
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    // Listing - Business Link
    case PROFILE: {
      const submitButtonTranslationKey = 'EditListingWizard.saveEditChanges';
      return (
        <div className={css.panelNavWrapper}>
          <EditListingProfilePanel
            {...panelProps(PROFILE)}
            handleStripeConnected={handleStripeConnected}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              const updatedValues = {
                ...values,
                publicData: {
                  ...values.publicData,
                  listing_type: listingType,
                },
              };

              onCompleteEditListingWizardTab(tab, updatedValues);
            }}
          />
          <FormNavigation
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    // Business - Introduction
    case INTRODUCTION: {
      const submitButtonTranslationKey = 'EditListingWizard.next';
      return (
        <div className={css.panelNavWrapper}>
          <EditBusinessIntroductionPanel
            {...panelProps(INTRODUCTION)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onRedirectNextTab();
            }}
          />
          <FormNavigation
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    // Business
    case DESCRIPTION: {
      const submitButtonTranslationKey = 'EditListingWizard.saveEditChanges';
      return (
        <div className={css.panelNavWrapper}>
          <EditListingDescriptionPanel
            {...panelProps(DESCRIPTION)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              const updatedValues = {
                ...values,
                publicData: {
                  ...values.publicData,
                  listing_type: listingType,
                },
              };

              onCompleteEditListingWizardTab(tab, updatedValues);
            }}
          />
          <FormNavigation
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case LOCATION: {

      const submitButtonTranslationKey = 'EditListingWizard.saveEditChanges';
      return (
        <div className={css.panelNavWrapper}>
          <EditListingLocationPanel
            {...panelProps(LOCATION)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, values);
            }}
          />
          <FormNavigation
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case PRICING: {
      const submitButtonTranslationKey = 'EditListingWizard.saveEditChanges';
      return (
        <div className={css.panelNavWrapper}>
          <EditListingPricingPanel
            {...panelProps(PRICING)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, values);
            }}
          />
          <FormNavigation
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case PHOTOS: {
      const submitButtonTranslationKey = 'EditListingWizard.saveEditChanges';

      return (
        <div className={css.panelNavWrapper}>
          <EditListingPhotosPanel
            {...panelProps(PHOTOS)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            images={images}
            backgroundImage={backgroundImage}
            profileImage={profileImage}
            actionImage={actionImage}
            onImageUpload={onImageUpload}
            uploadImageInProgress={uploadImageInProgress}
            onRemoveImage={onRemoveImage}
            onSubmit={values => {
              onCompleteEditListingWizardTab(tab, values);
            }}
            onUpdateImageOrder={onUpdateImageOrder}
          />
          <FormNavigation
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            className={css.photoNav}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case AGREEMENT: {
      const submitButtonTranslationKey = 'EditListingWizard.saveEditChanges';
      return (
        <div className={css.panelNavWrapper}>
          <EditBusinessFinancialPanel
            {...panelProps(AGREEMENT)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            intl={intl}
            onSubmit={values => {
              const updatedValues = {
                ...values,
                privateData: {
                  ...values.privateData,
                },
              };

              onCompleteEditListingWizardTab(tab, updatedValues);
            }}
          />
          <FormNavigation
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case ADMIN: {
      const submitButtonTranslationKey = 'EditListingWizard.saveEditChanges';
      return (
        <div className={css.panelNavWrapper}>
          <EditBusinessAdminPanel
            {...panelProps(ADMIN)}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              const updatedValues = {
                privateData: {
                  ...values.privateData,
                },
              };

              onCompleteEditListingWizardTab(tab, updatedValues);
            }}
          />
          <FormNavigation
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case PAYMENTS: {
      const submitButtonTranslationKey = 'EditListingWizard.saveEditPayments';
      return (
        <div className={css.panelNavWrapper}>
          <EditBusinessVerificationPanel
            {...panelProps(PAYMENTS)}
            handlePaymentVerification={handlePaymentVerification}
            submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
            onSubmit={values => {
              const updatedValues = {
                privateData: {
                  ...values.privateData,
                },
              };

              onCompleteEditListingWizardTab(tab, updatedValues);
            }}
          />
          <FormNavigation
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case VIEWDRAFTBUSINESS: {
      const submittedButtonTranslationKey = 'EditListingWizard.submitted';
      const publishButtonTranslationKey = 'EditListingWizard.saveNewProfile';
      return (
        <div className={css.panelNavWrapper}>
          <EditBusinessViewDraftPanel
            {...panelProps(VIEWDRAFTBUSINESS)}
            backgroundImage={backgroundImage}
            profileImage={profileImage}
            actionImage={actionImage}
            submittedButtonText={intl.formatMessage({ id: submittedButtonTranslationKey })}
            publishButtonText={intl.formatMessage({ id: publishButtonTranslationKey })}
            onSubmit={values => {
              const updatedValues = {
                ...values,
                privateData: {
                  ...values.privateData,
                },
              };

              onCompleteEditListingWizardTab(tab, updatedValues);
            }}
            viewport={viewport}
          />
          <FormNavigation
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    case VIEWDRAFTLISTING: {
      // const submitButtonTranslationKey = 'EditListingWizard.saveEditChanges';
      const publishButtonTranslationKey = 'EditListingWizard.saveNewProfile';
      return (
        <div className={css.panelNavWrapper}>
          <EditListingViewDraftPanel
            {...panelProps(VIEWDRAFTLISTING)}
            backgroundImage={backgroundImage}
            profileImage={profileImage}
            actionImage={actionImage}
            publishButtonText={intl.formatMessage({ id: publishButtonTranslationKey })}
            onSubmit={values => {
              const updatedValues = {
                ...values,
                privateData: {
                  ...values.privateData,
                },
              };

              onCompleteEditListingWizardTab(tab, updatedValues);
            }}
            onChange={visible => visible ? handleOpenListing(currentListing) : handleCloseListing(currentListing)}
            viewport={viewport}
            listingBackgroundImages={listingBackgroundImages}
            currencyConversionRate={currencyConversionRate}
          />
          <FormNavigation
            marketplaceTabs={marketplaceTabs}
            tab={tab}
            onRedirectPrevTab={onRedirectPrevTab}
            onRedirectNextTab={onRedirectNextTab}
            isTabCompleted={isTabCompleted}
          />
        </div>
      );
    }
    default:
      return null;
  }
};

EditListingWizardTab.defaultProps = {
  listing: null,
  updatedTab: null,
  availabilityExceptions: [],
};

EditListingWizardTab.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf([...TABS, ...COMPANY_TABS]).isRequired,
  }).isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
    fetchExceptionsError: object,
    addExceptionError: object,
    deleteExceptionError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  fetchExceptionsInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,
  handleCloseListing: func.isRequired,
  handleOpenListing: func.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onUpdateListing: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveImage: func.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,
  listingType: string.isRequired,

  intl: intlShape.isRequired,
};

export default EditListingWizardTab;
