import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  Form,
  Button,
  NamedLink,
} from '../../components';
import config from '../../config';

import css from './EditBusinessFinancialForm.css';
import { AGREEMENT } from 'util/constants';
import Checkbox from 'react-custom-checkbox';
import Cross_Icon from '../../assets/TripExpertPage/Cross_Icon.svg';

class EditBusinessFinancialFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: props.category,
      subCategories: [],
      categoryBasedCustomFields: [],
    };
  }

  componentDidMount() {
    this.handleCategoryChange(this.props.category);
  }

  handleCategoryChange = category => {
    const categoriesConfig = config.custom.categories;
    const categoryBasedCustomFieldsConfig = config.custom.categoryBasedFields;
    const subCategoryConfig = category ? categoriesConfig.find(cc => cc.key === category) : [];
    const subCategories = (subCategoryConfig && subCategoryConfig.subCategories) || [];
    const CustomFieldsConfig =
      category !== undefined && category !== '' && subCategories.length
        ? categoryBasedCustomFieldsConfig.find(cc => cc.key === category).fields
        : [];
    this.setState({
      category: category,
      subCategories: subCategories,
      categoryBasedCustomFields: CustomFieldsConfig,
    });
  };

  render() {

    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            className,
            disabled,
            ready,
            handleSubmit,
            intl,
            invalid,
            pristine,
            saveActionMsg,
            updated,
            updateInProgress,
            fetchErrors,
          } = formRenderProps;

          const { updateListingError, createListingDraftError, showListingsError } =
            fetchErrors || {};
          const errorMessageUpdateListing = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
            </p>
          ) : null;

          const [TnC1, setTnC1] = useState(false);
          const [TnC2, setTnC2] = useState(false);
          const [TnC3, setTnC3] = useState(false);
          const [TnC4, setTnC4] = useState(false);

          // This error happens only on first tab (of EditListingWizard)
          const errorMessageCreateListingDraft = createListingDraftError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
            </p>
          ) : null;
          
          const agreementVersionDateMessage = intl.formatMessage({
            id: 'EditBusinessFinancialForm.agreementVersionDate',
          });

          const classes = classNames(css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.btnWrapper}>
                <div>
                  <h1 className={css.headingTitle}>{AGREEMENT}</h1>
                </div>
                <Button
                  rootClassName={css.topSaveButton}
                  type="submit"
                  inProgress={false}
                  disabled={!(TnC1 && TnC2 && TnC3 && TnC4) || submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </Button>
              </div>
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}

              <div className={css.agreementVersionDateTitle}>
                Last Updated: {agreementVersionDateMessage}
              </div>
              <div className={css.formGroup}>
                <Checkbox
                  icon={<img className={css.crossIcon} src={Cross_Icon} />}
                  id="Travel_business_TnC1"
                  name="Travel_business_TnC1"
                  borderColor="#00ab99"
                  size={25}
                  checked={TnC1}
                  onChange={value => setTnC1(value)}
                  labelStyle={{
                    margin: '0',
                    marginLeft: '1rem',
                    fontSize: '15px',
                    fontWeight: '400',
                    lineHeight: '28px',
                  }}
                  containerClassName={css.checkbox}
                  label={
                    <FormattedMessage
                      id="EditBusinessFinancialForm.partnerTravelBusinessAgreement"
                      values={{
                        b: chunks => <strong>{chunks}</strong>,
                      }}
                    />
                  }
                />
                <p className={css.agreementInfo}>
                  <FormattedMessage
                    id="EditBusinessFinancialForm.partnerTravelBusinessAgreementInfo"
                    values={{
                      b: chunks => <strong>{chunks}</strong>,
                      br: <br />,
                      termsOfService: <NamedLink name="TermsOfServicePage" target="_blank">
                        <FormattedMessage id="Footer.termsService" />
                      </NamedLink>,
                      privacyPolicy: <NamedLink name="PrivacyPolicyPage" target="_blank">
                        <FormattedMessage id="Footer.privacyPolicyPage" />
                      </NamedLink>,
                      travelAgentTnC: <NamedLink name="TravelAgentTnCPage" target="_blank">
                        <FormattedMessage id="Footer.travelAgentTermsAndConditionsPage" />
                      </NamedLink>,
                      commercialModel: <NamedLink name="CommercialModelPage" target="_blank">
                        <FormattedMessage id="Footer.commercialModel" />
                      </NamedLink>
                    }}
                  />
                </p>

                <Checkbox
                  icon={<img className={css.crossIcon} src={Cross_Icon} />}
                  id="Travel_business_TnC2"
                  name="Travel_business_TnC2"
                  borderColor="#00ab99"
                  size={25}
                  checked={TnC2}
                  onChange={value => setTnC2(value)}
                  labelStyle={{
                    marginLeft: '1rem',
                    fontSize: '15px',
                    fontWeight: '400',
                    lineHeight: '28px',
                    alignItems: 'flex-star',
                  }}
                  containerClassName={css.checkbox}
                  label={
                    <FormattedMessage
                      id="EditBusinessFinancialForm.responsibility"
                      values={{
                        b: chunks => <strong>{chunks}</strong>,
                      }}
                    />
                  }
                />

                <p className={css.agreementInfo}>
                  <FormattedMessage
                    id="EditBusinessFinancialForm.responsibilityInfo"
                    values={{
                      b: chunks => <strong>{chunks}</strong>,
                      br: <br />,
                      em: (...chunks) => <em>{chunks}</em>,
                      overview: (...chunks) => (
                        <NamedLink name="OverviewPage" target="_blank">
                          {chunks}
                        </NamedLink>
                      ),
                      a: chunks => (
                        <a href="https://stripe.com" target="_blank">
                          {chunks}
                        </a>
                      ),
                    }}
                  />
                </p>

                <p className={css.agreementInfo}>
                  <FormattedMessage
                    id="EditBusinessFinancialForm.responsibilityInfo1"
                    values={{
                      a: chunks => (
                        <a
                          href="https://stripe.com/legal/connect-account/recipient"
                          target="_blank"
                        >
                          {chunks}
                        </a>
                      ),
                    }}
                  />
                </p>

                <Checkbox
                  icon={<img className={css.crossIcon} src={Cross_Icon} />}
                  id="Travel_business_TnC3"
                  name="Travel_business_TnC3"
                  borderColor="#00ab99"
                  size={25}
                  checked={TnC3}
                  onChange={value => setTnC3(value)}
                  labelStyle={{
                    marginLeft: '1rem',
                    fontSize: '15px',
                    fontWeight: '400',
                    lineHeight: '28px',
                    alignItems: 'flex-star',
                  }}
                  containerClassName={css.checkbox}
                  label={
                    <FormattedMessage
                      id="EditBusinessFinancialForm.travelAgentServices"
                      values={{
                        b: chunks => <strong>{chunks}</strong>,
                      }}
                    />
                  }
                />

                <p className={css.agreementInfo}>
                  <FormattedMessage
                    id="EditBusinessFinancialForm.travelAgentServicesInfo"
                    values={{
                      b: chunks => <strong>{chunks}</strong>,
                      br: <br />,
                    }}
                  />
                </p>

                <Checkbox
                  icon={<img className={css.crossIcon} src={Cross_Icon} />}
                  id="Travel_business_TnC4"
                  name="Travel_business_TnC4"
                  borderColor="#00ab99"
                  size={25}
                  checked={TnC4}
                  onChange={value => setTnC4(value)}
                  labelStyle={{
                    marginLeft: '1rem',
                    fontSize: '15px',
                    fontWeight: '400',
                    lineHeight: '28px',
                    alignItems: 'flex-star',
                  }}
                  containerClassName={css.checkbox}
                  label={
                    <FormattedMessage
                      id="EditBusinessFinancialForm.authority"
                      values={{
                        b: chunks => <strong>{chunks}</strong>,
                      }}
                    />
                  }
                />

                <p className={css.agreementInfo}>
                  <FormattedMessage
                    id="EditBusinessFinancialForm.authorityInfo"
                    values={{
                      b: chunks => <strong>{chunks}</strong>,
                      br: <br />,
                      commercialModel: <NamedLink name="CommercialModelPage" target="_blank">
                        <FormattedMessage id="Footer.commercialModel" />
                      </NamedLink>,
                    }}
                  />
                </p>

                <Button
                  rootClassName={css.submitButton}
                  type="submit"
                  inProgress={false}
                  disabled={!(TnC1 && TnC2 && TnC3 && TnC4) || submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditBusinessFinancialFormComponent.defaultProps = { className: null, fetchErrors: null };

EditBusinessFinancialFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  listingType: string,
};

export default compose(injectIntl)(EditBusinessFinancialFormComponent);
