import React, { useState, useEffect } from 'react';
import css from './Timer.module.css';

const Timer = ({ date, hoursToAdd }) => {
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    const targetTime = new Date(date);
    targetTime.setHours(targetTime.getHours() + parseInt(hoursToAdd));

    const intervalId = setInterval(() => {
      const timeDiff = targetTime - new Date();
      const totalSecondsLeft = Math.floor(timeDiff / 1000);
      const hoursLeft = Math.floor(totalSecondsLeft / 3600);
      const minutesLeft = Math.floor((totalSecondsLeft % 3600) / 60);

      if (totalSecondsLeft <= 0) {
        setTimeLeft({ hours: 0, minutes: 0 });
      } else {
        setTimeLeft({ hours: hoursLeft, minutes: minutesLeft });
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [date, hoursToAdd]);

  useEffect(() => {
    if (timeLeft?.hours === 0 && timeLeft?.minutes === 0 && typeof window !== 'undefined') {
      // window.location.reload();
    }
  }, [timeLeft]);

  if (timeLeft === null) {
    return <div>Loading countdown...</div>;
  }

  return (
    <div>
      {timeLeft.hours === 0 && timeLeft.minutes === 0 ? (
        <p className={css.timerText}>Time has expired!</p>
      ) : (
        <p className={css.timerText}>
          {timeLeft.hours.toString().padStart(2, '0')}
          {`hrs : `}
          {timeLeft.minutes.toString().padStart(2, '0')}
          {`mins`}
        </p>
      )}
    </div>
  );
};

export default Timer;
