import React, { useState, useEffect } from 'react';
import { func, node, object, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';
import { withViewport } from '../../util/contextHelpers';
import DropdownButton from '../ModifiedSearchFilters/Components/DropdownButton/DropdownButton';
import { compose } from 'redux';
import { FormattedMessage } from '../../util/reactIntl';

import css from './FieldMultipleSelection.css';

const handleChange = (propsOnChange, inputOnChange) => event => {
  // If "onChange" callback is passed through the props,
  // it can notify the parent when the content of the input has changed.
  if (propsOnChange) {
    // "handleChange" function is attached to the low level <select> component
    // value of the element needs to be picked from target
    const value = event.nativeEvent.target.value;
    propsOnChange(value);
  }
  // Notify Final Form that the input has changed.
  // (Final Form knows how to deal with synthetic events of React.)
  inputOnChange(event);
};

const FieldMultipleSelectionComponent = props => {
  const {
    rootClassName,
    className,
    selectClassName,
    id,
    label,
    supportText,
    input,
    meta,
    children,
    onChange,
    inputRef,
    limit,
    placeholder,
    options,
    viewport,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }
  const [selectedValues, setSelectedValues] = useState([])
  const [selectedSingleValue, setSelectedSingleValue] = useState(null)
  const [showSupport, setShowSupport] = useState(false)
  const [showLimitError, setShowLimitError] = useState(false)

  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  useEffect(() => {
    if (input.value) {
      if (!limit || limit === 1) {
        setSelectedSingleValue(input.value)
      }
      else {
        setSelectedValues(input.value)
      }

    }
  }, [])

  const selectClasses = classNames(selectClassName, css.select, {
    [css.selectSuccess]: valid,
    [css.selectError]: hasError,
  });

  const { onChange: inputOnChange, ...restOfInput } = input;

  const refMaybe = inputRef ? { ref: inputRef } : {};

  const selectProps = {
    className: selectClasses,
    id,
    ...refMaybe,
    ...restOfInput,
    ...rest,
  };

  const onClickHandle = (value) => {
    if (!limit || limit === 1) {
      setSelectedSingleValue(value)
      inputOnChange(value)
      return
    }
    // let arr = Array.isArray(input.value) ? [...selectedValues] : [selectedValues];
    let arr = [...selectedValues];
    if (selectedValues.length === 0 && input.value !== "") {
      // arr=[...input.value]
      arr = Array.isArray(input.value) ? input.value.filter(function (value) {
        return value !== null && value !== undefined;
      }) :
        [input.value];
    }
    const unique = arr.findIndex((item) => item === value)
    if (unique === -1)
      arr.push(value)
    else {
      arr.splice(unique, 1)
    }

    if (limit && arr.length > limit) {
      setShowLimitError(true)
      return
    }
    else {
      if (showLimitError)
        setShowLimitError(false)
    }

    setSelectedValues(arr)
    if (arr.length === 0) inputOnChange("")
    else inputOnChange(arr)
  }

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>

      <div className={css.headingWrapper}>
        {label ? <label htmlFor={id}>{label}</label> : null}
        {supportText ? (<div
          onMouseLeave={() => setShowSupport(false)}
          className={css.support}>
          <svg xmlns="http://www.w3.org/2000/svg" onClick={() =>
            setShowSupport((prevState) => (!prevState))
          }
            width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_906_753)">
              <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9.08984 8.99959C9.32495 8.33126 9.789 7.7677 10.3998 7.40873C11.0106 7.04975 11.7287 6.91853 12.427 7.0383C13.1253 7.15808 13.7587 7.52112 14.2149 8.06312C14.6712 8.60512 14.9209 9.29112 14.9198 9.99959C14.9198 11.9996 11.9198 12.9996 11.9198 12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <circle cx="12" cy="17" r="1" fill="black" />
            </g>
            <defs>
              <clipPath id="clip0_906_753">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span className={classNames(css.supportText, { [css.hideSupportText]: !showSupport })}>
            {supportText}
          </span>
        </div>) : null}
      </div>
      {placeholder && <span className={css.extraLabel}>{placeholder}</span>}

      {viewport.width > 550 ? (
        <div className={css.tabsContainer}>
          {options.map(option => {
            return (
              <div
                key={option.key}
                onClick={() => onClickHandle(option.key)}
                className={classNames(css.subCategoryItem, {
                  [css.selectedSubCategoryItem]: selectedSingleValue ?
                    selectedSingleValue === option.key :
                    selectedValues.includes(option.key),
                })}
              >
                {option.label}
              </div>
            );
          })}
        </div>) :
        (<div className={css.tabsContainer}>
          {options.slice(0, 4).map(option => {
            return (
              <div
                key={option.key}
                onClick={() => onClickHandle(option.key)}
                className={classNames(css.subCategoryItem, {
                  [css.selectedSubCategoryItem]: selectedValues.includes(option.key),
                })}
              >
                {option.label}
              </div>
            );
          })}

          <DropdownButton
            labelClassName={css.subCategoryMoreItems}
            label={<FormattedMessage id={'ModifiedSearchFilters.more'} />}
            inProgress={false}
            selectedOption={selectedValues}
            menuContentClass={css.menuContentClass}
            actions={options.map(option => {
              return {
                label: option.label,
                key: option.key,
                handler: () => onClickHandle(option.key),
              };
            })}
          />
        </div>

        )}



      <input {...selectProps} type='hidden' />
      {showLimitError && <span className={css.limitError}>{`Only ${limit || ''} options can be selectable`}</span>}
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldMultipleSelectionComponent.defaultProps = {
  rootClassName: null,
  className: null,
  selectClassName: null,
  id: null,
  label: null,
  children: null,
};

FieldMultipleSelectionComponent.propTypes = {
  rootClassName: string,
  className: string,
  selectClassName: string,

  onChange: func,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  children: node,
};

const FieldMultipleSelection = props => {
  return <Field component={FieldMultipleSelectionComponent} {...props} />;
};

export default compose(withViewport)(FieldMultipleSelection);
