import React, { useEffect, useState } from 'react';
import { injectIntl } from 'util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { propTypes } from 'util/types';
import { resetMarketplaceEntities } from 'ducks/marketplaceData.duck';
import SchedulePageContent from './components/SchedulePageContent/SchedulePageContent';
import { intlShape } from 'util/reactIntl';
import { getMarketplaceEntities } from 'ducks/marketplaceData.duck';
import { sortTransactionsByDay } from './scheduleHelpers';
import { manageDisableScrolling } from 'ducks/UI.duck';

function Schedule({
  intl,
  ownListings,
  transactions,
  companyListings,
  // transactionsByDay = [],
  // fetchBookingsInProgress,
  // fetchBookingsRequestError,
  manageDisableScrolling,
  resetMarketplaceEntities,
  isLoading,
  params,
  recentTransactions,
  onSelect,
  openModalSendMessage,
  selectedBookings,
  onAllSelectedSchedule,
  pagination,
  history,
}) {
  const [selectedListing, setSelectedListing] = useState(null);
  useEffect(
    () => () => {
      resetMarketplaceEntities();
      // eslint-disable-next-line
    },[]);

  useEffect(() => {
    !selectedListing &&
      !!ownListings.length &&
      setSelectedListing(ownListings[0] || companyListings[0]);
      // eslint-disable-next-line
  }, [ownListings]);

  return (
    <SchedulePageContent
      intl={intl}
      onManageDisableScrolling={manageDisableScrolling}
      transactions={transactions}
      isLoading={isLoading}
      recentTransactions={recentTransactions}
      params={params}
      history={history}
      onSelect={onSelect}
      openModalSendMessage={openModalSendMessage}
      selectedBookings={selectedBookings}
      onAllSelectedSchedule={onAllSelectedSchedule}
      pagination={pagination}
    ></SchedulePageContent>
  );
}

Schedule.propTypes = {
  intl: intlShape.isRequired,
  onResetMarketplaceEntities: PropTypes.func,
  ownListing: PropTypes.arrayOf(propTypes.listing),
  companyListings: PropTypes.arrayOf(propTypes.listing),
  transactionByDay: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      date: PropTypes.string,
      transactions: PropTypes.arrayOf(propTypes.transaction),
    })
  ),
};

const mapStateToProps = state => {
  const {
    ownListingsRefs,
    companyListingsRefs,
    transactions,
    fetchBookingsInProgress,
    fetchBookingsRequestError,
  } = state.Schedule;
  const ownListings = getMarketplaceEntities(state, ownListingsRefs);
  const companyListings = getMarketplaceEntities(state, companyListingsRefs);
  const transactionsByDay = sortTransactionsByDay(transactions);

  return {
    ownListings,
    companyListings,
    transactionsByDay,
    fetchBookingsInProgress,
    fetchBookingsRequestError,
  };
};

const mapDispatchToProps = {
  manageDisableScrolling,
  resetMarketplaceEntities,
};

export default compose(injectIntl, connect(mapStateToProps, mapDispatchToProps))(Schedule);
