import { convertArrayToSearchString } from 'util/urlHelpers';
import config from '../../config';

//  { key: '', label: 'Learn & Explore' },
//  { key: '', label: 'Relax & Recharge' },
//  { key: '', label: 'Escape & Reflect' },
//  { key: '', label: 'Have an Adventure' },
//  { key: '', label: "Celebration Event" },
//  { key: '', label: 'Socialise & Experience' },
//  { key: '', label: 'Work' },
//  { key: '', label: 'Visit Someone' },
//  { key: '', label: 'Family Getaway' },

const convertLabelsToSuggestionsKeysSearchString = labels => {
  const suggestionsOptions = config.custom.suggestionCategoryOptions;
  const filtered = labels.filter(l => suggestionsOptions.find(x => x.label === l));
  const converted = filtered.map(f => suggestionsOptions.find(x => x.label === f).key);

  const type = 'has_any';
  const searchString = convertArrayToSearchString(converted, type);
  return searchString;
};

export const getPredictionsFromCategory = category => {
  switch (category) {
    case 'learn_&_explore':
      return convertLabelsToSuggestionsKeysSearchString([
        'Backpacking',
        'Camping',
        'Clubs & Pubs',
        'Historical Tours',
        'Long Weekend',
        'Nature Parks',
        'Photography',
        'Religion',
        'Road Trip',
        'Sightseeing',
        'Volunteering',
      ]);
    case 'relax_&_recharge':
      return convertLabelsToSuggestionsKeysSearchString([
        'Beach',
        'Cruise',
        'Health & Fitness',
        'Long Weekend',
        'Photography',
        'Resort',
        'Road Trip',
        'Shopping',
        'Snowsports',
        'Spa & Massage',
        'Sporting Events',
        'Watersports',
      ]);

    case 'escape_&_Reflect':
      return convertLabelsToSuggestionsKeysSearchString([
        'Backpacking',
        'Beach',
        'Cruise',
        'Festivals',
        'Long Weekend',
        'Nature Parks',
        'Resort',
        'Shopping',
        'Spa & Massage',
        'Volunteering',
      ]);

    case 'have_an_adventure':
      return convertLabelsToSuggestionsKeysSearchString([
        'Adrenaline Sports',
        'Backpacking',
        'Festivals',
        'Group Sports',
        'Hiking & Climbing',
        'Individual Sports',
        'Music Events',
        'Road Trip',
        'Snowsports',
        'Sporting Events',
        'Watersports',
      ]);
    case 'celebratration_event':
      return convertLabelsToSuggestionsKeysSearchString([
        'Birthday',
        'Wedding',
        'Buck & Hens',
        'Anniversary',
        'Romance',
        'New Year',
        'Restaurants & Dining',
        'Clubs & Pubs',
      ]);
    case 'socialise_&_experience':
      return convertLabelsToSuggestionsKeysSearchString([
        'Clubs & Pubs',
        'Restaurants & Dining',
        'Gambling',
        'Volunteering',
        'Religion',
        'Group Sports',
        'Music Events',
        'Long Weekend',
      ]);

    case 'work':
      return convertLabelsToSuggestionsKeysSearchString([
        'Long Weekend',
        'Resort',
        'Spa & Massage',
        'Sightseeing',
        'Historical',
        'Health & Fitness',
        'Shopping',
        'Restaurants & Dining',
      ]);
    case 'visit_Someone':
      return convertLabelsToSuggestionsKeysSearchString([
        'Road Trip',
        'Long Weekend',
        'Clubs & Pubs',
        'Restaurants & Dining',
      ]);
    case 'family_getaway':
      return convertLabelsToSuggestionsKeysSearchString([
        'Backpacking',
        'Birthday',
        'Health & Fitness',
        'Hiking & Climbing',
        'Historical Tours',
        'Long Weekend',
        'Nature Parks',
        'Resort',
        'Road Trip',
        'Snowsports',
        'Watersports',
      ]);

    default:
      return null;
  }
  // if (value === 'Relax & Recharge') {
  //   suggestions.push({
  //     title: '',
  //     options: suggestionsOptions.filter(s => {
  //       const targetedKeys = [
  //         'beach',
  //         'cruise',
  //         'health_&_fitness',
  //         'long_weekend',
  //         'photography',
  //         'resort',
  //         'road_trip',
  //         'shopping',
  //         'snowsports',
  //         'spa_&_massage',
  //         'sporting_events',
  //         'watersports',
  //       ];
  //       return targetedKeys.includes(s.key);
  //     }),
  //   });
  // }

  // if (value === 'Family Getaway') {
  //   suggestions.push({
  //     title: '',
  //     options: suggestionsOptions.filter(s => {
  //       const targetedKeys = [
  //         'backpacking',
  //         'birthday',
  //         'health_&_fitness',
  //         'hiking_&_climbing',
  //         'historical',
  //         'nature_parks',
  //         'long_weekend',
  //         'resort',
  //         'road_trip',
  //         'snowsports',
  //         'watersports',
  //       ];
  //       return targetedKeys.includes(s.key);
  //     }),
  //   });
  // }

  // if (value === 'Escape & Reflect') {
  //   suggestions.push({
  //     title: '',
  //     options: suggestionsOptions.filter(s => {
  //       const targetedKeys = [
  //         'backpacking',
  //         'beach',
  //         'cruise',
  //         'festivals',
  //         'long_weekend',
  //         'music_events',
  //       ];
  //       return targetedKeys.includes(s.key);
  //     }),
  //   });
  // }

  // if (value === 'Work') {
  //   suggestions.push({
  //     title: '',
  //     options: suggestionsOptions.filter(s => {
  //       const targetedKeys = [
  //         'long_weekend',
  //         'resort',
  //         'spa_&_massage',
  //         'sightseeing',
  //         'historical',
  //         'health_&_fitness',
  //         'shopping',
  //         'restaurants_&_dining',
  //       ];
  //       return targetedKeys.includes(s.key);
  //     }),
  //   });
  // }

  // if (value === 'Visit Someone') {
  //   suggestions.push({
  //     title: '',
  //     options: suggestionsOptions.filter(s => {
  //       const targetedKeys = [
  //         'road_trip',
  //         'long_weekend',
  //         'clubs_&_pubs',
  //         'restaurants_&_dining',
  //       ];
  //       return targetedKeys.includes(s.key);
  //     }),
  //   });
  // }
};
