import pick from 'lodash/pick';
import { TRANSITION_ENQUIRE } from 'util/transaction';
import { initiatePrivilegedEnquiry } from 'util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CustomerRequirementPage/SET_INITIAL_VALUES';
export const INITIATE_ENQUIRY_REQUEST = 'app/CustomerRequirementPage/INITIATE_ENQUIRY_REQUEST';
export const INITIATE_ENQUIRY_SUCCESS = 'app/CustomerRequirementPage/INITIATE_ENQUIRY_SUCCESS';
export const INITIATE_ENQUIRY_ERROR = 'app/CustomerRequirementPage/INITIATE_ENQUIRY_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  orderData: null,
};

export default function requirementPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const initiateEnquiryRequest = () => ({ type: INITIATE_ENQUIRY_REQUEST })
export const initiateEnquirySuccess = () => ({ type: INITIATE_ENQUIRY_SUCCESS })
export const initiateEnquiryError = e => ({
  type: INITIATE_ENQUIRY_ERROR,
  error: true,
  payload: e,
})
// ================= Thunks ====================== //

export const initiateEnquiry = (enquiryParams) => async (dispatch, getState, sdk) => {
  dispatch(initiateEnquiryRequest());

  const { listingId, tripDetails, ...otherParams } = enquiryParams;

  const transition = TRANSITION_ENQUIRE;

  const params = {
    listingId,
    transition,
    tripDetails,
    ...otherParams,
  }

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    const transaction = entities[0];
    dispatch(initiateEnquirySuccess(transaction));

    return transaction;
  }

  const handleError = e => {
    dispatch(initiateEnquiryError(storableError(e)));

    log.error(e, 'initiate-enquiry-failed', {
      listingId,
    })

    throw e;
  }

  return initiatePrivilegedEnquiry(params)
    .then(handleSuccess)
    .catch(handleError);
}