import React from 'react';
import { propTypes } from 'util/types';
import ShowMore from 'react-show-more';
import css from './styles.css';
import { AvatarLarge } from 'components';
import config from '../../config';
import moment from 'moment';
import classNames from 'classnames';
import StarRatings from 'react-star-ratings';

function LatestReview(props) {
  const { review, subCategoryLabel } = props;
  const rating = review.attributes.rating;

  return (
    <div className={css.reviewItem}>
      <div className={css.authorDetailsContainer}>
        <div className={css.authorHeaderContent}>
          <div className={css.authorDetailsImageRelative}>
            <AvatarLarge
              className={css.authorDetailsImageLarge}
              user={review.author}
              disableProfileLink
            />
          </div>
          <div className={css.authorDetails}>
            <span className={css.authorDisplayName}>
              {review.author.attributes.profile.displayName}
            </span>
            <span className={css.reviewCreated}>
              {moment(review.attributes.createdAt).format('MMM YYYY')}
            </span>
          </div>
        </div>
        {subCategoryLabel ? <div className={css.authorSports}>{subCategoryLabel}</div> : null}
      </div>
      <div className={css.descriptionFontStyle}>
        <ShowMore
          className={css.msg}
          lines={4}
          more={<div className={css.seeMoreLessLink}>See More</div>}
          less={<div className={css.seeMoreLessLink}>See Less</div>}
          anchorClass=""
        >
          <span className={css.msg}>
            {review.attributes.content !== config.dummyReviewContent
              ? `"${review.attributes.content}"`
              : null}
          </span>
        </ShowMore>
      </div>
      <div className={css.starContent}>
        <span className={classNames(css.ratingWrapper, { [css.filledRating]: rating })}>
          <StarRatings
            rating={rating}
            starRatedColor="#00bfe6"
            starEmptyColor="#fff"
            numberOfStars={rating}
            starDimension="24px"
            starSpacing="0px"
          />
        </span>
      </div>
    </div>
  );
}

LatestReview.propTypes = {
  review: propTypes.review,
};

export default LatestReview;
