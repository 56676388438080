// Remembering the current user ID here is just to detect if it's changed.
let currentUserId = null;

export function setUserId(id) {
  if (!window?.gtag) {
    console.log(`skipped gtag('set', {user_id: ${id}})`);
    return;
  }
  if (currentUserId !== (id ?? null)) {
    currentUserId = id;
    window.gtag('set', {user_id: id});
  }
}

export function trackGAEvent(action, params) {
  if (window?.gtag) {
    window.gtag('event', action, params);
  } else {
    console.log(`skipped gtag('event', ${action}, ${JSON.stringify(params)})`);
  }
  trackEventWithMetaPixel(action, params);
}

/**
 * Also track some Google Analytics events as MetaPixel events by converting
 * the event format as required.
 */
function trackEventWithMetaPixel(action, params) {
  let command = 'track';
  let name = null;
  let fbqParams = null;
  switch(action) {
    case 'add_to_cart':
      name = 'AddToCart';
      fbqParams = {
        value: params.value,
        currency: params.currency,
        content_name: params.items[0].item_name,
      };
      break;
    case 'begin_order_form':
      command = 'trackCustom';
      name = 'begin_order_form';
      break;
    case 'submit_order_form':
      command = 'trackCustom';
      name = 'submit_order_form';
      break;
    case 'begin_checkout':
      name = 'InitiateCheckout';
      fbqParams = {
        value: params.value,
        currency: params.currency,
        content_name: params.items[0].item_name,
      };
      break;
    case 'purchase':
      name = 'Purchase';
      fbqParams = {
        value: params.value,
        currency: params.currency,
        content_name: params.items[0].item_name,
      };
      break;
  }
  if (name) {
    if (window?.fbq) {
      window.fbq(command, name, fbqParams);
    } else {
      console.log(`skipped fbq('${command}', ${name})`);
      return;
    }
  }
}
