import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
  required,
} from '../../util/validators';
import {
  Form,
  LocationAutocompleteInputField,
  Button,
  FieldPlaceInput,
  HeadingFormFieldWrapper,
  FieldSelect,
} from '../../components';

import css from './EditListingLocationForm.css';
import { LOCATION } from 'util/constants';
import { getCountryDataList } from 'lib/countries-list';

const identity = v => v;

export const EditListingLocationFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
      } = formRenderProps;

      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      //Living In
      const livingInLabel = intl.formatMessage({
        id: 'EditListingOverviewForm.livingInLabel',
      });
      const livingInPlaceholderMessage = intl.formatMessage({
        id: 'EditListingOverviewForm.livingInPlaceholder',
      });
      const livingInRequiredMessage = intl.formatMessage({
        id: 'EditListingOverviewForm.livingInRequired',
      });
      const livingInNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingOverviewForm.livingInNotRecognized',
      });
      const livingInSupportMessage = intl.formatMessage({
        id: 'EditListingOverviewForm.livingInSupport',
      });

      //nationality
      const nationalityLabel = intl.formatMessage({
        id: 'EditListingProfileForm.nationalityLabel',
      });
      const nationalityLocationRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.nationalityLocationRequired',
      });
      const nationalityLocationSupportMessage = intl.formatMessage({
        id: 'EditListingLocationForm.nationalityLocationSupport',
      });

      //Specialisation
      const specialisationLabel = intl.formatMessage({
        id: 'EditListingLocationForm.specialisationLabel',
      });
      const specialisationSupportMessage = intl.formatMessage({
        id: 'EditListingLocationForm.specialisationSupport',
      });

      //Countries
      const countriesLabel = intl.formatMessage(
        {
          id: 'EditListingLocationForm.countriesLabel',
        },
        {
          bold: header => <span className={css.placesText}>{header}</span>,
        }
      );
      const countriesPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.countriesPlaceholder',
      });
      const countriesRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.countriesRequired',
      });

      //Places
      const placesPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.placesPlaceholder',
      });
      const placesRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.placesRequired',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || pristine;
      const countriesOptions = getCountryDataList().sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.btnWrapper}>
            <div>
              <h1 className={css.headingTitle}>{LOCATION}</h1>
            </div>
            <Button
              rootClassName={css.topSaveButton}
              type="submit"
              inProgress={false}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>

          <div className={css.formGroup}>
            {errorMessage}
            {errorMessageShowListing}
            <LocationAutocompleteInputField
              className={css.locationAddress}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.desktopPredictions}
              validClassName={css.validLocation}
              autoFocus
              autocomplete="off"
              name="location"
              locationTypes={['locality', 'place', 'district', 'region']}
              needContext={true}
              label={livingInLabel}
              supportText={livingInSupportMessage}
              placeholder={livingInPlaceholderMessage}
              useDefaultPredictions={false}
              format={identity}
              valueFromForm={values.location}
              validate={composeValidators(
                autocompleteSearchRequired(livingInRequiredMessage),
                autocompletePlaceSelected(livingInNotRecognizedMessage)
              )}
            />

            <LocationAutocompleteInputField
              className={css.locationAddress}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.desktopPredictions}
              validClassName={css.validLocation}
              autocomplete="off"
              name="nationality"
              locationTypes={['country', 'region']}
              label={nationalityLabel}
              supportText={nationalityLocationSupportMessage}
              placeholder={nationalityLocationSupportMessage}
              useDefaultPredictions={false}
              format={identity}
              valueFromForm={values.nationality}
              validate={composeValidators(
                autocompleteSearchRequired(nationalityLocationRequiredMessage),
                autocompletePlaceSelected(addressNotRecognizedMessage)
              )}
            />

            <HeadingFormFieldWrapper
              label={specialisationLabel}
              supportText={specialisationSupportMessage}
              rootClassName={css.sectionTitle}
            />

            <HeadingFormFieldWrapper
              label={countriesLabel}
              supportText={countriesPlaceholderMessage}
              rootClassName={css.countriesFields}
            />
            <FieldSelect
              className={css.customField}
              type="select"
              name="countries[0]"
              id="countries_1"
              validate={composeValidators(required(countriesRequiredMessage))}
            >
              <option value="">
                {countriesPlaceholderMessage}
              </option>
              {countriesOptions.map(country => (
                <option key={country.name} value={country.name}>
                  {country.name}
                </option>
              ))}
            </FieldSelect>

            {[...Array(4)].map((_, index) => (
              <FieldSelect
                className={css.customField}
                type="select"
                name={`countries[${index + 1}]`}
                id={`countries_${index + 2}`}
              >
                <option value="">
                  {intl.formatMessage(
                    { id: 'EditListingLocationForm.countryLabel' },
                    {
                      count: index + 2,
                    }
                  )}
                </option>
                {countriesOptions.map(country => (
                  <option key={country.name} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </FieldSelect>
            ))}

            <HeadingFormFieldWrapper
              label={intl.formatMessage(
                { id: 'EditListingLocationForm.placesLabel' },
                {
                  bold: header => <span className={css.placesText}>{header}</span>,
                }
              )}
              supportText={placesPlaceholderMessage}
              rootClassName={css.placesTitle}
            />

            <FieldPlaceInput
              type="text"
              className={css.customField}
              id="places_1"
              name="places[0]"
              placeholder={placesPlaceholderMessage}
              validate={composeValidators(required(placesRequiredMessage))}
              needContext={true}
              locationTypes={['country', 'region', 'locality', 'place', 'district', 'poi']}
            />

            {[...Array(9)].map((_, index) => (
              <FieldPlaceInput
                type="text"
                className={css.customField}
                id={`places_${index + 2}`}
                name={`places[${index + 1}]`}
                placeholder={intl.formatMessage(
                  { id: 'EditListingLocationForm.placeLabel' },
                  {
                    count: index + 2,
                  }
                )}
                needContext={true}
                locationTypes={['country', 'region', 'locality', 'place', 'district', 'poi']}
              />
            ))}

            <Button
              rootClassName={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationFormComponent);
