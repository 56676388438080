import React from 'react';
import { Select, MenuItem } from '@material-ui/core';

const CurrencyDropDown = ({ currencyDB, selectedValue, onChange, className, label, id }) => {
  const customOptions = currencyDB.map(i => (
    <MenuItem value={i.value} key={i.value}>
      <div>
        <span className={`fi fi-${i.code}`} />
        <span>{` ${i.sign}${i.label}`}</span>
      </div>
    </MenuItem>
  ));

  return (
    <div className={className}>
      {label && <label htmlFor={id}>{label}</label>}
      <Select
        labelId="currency-select-label"
        id={id}
        value={selectedValue}
        onChange={onChange}
      >
        {customOptions}
      </Select>
    </div>
  );
};

export default CurrencyDropDown;