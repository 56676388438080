import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from 'components';
import { StaticPage, TopbarContainer } from 'containers';
import React from 'react';
import css from './BusinessGettingStartedPage.css';
import outline from 'assets/GettingStartedPage/Travel Agency Partnership thumbnail.png';

const content = {
  outline: {
    img: outline,
    url:
      'https://docs.google.com/presentation/d/e/2PACX-1vQP3jYwBpA49i3_2nDXKyOjbmlcm0l-NP_f_SEK5ypHYPmEDEgs7_xCBb8Q9QvS_RbFzYskr63fbLEh/pub?start=false&loop=false&delayms=3000',
    title: 'Travel Agency Partnership',
    list: [
      'Login',
      'Start Partnership Request',
      'Process',
      'Business Information',
      'Business Branding',
      'Marketplace Agreement',
      'Administration Contacts',
      'Connect Bank Account (Stripe)',
      'Publish/Submit Partnership Request',
      'Approval',
      'Review Business Listing',
      'Business Admin Menu',
      'Invite Travel Agents',
      'Associated Accounts',
    ],
  },
};

function BusinessGettingStartedPage() {
  return (
    <StaticPage title="Business Getting Started Page">
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer isDarkMode={true} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.container}>
            <div className={css.contentWrapper}>
              {Object.keys(content).map(section => {
                const { img, title, list, url } = content[section];
                return (
                  <div className={css.section}>
                    <div className={css.imageContainer}>
                      <a href={url}>
                        <img src={img} alt={title} width="100%" />
                      </a>
                    </div>
                    <div className={css.processDescription}>
                      <h2>
                        <a href={url}>{title}</a>
                      </h2>
                      <ul>
                        {list.map(item => (
                          <li>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
}

export default BusinessGettingStartedPage;
