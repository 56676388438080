import Decimal from 'decimal.js';
import { convertMoneyToNumber, convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { TRANSITION_REQUEST_PAYMENT, TX_TRANSITION_ACTOR_CUSTOMER } from '../../util/transaction';
import { types as sdkTypes } from '../../util/sdkLoader';
import axios from 'axios';
import { post } from '../../util/api';
import Geocoder from '../LocationAutocompleteInput/GeocoderMapbox';
import config from 'config';

const { Money, UUID } = sdkTypes;

const getLocationFromLatLng = (lat, lng) => {
  return axios
    .get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`
    )
    .then(resp => {
      const context = resp.data.features[0].context;

      const country = context.find(c => c.id.includes('country')).short_code?.toUpperCase();
      const zip = context.find(c => c.id.includes('postcode')).text;
      const state = context
        .find(
          c =>
            (c.id.includes('region') || c.id.includes('place') || c.id.includes('district')) &&
            c.short_code
        )
        ?.short_code?.toUpperCase()
        .replace(`${country}-`, '');

      return {
        country,
        zip,
        state,
      };
    })
    .catch(e => {
      console.log(e);
    });
};

const getGeocoder = () => {
  const geocoder = new Geocoder();

  return geocoder;
};

export const estimatedTotalPrice = lineItems => {
  const numericTotalPrice = lineItems.reduce((sum, lineItem) => {
    const numericPrice = convertMoneyToNumber(lineItem.lineTotal);
    return new Decimal(numericPrice).add(sum);
  }, 0);

  // All the lineItems should have same currency so we can use the first one to check that
  // In case there are no lineItems we use currency from config.js as default
  const currency =
    lineItems[0] && lineItems[0].unitPrice ? lineItems[0].unitPrice.currency : config.currency;

  return new Money(
    convertUnitToSubUnit(numericTotalPrice.toNumber(), unitDivisor(currency)),
    currency
  );
};

export const getTaxInfo = (setTaxInfo, lineItems, listing) => {
  return getGeocoder()
    .getPlaceDetails({
      id: 'current-location',
      predictionPlace: {},
    })
    .then(place => {
      const { lat, lng } = place?.origin || {};
      return getLocationFromLatLng(lat, lng)
        .then(resp => {
          const from_country = resp.country;
          const from_zip = resp.zip;
          const from_state = resp.state;

          const totalPayin =
            lineItems &&
            estimatedTotalPrice(lineItems.filter(item => item.includeFor.includes('customer')));

          const providerLat = listing?.attributes?.geolocation?.lat;
          const providerLng = listing?.attributes?.geolocation?.lng;

          return getLocationFromLatLng(providerLat, providerLng).then(resp => {
            const to_country = resp.country;
            const to_zip = resp.zip;
            const to_state = resp.state;

            post('/api/taxjar', {
              from_country,
              from_zip,
              from_state,
              to_country,
              to_zip,
              to_state,
              totalPayin: totalPayin?.amount,
            })
              .then(resp => {
                setTaxInfo(resp?.tax);
              })
              .catch(e => {
                console.log(e);
              });
          });
        })
        .catch(e => {
          console.log(e);
        });
    })
    .catch(e => console.log(e));
};
