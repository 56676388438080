import React from 'react';
import css from './IconCalendar.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const IconCalendar = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={classes} width="14" height="16" viewBox="0 0 14 16" fill="none">
      <path d="M10.524 8.984H6.83996V12.667H10.523L10.524 8.984ZM9.78696 0.884003V2.357H3.89296V0.884003H2.41996V2.357H1.68296C1.48981 2.35713 1.29858 2.39541 1.12025 2.46962C0.941923 2.54384 0.780009 2.65255 0.6438 2.7895C0.50759 2.92646 0.399768 3.08896 0.326519 3.26769C0.253271 3.44642 0.216039 3.63785 0.216961 3.831L0.209961 14.144C0.209961 14.5347 0.365152 14.9093 0.641393 15.1856C0.917634 15.4618 1.2923 15.617 1.68296 15.617H11.997C12.3872 15.6157 12.7611 15.4601 13.0371 15.1841C13.313 14.9082 13.4686 14.5343 13.47 14.144V3.831C13.4686 3.44074 13.313 3.06685 13.0371 2.79089C12.7611 2.51493 12.3872 2.35932 11.997 2.358H11.26V0.884003H9.78696ZM11.997 14.144H1.68296V6.044H11.997V14.144Z" fill="#03c1f4"/>
    </svg>
  );
};

IconCalendar.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCalendar.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCalendar;
