import React, { Component } from 'react';
import { bool, func, number, shape, string, array } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration, { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { parse, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import {
  Button,
  Logo,
  Modal,
  ModalMissingInformation,
  NamedLink,
  TopbarDesktop,
  IconSearch,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  Avatar,
  InlineTextButton,
  CurrencyDropDown,
} from '../../components';
import { TopbarSearchForm } from '../../forms';

import MenuIcon from './MenuIcon';
import css from './Topbar.css';
import { LISTING_TYPES } from 'util/constants';
import User_Icon from '../../assets/Landing_Pages/User_Icon.png';
import { NavLink } from 'react-router-dom';
import { currencyDB } from 'lib/currency-list';
import countryToCurrency from 'country-to-currency';
import { getUserLocation } from 'util/browselocateme';

const sharetribeSdk = require('sharetribe-flex-sdk');

const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const MAX_MOBILE_SCREEN_WIDTH = 1024;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

const preferredCurrencyTitle = (
  <FormattedMessage
    id="Topbar.preferredCurrencyTitle"
    values={{ b: chunks => <strong>{chunks}</strong> }}
  />
);

const preferredCurrencyDescription = (
  <FormattedMessage
    id="Topbar.preferredCurrencyDescription"
    values={{ b: chunks => <strong>{chunks}</strong> }}
  />
);

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyWordValue: '',
      ownListings: [],
      showCurrencyPickupModal: false,
      selectedCurrency: props.preferredCurrency,
    };
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);

    this.handleCurrencyPickupModalOpen = this.handleCurrencyPickupModalOpen.bind(this);
    this.handleCloseCurrencyPickupModal = this.handleCloseCurrencyPickupModal.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
  }

  async componentDidMount() {
    const { isAuthenticated, onSavePreferredCurrency, onSetCurrencyConversionRate } = this.props;

    const primaryPreferredCurrency = localStorage.getItem('preferredCurrency');
    const {
      country: { code: countryCodePrimary },
    } = getUserLocation();
    const localeSecondary = window && window.navigator.language;
    const countryCodeSecondary =
      localeSecondary && localeSecondary.split('-').length === 2 && localeSecondary.split('-')[1];
    const countryCode = countryCodePrimary || countryCodeSecondary;
    const secondaryPreferredCurrency = countryToCurrency[countryCode];

    const initialPreferredCurrency = primaryPreferredCurrency || secondaryPreferredCurrency;
    if (initialPreferredCurrency && initialPreferredCurrency !== 'USD') {
      this.setState({selectedCurrency: initialPreferredCurrency});
      try {
        onSavePreferredCurrency(initialPreferredCurrency);
        await onSetCurrencyConversionRate(initialPreferredCurrency);
      } catch (error) {
        console.error('Failed to set the preferred currency.');
        throw new Error(error);
      }
    }

    const urlQueryParams = parse(this.props.location.search);
    if (urlQueryParams && urlQueryParams.keywords && this.state.keyWordValue === '') {
      this.setState({ keyWordValue: urlQueryParams.keywords });
    }

    if (isAuthenticated) {
      sdk.ownListings
        .query({})
        .then(res => {
          this.setState({ ownListings: res.data.data });
        })
        .catch(e => {
          //ignore error
        });
    }
  }

  handleCurrencyPickupModalOpen() {
    this.setState({ showCurrencyPickupModal: true });
  }

  handleCloseCurrencyPickupModal() {
    this.setState({ showCurrencyPickupModal: false });
  }
  
  handleCurrencyChange = async e => {
    const selectedValue = e.target.value;

    const { onSavePreferredCurrency, onSetCurrencyConversionRate } = this.props;
    this.setState({ selectedCurrency: selectedValue });
    try {
      if (selectedValue) {
        onSavePreferredCurrency(selectedValue);
        await onSetCurrencyConversionRate(selectedValue);
      }
    } catch (error) {
      console.log('Failed to fetch currency data.');
    }
  };

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleSubmit(values) {
    const { currentSearchParams } = this.props;
    const { search, selectedPlace } = values.location;
    const { history } = this.props;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      pub_listing_type: currentSearchParams?.pub_listing_type || LISTING_TYPES.COMPANY,
      address: search,
      sort: currentSearchParams?.sort || 'pub_total_ratings',
      bounds,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  handleLogout() {
    const { onLogout, history } = this.props;
    onLogout().then(() => {
      // Note: redirecting to privateSiteUrl is disabled because a redirect to
      // logout of Lyfshort auth server happens instead.
      //let path = pathByRouteName('LandingPage', routeConfiguration());
      //let isPrivateSite = false;
      //if (typeof window !== 'undefined' && !!window.sessionStorage) {
      //  isPrivateSite = window.sessionStorage.getItem('privateSiteUrl') ? true : false;
      //}
      //if (isPrivateSite) {
      //  path = window.sessionStorage.getItem('privateSiteUrl');
      //}

      //if (typeof window !== 'undefined') {
      //  // In production we ensure that data is really lost,
      //  // but in development mode we use stored values for debugging
      //  if (config.dev) {
      //    history.push(path);
      //  } else if (typeof window !== 'undefined') {
      //    window.location = path;
      //  }
      //}

      console.log('logged out'); // eslint-disable-line
    });
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserListing,
      currentUserListingFetched,
      currentUserHasOrders,
      currentPage,
      currentSearchParams,
      notificationCount,
      saleNotificationCount,
      orderNotificationCount,
      viewport,
      intl,
      location,
      history,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      showGenericError,
      lastCompleteTransaction,
      lastBookingReviewDone,
      onSendReview,
      onSkipReview,
      onResetReviewState,
      sendReviewInProgress,
      sendReviewError,
      onSetLastBookingReviewed,
      lastAcceptedPackage,
      lastAcceptedPackageNoteSeen,
      onSetAcceptedPackageNoteSeen,
      isDarkMode,
      keywordSearchTopbar,
      languageConfig,
      priceFilterConfig,
    } = this.props;
    const { mobilemenu, mobilesearch, address, origin, bounds } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });
    // const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
    const saleNotificationDot = saleNotificationCount > 0 ? <div className={css.notificationDot} /> : null;
    const orderNotificationDot = orderNotificationCount > 0 ? <div className={css.notificationDot} /> : null;
    const avatarhasDot = saleNotificationCount + orderNotificationCount > 0;

    let isPrivateSite = false;
    if (typeof window !== 'undefined' && !!window.sessionStorage) {
      isPrivateSite = window.sessionStorage.getItem('privateSiteUrl') ? true : false;
    }
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

    const keywordPlaceholder = intl.formatMessage({ id: 'SectionSearch.search' });
    const handleKeyword = (urlParam, values) => {
      const urlQueryParams = parse(location.search);
      const queryParams = values
        ? { ...urlQueryParams, [urlParam]: values }
        : omit(urlQueryParams, urlParam);

      history.push(createResourceLocatorString(currentPage, routeConfiguration(), {}, queryParams));
    };
    const filterKeywordContent = (
      <div className={css.filterKeywordWrap}>
        <div className={css.filterWrap}>
          <div className={css.inputWrap}>
            <input
              id={'SearchFilters.keywordFilter'}
              value={this.state.keyWordValue}
              placeholder={keywordPlaceholder}
              onChange={e => this.setState({ keyWordValue: e.target.value })}
            />
          </div>
          <div
            className={css.btnapply}
            onClick={() => handleKeyword('keywords', this.state.keyWordValue)}
          >
            <IconSearch />
          </div>
        </div>
      </div>
    );

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = config.sortSearchByDistance
      ? address && origin && bounds
      : address && bounds;
    const initialSearchFormValues = {
      location: locationFieldsPresent
        ? {
          search: address,
          selectedPlace: { address, origin, bounds },
        }
        : null,
    };

    // const search = (
    //   <TopbarSearchForm
    //     className={css.searchLink}
    //     desktopInputRoot={css.topbarSearchWithLeftPadding}
    //     onSubmit={this.handleSubmit}
    //     initialValues={initialSearchFormValues}
    //   />
    // );

    const classes = classNames(rootClassName || css.root, className);

    const expertListing = this.state.ownListings?.find(
      l => l?.attributes?.publicData?.listing_type === 'listing'
    );
    const partnerListing = this.state.ownListings?.find(
      l => l?.attributes?.publicData?.listing_type === 'company'
    );
    const isPartnerListingDraft = partnerListing && partnerListing?.attributes?.state === 'draft';
    const isExpertListingDraft = expertListing && expertListing?.attributes?.state === 'draft';
    const hasAdminId = currentUser?.attributes?.profile?.publicData?.adminId;
    const agentSupport = hasAdminId || partnerListing;
    const agentSupportURL = 'https://lyfshort.com/travel-agent-support/';

    const currentPageClass = page => {
      const isAccountSettingsPage =
        page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
      return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
    };

    const profileMenu = isAuthenticated ? (
      <Menu isHoverDropDown={true} className={css.profileMenuRoot}>
        <MenuLabel
          className={classNames(css.profileMenuLabel, { [css.darkProfileMenuLabel]: isDarkMode })}
          isOpenClassName={css.profileMenuIsOpen}
        >
          {/* <span>{currentUser && currentUser.attributes.profile.firstName} &nbsp;</span> */}
          <div className={css.activeUser}>
            <Avatar className={css.avatar} user={currentUser} disableProfileLink />
            <div className={avatarhasDot ? css.redDot : css.active} />
          </div>
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="UserProfile">
            <NamedLink
              className={classNames(css.profileSettingsLink, currentPageClass('UserProfile'))}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="MySettings">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('PaymentMethods'))}
              name="PaymentMethodsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="MyOrdersPage">
            <div className={css.notificationContainer}>
              {orderNotificationDot}
              <NamedLink
                className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
                name="InboxPage"
                params={{ tab: 'orders', state: 'all' }}
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.myOrdersPage" />
              </NamedLink>
            </div>
          </MenuItem>
          <MenuItem key="FavListingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('FavListingsPage'))}
              name="FavListingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.FavListingsPage" />
            </NamedLink>
          </MenuItem>

          {currentUser ? (
            <MenuItem key="MyReviewsPage">
              <NamedLink
                className={classNames(css.yourListingsLink, currentPageClass('ProfilePage1'))}
                name="ProfilePage"
                params={{ id: currentUser.id.uuid }}
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.myReviews" />
              </NamedLink>
            </MenuItem>
          ) : null}

          {hasAdminId ? ( //expertListing
            <>
              <div className={css.divider} />

              <MenuItem key="tripExpertAdmin">
                <span
                  className={classNames(css.tripExpertAdmin, currentPageClass('TripExpertAdmin'))}
                >
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id="TopbarDesktop.tripExpertAdmin" />
                </span>
              </MenuItem>

              <MenuItem key="tripExpertAdmin_1">
                {expertListing ? (
                  <NamedLink
                    className={classNames(
                      css.yourListingsLink,
                      currentPageClass('TripExpertAdmin')
                    )}
                    name={isExpertListingDraft ? 'EditListingPage' : 'ListingPage'}
                    params={
                      isExpertListingDraft
                        ? {
                            id: expertListing.id.uuid,
                            slug: expertListing.attributes.title.replaceAll(' ', '-'),
                            type: 'draft',
                            tab: 'overview',
                          }
                        : {
                            id: expertListing.id.uuid,
                            slug: expertListing.attributes.title.replaceAll(' ', '-'),
                          }
                    }
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="TopbarDesktop.editSalesPage" />
                  </NamedLink>
                ) : (
                  <NamedLink
                    className={classNames(
                      css.yourListingsLink,
                      currentPageClass('BecomeTripExpert')
                    )}
                    name="NewListingPage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="TopbarDesktop.becomeTripExpert" />
                  </NamedLink>
                )}
              </MenuItem>

              <MenuItem key="OrderManagement">
                <div className={css.notificationContainer}>
                  {saleNotificationDot}
                  <NamedLink
                    className={classNames(
                      css.yourListingsLink,
                      currentPageClass('OrderManagement'),
                      orderNotificationCount > 0 && css.boldText
                    )}
                    name="InboxPage"
                    params={{ tab: 'sales', state: 'all' }}
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="TopbarDesktop.orderManagement" />
                  </NamedLink>
                </div>
              </MenuItem>

              <MenuItem key="Reporting">
                <NamedLink
                  className={classNames(css.yourListingsLink, currentPageClass('Reporting'))}
                  name="InboxPage"
                  params={{ tab: 'myClients', state: 'all' }}
                >
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id="TopbarDesktop.reporting" />
                </NamedLink>
              </MenuItem>
            </>
          ) : null}

          {partnerListing ? (
            <>
              <div className={css.divider} />
              <MenuItem key="travelBusinessAdminLabel">
                <span
                  className={classNames(
                    css.tripExpertAdmin,
                    currentPageClass('travelBusinessAdmin')
                  )}
                >
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id="TopbarDesktop.travelBusinessAdmin" />
                </span>
              </MenuItem>

              <MenuItem key="travelBusinessAdmin">
                <NamedLink
                  className={classNames(
                    css.yourListingsLink,
                    currentPageClass('travelBusinessAdmin')
                  )}
                  name={isPartnerListingDraft ? 'EditCompanyPage' : 'ListingPage'}
                  params={
                    isPartnerListingDraft
                      ? {
                          id: partnerListing.id.uuid,
                          slug: partnerListing.attributes.title.replaceAll(' ', '-'),
                          type: 'draft',
                          tab: 'introduction',
                        }
                      : {
                          id: partnerListing.id.uuid,
                          slug: partnerListing.attributes.title.replaceAll(' ', '-'),
                        }
                  }
                >
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id="TopbarDesktop.editBusinessPage" />
                </NamedLink>
              </MenuItem>

              <MenuItem key="AssociatedAccountsPage">
                <NamedLink
                  className={classNames(
                    css.yourListingsLink,
                    currentPageClass('AssociatedAccountsPage')
                  )}
                  name="AssociatedAccountsPage"
                >
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id="TopbarDesktop.associatedAccountsPage" />
                </NamedLink>
              </MenuItem>

              <MenuItem key="ReportingBusiness">
                <NamedLink
                  className={classNames(css.yourListingsLink, currentPageClass('Reporting'))}
                  name="InboxPage"
                  params={{ tab: 'enquiry-client', state: 'all' }}
                >
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id="TopbarDesktop.reportingBusiness" />
                </NamedLink>
              </MenuItem>
            </>
          ) : null}

          <div className={css.divider} />

          {agentSupport && (
            <MenuItem key="agentSupport">
              <a
                className={classNames(css.yourListingsLink, currentPageClass('agentSupport'))}
                href={agentSupportURL}
                target='_blank'
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="TopbarDesktop.agentSupport" />
              </a>
            </MenuItem>
          )}

          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={this.handleLogout}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>
    ) : (
      <Menu className={css.profileMenuRoot}>
        <MenuLabel
          className={classNames(css.profileMenuLabel, { [css.darkProfileMenuLabel]: isDarkMode })}
          isOpenClassName={css.profileMenuIsOpen}
        >
          <NamedLink
            name="LoginPage"
            className={classNames(css.loginIcon, { [css.darkTopbarMenuLink]: isDarkMode })}
          >
            <img src={User_Icon} />
          </NamedLink>
        </MenuLabel>
      </Menu>
    );

    const hamburgerMenu = (
      <Menu className={css.hamburgerMenuRoot}>
        <MenuLabel
          className={classNames(css.hamburgerMenuLabel, { [css.darkHamburgerMenuLabel]: isDarkMode })}
        >
          <MenuIcon />
        </MenuLabel>
        <MenuContent className={css.menuContent}>
          <MenuItem key="Marketplace">
            <div className={classNames(css.yourListingsLink)}>
              <strong>
                <FormattedMessage id="TopbarDesktop.marketPlace" />
              </strong>
            </div>
          </MenuItem>
          <MenuItem key="SearchForExpert">
            <NamedLink
              className={classNames(css.yourListingsLink)}
              name="SearchPage"
              to={{
                search: `pub_listing_type=listing`
              }}
            >
              <span className={css.menuItemBorder} />
              &nbsp;&nbsp;
              <FormattedMessage id="TopbarDesktop.searchForATripExpert" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="Overview">
            <NamedLink
              className={classNames(css.yourListingsLink)}
              name="OverviewPage"
            >
              <span className={css.menuItemBorder} />
              &nbsp;&nbsp;
              <FormattedMessage id="TopbarDesktop.overview" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="IntroVideo">
            <NavLink
              to="/#intro"
              className={classNames(css.yourListingsLink)}
            >
              <span className={css.menuItemBorder} />
              &nbsp;&nbsp;
              <FormattedMessage id="TopbarDesktop.introVideo" />
            </NavLink>
          </MenuItem>
          <MenuItem key="OrganiserApp">
            <a
              href="https://www.lyfshort.com/organiser-app"
              className={classNames(css.yourListingsLink)}
              target="_blank"
            >
              <span className={css.menuItemBorder} />
              <strong>
                <FormattedMessage id="TopbarDesktop.organiserApp" />
              </strong>
            </a>
          </MenuItem>
          <MenuItem key="Trip Experts">
            <div className={classNames(css.yourListingsLink)}>
              <strong>
                <FormattedMessage id="TopbarDesktop.tripExpert" />
              </strong>
            </div>
          </MenuItem>
          <MenuItem key="Benefits">
            <NavLink
              to="/trip_expert#benefits"
              className={classNames(css.yourListingsLink, currentPageClass('Benefits'))}
            >
              <span className={css.menuItemBorder} />
              &nbsp;&nbsp;
              <FormattedMessage id="TopbarDesktop.benefits" />
            </NavLink>
          </MenuItem>
          <MenuItem key="Process">
            <NavLink
              to="/trip_expert#process"
              className={classNames(css.yourListingsLink, currentPageClass('Process'))}
            >
              <span className={css.menuItemBorder} />
              &nbsp;&nbsp;
              <FormattedMessage id="TopbarDesktop.process" />
            </NavLink>
          </MenuItem>
          <MenuItem key="Pricing">
            <NavLink
              to="/trip_expert#pricing"
              className={classNames(css.yourListingsLink, currentPageClass('Pricing'))}
            >
              <span className={css.menuItemBorder} />
              &nbsp;&nbsp;
              <FormattedMessage id="TopbarDesktop.pricing" />
            </NavLink>
          </MenuItem>
          <MenuItem key="Become a Trip Expert">
            <NavLink
              to="/trip_expert#trip-expert-partner"
              className={classNames(css.yourListingsLink, currentPageClass('trip-expert-partner'))}
            >
              <span className={css.menuItemBorder} />
              &nbsp;&nbsp;
              <FormattedMessage id="TopbarDesktop.becomeTripExpert" />
            </NavLink>
          </MenuItem>
          <MenuItem key="Partners">
            <div className={classNames(css.yourListingsLink)}>
              <strong>
                <FormattedMessage id="TopbarDesktop.partners" />
              </strong>
            </div>
          </MenuItem>
          <MenuItem key="Travel Agencies">
            <NamedLink
              className={classNames(css.yourListingsLink)}
              name="SearchPage"
              to={{
                search: `pub_listing_type=company`,
              }}
            >
              <span className={css.menuItemBorder} />
              &nbsp;&nbsp;
              <FormattedMessage id="TopbarDesktop.travelAgencies" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="Become a Partner">
            <NamedLink
              className={classNames(css.yourListingsLink)}
              name="BecomeExpertOrPartnerPage"
            >
              <span className={css.menuItemBorder} />
              &nbsp;&nbsp;
              <FormattedMessage id="TopbarDesktop.becomeAPartner" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="Home">
            <NamedLink
              className={classNames(css.yourListingsLink)}
              name="LandingPage"
            >
              <span className={css.menuItemBorder} />
              <strong>
                <FormattedMessage id="TopbarDesktop.home" />
              </strong>
            </NamedLink>
          </MenuItem>
        </MenuContent>
      </Menu>);

    const preferenceCurrencyCode = currencyDB.find(c => c.label === this.state.selectedCurrency)
      ?.code;

    const currencyLink = (
      <div
        className={css.currencyFlagClass}
        onClick={this.handleCurrencyPickupModalOpen}
      >
        <span className={`fi fi-${preferenceCurrencyCode}`} />        
      </div>
    );

    return (
      <div className={classes}>
        {!keywordSearchTopbar ? (
          <div
            className={classNames(mobileRootClassName || css.container, mobileClassName, {
              [css.darkContainer]: isDarkMode,
            })}
          >
            <div className={css.menuContainer}>
              {hamburgerMenu}
              <div className={css.topRight}>
                {currencyLink}
                {profileMenu}
              </div>
            </div>
          </div>
        ) : (
          <div className={css.keywordMobileTopbarContainer}>
            <svg
              onClick={() => history.push('/')}
              className={css.goBackArrow}
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="22"
              viewBox="0 0 13 22"
              fill="none"
            >
              <path
                d="M10.8594 2.3457L2.50057 10.7045L10.8594 19.0633"
                stroke="#2D2D2D"
                strokeWidth="4.1794"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            {filterKeywordContent}

            <Button
              rootClassName={css.topbarMenu}
              onClick={this.handleMobileMenuOpen}
              title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="17"
                viewBox="0 0 22 17"
                fill="none"
              >
                <path d="M2 1.54297H20" stroke="#233045" strokeWidth="2.5" strokeLinecap="round" />
                <path d="M2 8.58789H20" stroke="#233045" strokeWidth="2.5" strokeLinecap="round" />
                <path d="M2 15.543H12" stroke="#233045" strokeWidth="2.5" strokeLinecap="round" />
              </svg>
            </Button>

          </div>
        )}
        <div className={css.desktop}>
          <TopbarDesktop
            className={desktopClassName}
            currentUserHasListings={currentUserHasListings}
            currentUserListing={currentUserListing}
            currentUserListingFetched={currentUserListingFetched}
            currentUser={currentUser}
            currentPage={currentPage}
            initialSearchFormValues={initialSearchFormValues}
            intl={intl}
            isAuthenticated={isAuthenticated}
            notificationCount={notificationCount}
            saleNotificationCount={saleNotificationCount}
            orderNotificationCount={orderNotificationCount}
            onLogout={this.handleLogout}
            onSearchSubmit={this.handleSubmit}
            isDarkMode={isDarkMode}
            currencyLink={currencyLink}
          />
        </div>
        {isMobileSearchOpen ? (
          <Modal
            id="TopbarMobileSearch"
            containerClassName={css.modalContainer}
            isOpen={isMobileSearchOpen}
            onClose={this.handleMobileSearchClose}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.searchContainer}>
              <TopbarSearchForm
                onSubmit={this.handleSubmit}
                initialValues={initialSearchFormValues}
                isMobile
              />
              <p className={css.mobileHelp}>
                <FormattedMessage id="Topbar.mobileSearchHelp" />
              </p>
            </div>
          </Modal>
        ) : null}

        <ModalMissingInformation
          id="MissingInformationReminder"
          containerClassName={css.missingInformationModal}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          location={location}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
          lastCompleteTransaction={lastCompleteTransaction}
          lastBookingReviewDone={lastBookingReviewDone}
          onSendReview={onSendReview}
          onSkipReview={onSkipReview}
          onResetReviewState={onResetReviewState}
          onSetLastBookingReviewed={onSetLastBookingReviewed}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
          lastAcceptedPackage={lastAcceptedPackage}
          lastAcceptedPackageNoteSeen={lastAcceptedPackageNoteSeen}
          onSetAcceptedPackageNoteSeen={onSetAcceptedPackageNoteSeen}
          history={history}
        />

        <Modal
          id="currency-pickup"
          isOpen={this.state.showCurrencyPickupModal}
          onClose={this.handleCloseCurrencyPickupModal}
          containerClassName={css.modalContainerClass}
          contentClassName={css.contentContainerClass}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div style={{ marginBottom: '20px' }}>{preferredCurrencyTitle}</div>
          <div style={{ marginBottom: '40px' }}>{preferredCurrencyDescription}</div>
          <div style={{ marginTop: '40px' }}>
            <CurrencyDropDown
              currencyDB={currencyDB.filter(c => c.code.trim() && c.flag.trim())}
              selectedValue={this.state.selectedCurrency}
              onChange={this.handleCurrencyChange}
            />
          </div>
        </Modal>

        {/* <GenericError show={showGenericError} /> */}
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  saleEnquiriesNotificationCount: 0,
  orderEnquiriesNotificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  priceFilterConfig: config.custom.priceFilterConfig,
  languageConfig: config.custom.languageFilterConfig,
  sendVerificationEmailError: null,
  isDarkMode: true,
};

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  saleEnquiriesNotificationCount: number,
  orderEnquiriesNotificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,
  onSetLastBookingReviewed: func,
  isDarkMode: bool,
  languageConfig: array,
  priceFilterConfig: shape({
    min: number.isRequired,
    max: number.isRequired,
    step: number.isRequired,
  }),

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(withViewport, injectIntl)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
