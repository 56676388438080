import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import IconUser from 'components/IconUser/IconUser';
import { NamedLink } from 'components';
import css from 'containers/BottomMenu/BottomMenu.css';
import { propTypes } from 'util/types';
import { stringify, parse } from 'util/urlHelpers';
import classNames from 'classnames';
import { IconExplore, IconClient, IconGetMatched, IconVendor } from './ButtomMenuIcon'

const getActiveMenu = (location) => {
  if (location.hash === '#clientmenu')
    return 'Client';
  else if (location.pathname.substring(0, 3) === '/i/' && location.hash === '')
    return 'Get Matched';
  else if (location.hash === '#vendormenu')
    return 'Vendor';
  else if (location.pathname === '/login' && location.hash === '')
    return 'Login';
  else
    return 'Explore';
}

function BottomMenuComponent({
  currentUser,
  // currentUserListing,
  // currentUserNotificationCount,
  // currentUserPackagesNotificationCount,
  currentUserWasFetched,
  location,
  history,
}) {
  // if (location.pathname === '/s') return null;

  const isLoggedin = !!currentUser;
  const activeMenu = getActiveMenu(location)
  // const bookingNotificationBadge =
  //   currentUserNotificationCount > 0 ? (
  //     <NotificationBadge className={css.notificationBadge} count={currentUserNotificationCount} />
  //   ) : null;

  // const packagesNotificationBadge =
  //   currentUserPackagesNotificationCount > 0 ? (
  //     <NotificationBadge
  //       className={css.notificationBadge}
  //       count={currentUserPackagesNotificationCount}
  //     />
  //   ) : null;

  const onClickMenuPortalLink = (openPortalMenu) => {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ mobilemenu: 'open', ...parse(search) })}`;
    history.push(`${pathname}${searchString}#${openPortalMenu}`, state);
  }
  return (
    currentUserWasFetched && (
      <div className={css.root}>
        <div className={css.iconWrap}>
          <NamedLink
            className={css.bottomMenuLink}
            name="SearchPage"
            to={{ search: stringify({ pub_listing_type: 'listing' }) }}
          >
            <IconExplore className={classNames(css.bottomMenuIcon, activeMenu === 'Explore' && css.blueSvg)} />
            <div>Explore</div>
          </NamedLink>
          <div
            onClick={() => onClickMenuPortalLink("clientmenu")}
            className={css.bottomMenuLink}
          >
            <IconClient className={classNames(css.bottomMenuIcon, activeMenu === 'Client' && css.blueSvg)} />
            Bookings
          </div>

          <NamedLink
            className={css.bottomMenuLink}
            name="NewInquirePage"
          >
            <IconGetMatched  className={classNames(css.bottomMenuIcon, activeMenu === 'Get Matched' && css.blueSvg)} />
            Get Matched
          </NamedLink>

          <div
            onClick={() => onClickMenuPortalLink("vendormenu")}
            className={css.bottomMenuLink}
          >
            <IconVendor className={classNames(css.bottomMenuIcon, activeMenu === 'Vendor' && css.blueSvg)} />
            Hosting
          </div>

          {/* <NamedLink
            className={cns(css.bottomMenuLink, css.withBadge)}
            name="InboxPage"
            params={{ tab: currentUserListing ? 'sales' : 'orders' }}
          >
            <IconBookings className={css.bottomMenuIcon} />
            Bookings
            {bookingNotificationBadge}
          </NamedLink> */}

          {!isLoggedin && (
            <NamedLink className={css.bottomMenuLink} name="LoginPage">
              <IconUser className={classNames(css.loginMenuIcon, activeMenu === 'Login' && css.blueSvg)} />
              <div>Login</div>
            </NamedLink>
          )}
        </div>
      </div>
    )
  );
}

BottomMenuComponent.propTypes = {
  currentUser: propTypes.currentUser,
  currentUserListing: PropTypes.oneOfType([propTypes.ownListing, propTypes.listing]),
  currentUserNotificationCount: PropTypes.number,
  currentUserPackagesNotificationCount: PropTypes.number,
  currentUserWasFetched: PropTypes.bool,
};

const mapStateToProps = state => {
  const {
    currentUser,
    currentUserListing,
    currentUserWasFetched,
    currentUserNotificationCount,
    currentUserPackagesNotificationCount,
  } = state.user;

  return {
    currentUser,
    currentUserListing,
    currentUserWasFetched,
    currentUserNotificationCount,
    currentUserPackagesNotificationCount,
  };
};

const BottomMenu = compose(withRouter, connect(mapStateToProps, null))(BottomMenuComponent);

export default BottomMenu;
