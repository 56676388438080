import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from 'components/IconPractice/IconPractice.css';

const IconPractice = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M4.75 0.93457C5.30812 0.93457 5.86077 1.0445 6.37641 1.25808C6.89204 1.47166 7.36056 1.78472 7.75521 2.17937C8.14986 2.57402 8.46291 3.04253 8.67649 3.55817C8.89007 4.0738 9 4.62645 9 5.18457V9.43457H4.75C3.62283 9.43457 2.54183 8.9868 1.7448 8.18977C0.947768 7.39275 0.500002 6.31174 0.500002 5.18457C0.500002 4.0574 0.947768 2.9764 1.7448 2.17937C2.54183 1.38234 3.62283 0.93457 4.75 0.93457ZM4.75 11.4346H9V15.6846C9 16.5251 8.75074 17.3468 8.28375 18.0457C7.81675 18.7447 7.15299 19.2894 6.37641 19.6111C5.59982 19.9327 4.74529 20.0169 3.92087 19.8529C3.09645 19.6889 2.33917 19.2841 1.7448 18.6898C1.15042 18.0954 0.745652 17.3381 0.581664 16.5137C0.417677 15.6893 0.501841 14.8348 0.823514 14.0582C1.14519 13.2816 1.68992 12.6178 2.38883 12.1508C3.08774 11.6838 3.90943 11.4346 4.75 11.4346ZM15.25 0.93457C16.3772 0.93457 17.4582 1.38234 18.2552 2.17937C19.0522 2.9764 19.5 4.0574 19.5 5.18457C19.5 6.31174 19.0522 7.39275 18.2552 8.18977C17.4582 8.9868 16.3772 9.43457 15.25 9.43457H11V5.18457C11 4.0574 11.4478 2.9764 12.2448 2.17937C13.0418 1.38234 14.1228 0.93457 15.25 0.93457ZM11 11.4346H15.25C16.0906 11.4346 16.9123 11.6838 17.6112 12.1508C18.3101 12.6178 18.8548 13.2816 19.1765 14.0582C19.4982 14.8348 19.5823 15.6893 19.4183 16.5137C19.2544 17.3381 18.8496 18.0954 18.2552 18.6898C17.6608 19.2841 16.9036 19.6889 16.0791 19.8529C15.2547 20.0169 14.4002 19.9327 13.6236 19.6111C12.847 19.2894 12.1833 18.7447 11.7163 18.0457C11.2493 17.3468 11 16.5251 11 15.6846V11.4346Z" fill="#0095B3" />
    </svg>
  );
};

IconPractice.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconPractice.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconPractice;
