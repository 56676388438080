import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from 'components';
import { TopbarContainer, StaticPage } from 'containers';
import React from 'react';
import css from './OrderDeliveryGuidePage.css';
import thumbnail from 'assets/GettingStartedPage/Order Delivery thumbnail.png';

const thumbnailLink =
  'https://docs.google.com/presentation/d/e/2PACX-1vQ2iaX2G_o5aaEzZ4KMDvFgI2F-qVQ857UZoH8DlW-keS47o4lqdPC-oGyvlVnFHzjE5EsyBLRT0VX3/pub?start=false&loop=false&delayms=3000';

const content = {
  accept: {
    title: 'quoting & accepting',
    url:
      'https://docs.google.com/presentation/d/e/2PACX-1vQ2iaX2G_o5aaEzZ4KMDvFgI2F-qVQ857UZoH8DlW-keS47o4lqdPC-oGyvlVnFHzjE5EsyBLRT0VX3/pub?start=false&loop=false&delayms=3000&slide=id.g2dbfa1d3364_0_52',
    list: [
      'Process',
      'Customer Orders menu',
      'Accepting Order',
      'Automatic Itinerary Setup',
      'Order Status',
    ],
  },
  delivery: {
    title: 'delivering',
    url:
      'https://docs.google.com/presentation/d/e/2PACX-1vQ2iaX2G_o5aaEzZ4KMDvFgI2F-qVQ857UZoH8DlW-keS47o4lqdPC-oGyvlVnFHzjE5EsyBLRT0VX3/pub?start=false&loop=false&delayms=3000&slide=id.g2dbfa1d3364_0_220',
    list: [
      'Process',
      'Organiser App',
      'Accepting Order',
      'Feature Images',
      'Creating / Editing Items',
      'Quick Placeholders',
      'Dates & Times',
      'Location & Mapping',
      'Expenses & Quotes',
      'Adding Content to Items',
      'Order Delivery',
      'Messaging / Chat',
      'Booking Imports',
      'Exporting PDF Itinerary',
      'Rate & Review',
    ],
  },
  agent_booking: {
    title: 'travel agent bookings',
    url:
      'https://docs.google.com/presentation/d/e/2PACX-1vQ2iaX2G_o5aaEzZ4KMDvFgI2F-qVQ857UZoH8DlW-keS47o4lqdPC-oGyvlVnFHzjE5EsyBLRT0VX3/pub?start=false&loop=false&delayms=3000&slide=id.g2dbfa1d3364_0_993',
    list: ['Trip Expert as a Travel Agent'],
  },
};

function OrderDeliveryGuidePage() {
  return (
    <StaticPage
      title="Assisted Trip Planning Order Delivery"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Assisted Trip Planning Order Delivery',
        description: 'Order Delivery Guide',
        name: 'Assisted Trip Planning Order Delivery Guide Page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer isDarkMode={true} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.container}>
            <div className={css.contentWrapper}>
              <div className={css.section}>
                <div className={css.imageContainer}>
                  <a href={thumbnailLink}>
                    <img src={thumbnail} alt="thumbnail" width="100%" />
                  </a>
                </div>
                <div className={css.processDescription}>
                  {Object.keys(content).map(section => {
                    const { title, list, url } = content[section];
                    return (
                      <>
                        <h2>
                          <a href={url}>{title}</a>
                        </h2>
                        <ul>
                          {list.map(item => (
                            <li>{item}</li>
                          ))}
                        </ul>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
}

export default OrderDeliveryGuidePage;
