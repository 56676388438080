import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldSelect, FieldMultipleSelection, HeadingFormFieldWrapper, NamedLink } from 'components';
import config from '../../config';

import css from './EditListingOverviewForm.css';
import { LISTING_TYPES, OVERVIEW } from 'util/constants';
import { interestsOptions } from 'marketplace-custom-config';


const DETAILED_MAX_LENGTH = 2000;
const SUMMARY_MAX_LENGTH = 1000;
const TAGLINE_MAX_LENGTH = 100;
const TITLE_MAX_LENGTH = 60;


const descriptionMessages = {
  [LISTING_TYPES.LISTING]: 'EditListingDescriptionForm.description',
  [LISTING_TYPES.ENQUIRY]: 'EditListingDescriptionForm.enquiryDescription',
  [LISTING_TYPES.COMPANY]: 'EditListingDescriptionForm.companyDescription',
  [LISTING_TYPES.CLASS]: 'EditListingDescriptionForm.description',
  [LISTING_TYPES.FACILITY]: 'EditListingDescriptionForm.facilityDescription',
};

const descriptionPlaceholderMessages = {
  [LISTING_TYPES.LISTING]: 'EditListingDescriptionForm.descriptionPlaceholder',
  [LISTING_TYPES.ENQUIRY]: 'EditListingDescriptionForm.enquiryDescriptionPlaceholder',
  [LISTING_TYPES.COMPANY]: 'EditListingDescriptionForm.companyDescriptionPlaceholder',
  [LISTING_TYPES.CLASS]: 'EditListingDescriptionForm.classDescriptionPlaceholder',
  [LISTING_TYPES.FACILITY]: 'EditListingDescriptionForm.facilityDescriptionPlaceholder',
};

class EditListingOverviewFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: props.category,
      subCategories: [],
      categoryBasedCustomFields: [],
    };
  }

  componentDidMount() {
    this.handleCategoryChange(this.props.category);
  }

  handleCategoryChange = category => {
    const categoriesConfig = config.custom.categories;
    const categoryBasedCustomFieldsConfig = config.custom.categoryBasedFields;
    const subCategoryConfig = category ? categoriesConfig.find(cc => cc.key === category) : [];
    const subCategories = (subCategoryConfig && subCategoryConfig.subCategories) || [];
    const CustomFieldsConfig =
      category !== undefined && category !== '' && subCategories.length
        ? categoryBasedCustomFieldsConfig.find(cc => cc.key === category).fields
        : [];
    this.setState({
      category: category,
      subCategories: subCategories,
      categoryBasedCustomFields: CustomFieldsConfig,
    });
  };

  render() {
    const { listingType } = this.props;

    const showCompanyName = [LISTING_TYPES.LISTING].some(type => listingType === type);
    const showBankDetails = [
      LISTING_TYPES.LISTING,
      LISTING_TYPES.CLASS,
      LISTING_TYPES.FACILITY,
    ].some(type => listingType === type);
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            className,
            disabled,
            ready,
            handleSubmit,
            intl,
            invalid,
            pristine,
            saveActionMsg,
            updated,
            updateInProgress,
            fetchErrors,
            values,
          } = formRenderProps;

          const otherLanguagesLength = values.other_languages?.length ?? 0;

          const maxLengthMessage = intl.formatMessage(
            { id: 'EditListingOverviewForm.maxLength' },
            {
              maxLength: TITLE_MAX_LENGTH,
            }
          );


          const { updateListingError, createListingDraftError, showListingsError } =
            fetchErrors || {};
          const errorMessageUpdateListing = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
            </p>
          ) : null;

          // This error happens only on first tab (of EditListingWizard)
          const errorMessageCreateListingDraft = createListingDraftError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
            </p>
          ) : null;

          const classes = classNames(css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;

          //Tagline
          const taglineLabel = intl.formatMessage({
            id: 'EditListingOverviewForm.taglineLabel',
          });
          const taglinePlaceholderMessage = intl.formatMessage({
            id: 'EditListingOverviewForm.taglinePlaceholder',
          });
          const taglineRequiredMessage = intl.formatMessage({
            id: 'EditListingOverviewForm.taglineRequired',
          });
          const taglineSupportMessage = intl.formatMessage({
            id: 'EditListingOverviewForm.taglineSupport',
          });
          const maxLengthTaglineMessage = maxLength(maxLengthMessage, TAGLINE_MAX_LENGTH);

          //summary
          const summaryLabel = intl.formatMessage({
            id: 'EditListingOverviewForm.summaryLabel',
          });
          const summaryPlaceholderMessage = intl.formatMessage({
            id: 'EditListingOverviewForm.summaryPlaceholder',
          });
          const summaryRequiredMessage = intl.formatMessage({
            id: 'EditListingOverviewForm.summaryRequired',
          });
          const summarySupportMessage = intl.formatMessage({
            id: 'EditListingOverviewForm.summarySupport',
          });
          const maxLengthSummaryMessage = maxLength(maxLengthMessage, SUMMARY_MAX_LENGTH);

          //detailed
          const detailedLabel = intl.formatMessage({
            id: 'EditListingOverviewForm.detailedLabel',
          });
          const detailedLabelWithSVG = (
            <span className={css.detailedLabelWithSVG}>
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16" fill="none">
                <path d="M0.251377 0.914844L12.7327 8.45608L0.541639 15.4946L0.251377 0.914844Z" fill="#00AB99" />
              </svg>
              <span>{detailedLabel}</span>
            </span>
          )
          const detailedPlaceholderMessage = intl.formatMessage({
            id: 'EditListingOverviewForm.detailedPlaceholder',
          });
          const detailedSupportMessage = intl.formatMessage({
            id: 'EditListingOverviewForm.detailedSupport',
          });
          const maxLengthDetailedMessage = maxLength(maxLengthMessage, DETAILED_MAX_LENGTH);

          //Interests
          const interestsLabel = intl.formatMessage({
            id: 'EditListingOverviewForm.interestsLabel',
          });
          const interestsSupportMessage = intl.formatMessage({
            id: 'EditListingOverviewForm.interestsSupport',
          });


          //Suggestion category
          const suggestionCategoryLabel = intl.formatMessage({
            id: 'EditListingOverviewForm.suggestionCategoryLabel',
          });
          const suggestionCategoryPlaceholderMessage = intl.formatMessage({
            id: 'EditListingOverviewForm.suggestionCategoryPlaceholder',
          });
          const suggestionCategoryRequiredMessage = intl.formatMessage({
            id: 'EditListingOverviewForm.suggestionCategoryRequired',
          });
          const suggestionCategorySupportMessage = intl.formatMessage({
            id: 'EditListingOverviewForm.suggestionCategorySupport',
          });

          //Primary language
          const selectPrimaryLanguageLabel = intl.formatMessage({
            id: 'EditListingOverviewForm.selectPrimaryLanguageLabel',
          });
          const primaryLanguagePlaceholderMessage = intl.formatMessage({
            id: 'EditListingOverviewForm.primaryLanguagePlaceholder',
          });
          const primaryLanguageRequiredMessage = intl.formatMessage({
            id: 'EditListingOverviewForm.primaryLanguageRequired',
          });
          const primaryLanguageSupportMessage = intl.formatMessage({
            id: 'EditListingOverviewForm.primaryLanguageSupport',
          });

          //Other languages
          const otherLanguagesLabel = intl.formatMessage({
            id: 'EditListingOverviewForm.otherLanguagesLabel',
          });
          const otherLanguagesPlaceholderMessage = intl.formatMessage({
            id: 'EditListingOverviewForm.otherLanguagesPlaceholder',
          });
          const otherLanguagesSupportMessage = intl.formatMessage({
            id: 'EditListingOverviewForm.otherLanguagesSupport',
          });

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.btnWrapper}>
                <div>
                  <h1 className={css.headingTitle}>
                    {OVERVIEW}
                  </h1>
                </div>
                <div className={css.topButtons}>
                  <div className={css.guideButton}>
                    <NamedLink name="TripExpertGettingStartedPage" className={css.link} target="_blank">
                      Help Getting Started
                    </NamedLink>
                  </div>
                  <Button
                    rootClassName={css.topSaveButton}
                    type="submit"
                    inProgress={false}
                    disabled={submitDisabled}
                    ready={submitReady}
                  >
                    {saveActionMsg}
                  </Button>
                </div>
              </div>
              {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing}


              <div className={css.formGroup}>
                <FieldTextInput
                  id="tagline"
                  name="tagline"
                  className={css.title}
                  type="text"
                  label={taglineLabel}
                  placeholder={taglinePlaceholderMessage}
                  maxLength={TAGLINE_MAX_LENGTH}
                  supportText={taglineSupportMessage}
                  validate={composeValidators(required(taglineRequiredMessage), maxLengthTaglineMessage)}
                />

                <FieldTextInput
                  id="summary"
                  name="summary"
                  className={css.summary}
                  type="textarea"
                  rows="5"
                  label={summaryLabel}
                  placeholder={summaryPlaceholderMessage}
                  maxLength={SUMMARY_MAX_LENGTH}
                  supportText={summarySupportMessage}
                  validate={composeValidators(required(summaryRequiredMessage), maxLengthSummaryMessage)}
                />

                {/* <FieldTextInput
                  id="detailed"
                  name="detailed"
                  className={css.title}
                  type="textarea"
                  label={detailedLabelWithSVG}
                  placeholder={detailedPlaceholderMessage}
                  maxLength={DETAILED_MAX_LENGTH}
                  supportText={detailedSupportMessage}
                  validate={maxLengthDetailedMessage}
                /> */}

                <HeadingFormFieldWrapper
                  label={interestsLabel}
                  supportText={interestsSupportMessage}
                >
                  {[...Array(5)].map((_, index) => {
                    return (
                      <FieldSelect
                        key={`interest_${index + 1}`}
                        className={css.customField}
                        type="select"
                        name={`interests[${index}]`}
                        id={`interest_${index + 1}`}
                      >
                        <option value="" disabled >
                          {intl.formatMessage(
                            { id: 'EditListingOverviewForm.interestsPlaceholder' },
                            {
                              count: index + 1,
                            }
                          )}
                        </option>
                        {interestsOptions.map(interest => (
                          <option key={interest.key} value={interest.key}>
                            {interest.label}
                          </option>
                        ))}
                      </FieldSelect>
                    )
                  })}
                </HeadingFormFieldWrapper>

                <FieldMultipleSelection
                  name="suggestion_category"
                  id="suggestion_category"
                  className={css.customField}
                  label={suggestionCategoryLabel}
                  supportText={suggestionCategorySupportMessage}
                  placeholder={suggestionCategoryPlaceholderMessage}
                  options={config.custom.suggestionCategoryOptions}
                  limit={10}
                />

                <FieldSelect
                  className={css.customField}
                  type="select"
                  name="primary_language"
                  id="primary_language"
                  label={selectPrimaryLanguageLabel}
                  supportText={primaryLanguageSupportMessage}
                  validate={composeValidators(
                    required(primaryLanguageRequiredMessage)
                  )}
                >
                  <option value="" disabled>{primaryLanguagePlaceholderMessage}</option>
                  {config.custom.languageFilterConfig.map(language => (
                    <option key={language.key} value={language.key}>
                      {language.label}
                    </option>
                  ))}
                </FieldSelect>

                <HeadingFormFieldWrapper
                  label={otherLanguagesLabel}
                  supportText={otherLanguagesSupportMessage}
                  >
                  {values.other_languages?.map((_, index) => (
                    <FieldSelect
                      key={`secondary_language_${index + 1}`}
                      className={css.customField}
                      type="select"
                      name={`other_languages[${index}]`}
                      id={`other_languages[${index}]`}
                      options={config.custom.languageFilterConfig}
                    >
                      <option value="">{otherLanguagesPlaceholderMessage}</option>
                      {config.custom.languageFilterConfig.map(language => (
                        <option key={language.key} value={language.key}>
                          {language.label}
                        </option>
                      ))}
                    </FieldSelect>
                  ))}
                  {otherLanguagesLength < 5 && <FieldSelect
                    className={css.customField}
                    type="select"
                    name={`other_languages[${otherLanguagesLength}]`}
                    id={`other_languages[${otherLanguagesLength}]`}
                    options={config.custom.languageFilterConfig}
                  >
                    <option value="">{otherLanguagesPlaceholderMessage}</option>
                    {config.custom.languageFilterConfig.map(language => (
                      <option key={language.key} value={language.key}>
                        {language.label}
                      </option>
                    ))}
                  </FieldSelect>}
                </HeadingFormFieldWrapper>
                <div id="descriptionSubmitButton" className={css.submitButtonWraper}>
                  <Button
                    rootClassName={css.submitButton}
                    type="submit"
                    inProgress={false}
                    disabled={submitDisabled}
                    ready={submitReady}
                  >
                    {saveActionMsg}
                  </Button>
                </div>
              </div>

            </Form>
          );
        }}
      />
    );
  }
}

EditListingOverviewFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingOverviewFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  listingType: string,
};

export default compose(injectIntl)(EditListingOverviewFormComponent);
