import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cns from 'classnames';
import { SmallScrollArrow, ScrollArrow } from 'components/index';

import css from 'components/HorizontalScroll/HorizontalScroll.css';

const getOffsetWidth = elem => {
  const styles = typeof window !== 'undefined' && window.getComputedStyle(elem);
  const innerOffsetWidth = elem.offsetWidth;
  const [marginLeft, marginRight] = [styles.marginLeft, styles.marginRight].map(value =>
    Number(value.split('px')[0])
  );
  console.log(innerOffsetWidth, marginLeft, marginRight);
  return innerOffsetWidth + marginLeft + marginRight;
};

const getTotalNumberOfListCard = () => {
  if (typeof window !== 'undefined') {
    if (window.screen.width < 870) {
      return 4;
    }
    else if (window.screen.width < 1270) {
      return 5;
    }
    else if (window.screen.width < 1480) {
      return 6;
    }
    else {
      return 6;
    };
  }
  else {
    return 6;
  };

};

function HorizontalScroll({ className, scrollClassName, arrowClassName, children, scrollArrowContainerClassNames, isSmallScrollArrow }) {
  const scrollContainerRef = useRef(null);
  const numberOfListcard = getTotalNumberOfListCard()
  const [currentCount, setCurrentcount] = useState(1)
  let count = 1;
  let totalCount = (children && (children.length - numberOfListcard)) || 1;
  if (totalCount < 1) {
    totalCount = 1;
  }

  const onScrollArrowClickLeft = useCallback(
    e => {
      if (!scrollContainerRef.current) return;
      if (count !== 1) {
        // eslint-disable-next-line
        count -= 1
        setCurrentcount(count)
        console.log('currentCount', currentCount)
        console.log('count', count)
      }
      const listingCard = scrollContainerRef.current.firstChild;
      const offset = getOffsetWidth(listingCard);
      console.log('offset', offset);

      scrollContainerRef.current.scrollLeft = scrollContainerRef.current.scrollLeft - offset;
    },
    [scrollContainerRef]
  );

  const onScrollArrowClickRight = useCallback(
    e => {
      if (!scrollContainerRef.current) return;
      if (count !== totalCount) {
        // eslint-disable-next-line
        count += 1
        setCurrentcount(count)
        console.log('currentCount', currentCount)
        console.log('count', count)
      }

      const listingCard = scrollContainerRef.current.firstChild;
      const offset = getOffsetWidth(listingCard);
      console.log('offset', offset);

      scrollContainerRef.current.scrollLeft = scrollContainerRef.current.scrollLeft + offset;
    },
    [scrollContainerRef]
  );

  const rootClassName = cns(css.root, className || '');
  const scrollContainerClassName = cns(css.scrollContainer, scrollClassName || '');
  const arrowClassNames = cns(css.scrollArrow, arrowClassName || '');
  const arrowContainerClassNames = cns(scrollArrowContainerClassNames);

  const scrollContent = isSmallScrollArrow ? (<>
    <div className={css.sectionCounter}>{currentCount}/{totalCount}</div>
    <SmallScrollArrow
      className={arrowClassNames}
      onClick={onScrollArrowClickLeft}
      direction={'left'}
      disable={currentCount === 1}
    />
    <SmallScrollArrow
      className={arrowClassNames}
      onClick={onScrollArrowClickRight}
      direction={'right'}
      disable={currentCount === totalCount}
    /></>)
    : (<>
      <ScrollArrow
        className={arrowClassNames}
        onClick={onScrollArrowClickLeft}
        direction={'left'}
      />
      <ScrollArrow
        className={arrowClassNames}
        onClick={onScrollArrowClickRight}
        direction={'right'}
      />
    </>)

  return (
    <div className={rootClassName}>
      <div ref={scrollContainerRef} className={scrollContainerClassName}>
        {children}
      </div>
      <div className={arrowContainerClassNames}>
        {scrollContent}
      </div>

    </div>
  );
}

HorizontalScroll.propTypes = {
  className: PropTypes.string,
  scrollClassName: PropTypes.string,
  arrowClassName: PropTypes.string,
  children: PropTypes.node,
};

export default HorizontalScroll;
