import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import css from './KeywordsFilter.module.css';

const KeywordsFilter = props => {
  const { currentQueryParams, handleFiltersChange } = props;

  const [keywords, setKeywords] = useState(currentQueryParams?.keywords);

  const keywordsEmpty = !keywords || keywords === '';

  const handleSubmit = () => {
    const newValue = {
      keywords: keywordsEmpty ? null : keywords,
    };
    handleFiltersChange(newValue);
  };
  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <div className={css.wrapper}>
      {/* <p className={css.label}>Search</p> */}
      <input
        // className={keywordsEmpty ? css.keywordsInputNotFilled : css.keywordsInputFilled}
        className={css.keywordsInputFilled}
        value={keywords}
        onChange={e => setKeywords(e.target.value)}
        placeholder={'Search keywords...'}
        onKeyDown={handleKeyDown}
      />
      {/* {!keywordsEmpty && ( */}
      <div className={css.applyButton} onClick={handleSubmit}>
        <SearchIcon className={css.searchIcon} />
      </div>
      {/* )} */}
    </div>
  );
};

export default KeywordsFilter;
