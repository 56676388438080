import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import {
  createSlug,
} from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import {
  InlineTextButton,
  ResponsiveImage,
} from '../../components';

import css from './WalletListingCard.css';
const MAX_LENGTH_FOR_WORDS_IN_TITLE = 7;
const formatTitle = (title, maxLength) => {
  const nonWhiteSpaceSequence = /([^\s]+)/gi;
  return title.split(nonWhiteSpaceSequence).map((word, index) => {
    return word.length > maxLength ? (
      <span key={index} style={{ wordBreak: 'break-all' }}>
        {word}
      </span>
    ) : (
      word
    );
  });
};

const createListingURL = (routes, listing) => {
  const id = listing.id.uuid;
  const slug = createSlug(listing.attributes.title);

  const linkProps =
  {
    name: 'ListingPage',
    params: { id, slug },
  };

  return createResourceLocatorString(linkProps.name, routes, linkProps.params, {});
};

export const WalletListingCardComponent = props => {
  const {
    className,
    rootClassName,
    history,
    listing,
    renderSizes,
    sessionsRemaining,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = listing;
  const { title = '' } = currentListing.attributes;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  return (
    <div className={classes}>
      <div
        className={css.threeToTwoWrapper}
        tabIndex={0}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          history.push(createListingURL(routeConfiguration(), listing));
        }}
      >
        <div className={css.aspectWrapper}>
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
        <div className={css.mainInfo}>
          <div className={css.titleWrapper}>
            <InlineTextButton
              rootClassName={css.title}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                history.push(createListingURL(routeConfiguration(), listing));
              }}
            >
              {formatTitle(title, MAX_LENGTH_FOR_WORDS_IN_TITLE)}
            </InlineTextButton>
          </div>
        </div>
        <div className={css.sessionsRemaining}>
          {sessionsRemaining && sessionsRemaining === 1 ?
            <FormattedMessage id="WalletListingCard.sessionsRemainingSingle" values={{ sessions: sessionsRemaining }} /> :
            <FormattedMessage id="WalletListingCard.sessionsRemainingPlural" values={{ sessions: sessionsRemaining }} />
          }
        </div>

      </div>
    </div>
  );
};

WalletListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
};

const { func, shape, string } = PropTypes;

WalletListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  // Responsive image sizes hint
  renderSizes: string,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(WalletListingCardComponent);
