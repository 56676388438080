import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import {
  denormalisedResponseEntities,
  entityRefs,
  getAllItems,
} from '../../util/data';
import { storableError } from '../../util/errors';
import {
  getTransitionsByState,
  TRANSITION_ENQUIRE,
} from 'util/transaction';
import config from 'config';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_COMPANY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_COMPANY_LISTINGS_REQUEST';
export const QUERY_COMPANY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_COMPANY_LISTINGS_SUCCESS';
export const QUERY_COMPANY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_COMPANY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const FETCH_BOOKINGS_FROM_CURRENT_USER_REQUEST =
  'app/ListingPage/FETCH_BOOKINGS_FROM_CURRENT_USER_REQUEST';
export const FETCH_BOOKINGS_FROM_CURRENT_USER_SUCCESS =
  'app/ListingPage/FETCH_BOOKINGS_FROM_CURRENT_USER_SUCCESS';
export const FETCH_BOOKINGS_FROM_CURRENT_USER_ERROR =
  'app/ListingPage/FETCH_BOOKINGS_FROM_CURRENT_USER_ERROR';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userListingRefs: [],
  companyListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,

  bookingsFromCurrentUser: [],
  bookingsFromCurrentUserIsLoading: false,
  bookingsFromCurrentUserError: null,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],

        queryListingsError: null,
      };
    case QUERY_COMPANY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userCompanyListingRefs: payload.userId === state.userId ? state.companyListingRefs : [],

        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs };
    case QUERY_COMPANY_LISTINGS_SUCCESS:
      return { ...state, companyListingRefs: payload.listingRefs };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload };
    case QUERY_COMPANY_LISTINGS_ERROR:
      return { ...state, companyListingRefs: [], queryListingsError: payload };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case FETCH_BOOKINGS_FROM_CURRENT_USER_REQUEST: {
      return { ...state, bookingsFromCurrentUserIsLoading: true };
    }
    case FETCH_BOOKINGS_FROM_CURRENT_USER_SUCCESS:
      return {
        ...state,
        bookingsFromCurrentUser: payload,
        bookingsFromCurrentUserIsLoading: false,
      };
    case FETCH_BOOKINGS_FROM_CURRENT_USER_ERROR:
      return {
        ...state,
        bookingsFromCurrentUserError: payload,
        bookingsFromCurrentUserIsLoading: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryCompanyListingsRequest = userId => ({
  type: QUERY_COMPANY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryCompanyListingsSuccess = listingRefs => ({
  type: QUERY_COMPANY_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryCompanyListingsError = e => ({
  type: QUERY_COMPANY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const fetchBookingsFromCurrentUserRequest = () => ({
  type: FETCH_BOOKINGS_FROM_CURRENT_USER_REQUEST,
});
export const fetchBookingsFromCurrentUserSuccess = payload => ({
  type: FETCH_BOOKINGS_FROM_CURRENT_USER_SUCCESS,
  payload,
});
export const fetchBookingsFromCurrentUserError = e => ({
  type: FETCH_BOOKINGS_FROM_CURRENT_USER_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryUserListings = userId => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId));
  return sdk.listings
    .query({
      author_id: userId,
      include: ['author', 'images'],
      'fields.image': [
        // Listing page
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Image carousel
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(listingRefs));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryCompanyListings = userId => async (dispatch, getState, sdk) => {
  dispatch(queryCompanyListingsRequest(userId));

  try {
    const user = await sdk.users.show({
      id: userId,
    });

    const { companyName } = user.data.data.attributes.profile.publicData;

    if (!companyName) {
      return Promise.resolve(null);
    }

    const query = {
      pub_companyName: companyName,
      include: ['author', 'images'],
      'fields.image': [
        // Listing page
        'variants.landscape-crop',
        'variants.landscape-crop2x',
        'variants.landscape-crop4x',
        'variants.landscape-crop6x',

        // Social media
        'variants.facebook',
        'variants.twitter',

        // Image carousel
        'variants.scaled-small',
        'variants.scaled-medium',
        'variants.scaled-large',
        'variants.scaled-xlarge',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    };

    const companyListings = await sdk.listings.query(query);

    const companyListingRefs = companyListings.data.data.map(({ id, type }) => ({ id, type }));
    dispatch(addMarketplaceEntities(companyListings));
    dispatch(queryCompanyListingsSuccess(companyListingRefs));

    return companyListings;
  } catch (e) {
    dispatch(queryCompanyListingsError(storableError(e)));
  }
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const sendEnquiry = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.bookingProcessAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendEnquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

export const fetchBookingsFromCurrentUser = (userId, status) => async (dispatch, getState, sdk) => {
  dispatch(fetchBookingsFromCurrentUserRequest());

  try {
    const responses = await getAllItems(sdk.transactions.query, {
      only: 'sale',
      lastTransitions: getTransitionsByState(status),
      include: ['provider', 'customer', 'booking', 'listing'],
    });

    const refs = responses.reduce((acc, res) => [...acc, ...entityRefs(res.data.data)], []);

    dispatch(fetchBookingsFromCurrentUserSuccess(refs));
    responses.forEach(res => dispatch(addMarketplaceEntities(res)));
  } catch (e) {
    dispatch(fetchBookingsFromCurrentUserError(storableError(e)));
    throw e;
  }
};

export const loadData = userId => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    dispatch(queryUserListings(userId)),
    dispatch(queryCompanyListings(userId)),
    dispatch(queryUserReviews(userId)),
    // dispatch(fetchBookingsFromCurrentUser(userId)),
  ]);
};
