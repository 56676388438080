import {
  TRANSITION_ENQUIRE,
  TRANSITION_CANCEL_ENQUIRY_BY_PROVIDER,
  TRANSITION_EXPIRE_ENQUIRY_2,
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_EXPIRE_PAYMENT,
  TRANSITION_OP_CANCEL_ORDER,
  TRANSITION_OP_REVIEW_1_BY_CUSTOMER,
  TRANSITION_OP_REVIEW_1_BY_PROVIDER,
  TRANSITION_OP_REVIEW_2_BY_CUSTOMER,
  TRANSITION_OP_REVIEW_2_BY_PROVIDER,
  TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
  TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
} from './transaction.js';

// Maps of old transitions to their current equivalents.
const transitionMaps = {
  'flex-product-default-process/release-1': {
    'transition/charge-customer': TRANSITION_CONFIRM_PAYMENT,
    'transition/request-payment': TRANSITION_REQUEST_PAYMENT,
    'transition/expire-payment': TRANSITION_EXPIRE_PAYMENT,
    'transition/confirm-payment': TRANSITION_ENQUIRE,
    'transition/confirm-payment-by-operator': TRANSITION_ENQUIRE,
    'transition/decline': TRANSITION_CANCEL_ENQUIRY_BY_PROVIDER,
    /** 
     README: 
     * transition/expire-request-period was intended to happen after the payment in the previous release-1 transaction model  
     * and it would affect how to explain the reason of the reversal amount (Refund or Cancelled) so use transition/op-cancel-order instead. 
     */
    'transition/expire-request-period': TRANSITION_OP_CANCEL_ORDER, 
    'transition/request-accept': TRANSITION_CONFIRM_PAYMENT,
    'transition/payment-capture-fail': TRANSITION_EXPIRE_ENQUIRY_2,
    'transition/accept': TRANSITION_CONFIRM_PAYMENT,
    'transition/request-extend-delivery-period': TRANSITION_CONFIRM_PAYMENT,
    'transition/op-request-extend-delivery-period': TRANSITION_CONFIRM_PAYMENT,
    'transition/accept-extend-delivery-period': TRANSITION_CONFIRM_PAYMENT,
    'transition/op-accept-extend-delivery-period': TRANSITION_CONFIRM_PAYMENT,
    'transition/auto-request-extend-delivery-period': TRANSITION_CONFIRM_PAYMENT,
    'transition/set-delivered': TRANSITION_CONFIRM_PAYMENT,
    'transition/op-set-delivered': TRANSITION_CONFIRM_PAYMENT,
    'transition/decline-extend-delivery-period': TRANSITION_OP_CANCEL_ORDER,
    'transition/op-decline-extend-delivery-period': TRANSITION_OP_CANCEL_ORDER,
    'transition/expired-from-accepted-1': TRANSITION_OP_CANCEL_ORDER,
    'transition/request-changes': TRANSITION_CONFIRM_PAYMENT,
    'transition/op-request-changes': TRANSITION_CONFIRM_PAYMENT,
    'transition/mark-delivered-changes': TRANSITION_CONFIRM_PAYMENT,
    'transition/op-mark-delivered-changes': TRANSITION_CONFIRM_PAYMENT,
    'transition/complete': TRANSITION_CONFIRM_PAYMENT,
    'transition/op-complete': TRANSITION_CONFIRM_PAYMENT,
    'transition/expired-from-delivered-1': TRANSITION_CONFIRM_PAYMENT,
    'transition/review-1-by-customer': TRANSITION_OP_REVIEW_1_BY_CUSTOMER,
    'transition/op-review-1-by-customer': TRANSITION_OP_REVIEW_1_BY_CUSTOMER,
    'transition/review-1-by-provider': TRANSITION_OP_REVIEW_1_BY_PROVIDER,
    'transition/op-review-1-by-provider': TRANSITION_OP_REVIEW_1_BY_PROVIDER,
    'transition/expire-provider-review-period': TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD,
    'transition/review-2-by-customer': TRANSITION_OP_REVIEW_2_BY_CUSTOMER,
    'transition/op-review-2-by-customer': TRANSITION_OP_REVIEW_2_BY_CUSTOMER,
    'transition/review-2-by-provider': TRANSITION_OP_REVIEW_2_BY_PROVIDER,
    'transition/op-review-2-by-provider': TRANSITION_OP_REVIEW_2_BY_PROVIDER,
    'transition/expire-customer-review-period': TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
    'transition/expire-review-period': TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD,
  }
}

const deliverMatchOldTransitions = [
  'transition/complete',
  'transition/op-complete',
  'transition/expired-from-delivered-1',
];

class WrappedTransaction {
  constructor({transaction, transitionMap}) {
    // Store the original transaction object without modifications.
    this._rawTransaction = transaction;
    this._transitionMap = transitionMap;


    // Note: using getters instead of copying these properties didn't work.
    //   There seemed to be some incompatibility with other processing, most
    //   likely denormalisedEntities of src/util/data.js.
    this.id = this._rawTransaction.id;
    this.type = this._rawTransaction.type;
    this.relationships = this._rawTransaction.relationships;
    // Setup up nested object structure.
    this.attributes = {};

    // Setup pass through accessors (getters).
    const passThroughAttributes = [
      'createdAt',
      'processName',
      'processVersion',
      'lastTransitionedAt',
      'lineItems',
      'payinTotal',
      'payoutTotal',
      'protectedData',
      'metadata',
    ];
    passThroughAttributes.forEach((field) => {
      this.attributes[field] = transaction.attributes[field];
    });

    // Setup transformed transition fields.
    this.attributes.lastTransition = transitionMap[
      transaction.attributes.lastTransition
    ];

    // Setup metadata workflowStatus & workflowEvents before mapping transitions
    this._updateMetadata(transaction);

    this.attributes.transitions = transaction
      .attributes
      .transitions
      .map((transition) => ({
        transition: transitionMap[transition.transition],
        createdAt: transition.createdAt,
        by: transition.by,
      }));
  }

  _updateMetadata(transaction) {
    const { transitions = [] } = transaction.attributes;
    const deliverMatchTransition = transitions.find(transition => 
      deliverMatchOldTransitions.includes(transition.transition));
    if (!!deliverMatchTransition) {
      this.attributes.metadata.workflowStatus = 'delivered';
      this.attributes.metadata.workflowEvents = [
        {
          time: deliverMatchTransition.createdAt,
          type: 'delivered',
        }
      ]
    }
  }
}

/**
 * Return the given transaction object optionally transformed to be compatible
 * with the current Shretatribe process model.
 *
 * Note: this compatibility layer is read only. It doesn't support new
 * transitions on old transactions.
 *
 * If the given transaction is already using the current model then it's
 * returned without change.
 */
export function compatibleTransaction(transaction) {
  // Detect process model of transaction.
  // Note: we are using process alias as a version ID and storing this in
  //   transaction metadata because the processVersion value is inconsistent
  //   across environments.
  const version = transaction.attributes.metadata?.processAlias;
  if (version === undefined) {
    throw new Error(`Failed to get transaction process version`);
  }
  if (
    version === 'flex-product-default-process/release-2'
    || transaction._rawTransaction !== undefined
  ) {
    return transaction;
  }
  // Create the wrapper.
  const transitionMap = transitionMaps[version];
  if (!transitionMap) {
    throw new Error(`Unsupported transition map version: ${version}`);
  }
  return new WrappedTransaction({transaction, transitionMap});

}

