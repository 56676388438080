import React from 'react';
import css from './LandingPageTripExpertSection.css';
import { FormattedMessage } from '../../util/reactIntl';
import SpecialistKnowledge_Icon from '../../assets/Landing_Pages/TripExpert/SpecialistKnowledge_Icon.svg';
import Msg from '../../assets/Landing_Pages/TripExpert/Msg_Icon.svg';
import smile_2 from '../../assets/Landing_Pages/TripExpert/Smile_2_Icon.svg';
import MoneySaving_Icon from '../../assets/Landing_Pages/TripExpert/MoneySaving_Icon.svg';

const LandingPageTripExpertSection = ({ title }) => {
  const TripExpertList = [
    {
      id: 'Specialist Knowledge',
      src: SpecialistKnowledge_Icon,
      title: 'TripExpertSection.specialistKnowledge',
      description: 'TripExpertSection.specialistKnowledgeText',
    },
    {
      id: 'Simple Communication',
      src: Msg,
      title: 'TripExpertSection.simpleCommunication',
      description: 'TripExpertSection.simpleCommunicationText',
    },
    {
      id: 'Money Saving',
      src: smile_2,
      title: 'TripExpertSection.time&MoneySaving',
      description: 'TripExpertSection.time&MoneySavingText',
    },
    {
      id: 'Trust',
      src: MoneySaving_Icon,
      title: 'TripExpertSection.trust',
      description: 'TripExpertSection.trustText',
    },
  ];

  return (
    <div>
      {title && (
        <div className={css.title}>
          <FormattedMessage id="TripExpertSection.title" />
        </div>
      )}
      <div className={css.TripExpertListContainer}>
        {TripExpertList.map(data => (
          <div id={data.id} className={css.ListWrapper} key={data.id}>
            <div className={css.imageContainer}>
              <img src={data.src} alt={data.id} />
            </div>
            <div className={css.DetailsContainer}>
              <h3>
                <FormattedMessage id={data.title} />
              </h3>
              <FormattedMessage id={data.description} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LandingPageTripExpertSection;
