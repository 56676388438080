export const sortImagesFunction = images => {
  const backgroundImage = images.shift();
  images.push(backgroundImage);
  return images;
};

export const limitTextLength = (text, limit) => {
  if (!text || !limit) {
    return null;
  }
  if (text.length > limit) {
    const newText = text.slice(0, limit - 3);
    return newText + '...';
  }

  return text;
};

export const validateStringLength = (str, maxLength = 30) => {
  if (typeof str === 'string' && str.length < maxLength) {
    return str;
  } else {
    return '';
  }
};
