import React, { useEffect, useState } from 'react';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
} from '../../components';
import { injectIntl } from '../../util/reactIntl';

import TransactionsTable from './components/TransactionsTable/TransactionsTable';
import css from './PartnerOrdersPage.css';
import { post } from '../../util/api';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

const sharetribeSdk = require('sharetribe-flex-sdk');

const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const PartnerOrdersPageComponent = props => {
  const { intl } = props;
  // const [transactions, setTransactions] = useState([]);
  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   setLoading(true);
  //   sdk.currentUser
  //     .show()
  //     .then(resp => {
  //       const userId = resp.data.data.id.uuid;
  //       return post('/api/query-txs-for-partner', { userId })
  //         .then(resp => {
  //           if (resp && resp.length > 0) {
  //             setTransactions(resp);
  //           }
  //           setLoading(false);
  //         })
  //         .catch(e => console.log(e));
  //     })
  //     .catch(e => console.log(e));
  // }, []);

  return (
    <StaticPage
      title="Orders Page"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'PartnerOrdersPage',
        description: 'Orders Page',
        name: 'Orders Page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div style={{ textAlign: 'center', margin: '40px' }}>
            <p style={{ fontWeight: 'bold' }}>Coming Soon</p>
            <p>Aggregated customer order view and reporting.</p>
          </div>
          {/* {loading ? (
            <center>
              <IconSpinner />
            </center>
          ) : (
            <TransactionsTable transactions={transactions} intl={intl} />
          )} */}
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const PartnerOrdersPage = compose(withRouter, injectIntl)(PartnerOrdersPageComponent);

export default PartnerOrdersPage;
