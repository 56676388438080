import React from 'react';
import css from '../ListingDetailsSectionMainTab.css';
import ListingDetailsSectionSubTabs from './ListingDetailsSectionSubTabs';
import ShowMore from 'react-show-more';
import classNames from 'classnames';
import StarRatings from 'react-star-ratings';
import Triangle_Orange from '../../../assets/Listing_Page/Triangle_Orange.png';
import { continents } from 'marketplace-custom-config';
import { context2Location } from 'util/location';
import config from 'config';

const MainTabSectionDetailsContent = props => {
  const { rating, authorDetails, id, photosSection, videosSection, ...rest } = props;
  const currentListing = props.currentListing;
  const tagline = currentListing?.attributes?.publicData?.tagline;
  const content = currentListing?.attributes?.description;
  const publicData = currentListing?.attributes?.publicData;

  const context = publicData?.location?.context;
  const address = publicData?.location?.address;
  const currentLocation = context2Location(context) || address || 'undefined';

  const specialisations = publicData?.suggestion_category
    ? publicData?.suggestion_category.filter(s => typeof s === 'string')
    : [];

  const { primary_language, other_languages = [], interests = [] } = publicData;

  const primaryLanugage = config.custom.languageFilterConfig
    .filter(({ key }) => key === primary_language)
    .map(({ label }) => label);

  const otherLanguages = config.custom.languageFilterConfig
    .filter(({ key }) => other_languages.includes(key))
    .map(({ label }) => label);
  const specialisationList = [
    {
      id: 'primary_language',
      title: 'Primary Language',
      details: primaryLanugage,
    },
    {
      id: 'other_languages',
      title: 'Other Languages',
      details: otherLanguages,
    },
    {
      id: 'specialising',
      title: 'Trip Type Specialities',
      details: specialisations.map(i => {
        i = i.replace(/['_']/g, ' ');
        return i;
      }),
    },
    {
      id: 'interests',
      title: 'Interests',
      details: interests,
    },
  ];

  const locationList = [
    {
      id: 'living_In',
      title: 'Living In',
      details: [currentLocation],
    },
    {
      id: 'nationality',
      title: 'Born In',
      details: [publicData?.nationality?.search],
    },
  ];

  const specialistPlanningList = [
    // {
    //   id: 'favourite_continent',
    //   title: 'Favourite Continent',
    //   details: [continents.filter(continent => continent.key == publicData?.continent)[0].label],
    // },
    {
      id: 'countries',
      title: 'Countries',
      details:
        typeof publicData?.countries === 'string'
          ? publicData?.countries?.split(':')
          : publicData?.countries?.filter(Boolean),
    },
    {
      id: 'places',
      title: 'Places',
      details: publicData?.places.split(':'),
    },
  ];

  return (
    <div className={css.mainTitle}>
      {id === 'overview' && (
        <>
          <h3>{tagline}</h3>
          <div className={css.mainContentWrap}>
            {rating && (
              <div className={css.starContent}>
                <span className={classNames(css.ratingWrapper, { [css.filledRating]: rating })}>
                  <StarRatings
                    rating={rating}
                    starRatedColor="#00bfe6"
                    starEmptyColor="#fff"
                    numberOfStars={1}
                    starDimension="20px"
                    starSpacing="0px"
                  />
                  <span className={css.reviewCount}>{rating} Reviews</span>
                </span>
              </div>
            )}
          </div>
          <div className={css.descriptionFontStyle}>
            <ShowMore
              className={css.msg}
              lines={15}
              more={<div className={css.seeMoreLessLink}>More Details...</div>}
              less={<div className={css.seeMoreLessLink}>Less Details</div>}
              anchorClass=""
            >
              <span className={css.msg}>{content}</span>
            </ShowMore>
          </div>
          <div className={css.specializationWrapper}>
            {specialisationList &&
              specialisationList.map(list => {
                let count = list.details.length;
                return (
                  count > 0 && (
                    <li
                      className={count > 1 ? css.columnDirection : css.rowDirection}
                      key={list.id}
                    >
                      <div className={css.verticalAlignCenter}>
                        <div className={css.verticalAlignCenter}>
                          <img src={Triangle_Orange} alt={list.id} />
                        </div>
                        <h5>{list.title} :</h5>
                      </div>
                      <div className={css.wrapper}>
                        {list.details.map(c => (
                          <div className={css.chip} key={c}>
                            {c}
                          </div>
                        ))}
                      </div>
                    </li>
                  )
                );
              })}
          </div>
        </>
      )}

      {id === 'locations' && (
        <div className={css.specializationWrapper}>
          <h2>Locations </h2>

          {locationList &&
            locationList.map(list => {
              let count = list.details.length;
              return (
                count > 0 && (
                  <li className={count > 1 ? css.columnDirection : css.rowDirection} key={list.id}>
                    <div className={css.verticalAlignCenter}>
                      <div className={css.verticalAlignCenter}>
                        <img src={Triangle_Orange} alt={list.id} />
                      </div>
                      <h5>{list.title} :</h5>
                    </div>
                    <div className={css.wrapper}>
                      {list.details.map(c => (
                        <div className={css.chip} key={c}>
                          {c}
                        </div>
                      ))}
                    </div>
                  </li>
                )
              );
            })}

          <h4>Specialist Planning Regions</h4>

          {specialistPlanningList &&
            specialistPlanningList.map(list => {
              let count = list.details.length;
              return (
                count > 0 && (
                  <li className={count > 1 ? css.columnDirection : css.rowDirection} key={list.id}>
                    <div className={css.verticalAlignCenter}>
                      <div className={css.verticalAlignCenter}>
                        <img src={Triangle_Orange} alt={list.id} />
                      </div>
                      <h5>{list.title} :</h5>
                    </div>
                    <div className={css.wrapper}>
                      {list.details.map(c => (
                        <div className={css.chip} key={c}>
                          {c}
                        </div>
                      ))}
                    </div>
                  </li>
                )
              );
            })}
        </div>
      )}

      {id === 'gallery' && (
        <>
          {photosSection}
          {videosSection}
        </>
      )}

      {id === 'pricing' && (
        <ListingDetailsSectionSubTabs id={id} authorDetails={authorDetails} {...rest} />
      )}
    </div>
  );
};

export default MainTabSectionDetailsContent;
