import React, { useState, useMemo, useEffect } from 'react';
import {
  IconSpinner,
  Button,
  PaginationLinks,
} from 'components';
import RecentTable from '../RecentTable/RecentTable';
import { parse } from '../../../../util/urlHelpers';
import css from './SchedulePageContent.css';

function SchedulePageContent({
  intl,
  transactions = [],
  recentTransactions = [],
  isLoading,
  params,
  onSelect,
  history,
  selectedBookings,
  onAllSelectedSchedule,
  pagination,
}) {
  /* eslint-disable-next-line */
  const [keyWordValue, setKeyWordValue] = useState('');
  const [filteredTransactions, setFilteredTransactions] = useState(recentTransactions);
  const { tab } = params;
  const urlQueryParams = parse(history.location.search);

  useEffect(() => {
    setFilteredTransactions(recentTransactions);
  }, [recentTransactions]);

  const content = useMemo(() => {
    if (tab === 'myClients' && !recentTransactions.length)
      return <div className={css.tablePlaceholder}>No Results</div>;
    if (tab === 'enquiry-client' && !recentTransactions.length)
      return <div className={css.tablePlaceholder}>No Results</div>;

    switch (tab) {
      case 'myClients':
        return <RecentTable intl={intl} data={filteredTransactions} urlQueryParams={urlQueryParams} selectedBookings={selectedBookings} onSelect={onSelect} onAllSelectedClient={onAllSelectedSchedule} />;
      case 'enquiry-client':
        return <RecentTable intl={intl} data={filteredTransactions} urlQueryParams={urlQueryParams} selectedBookings={selectedBookings} onSelect={onSelect} onAllSelectedClient={onAllSelectedSchedule} />;
      default:
        return null;
    }
    // eslint-disable-next-line
  }, [filteredTransactions, tab]);

  const applyButtonText = intl.formatMessage({ id: 'SchedulePageContent.applyButton' })
  const keywordPlaceholder = intl.formatMessage({ id: 'SchedulePageContent.searchByName' });

  const handleFilter = (values) => {
    const filteredTx = recentTransactions.filter(transactions => {
      const customerDetails = transactions.customer.attributes.profile;
      return customerDetails.displayName.toLowerCase().includes(values.toLowerCase()) || customerDetails.publicData.userName.toLowerCase().includes(values.toLowerCase());
    });
    setFilteredTransactions(filteredTx);
  };

  const filterKeywordContent = (<div className={css.filterKeywordWrap}>
    <div className={css.filterWrap}>
      <div className={css.inputWrap}>
        <input id={'SearchFilters.keywordFilter'} value={keyWordValue} placeholder={keywordPlaceholder} onChange={(e) => setKeyWordValue(e.target.value)} />
      </div>
      <Button className={css.btnapply} onClick={() => handleFilter(keyWordValue)}>{applyButtonText}</Button>
    </div>
  </div>);

  const subHeading = useMemo(() => {
    switch (tab) {
      case 'myClients':
        return 'Customers';
      default:
        return '';
    }
  }, [tab]);

  return (<>
    <div className={css.heading}>
      <div className={css.subHeadingWrapper}>
        <h3 className={css.subHeading} >{subHeading}</h3>
        {tab === 'myClients' || 'enquirt-client' ? filterKeywordContent : null}
      </div>
    </div>
    {isLoading ? <IconSpinner className={css.spinner} /> : content}

    {tab === 'myClients' && pagination &&
      pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="InboxPage"
        pagePathParams={params}
        pagination={pagination}
      />) : null}

  </>
  );
}

SchedulePageContent.propTypes = {};

export default SchedulePageContent;

