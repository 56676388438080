import React, { useState } from 'react';
import cns from 'classnames';
import PropTypes from 'prop-types';
import css from './PlainTabs.css';

function PlainTabs(props) {
  const { data, menuListClass } = props;
  const [activeTab, setActiveTab] = useState(data[0]?.id);

  const menu = data.map(i => ({ label: i.label, id: i.id, price: i.price }));
  const content = data.map(i => ({ id: i.id, content: i.content }));

  return (
    <div>
      <div className={cns(css.tabLabels, menuListClass || '')}>
        {menu.map(item => (
          <div
            key={item.id}
            className={cns(css.tabLabelItem, activeTab === item.id && css.tabLabelItemActive)}
            onClick={() => setActiveTab(item.id)}
          >
            {item.label}
            <div className={css.priceInfo}>{item.price}</div>
          </div>
        ))}
      </div>
      <div>
        {content.map(item => (
          <div key={item.id} style={{ display: activeTab === item.id ? 'block' : 'none' }}>
            {item.content}
          </div>
        ))}
      </div>
    </div>
  );
}

PlainTabs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      content: PropTypes.oneOfType([PropTypes.element, PropTypes.oneOf([null])]),
      price: PropTypes.string,
    })
  ).isRequired,
  menuListClass: PropTypes.string,
};

PlainTabs.defaultProps = {
  menuListClass: '',
};

export default PlainTabs;
