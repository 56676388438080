import React from 'react';

export const IconExplore = props => {
    const { className } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width="31" height="31" viewBox="0 0 31 31" fill="none">
              <path d="M26.5127 25.4655C26.5127 25.5318 26.4864 25.5954 26.4395 25.6423L25.4934 26.5884C25.4465 26.6353 25.3829 26.6616 25.3166 26.6616C25.2502 26.6616 25.1867 26.6353 25.1398 26.5884L22.4321 23.8801C21.3544 24.6822 20.0446 25.1163 18.6972 25.1144M26.5127 25.4655L24.9655 18.8461C24.9653 15.3864 22.1574 12.5786 18.6976 12.5786C15.2376 12.5786 12.4297 15.3866 12.4297 18.8465C12.4297 22.3063 15.2374 25.1142 18.6972 25.1144M26.5127 25.4655C26.5127 25.3992 26.4864 25.3356 26.4395 25.2887M26.5127 25.4655L26.4395 25.2887M18.6972 25.1144L18.6976 24.8644V25.1144C18.6975 25.1144 18.6973 25.1144 18.6972 25.1144ZM26.4395 25.2887L23.7312 22.581M26.4395 25.2887L23.7312 22.581M23.7312 22.581C24.5332 21.5034 24.9673 20.1938 24.9655 18.8465L23.7312 22.581ZM23.1281 18.8465V18.8469C23.13 19.9983 22.6819 21.1049 21.8795 21.9307L21.7817 22.0284C20.956 22.8308 19.8494 23.2789 18.698 23.2771H18.6976C16.2493 23.2771 14.267 21.2941 14.267 18.8465C14.267 16.3982 16.2493 14.4159 18.6976 14.4159C21.1452 14.4159 23.1281 16.3982 23.1281 18.8465Z" fill="#898989" stroke="#898989" strokeWidth="0.5" strokeLinejoin="round" />
              <path fillRule="evenodd" clipRule="evenodd" d="M5.125 4.52832C4.29657 4.52832 3.625 5.19989 3.625 6.02832V12.0837C3.625 12.9121 4.29657 13.5837 5.125 13.5837H11.1804C11.2433 13.5837 11.3054 13.5798 11.3663 13.5723C11.7506 13.0397 12.1915 12.5505 12.6801 12.1137C12.6803 12.1037 12.6804 12.0937 12.6804 12.0837V6.02832C12.6804 5.19989 12.0088 4.52832 11.1804 4.52832H5.125ZM24.0003 11.5128C22.5128 10.4416 20.6871 9.81052 18.714 9.81052C17.369 9.81052 16.0925 10.1038 14.9449 10.6298V6.02832C14.9449 5.19989 15.6165 4.52832 16.4449 4.52832H22.5003C23.3287 4.52832 24.0003 5.19989 24.0003 6.02832V11.5128ZM10.1738 15.8478C9.84018 16.7918 9.65865 17.8076 9.65865 18.8659C9.65865 21.1139 10.4778 23.1705 11.8339 24.7537C11.6363 24.8495 11.4146 24.9032 11.1804 24.9032H5.125C4.29657 24.9032 3.625 24.2316 3.625 23.4032V17.3478C3.625 16.5194 4.29657 15.8478 5.125 15.8478H10.1738Z" fill="#898989" />
            </svg>
    );
};

export const IconClient = props => {
    const { className } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width="31" height="31" viewBox="0 0 31 31" fill="none">
              <path d="M17.0053 17.0087C18.1412 17.0087 19.1248 16.6013 19.9285 15.7975C20.7322 14.9938 21.1396 14.0104 21.1396 12.8743C21.1396 11.7387 20.7322 10.7552 19.9284 9.95121C19.1246 9.14765 18.1411 8.74023 17.0053 8.74023C15.8693 8.74023 14.886 9.14765 14.0823 9.95134C13.2786 10.755 12.8711 11.7385 12.8711 12.8743C12.8711 14.0104 13.2786 14.9939 14.0824 15.7976C14.8862 16.6012 15.8697 17.0087 17.0053 17.0087Z" fill="#898989" />
              <path d="M24.4097 22.4963C24.3856 22.1223 24.3369 21.7143 24.2651 21.2835C24.1927 20.8495 24.0995 20.4392 23.9879 20.0642C23.8726 19.6766 23.7158 19.2938 23.522 18.927C23.3209 18.5463 23.0846 18.2148 22.8195 17.942C22.5422 17.6566 22.2028 17.4271 21.8103 17.2598C21.4191 17.0933 20.9856 17.0089 20.5219 17.0089C20.3398 17.0089 20.1637 17.0893 19.8236 17.3276C19.6143 17.4744 19.3694 17.6443 19.0961 17.8322C18.8625 17.9924 18.5459 18.1425 18.1549 18.2783C17.7734 18.4112 17.386 18.4785 17.0037 18.4785C16.6214 18.4785 16.2342 18.4112 15.8523 18.2783C15.4617 18.1426 15.1451 17.9925 14.9117 17.8323C14.641 17.6462 14.396 17.4763 14.1836 17.3274C13.8438 17.0892 13.6676 17.0088 13.4855 17.0088C13.0217 17.0088 12.5883 17.0933 12.1973 17.2599C11.805 17.427 11.4655 17.6565 11.188 17.9421C10.923 18.2151 10.6866 18.5465 10.4857 18.927C10.292 19.2938 10.1352 19.6765 10.0198 20.0644C9.90833 20.4394 9.8151 20.8495 9.7427 21.2835C9.67097 21.7138 9.62225 22.1219 9.59816 22.4967C9.57448 22.864 9.5625 23.2451 9.5625 23.6301C9.5625 24.632 9.85852 25.4431 10.4423 26.0412C11.0188 26.6315 11.7816 26.931 12.7093 26.931H21.2989C22.2266 26.931 22.9892 26.6316 23.5659 26.0412C24.1497 25.4435 24.4457 24.6323 24.4457 23.63C24.4456 23.2432 24.4335 22.8618 24.4097 22.4963Z" fill="#898989" />
              <path d="M12.1848 6.26003C13.5628 7.36249 15.3558 7.6381 15.3558 7.6381C15.3558 7.6381 13.1494 8.32714 12.1848 9.2918C11.2201 10.2565 10.3947 12.8748 10.3947 12.8748C10.3947 12.8748 9.98125 10.3943 8.74098 9.15399C7.50071 7.91372 5.43359 7.6381 5.43359 7.6381C5.43359 7.6381 7.63852 7.36249 8.74098 6.26003C9.84344 5.15756 10.3947 2.95264 10.3947 2.95264C10.3947 2.95264 10.8067 5.15756 12.1848 6.26003Z" fill="#898989" />
            </svg>
    );
};

export const IconGetMatched = props => {
    const { className } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className}
            width="25" height="28" viewBox="0 0 25 28" fill="none">
            <path d="M17.0103 8.33333C20.4826 11.1111 25 11.8056 25 11.8056C25 11.8056 19.4409 13.5417 17.0103 15.9722C14.5798 18.4028 12.5 25 12.5 25C12.5 25 11.4583 18.75 8.33333 15.625C5.20833 12.5 0 11.8056 0 11.8056C0 11.8056 5.55556 11.1111 8.33333 8.33333C11.1111 5.55556 12.5 0 12.5 0C12.5 0 13.5381 5.55556 17.0103 8.33333Z" fill="#898989" />
        </svg>
    );
};

export const IconVendor = props => {
    const { className } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width="31" height="31" viewBox="0 0 31 31" fill="none">
              <path d="M9.67648 5.43359H4.43017C3.48646 5.43359 2.71875 6.20131 2.71875 7.14501V12.3913C2.71875 13.335 3.48646 14.1027 4.43017 14.1027H9.67648C10.6202 14.1027 11.3879 13.335 11.3879 12.3913V7.14501C11.3879 6.20131 10.6202 5.43359 9.67648 5.43359Z" fill="#898989" />
              <path d="M5.25 9.89818L6.16974 10.8467L8.62237 8.31738" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M26.0527 8.7521H15.7581C15.141 8.7521 14.6406 8.25174 14.6406 7.6346C14.6406 7.01745 15.141 6.51709 15.7581 6.51709H26.0527C26.6699 6.51709 27.1703 7.01745 27.1703 7.6346C27.1703 8.25174 26.6699 8.7521 26.0527 8.7521Z" fill="#898989" />
              <path d="M19.9065 13.0871H15.7581C15.141 13.0871 14.6406 12.5867 14.6406 11.9696C14.6406 11.3524 15.141 10.8521 15.7581 10.8521H19.9065C20.5236 10.8521 21.024 11.3524 21.024 11.9696C21.024 12.5867 20.5236 13.0871 19.9065 13.0871Z" fill="#898989" />
              <path d="M9.67648 16.749H4.43017C3.48646 16.749 2.71875 17.5167 2.71875 18.4604V23.7068C2.71875 24.6505 3.48646 25.4182 4.43017 25.4182H9.67648C10.6202 25.4182 11.3879 24.6505 11.3879 23.7068V18.4604C11.3879 17.5167 10.6202 16.749 9.67648 16.749Z" fill="#898989" />
              <path d="M5.25 21.2136L6.16974 22.1621L8.62237 19.6328" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M26.0527 20.0675H15.7581C15.141 20.0675 14.6406 19.5672 14.6406 18.95C14.6406 18.3329 15.141 17.8325 15.7581 17.8325H26.0527C26.6699 17.8325 27.1703 18.3329 27.1703 18.95C27.1703 19.5672 26.6699 20.0675 26.0527 20.0675Z" fill="#898989" />
              <path d="M19.9065 24.4025H15.7581C15.141 24.4025 14.6406 23.9021 14.6406 23.285C14.6406 22.6678 15.141 22.1675 15.7581 22.1675H19.9065C20.5236 22.1675 21.024 22.6678 21.024 23.285C21.024 23.9021 20.5236 24.4025 19.9065 24.4025Z" fill="#898989" />
            </svg>
    );
};