import React from 'react';
import { CheckoutDetailsForm, CheckoutTripForm } from 'forms'
import css from './CustomerRequirementPage.css'

export default function TripRequirementForm(props) {
  const {
    formStep,
    onSubmit,
    initialFormValues,
    intl,
    listing,
    orderData,
    currencyConversionRate,
    recentTransactions,
    onChange,
    detailsFormRef,
    tripFormRef,
    onBack,
    callTrackAnalyticsEventWithContext,
  } = props;

  return (
    <div className={formStep === 2 ? css.modifiedBookListingContainer : css.bookListingContainer}>
      {formStep === 1 && (
        <div className={css.paymentContainer}>
          <CheckoutTripForm
            ref={tripFormRef}
            intl={intl}
            listing={listing}
            onBack={onBack}
            initialValues={initialFormValues}
            lastTransaction={recentTransactions[0]}
            submitButtonWrapperClassName={css.detailedFormButtonWrapper}
            onChange={onChange}
            onSubmit={onSubmit}
          />
        </div>
      )}

      {formStep === 2 && (
        <div>
          <div>
            <CheckoutDetailsForm
              ref={detailsFormRef}
              intl={intl}
              listing={listing}
              onBack={onBack}
              initialValues={initialFormValues}
              submitButtonWrapperClassName={css.detailedFormButtonWrapper}
              onChange={onChange}
              onSubmit={() => {
                try {
                  callTrackAnalyticsEventWithContext('submit_order_form', {});
                } catch(error) {
                  console.error(error);
                }
                onSubmit();
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}
