import React, { useState, useEffect } from 'react';
import cns from 'classnames';
import { compose } from 'redux';
import { uniqBy, omit } from 'lodash';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { FormattedMessage } from '../../util/reactIntl';
import { PriceFilter, IconSearch } from '../../components';
import css from './SubCategoryFilter.css';
import { parse } from '../../util/urlHelpers';
import DropdownButton from '../ModifiedSearchFilters/Components/DropdownButton/DropdownButton';
import FilterDropdown from '../ModifiedSearchFilters/Components/FilterDropdown/FilterDropdown';
import { withViewport } from '../../util/contextHelpers';
import AutocompleteMultiselectField from '../AutocompleteMultiselectField/AutocompleteMultiselectField';
import config from '../../config';

const RADIX = 10;
const Level_Of_Seller = [
  {
    key: 'new',
    label: 'New Arrival',
    isSortable: false,
  },
  {
    key: 'pub_total_bookings',
    label: 'Most Bookings',
    isSortable: true,
  },
  {
    key: 'pro',
    label: 'Pro Verified',
    isSortable: false,
  },
  {
    key: 'master_verified',
    label: 'Master Verified',
    isSortable: false,
  },
  {
    key: 'pub_total_ratings',
    label: 'Best Rated',
    isSortable: true,
  },
  {
    key: 'pub_last_active',
    label: 'Last Online',
    isSortable: true,
  },
];

const SubCategoryFilter = props => {
  const {
    urlQueryParams,
    isMobileLayout,
    categoryFilter,
    priceFilter,
    languageFilter,
    redirectPageName,
    history,
    viewport,
    listingType,
  } = props;

  const [selectedSellerLevels, setSelectedSellerLevels] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('sports');
  const [keyWordValue, setKeyWordValue] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [travellerType, setTravellerType] = useState('');

  const [selectedSuggestionCategories, setSelectedSuggestionCategories] = useState([]);

  const { width } = viewport;
  const numSum = width && width > 768 ? Math.round((width - 500) / 120 - 1) : 6;

  useEffect(() => {
    const subCategoryData = filterSubCategoriesByCategory();
    const uniqueSubCategory = uniqBy(subCategoryData, 'key');
    setSubCategories(uniqueSubCategory);
    /* eslint-disable-next-line */
  }, [urlQueryParams]);

  const redirectToUrl = (redirectPageName, queryParams) => {
    return history.push(
      createResourceLocatorString(redirectPageName, routeConfiguration(), {}, queryParams)
    );
  };

  const initialStateData = () => {
    const urlParams = parse(history.location.search);
    if (urlParams && (urlParams.meta_level || urlParams.sort)) {
      const selectedLevels = urlParams.meta_level
        ? Array.isArray(urlParams.meta_level)
          ? urlParams.meta_level
          : urlParams.meta_level.split(',')
        : [];

      const selectedSorts = urlParams.sort
        ? Array.isArray(urlParams.sort)
          ? urlParams.sort
          : urlParams.sort.split(',')
        : [];

      setSelectedSellerLevels([...selectedLevels, ...selectedSorts]);
    }
    if (urlParams && urlParams.pub_category) {
      setSelectedCategory(urlParams && urlParams.pub_category);
    }
    if (urlParams && urlParams.pub_suggestion_category) {
      const subCategoriesData = Array.isArray(urlParams.pub_suggestion_category)
        ? urlParams.pub_suggestion_category
        : urlParams.pub_suggestion_category.split(',');
      setSelectedSuggestionCategories(subCategoriesData);
    }
    if (urlParams && urlParams.keywords) {
      setKeyWordValue(urlParams.keywords);
    }
  };
  useEffect(() => {
    initialStateData();
    // eslint-disable-next-line
  }, [urlQueryParams]);

  const filterSubCategoriesByCategory = () => {
    const initialcategories = selectedCategory;
    if (initialcategories.length) {
      let childSubCategories = [];
      const categories = categoryFilter.options.filter(
        category => initialcategories === category.key
      );
      categories &&
        categories.forEach(category => {
          category &&
            category.subCategories.length &&
            category.subCategories.forEach(sb => {
              sb.options.forEach(childCategory => {
                childSubCategories.push(childCategory);
              });
            });
        });
      return childSubCategories;
    } else {
      return [];
    }
  };

  const onChangeTravellerTypeHandler = value => {
    const urlParams = parse(history.location.search);

    setTravellerType(value);
    let queryParams = { ...urlParams, pub_traveller_type: value };
    return redirectToUrl(redirectPageName, queryParams);
  };

  const onSelectSuggestionCategoryHandler = value => {
    let updateValue = selectedSuggestionCategories;
    const urlParams = parse(history.location.search);

    // if (updateValue.includes('all_sports')) {
    //   updateValue.splice(updateValue.indexOf('all_sports'), 1);
    // }

    if (updateValue.includes(value)) {
      updateValue.splice(updateValue.indexOf(value), 1);
    } else {
      updateValue.push(value);
    }

    setSelectedSuggestionCategories(updateValue);
    let queryParams = { ...urlParams, pub_suggestion_category: updateValue };
    return redirectToUrl(redirectPageName, queryParams);
  };

  // const onSelectAllSubCategoryHandler = value => {
  //   const urlParams = parse(history.location.search);
  //   setSelectedSuggestionCategories([value]);
  //   let queryParams = { ...urlParams, pub_suggestion_category: null };
  //   return redirectToUrl(redirectPageName, queryParams);
  // };
  const initialPriceRangeValue = (queryParams, paramName) => {
    const price = queryParams[paramName];
    const valuesFromParams = !!price ? price.split(',').map(v => Number.parseInt(v, RADIX)) : [];

    return !!price && valuesFromParams.length === 2
      ? {
          minPrice: valuesFromParams[0],
          maxPrice: valuesFromParams[1],
        }
      : null;
  };

  const initialPriceRange = priceFilter
    ? initialPriceRangeValue(urlQueryParams, priceFilter.paramName)
    : null;

  const handlePrice = (urlParam, range) => {
    const { minPrice, maxPrice } = range || {};

    const queryParams =
      minPrice != null && maxPrice != null
        ? { ...urlQueryParams, [urlParam]: `${minPrice},${maxPrice}` }
        : omit(urlQueryParams, urlParam);

    return redirectToUrl(redirectPageName, queryParams);
  };

  const priceFilterElement = priceFilter ? (
    <PriceFilter
      id="SearchFilters.priceFilter"
      urlParam={priceFilter.paramName}
      onSubmit={handlePrice}
      rootClassName={css.priceFilter}
      showAsPopup
      {...priceFilter.config}
      initialValues={initialPriceRange}
      contentPlacementOffset={4}
    />
  ) : null;

  const handleKeyword = (urlParam, values) => {
    const urlQueryParams = parse(history.location.search);
    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values, origin: null }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };
  const filterKeywordContent = (
    <div className={css.filterKeywordWrap}>
      <div className={css.filterWrap}>
        <div className={css.inputWrap}>
          <input
            id={'SearchFilters.keywordFilter'}
            value={keyWordValue}
            placeholder={'Search by Keyword'}
            onChange={e => setKeyWordValue(e.target.value)}
          />
        </div>
        {keyWordValue && (
          <div className={css.btnapply} onClick={() => handleKeyword('keywords', keyWordValue)}>
            <IconSearch />
          </div>
        )}
      </div>
    </div>
  );
  const selectedInterests = urlQueryParams?.interests || [];
  const handleSubmitSearch = (key, value) => {
    const queryParams = { ...urlQueryParams, [`${key}`]: value };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  };

  return (
    <section className={css.root}>
      <div className={css.advanceFilterContainer}>
        <div className={css.advanceFilterWrapper}>
          <div className={css.rightSideFilters}>
            {priceFilterElement}

            {/* <DropdownButton
              labelClassName={css.groupSizeDropdownLabel}
              inProgress={false}
              label={<FormattedMessage id={'ModifiedSearchFilters.interests'} />}
              selectedOption={[travellerType]}
              direction={'right'}
              menuContentClass={css.menuContentClass}
              actions={config.custom.interestsOptions.map(type => {
                return {
                  label: type.label,
                  key: type.key,
                  handler: () => onChangeTravellerTypeHandler(type.key),
                };
              })}
            /> */}

            <AutocompleteMultiselectField
              options={config.custom.interestsOptions || []}
              selectedInterests={selectedInterests}
              handleSubmitSearch={handleSubmitSearch}
            />

            <FilterDropdown
              urlQueryParams={parse(history.location.search)}
              languageFilter={languageFilter}
              priceFilter={priceFilter}
              redirectPageName={redirectPageName}
              history={history}
              isMobileLayout={isMobileLayout}
            />

            {filterKeywordContent}
          </div>

          {listingType !== 'company' ? (
            <div className={css.subCategoryItemsWrap}>
              {/* <div
              onClick={() => onSelectAllSubCategoryHandler('all_sports')}
              className={cns(css.subCategoryItem, {
                [css.selectedSubCategoryItem]: selectedSubCategories.includes('all_sports'),
              })}
            >
              All Sports
            </div> */}

              {config.custom.suggestionCategoryOptions.slice(0, 4).map(sub => {
                return (
                  <div
                    key={sub.key}
                    onClick={() => onSelectSuggestionCategoryHandler(sub.key)}
                    className={cns(css.subCategoryItem, {
                      [css.selectedSubCategoryItem]: selectedSuggestionCategories.includes(sub.key),
                    })}
                  >
                    {sub.label}
                  </div>
                );
              })}

              <DropdownButton
                labelClassName={css.subCategoryMoreItems}
                label={<FormattedMessage id={'ModifiedSearchFilters.more'} />}
                inProgress={false}
                selectedOption={selectedSuggestionCategories}
                menuContentClass={css.menuContentMoreClass}
                actions={config.custom.suggestionCategoryOptions
                  .slice(4, config.custom.suggestionCategoryOptions.length)
                  .map(sub => {
                    return {
                      label: sub.label,
                      key: sub.key,
                      handler: () => onSelectSuggestionCategoryHandler(sub.key),
                    };
                  })}
              />
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default compose(withViewport)(SubCategoryFilter);
