import React from 'react';
import css from 'components/ListingTypeFilter/ListingTypeFilter.css';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import Slider from 'react-slick';


function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <img
      src={'/static/images/searchPage/arrow-right.svg'}
      className={css.ArrowRight}
      style={{ ...style, display: 'block', zIndex: '2' }}
      onClick={onClick}
      alt="NextArrow"
    />
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <img
      src={'/static/images/searchPage/arrow-left.svg'}
      className={css.ArrowLeft}
      style={{ ...style, display: 'block', zIndex: '2' }}
      onClick={onClick}
      alt="PrevArrow"
    />
  );
}

function ListingTypeFilter({ value, onSelect, urlParam, options, isWithImage = false, isSlider = false }) {


  const sliderSettings = {
    speed: 500,
    className: "slider variable-width",
    dots: true,
    infinite: false,
    appendDots: dots => (
      <div className={css.dotsStyle}>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    slidesToShow: 5,
    variableWidth: true,
    slidesToScroll: 1,
    swipeToSlide: true,
    swipe: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,
          rows: 2,
          slidesPerRow: 1,
        },
      },
      {
        breakpoint: 1543,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return isWithImage && isSlider ? (<div className={css.sliderCheckboxWrap} >
    <Slider {...sliderSettings}>
      {options.map(option => (
        <div key={option.key} className={css.sliderCheckboxItem}>
          <input
            className={css.checkboxInput}
            type="checkbox"
            id={option.key}
            name="listing_type"
            checked={value === option.key}
            onChange={() => onSelect(urlParam, option.key, true)}
          />
          <label htmlFor={option.key}>
            <div className={classNames(css.imageWrap, { [css.imageDisabled]: !isWithImage })}>
              <img src={`/static/images/searchPage/${option.key}.png`} alt={option.key} />
            </div>
            <span className={classNames(css.checkboxLabel, { [css.checkboxLabelWithImage]: isWithImage })} >
              <img src={"/static/images/Icons/roundCheckYellow.png"} alt="roundCheckYellow" />
              {option.label}</span>
          </label>
        </div>
      ))}
    </Slider>
  </div>)
    : (<div className={classNames(css.checkboxWrap, { [css.checkboxWrapWithImage]: isWithImage })} id="checkboxWrap" >
      {options.map(option => (
        <div key={option.key} className={css.checkboxItem}>
          <input
            className={css.checkboxInput}
            type="checkbox"
            id={option.key}
            name="listing_type"
            checked={value === option.key}
            onChange={() => onSelect(urlParam, option.key, true)}
          />
          <label htmlFor={option.key}>
            <div className={classNames(css.imageWrap, { [css.imageDisabled]: !isWithImage })}>
              <img src={`/static/images/searchPage/${option.key}.png`} alt={option.key} />
            </div>
            <span className={classNames(css.checkboxLabel, { [css.checkboxLabelWithImage]: isWithImage })} >
              <img src={"/static/images/Icons/roundCheckYellow.png"} alt="roundCheckYellow" />
              {option.label}</span>
          </label>
        </div>
      ))}
    </div>
    );
}

ListingTypeFilter.propTypes = {
  rootClassName: string,
  className: string,
  urlParam: string.isRequired,
  // label: string.isRequired,
  onSelect: func.isRequired,
  isWithImage: bool,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  value: string,
  contentPlacementOffset: number,
};

export default ListingTypeFilter;
