import React, { useMemo, useState, useCallback } from 'react';
import { uid } from 'react-uid';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import css from './UpcomingCalendar.css';
import cns from 'classnames';
import { HorizontalScroll } from 'components';

const renderCalendarItems = (selectedDate, onDateSelect, numberOfDays) => {
  const currentDate = moment();
  const days = Array(numberOfDays).fill();
  return [...days].map((w, i) => {
    const date = currentDate.clone().add(i, 'days');
    const isSelectedDate = selectedDate.isSame(date, 'day');
    const dd = date.format('DD');

    return (
      <div
        key={uid(dd, i)}
        className={cns(css.date, isSelectedDate && css.selected)}
        onClick={() => onDateSelect(date)}
      >
        <div className={css.dateLabelContainer}>
          <div className={css.weekDate}>{date.format('dd').substr(0, 1)}</div>
          <div className={css.numberDate}>{date.format('DD')}</div>
        </div>
      </div>
    );
  });
};

function UpcomingCalendar({
  initialDate,
  onDateSelect,
  numberOfDays,
  totalItems,
  isCompanyPage = false,
}) {
  const [selectedDate, setSelectedDate] = useState(initialDate || moment());

  const onClickHandler = useCallback(date => {
    onDateSelect(date);
    setSelectedDate(date);
    // eslint-disable-next-line
  }, []);

  const calendarDates = useMemo(() => {
    return renderCalendarItems(selectedDate, onClickHandler, numberOfDays);
    // eslint-disable-next-line
  }, [selectedDate, numberOfDays]);
  const subheaderDate = selectedDate.format('dddd Do');

  return (
    <div>
      <div className={css.mobileTextSeaction}>
        <h2 className={css.selectedDateText}>
          <span className={css.classesFound}>Schedule</span>
          <span className={css.selectedDate}>{subheaderDate}</span>
        </h2>
      </div>
      <div className={classNames(css.desktopData, { [css.textSeaction]: !isCompanyPage })}>
        <h2 className={css.scheduleText}>
          <span>Schedule</span>
        </h2>
      </div>
      <div className={css.upcomingCalendarContainer}>
        <HorizontalScroll
          arrowClassName={css.scrollArrow}
          className={css.calendarContainer}
          scrollClassName={css.upcomingCalendar}
        >
          {calendarDates}
        </HorizontalScroll>
      </div>
      <div
        className={classNames(css.desktopData, { [css.classFoundTextSeaction]: !isCompanyPage })}
      >
        <h2 className={css.selectedDateText}>
          <span className={css.selectedDate}>{subheaderDate}</span>
          <span className={css.classesFound}>{totalItems} Classes</span>
        </h2>
      </div>
    </div>
  );
}

UpcomingCalendar.propsTypes = {
  onDateSelect: PropTypes.func.isRequired,
  numberOfDays: PropTypes.number.isRequired,
  initialDate: PropTypes.instanceOf(moment),
};

export default UpcomingCalendar;
