/* eslint-disable */
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import _ from 'lodash';
import moment from 'moment';
import fileDownload from 'js-file-download';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import {
  COMPLETE_TRANSITIONS,
  getTransitionsByState,
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_EXPIRE_PAYMENT,
  TRANSITION_CANCEL,
  TRANSITION_CANCEL_READY_TO_COMPLETE,
  TRANSITION_COMPLETE_MANUAL,
  TRANSITION_DECLINE,
  TRANSITION_ENQUIRE,
  TRANSITION_ACCEPT,
  TRANSITION_RESCHEDULE_BY_PROVIDER,
  TRANSITION_RESCHEDULE_BY_CUSTOMER,
  TRANSITION_RESCHEDULE_BY_PROVIDER_AFTER_READY_TO_COMPLETE,
  TRANSITION_SET_DELIVERED,
  TRANSITION_OP_SET_DELIVERED,
  TRANSITION_MARK_DELIVERED_CHANGES,
  TRANSITION_OP_MARK_DELIVERED_CHANGES,
  TRANSITION_REQUEST_CHANGES,
  TRANSITION_OP_REQUEST_CHANGES,
  TRANSITION_COMPLETE,
  TRANSITION_OP_COMPLETE,
  TRANSITION_CHARGE_CUSTOMER,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_SET_PRICE,
  TRANSITION_UPDATE_PRICE,
  filterState,
  TRANSITION_REQUEST_EXTEND_DELIVERY_PERIOD,
  TRANSITION_OP_REQUEST_EXTEND_DELIVERY_PERIOD,
  TRANSITION_AUTO_REQUEST_EXTEND_DELIVERY_PERIOD,
  TRANSITION_OP_REVIEW_1_BY_CUSTOMER,
  TRANSITION_OP_REVIEW_1_BY_PROVIDER,
  TRANSITION_ACCEPT_EXTEND_DELIVERY_PERIOD,
  TRANSITION_OP_ACCEPT_EXTEND_DELIVERY_PERIOD,
} from '../../util/transaction';
import { addMarketplaceEntities, resetMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { fetchUserListings } from './Schedule.duck';
import config from '../../config';
import axios from 'axios';
import { types as sdkTypes } from 'util/sdkLoader';
import {
  denormalisedEntities,
  getAllItems,
  updatedEntities,
  denormalisedResponseEntities,
} from 'util/data';
import { convertMoneyToNumber } from 'util/currency';
import { timestampToDate } from '../../util/dates';
import { getRatingFromReviews } from '../ListingPage/helper';
import { transformRecentTransactions } from 'containers/BookingPlanPage/helpers';
import { compatibleTransaction } from 'util/transaction-compatibility';
const { Money } = sdkTypes;

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

const calculatePaymentType = tx => {
  if (tx.attributes.processName === 'preauth-unit-time-booking') return 'Credit card';
  if (tx.attributes.protectedData.membership) return `Membership card`;
  if (
    tx.attributes.protectedData.paymentProofs &&
    tx.attributes.protectedData.paymentProofs.length > 0
  )
    return 'Bank transfer';
  if (tx.attributes.protectedData.withCredits) return 'Package booking';
  if (tx.attributes.protectedData.withCash) return 'Cash';
};

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_ENQUIRY_REQUEST = 'app/InboxPage/FETCH_ENQUIRY_REQUEST';
export const FETCH_ENQUIRY_SUCCESS = 'app/InboxPage/FETCH_ENQUIRY_SUCCESS';
export const FETCH_ENQUIRY_ERROR = 'app/InboxPage/FETCH_ENQUIRY_ERROR';

export const FETCH_SELLER_PACKAGE_REQUESTS = 'app/InboxPage/FETCH_SELLER_PACKAGE_REQUESTS';
export const FETCH_SELLER_PACKAGE_REQUESTS_SUCCESS =
  'app/InboxPage/FETCH_SELLER_PACKAGE_REQUESTS_SUCCESS';
export const FETCH_SELLER_PACKAGE_REQUESTS_ERROR =
  'app/InboxPage/FETCH_SELLER_PACKAGE_REQUESTS_ERROR';

export const FETCH_BUYER_PACKAGE_REQUESTS = 'app/InboxPage/FETCH_BUYER_PACKAGE_REQUESTS';
export const FETCH_BUYER_PACKAGE_REQUESTS_SUCCESS =
  'app/InboxPage/FETCH_BUYER_PACKAGE_REQUESTS_SUCCESS';
export const FETCH_BUYER_PACKAGE_REQUESTS_ERROR =
  'app/InboxPage/FETCH_BUYER_PACKAGE_REQUESTS_ERROR';

export const ACCEPT_PACKAGE_REQUEST = 'app/InboxPage/ACCEPT_PACKAGE_REQUEST';
export const ACCEPT_PACKAGE_REQUEST_SUCCESS = 'app/InboxPage/ACCEPT_PACKAGE_REQUEST_SUCCESS';
export const ACCEPT_PACKAGE_REQUEST_ERROR = 'app/InboxPage/ACCEPT_PACKAGE_REQUEST_ERROR';

export const CANCEL_PACKAGE_REQUEST = 'app/InboxPage/CANCEL_PACKAGE_REQUEST';
export const CANCEL_PACKAGE_REQUEST_SUCCESS = 'app/InboxPage/CANCEL_PACKAGE_REQUEST_SUCCESS';
export const CANCEL_PACKAGE_REQUEST_ERROR = 'app/InboxPage/CANCEL_PACKAGE_REQUEST_ERROR';

export const FETCH_CSV_DATA_REQUEST = 'app/InboxPage/FETCH_CSV_DATA_REQUEST';
export const FETCH_CSV_DATA_SUCCESS = 'app/InboxPage/FETCH_CSV_DATA_SUCCESS';
export const FETCH_CSV_DATA_ERROR = 'app/InboxPage/FETCH_CSV_DATA_ERROR';

export const FETCH_CSV_PACKAGES_DATA_REQUEST = 'app/InboxPage/FETCH_CSV_PACKAGES_DATA_REQUEST';
export const FETCH_CSV_PACKAGES_DATA_SUCCESS = 'app/InboxPage/FETCH_CSV_PACKAGES_DATA_SUCCESS';
export const FETCH_CSV_PACKAGES_DATA_ERROR = 'app/InboxPage/FETCH_CSV_PACKAGES_DATA_ERROR';

export const FETCH_LISTING_SUCCESS = 'app/InboxPage/FETCH_LISTING_SUCCESS';
export const FETCH_LISTING_ERROR = 'app/InboxPage/FETCH_LISTING_ERROR';

export const FETCH_USER_SUCCESS = 'app/InboxPage/FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'app/InboxPage/FETCH_USER_ERROR';

export const FETCH_INCOME_TRANSACTIONS_REQUEST =
  'app/IncomeContent/FETCH_INCOME_TRANSACTIONS_REQUEST';
export const FETCH_INCOME_TRANSACTIONS_SUCCESS =
  'app/IncomeContent/FETCH_INCOME_TRANSACTIONS_SUCCESS';
export const FETCH_INCOME_TRANSACTIONS_ERROR = 'app/IncomeContent/FETCH_INCOME_TRANSACTIONS_ERROR';
export const RESET_INCOME_TRANSACTIONS = 'app/IncomeContent/RESET_INCOME_TRANSACTIONS';

export const ADD_TO_PENDING_PROGRESS_REPORTS = 'app/InboxPage/ADD_TO_PENDING_PROGRESS_REPORTS';
export const REMOVE_PENDING_PROGRESS_REPORTS = 'app/InboxPage/REMOVE_PENDING_PROGRESS_REPORTS';

export const FETCH_SELLER_PROGRESS_REPORTS_REQUEST =
  'app/InboxPage/FETCH_SELLER_PROGRESS_REPORTS_REQUEST';
export const FETCH_SELLER_PROGRESS_REPORTS_REQUEST_SUCCESS =
  'app/InboxPage/FETCH_SELLER_PROGRESS_REPORTS_REQUEST_SUCCESS';
export const FETCH_SELLER_PROGRESS_REPORTS_REQUEST_ERROR =
  'app/InboxPage/FETCH_SELLER_PROGRESS_REPORTS_REQUEST_ERROR';

export const FETCH_BUYER_PROGRESS_REPORTS_REQUEST =
  'app/InboxPage/FETCH_BUYER_PROGRESS_REPORTS_REQUEST';
export const FETCH_BUYER_PROGRESS_REPORTS_REQUEST_SUCCESS =
  'app/InboxPage/FETCH_BUYER_PROGRESS_REPORTS_REQUEST_SUCCESS';
export const FETCH_BUYER_PROGRESS_REPORTS_REQUEST_ERROR =
  'app/InboxPage/FETCH_BUYER_PROGRESS_REPORTS_REQUEST_ERROR';

export const RESCHEDULE_REQUEST = 'app/InboxPage/RESCHEDULE_REQUEST';
export const RESCHEDULE_SUCCESS = 'app/InboxPage/RESCHEDULE_SUCCESS';
export const RESCHEDULE_ERROR = 'app/InboxPage/RESCHEDULE_ERROR';

export const WALLET_LISTINGS_REQUEST = 'app/InboxPage/WALLET_LISTINGS_REQUEST';
export const WALLET_LISTINGS_SUCCESS = 'app/InboxPage/WALLET_LISTINGS_SUCCESS';
export const WALLET_LISTINGS_ERROR = 'app/InboxPage/WALLET_LISTINGS_ERROR';
export const NO_WALLET_PACKAGE = 'app/InboxPage/NO_WALLET_PACKAGE';

// schedule
export const FETCH_TRANSACTIONS_REQUEST = 'app/InboxPage/FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'app/InboxPage/FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_ERROR = 'app/InboxPage/FETCH_TRANSACTIONS_ERROR';

export const FETCH_RECENT_TRANSACTIONS_REQUEST = 'app/InboxPage/FETCH_RECENT_TRANSACTIONS_REQUEST';
export const FETCH_RECENT_TRANSACTIONS_SUCCESS = 'app/InboxPage/FETCH_RECENT_TRANSACTIONS_SUCCESS';

export const SEND_MESSAGE_REQUEST = 'app/InboxPage/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'app/InboxPage/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'app/InboxPage/SEND_MESSAGE_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  fetchSellerPackageRequestInProgress: false,
  fetchSellerPackageRequestError: null,
  sellerPackageRequests: [],
  fetchBuyerPackageRequestInProgress: false,
  fetchBuyerPackageRequestError: null,
  buyerPackageRequests: [],

  fetchSellerProgressReportsRequestInProgress: false,
  fetchSellerProgressReportsRequestError: null,
  sellerProgressReports: [],

  fetchBuyerProgressReportsRequestInProgress: false,
  fetchBuyerProgressReportsRequestError: null,
  buyerProgressReports: [],

  acceptPackageRequestInProgress: false,
  acceptPackageRequestError: null,
  cancelPackageRequestInProgress: false,
  cancelPackageRequestError: null,
  fetchCSVDataInProgress: false,
  fetchCSVPackagesDataInProgress: false,
  dataForCSVRefs: [],
  fetchedLisitng: null,
  fetchedLisitngError: null,
  fetchedUser: null,
  fetchedUserError: null,
  incomeTransactions: [],
  incomeTransactionsLoading: false,
  enquiryRefs: [],
  fetchEnquiryError: null,
  isAnyPendingProgressReport: false,
  pendingProgressReportTxIds: [],
  rescheduleRequestInProgress: false,
  rescheduleError: null,
  rescheduledsuccessfully: null,

  walletRequestError: null,
  walletRequestInProgress: true,
  walletListings: [],

  recentTransactions: [],
  isLoading: false,
  error: null,
  sellerPackagesPagination: null,
  buyerPackagesPagination: null,
  scheduleTransactionRefs: [],
  sendMessageInProgress: false,
  sendMessageError: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return {
        ...state,
        fetchInProgress: true,
        fetchOrdersOrSalesError: null,
        transactionRefs: [],
      };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    // Enquiry request
    case FETCH_ENQUIRY_REQUEST:
      return { ...state, fetchInProgress: true, fetchEnquiryError: null };
    case FETCH_ENQUIRY_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        enquiryRefs: entityRefs(payload.data.data),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ENQUIRY_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchEnquiryError: payload };

    //credit requests
    //seller
    case FETCH_SELLER_PACKAGE_REQUESTS:
      return {
        ...state,
        fetchSellerPackageRequestInProgress: true,
        fetchSellerPackageRequestError: null,
      };
    case FETCH_SELLER_PACKAGE_REQUESTS_SUCCESS: {
      return {
        ...state,
        fetchSellerPackageRequestInProgress: false,
        sellerPackageRequests: payload.data,
        sellerPackagesPagination: payload.meta,
      };
    }
    case FETCH_SELLER_PACKAGE_REQUESTS_ERROR:
      return {
        ...state,
        fetchSellerPackageRequestInProgress: false,
        fetchSellerPackageRequestError: payload,
      };

    //buyer
    case FETCH_BUYER_PACKAGE_REQUESTS:
      return {
        ...state,
        fetchBuyerPackageRequestInProgress: true,
        fetchBuyerPackageRequestError: null,
      };
    case FETCH_BUYER_PACKAGE_REQUESTS_SUCCESS: {
      return {
        ...state,
        fetchBuyerPackageRequestInProgress: false,
        buyerPackageRequests: payload.data,
        buyerPackagesPagination: payload.meta,
      };
    }
    case FETCH_BUYER_PACKAGE_REQUESTS_ERROR:
      return {
        ...state,
        fetchBuyerPackageRequestInProgress: false,
        fetchBuyerPackageRequestError: payload,
      };

    //progress reports
    //seller
    case FETCH_SELLER_PROGRESS_REPORTS_REQUEST:
      return {
        ...state,
        fetchSellerProgressReportsRequestInProgress: true,
        fetchSellerProgressReportsRequestError: null,
      };
    case FETCH_SELLER_PROGRESS_REPORTS_REQUEST_SUCCESS: {
      return {
        ...state,
        fetchSellerProgressReportsRequestInProgress: false,
        sellerProgressReports: payload,
      };
    }
    case FETCH_SELLER_PROGRESS_REPORTS_REQUEST_ERROR:
      return {
        ...state,
        fetchSellerProgressReportsRequestInProgress: false,
        fetchSellerProgressReportsRequestError: payload,
      };

    //buyer
    case FETCH_BUYER_PROGRESS_REPORTS_REQUEST:
      return {
        ...state,
        fetchBuyerProgressReportsRequestInProgress: true,
        fetchBuyerProgressReportsRequestError: null,
      };
    case FETCH_BUYER_PROGRESS_REPORTS_REQUEST_SUCCESS: {
      return {
        ...state,
        fetchBuyerProgressReportsRequestInProgress: false,
        buyerProgressReports: payload,
      };
    }
    case FETCH_BUYER_PROGRESS_REPORTS_REQUEST_ERROR:
      return {
        ...state,
        fetchBuyerProgressReportsRequestInProgress: false,
        fetchBuyerProgressReportsRequestError: payload,
      };
    //accept
    case ACCEPT_PACKAGE_REQUEST:
      return { ...state, acceptPackageRequestInProgress: true, acceptPackageRequestError: null };
    case ACCEPT_PACKAGE_REQUEST_SUCCESS: {
      return {
        ...state,
        acceptPackageRequestInProgress: false,
        sellerPackageRequests: payload,
      };
    }
    case ACCEPT_PACKAGE_REQUEST_ERROR:
      return {
        ...state,
        acceptPackageRequestInProgress: false,
        acceptPackageRequestError: payload,
      };

    //reject
    case CANCEL_PACKAGE_REQUEST:
      return { ...state, cancelPackageRequestInProgress: true, cancelPackageRequestError: null };
    case CANCEL_PACKAGE_REQUEST_SUCCESS: {
      return {
        ...state,
        cancelPackageRequestInProgress: false,
        sellerPackageRequests: payload,
      };
    }
    case CANCEL_PACKAGE_REQUEST_ERROR:
      return {
        ...state,
        cancelPackageRequestInProgress: false,
        cancelPackageRequestError: payload,
      };
    case FETCH_CSV_DATA_REQUEST:
      return { ...state, fetchCSVDataInProgress: true };
    case FETCH_CSV_DATA_SUCCESS:
      return {
        ...state,
        fetchCSVDataInProgress: false,
      };
    case FETCH_CSV_DATA_ERROR:
      return { ...state, fetchCSVDataInProgress: false };
    case FETCH_CSV_PACKAGES_DATA_REQUEST:
      return { ...state, fetchCSVPackagesDataInProgress: true };
    case FETCH_CSV_PACKAGES_DATA_SUCCESS:
      return {
        ...state,
        fetchCSVPackagesDataInProgress: false,
      };
    case FETCH_CSV_PACKAGES_DATA_ERROR:
      return { ...state, fetchCSVPackagesDataInProgress: false };
    case FETCH_LISTING_SUCCESS:
      return {
        ...state,
        fetchedLisitng: payload,
      };
    case FETCH_LISTING_ERROR:
      return { ...state, fetchedLisitngError: payload };

    case FETCH_USER_SUCCESS:
      return {
        ...state,
        fetchedUser: payload,
      };
    case FETCH_USER_ERROR:
      return { ...state, fetchedUserError: payload };

    case FETCH_INCOME_TRANSACTIONS_REQUEST:
      return { ...state, incomeTransactionsLoading: true };
    case RESET_INCOME_TRANSACTIONS:
      return { ...state, incomeTransactions: [] };
    case FETCH_INCOME_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        incomeTransactions: payload,
        incomeTransactionsLoading: false,
      };
    case FETCH_INCOME_TRANSACTIONS_ERROR:
      return {
        ...state,
        incomeTransactionsLoading: false,
      };
    case ADD_TO_PENDING_PROGRESS_REPORTS:
      const txIds = state.pendingProgressReportTxIds.includes(payload)
        ? [...state.pendingProgressReportTxIds]
        : [...state.pendingProgressReportTxIds, payload];
      return {
        ...state,
        isAnyPendingProgressReport: true,
        pendingProgressReportTxIds: txIds,
      };
    case REMOVE_PENDING_PROGRESS_REPORTS:
      return {
        ...state,
        isAnyPendingProgressReport: false,
        pendingProgressReportTxIds: [],
      };
    case RESCHEDULE_REQUEST:
      return {
        ...state,
        rescheduleRequestInProgress: true,
        rescheduleError: null,
        rescheduledsuccessfully: null,
      };
    case RESCHEDULE_SUCCESS:
      return {
        ...state,
        rescheduleRequestInProgress: false,
        rescheduleError: null,
        rescheduledsuccessfully: true,
      };
    case RESCHEDULE_ERROR:
      return {
        ...state,
        rescheduleRequestInProgress: false,
        rescheduleError: payload,
        rescheduledsuccessfully: null,
      };
    case WALLET_LISTINGS_REQUEST:
      return {
        ...state,
        walletRequestError: null,
        walletRequestInProgress: true,
      };
    case WALLET_LISTINGS_SUCCESS:
      const listings = _.uniqBy([...state.walletListings, payload.listing], function(e) {
        return e.id.uuid;
      });
      return { ...state, walletListings: listings, walletRequestInProgress: false };
    case WALLET_LISTINGS_ERROR:
      return { ...state, walletRequestError: payload, walletRequestInProgress: false };
    case NO_WALLET_PACKAGE:
      return { ...state, walletRequestInProgress: false };

    //schedule

    case FETCH_TRANSACTIONS_REQUEST:
      return { ...state, isLoading: true, scheduleTransactionRefs: [], transactionRefs: [] };
    case FETCH_RECENT_TRANSACTIONS_REQUEST:
      return { ...state, isLoading: true, recentTransactions: [] };
    case FETCH_TRANSACTIONS_SUCCESS:
      return { ...state, scheduleTransactionRefs: payload, isLoading: false };
    case FETCH_RECENT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        recentTransactions: payload.recentTransactions,
        pagination: payload.pagination,
        isLoading: false,
      };
    case FETCH_TRANSACTIONS_ERROR:
      return { ...state, isLoading: false, error: payload };

    //Message
    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        sendMessageInProgress: true,
        sendMessageError: null,
      };
    case SEND_MESSAGE_SUCCESS:
      return { ...state, sendMessageInProgress: false };
    case SEND_MESSAGE_ERROR:
      return { ...state, sendMessageInProgress: false, sendMessageError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

export const fetchEnquiryRequest = () => ({ type: FETCH_ENQUIRY_REQUEST });
export const fetchEnquirySuccess = payload => ({
  type: FETCH_ENQUIRY_SUCCESS,
  payload,
});
export const fetchEnquiryError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const sellerPackageRequestsRequest = () => ({ type: FETCH_SELLER_PACKAGE_REQUESTS });
const sellerPackageRequestsSuccess = response => ({
  type: FETCH_SELLER_PACKAGE_REQUESTS_SUCCESS,
  payload: response,
});
const sellerPackageRequestsError = e => ({
  type: FETCH_SELLER_PACKAGE_REQUESTS_ERROR,
  error: true,
  payload: e,
});

const buyerPackageRequestsRequest = () => ({ type: FETCH_BUYER_PACKAGE_REQUESTS });
const buyerPackageRequestsSuccess = response => ({
  type: FETCH_BUYER_PACKAGE_REQUESTS_SUCCESS,
  payload: response,
});
const buyerPackageRequestsError = e => ({
  type: FETCH_BUYER_PACKAGE_REQUESTS_ERROR,
  error: true,
  payload: e,
});

const sellerProgressReportsRequest = () => ({ type: FETCH_SELLER_PROGRESS_REPORTS_REQUEST });
const sellerProgressReportsSuccess = response => ({
  type: FETCH_SELLER_PROGRESS_REPORTS_REQUEST_SUCCESS,
  payload: response,
});
const sellerProgressReportsError = e => ({
  type: FETCH_SELLER_PROGRESS_REPORTS_REQUEST_ERROR,
  error: true,
  payload: e,
});

const buyerProgressReportsRequest = () => ({ type: FETCH_BUYER_PROGRESS_REPORTS_REQUEST });
const buyerProgressReportsSuccess = response => ({
  type: FETCH_BUYER_PROGRESS_REPORTS_REQUEST_SUCCESS,
  payload: response,
});
const buyerProgressReportsError = e => ({
  type: FETCH_BUYER_PROGRESS_REPORTS_REQUEST_ERROR,
  error: true,
  payload: e,
});

const acceptPackageRequestRequest = () => ({ type: ACCEPT_PACKAGE_REQUEST });
const acceptPackageRequestSuccess = response => ({
  type: ACCEPT_PACKAGE_REQUEST_SUCCESS,
  payload: response,
});
const acceptPackageRequestError = e => ({
  type: ACCEPT_PACKAGE_REQUEST_ERROR,
  error: true,
  payload: e,
});

const cancelPackageRequestRequest = () => ({ type: CANCEL_PACKAGE_REQUEST });
const cancelPackageRequestSuccess = response => ({
  type: CANCEL_PACKAGE_REQUEST_SUCCESS,
  payload: response,
});
const cancelPackageRequestError = e => ({
  type: CANCEL_PACKAGE_REQUEST_ERROR,
  error: true,
  payload: e,
});

const fetchCSVDataRequest = () => ({ type: FETCH_CSV_DATA_REQUEST });
const fetchCSVDataSuccess = () => ({ type: FETCH_CSV_DATA_SUCCESS });
const fetchCSVDataError = e => ({ type: FETCH_ORDERS_OR_SALES_ERROR, error: true, payload: e });

const fetchCSVPackagesDataRequest = () => ({ type: FETCH_CSV_PACKAGES_DATA_REQUEST });
const fetchCSVPackagesDataRequestSuccess = () => ({ type: FETCH_CSV_PACKAGES_DATA_SUCCESS });
const fetchCSVPackagesDataRequestError = e => ({
  type: FETCH_CSV_PACKAGES_DATA_ERROR,
  error: true,
  payload: e,
});

const fetchListingSuccess = response => ({ type: FETCH_LISTING_SUCCESS, payload: response });
const fetchListingError = e => ({ type: FETCH_LISTING_ERROR, error: true, payload: e });

const fetchUserSuccess = response => ({ type: FETCH_USER_SUCCESS, payload: response });
const fetchUserError = e => ({ type: FETCH_USER_ERROR, error: true, payload: e });

const fetchIncomeTransactionsRequest = () => ({ type: FETCH_INCOME_TRANSACTIONS_REQUEST });
const resetIncomeTransactions = () => ({ type: RESET_INCOME_TRANSACTIONS });
const fetchIncomeTransactionsSuccess = payload => ({
  type: FETCH_INCOME_TRANSACTIONS_SUCCESS,
  payload,
});
const fetchIncomeTransactionsError = e => ({
  type: FETCH_INCOME_TRANSACTIONS_ERROR,
  error: e,
});

const addToPendingProgressReports = transactionId => ({
  type: ADD_TO_PENDING_PROGRESS_REPORTS,
  payload: transactionId,
});
const skipProgressReports = () => ({ type: REMOVE_PENDING_PROGRESS_REPORTS });

const rescheduleRequest = () => ({ type: RESCHEDULE_REQUEST });
const rescheduleSuccess = response => ({ type: RESCHEDULE_SUCCESS, payload: response });
const rescheduleError = e => ({ type: RESCHEDULE_ERROR, error: true, payload: e });

// schedule

const fetchTransactionsRequest = () => ({ type: FETCH_TRANSACTIONS_REQUEST });
const fetchTransactionsSuccess = payload => ({ type: FETCH_TRANSACTIONS_SUCCESS, payload });
const fetchTransactionsError = payload => ({ type: FETCH_TRANSACTIONS_ERROR, payload });

const fetchRecentTransactionsRequest = () => ({ type: FETCH_RECENT_TRANSACTIONS_REQUEST });
const fetchRecentTransactionsSuccess = payload => ({
  type: FETCH_RECENT_TRANSACTIONS_SUCCESS,
  payload,
});

export const walletListingsRequest = () => ({
  type: WALLET_LISTINGS_REQUEST,
});

export const walletListingsSuccess = listing => ({
  type: WALLET_LISTINGS_SUCCESS,
  payload: { listing },
});

export const walletListingsError = e => ({
  type: WALLET_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const noWalletPackage = () => ({
  type: NO_WALLET_PACKAGE,
});

const sendMessageRequest = () => ({ type: SEND_MESSAGE_REQUEST });
const sendMessageSuccess = () => ({ type: SEND_MESSAGE_SUCCESS });
const sendMessageError = e => ({ type: SEND_MESSAGE_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const fetchIncomeTransactions = () => async (dispatch, getState, sdk) => {
  const state = getState();
  if (state.InboxPage.incomeTransactions.length !== 0) {
    return;
  }
  dispatch(fetchIncomeTransactionsRequest());

  try {
    const responses = await getAllItems(sdk.transactions.query, {
      only: 'sale',
      lastTransitions: undefined,
    });

    dispatch(resetIncomeTransactions());
    const fetchIncomeTransactionsdata = [];
    responses.forEach((res) => {
      res.data.data = res.data.data.map(tx => compatibleTransaction(tx));
      const completedTxs = res.data.data.filter(tx => COMPLETE_TRANSITIONS.includes(tx.attributes.lastTransition))
      fetchIncomeTransactionsdata.push(...completedTxs);
    });
    dispatch(fetchIncomeTransactionsSuccess(fetchIncomeTransactionsdata));
  } catch (e) {
    dispatch(fetchIncomeTransactionsError(storableError(e)));
  }
};

export const updateListingStatus = (listingId, sellerId) => async (dispatch, getState, sdk) => {
  const state = getState();
  const currentUserId = state.user.currentUser ? state.user.currentUser.id.uuid : '';
  if (currentUserId !== sellerId) {
    return;
  }
  try {
    const params = {
      id: listingId,
      publicData: {
        last_active: moment().valueOf(),
      },
    };
    await sdk.ownListings.update(params);
  } catch (e) {
    console.log(e);
  }
};

export const transitionTransaction = params => async (dispatch, getState, sdk) => {
  const { transactionId, listing, transition, withCredits, customerId, sellerId } = params;
  try {
    if (
      (transition === TRANSITION_ACCEPT || transition === TRANSITION_COMPLETE_MANUAL) &&
      listing &&
      sellerId
    ) {
      dispatch(updateListingStatus(listing, sellerId));
    }

    if (
      withCredits &&
      [TRANSITION_CANCEL, TRANSITION_DECLINE, TRANSITION_CANCEL_READY_TO_COMPLETE].includes(
        transition
      )
    ) {
      const url = config.serverBaseUrl + config.creditRefundEndPoint;

      const headers = {
        headers: {
          Authorization: `Token token=${config.serverToken}`,
        },
      };

      const refundCreditsParams = {
        payment_params: {
          listing_id: listing,
          customer_id: customerId,
          seller_id: sellerId,
          transaction_id: transactionId,
        },
      };

      const resRefund = await axios.post(url, refundCreditsParams, headers);
      if (resRefund) {
        const res = await sdk.transactions.transition(
          {
            id: transactionId,
            transition: transition,
            params: {},
          },
          {
            expand: true,
          }
        );

        dispatch(addMarketplaceEntities(res));
      }
    } else {
      const res = await sdk.transactions.transition(
        {
          id: transactionId,
          transition: transition,
          params: {},
        },
        {
          expand: true,
        }
      );

      dispatch(addMarketplaceEntities(res));

      if (transition === TRANSITION_COMPLETE_MANUAL) {
        dispatch(addToPendingProgressReports(transactionId));
      }
    }
  } catch (e) {
    console.error(e);
  }
};

export const rescheduleBooking = params => async (dispatch, getState, sdk) => {
  dispatch(rescheduleRequest());
  const { transactionId, bookingStart, bookingEnd, transition } = params;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const rescheduleParams = {
    bookingStart: timestampToDate(
      moment(bookingStart)
        .tz(timeZone)
        .valueOf()
    ),
    bookingEnd: timestampToDate(
      moment(bookingEnd)
        .tz(timeZone)
        .valueOf()
    ),
  };
  return sdk.transactions
    .transition(
      {
        id: transactionId,
        transition: transition,
        params: rescheduleParams,
      },
      {
        expand: true,
        include: ['booking'],
      }
    )
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(rescheduleSuccess(response.data));
      return response;
    })
    .catch(e => dispatch(rescheduleError(storableError(e))));
};

const sortByBookingDate = (atx, btx) =>
  new Date(btx.booking.attributes.displayEnd) - new Date(atx.booking.attributes.displayEnd);
const sortByClientName = (atx, btx) => {
  const atxName = atx.customer.attributes.profile.displayName || '';
  const btxName = btx.customer.attributes.profile.displayName || '';
  return atxName.localeCompare(btxName);
};

export const downloadDeliveredTransactions = sortBy => async (dispatch, getState, sdk) => {
  dispatch(fetchCSVDataRequest());
  const params = {
    only: 'sale',
    lastTransitions: COMPLETE_TRANSITIONS,
    include: ['provider', 'customer', 'booking', 'listing'],
  };

  try {
    const txsResponses = await getAllItems(sdk.transactions.query, params);
    const txs = txsResponses.reduce((acc, res) => {
      const refs = entityRefs(res.data.data);
      const stateEntities = updatedEntities({}, res.data);
      return [...acc, ...denormalisedEntities(stateEntities, refs)];
    }, []);

    const sortFunction = sortBy === 'byClientName' ? sortByClientName : sortByBookingDate;
    const formattedTxs = txs.sort(sortFunction).map(tx => {
      const dateFormatPattern = 'DD-MM-YYYY HH:mm dddd';
      const timezone = tx.listing.attributes.availabilityPlan
        ? tx.listing.attributes.availabilityPlan.timezone
        : null;
      const payoutTotal = tx.attributes.metadata.amountPaid
        ? new Money(tx.attributes.metadata.amountPaid, config.currency)
        : tx.attributes.payoutTotal;

      const customerName = tx.customer.attributes.profile.displayName;
      const providerName = tx.provider.attributes.profile.displayName;
      const companyName = tx.provider.attributes?.profile?.publicData?.companyName;
      const listingName = tx.listing.attributes?.title;
      const kidsName = tx.attributes.protectedData.kidsName;
      const totalValue = convertMoneyToNumber(payoutTotal);
      const startTime = timezone
        ? moment(tx.booking.attributes.displayStart)
            .tz(timezone)
            .format(dateFormatPattern)
        : moment(tx.booking.attributes.displayStart).format(dateFormatPattern);
      const endTime = timezone
        ? moment(tx.booking.attributes.displayEnd)
            .tz(timezone)
            .format(dateFormatPattern)
        : moment(tx.booking.attributes.displayEnd).format(dateFormatPattern);
      const methodOfPayment = calculatePaymentType(tx);

      const paymentProof = tx.attributes.protectedData.membership
        ? `Membership card: ${tx.attributes.protectedData.membership}`
        : tx.attributes.protectedData.paymentProofs
        ? 'Payed by bank transfer'
        : null;
      return [
        customerName,
        kidsName,
        providerName,
        companyName,
        listingName,
        config.currency,
        totalValue,
        startTime,
        endTime,
        methodOfPayment,
        paymentProof,
      ];
    });

    let contentString =
      'customerName,kidsName,providerName,companyName,listingName,currency,payinTotal,startTime,endTime,methodOfPayment,paymentProof\n';
    contentString += '';
    formattedTxs.forEach(ftx => {
      let row = ftx.join(',');
      contentString += row + '\n';
    });

    const blob = new Blob([contentString], { type: 'text/csv;charset=utf-8;' });
    fileDownload(blob, 'data.csv');

    dispatch(fetchCSVDataSuccess());
  } catch (e) {
    console.error(e);
    dispatch(fetchCSVDataError(e));
  }
};

export const downloadPackages = sortBy => async (dispatch, getState, sdk) => {
  dispatch(fetchCSVPackagesDataRequest());

  return Promise.all([dispatch(fetchCurrentUser())]).then(response => {
    const currentUser = getState().user.currentUser;
    if (currentUser) {
      const url = config.serverBaseUrl + config.downloadPackagesEndPoint;

      const params = {
        headers: {
          Authorization: `Token token=${config.serverToken}`,
        },
        params: {
          sort_by: sortBy,
          seller_id: currentUser.id.uuid,
        },
      };

      return axios
        .get(url, params)
        .then(response => {
          const packagesData = response.data.map(pk => {
            const dateFormatPattern = 'DD-MM-YYYY HH:mm dddd';
            const listingTitle = pk.listing_title;
            const authorName = pk.author_name;
            const buyerName = pk.buyer_name;

            const totalAmount = (pk.amount / 100.0).toFixed(2) + ` ${pk.currency}`;
            const usedAmount =
              ((pk.amount / 100.0 / pk.credits) * pk.used_credits).toFixed(2) + ` ${pk.currency}`;
            const remainingAmount =
              ((pk.amount / 100.0 / pk.credits) * (pk.credits - pk.used_credits)).toFixed(2) +
              ` ${pk.currency}`;
            const totalHours = pk.credits;
            const usedHours = pk.used_credits;
            const remainingHours = pk.credits - pk.used_credits;
            const paymentMethod = pk.payment_type;
            const membershipCardNumber = pk.membership_card_number;
            const paymentProofs = pk.payment_proof_ss_urls;
            const createdAt = moment(pk.created_at).format(dateFormatPattern);

            return [
              listingTitle,
              authorName,
              buyerName,
              createdAt,
              totalAmount,
              usedAmount,
              remainingAmount,
              totalHours,
              usedHours,
              remainingHours,
              paymentMethod,
              membershipCardNumber,
              paymentProofs,
            ];
          });

          let contentString =
            'Listing Title,Author Name,Buyer Name,Package Date,Total Amount of Package,Total Amount of Package Sold,Remaining Amount of Package,Total Hours,Booked Hours,Remaining Hours,Payment Method,Membership Card Number,Payment Proof\n';
          contentString += '';
          packagesData.forEach(fp => {
            let row = fp.join(',');
            contentString += row + '\n';
          });

          const blob = new Blob([contentString], { type: 'text/csv;charset=utf-8;' });
          fileDownload(blob, 'data.csv');

          dispatch(fetchCSVPackagesDataRequestSuccess());
        })
        .catch(e => {
          dispatch(fetchCSVPackagesDataRequestError(e));
        });
    }
  });
};

const INBOX_PAGE_SIZE = 10;
const INBOX_PAGE_SIZE_FOR_READY_TO_COMPLETE = 40;

export const fetchListing = listingId => async (dispatch, getState, sdk) => {
  return sdk.listings
    .show({
      id: listingId,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.scaled-small',
        'variants.square-small2x',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchListingSuccess(response.data));
      return response;
    })
    .catch(e => dispatch(fetchListingError(storableError(e))));
};

export const fetchUser = userId => (dispatch, getState, sdk) => {
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchUserSuccess());
      return response;
    })
    .catch(e => dispatch(fetchUserError(storableError(e))));
};

export const sellerPackageRequests = (sellerId, query, page = 1) => (dispatch, getState, sdk) => {
  dispatch(sellerPackageRequestsRequest());
  const url =
    config.serverBaseUrl +
    config.creditRequestEndPoint +
    '/' +
    sellerId +
    '/' +
    config.sellerPackageRequestsEndPoint;
  const params = {
    headers: {
      Authorization: `Token token=${config.serverToken}`,
    },
    params: {
      query: query,
      page: page,
      per_page: INBOX_PAGE_SIZE,
    },
  };
  return axios
    .get(url, params)
    .then(response => {
      response.data.data.map(({ listing_id }) => {
        dispatch(fetchListing(listing_id));
      });
      response.data.data.map(({ buyer_id }) => {
        dispatch(fetchUser(buyer_id));
      });
      dispatch(sellerPackageRequestsSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(sellerPackageRequestsError(e));
    });
};

export const buyerPackageRequests = (buyerId, query, page = 1) => (dispatch, getState, sdk) => {
  dispatch(buyerPackageRequestsRequest());
  const url =
    config.serverBaseUrl +
    config.creditRequestEndPoint +
    '/' +
    buyerId +
    '/' +
    config.buyerPackageRequestsEndPoint;
  const params = {
    headers: {
      Authorization: `Token token=${config.serverToken}`,
    },
    params: {
      query: query,
      page: page,
      per_page: INBOX_PAGE_SIZE,
    },
  };
  return axios
    .get(url, params)
    .then(response => {
      response.data.data.map(({ listing_id }) => {
        dispatch(fetchListing(listing_id));
      });
      dispatch(buyerPackageRequestsSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(buyerPackageRequestsError(e));
    });
};

export const fetchSellerProgressReports = sellerId => (dispatch, getState, sdk) => {
  dispatch(sellerProgressReportsRequest());
  // eslint-disable-next-line
  const url = config.serverBaseUrl + 'progress_reports' + '/' + sellerId + '/seller';
  const headers = {
    headers: {
      Authorization: `Token token=${config.serverToken}`,
    },
  };
  return axios
    .get(url, headers)
    .then(response => {
      response.data.map(({ listing_id }) => {
        dispatch(fetchListing(listing_id));
      });
      response.data.map(({ buyer_id }) => {
        dispatch(fetchUser(buyer_id));
      });
      dispatch(sellerProgressReportsSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(sellerProgressReportsError(e));
    });
};

export const fetchBuyerProgressReports = buyerId => (dispatch, getState, sdk) => {
  dispatch(buyerProgressReportsRequest());
  // eslint-disable-next-line
  const url = config.serverBaseUrl + 'progress_reports' + '/' + buyerId + '/buyer';
  const headers = {
    headers: {
      Authorization: `Token token=${config.serverToken}`,
    },
  };
  return axios
    .get(url, headers)
    .then(response => {
      response.data.map(({ listing_id }) => {
        dispatch(fetchListing(listing_id));
      });
      dispatch(buyerProgressReportsSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(buyerProgressReportsError(e));
    });
};

export const acceptPackageRequest = id => (dispatch, getState, sdk) => {
  dispatch(acceptPackageRequestRequest());
  const url =
    config.serverBaseUrl +
    config.creditRequestEndPoint +
    '/' +
    id +
    '/' +
    config.packageAcceptEndPoint;
  const headers = {
    headers: {
      Authorization: `Token token=${config.serverToken}`,
    },
  };
  return axios
    .post(url, {}, headers)
    .then(response => {
      dispatch(acceptPackageRequestSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(acceptPackageRequestError(e));
    });
};

export const cancelPackageRequest = id => (dispatch, getState, sdk) => {
  dispatch(cancelPackageRequestRequest());
  const url =
    config.serverBaseUrl +
    config.creditRequestEndPoint +
    '/' +
    id +
    '/' +
    config.packageCancelEndPoint;
  const headers = {
    headers: {
      Authorization: `Token token=${config.serverToken}`,
    },
  };
  return axios
    .post(url, {}, headers)
    .then(response => {
      dispatch(cancelPackageRequestSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      dispatch(cancelPackageRequestError(e));
    });
};

export const fetchEnquiries = (page, isSale) => async (dispatch, getState, sdk) => {
  dispatch(fetchEnquiryRequest());
  try {
    const apiQueryParams = {
      only: isSale ? 'sale' : 'order',
      lastTransitions: TRANSITION_ENQUIRE,
      include: [
        'provider',
        'provider.profileImage',
        'customer',
        'customer.profileImage',
        'booking',
        'listing',
        'messages',
      ],
      'fields.transaction': [
        'lastTransition',
        'lastTransitionedAt',
        'transitions',
        'payinTotal',
        'payoutTotal',
        'metadata',
        'protectedData',
      ],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
      page,
      per_page: INBOX_PAGE_SIZE,
    };

    const response = await sdk.transactions.query(apiQueryParams);
    response.data.data = response.data.data.map(tx => compatibleTransaction(tx));

    dispatch(addMarketplaceEntities(response));
    dispatch(fetchEnquirySuccess(response));

    return response;
  } catch (e) {
    dispatch(fetchEnquiryError(storableError(e)));
    throw e;
  }
};

export const removePendingProgressReports = () => async (dispatch, getState, sdk) => {
  dispatch(skipProgressReports());
};

export const loadWalletData = params => (dispatch, getState, sdk) => {
  dispatch(walletListingsRequest());

  return dispatch(fetchCurrentUser()).then(response => {
    const currentUser = getState().user.currentUser;
    if (currentUser && currentUser.attributes.profile.privateData.sessions) {
      const listingIds = Object.keys(currentUser.attributes.profile.privateData.sessions);
      listingIds.map(listingId =>
        sdk.listings
          .show({
            id: listingId,
            include: ['author', 'author.profileImage', 'images'],
            'fields.image': [
              'variants.landscape-crop',
              'variants.landscape-crop2x',

              // Avatars
              'variants.square-small',
              'variants.square-small2x',
            ],
          })
          .then(response => {
            const listingResponse = denormalisedResponseEntities(response);
            dispatch(walletListingsSuccess(listingResponse[0]));
          })
          .catch(e => {
            console.log(listingId);
            dispatch(walletListingsError(storableError(e)));
          })
      );
    } else {
      dispatch(noWalletPackage());
    }
  });
};

//Schedule
export const fetchTransactions = () => async (dispatch, getState, sdk) => {
  try {
    dispatch(resetMarketplaceEntities());
    dispatch(fetchTransactionsRequest());

    const responses = await getAllItems(sdk.transactions.query, {
      only: 'sale',
      lastTransitions: [
        TRANSITION_ACCEPT,
        TRANSITION_RESCHEDULE_BY_PROVIDER,
        TRANSITION_RESCHEDULE_BY_CUSTOMER,
        TRANSITION_RESCHEDULE_BY_PROVIDER_AFTER_READY_TO_COMPLETE,
      ],
      include: [
        'provider',
        'customer',
        'booking',
        'listing',
        'provider.profileImage',
        'customer.profileImage',
      ],
      'fields.image': [
        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    });

    const refs = responses.reduce((acc, res) => [...acc, ...entityRefs(res.data.data)], []);
    dispatch(fetchTransactionsSuccess(refs));
    responses.forEach((res) => {
      dispatch(addMarketplaceEntities(res));
    });
  } catch (e) {
    dispatch(fetchTransactionsError(storableError(e)));
  }
};

export const fetchClientTransactions = () => async (dispatch, getState, sdk) => {
  try {
    dispatch(resetMarketplaceEntities());
    dispatch(fetchTransactionsRequest());

    const responses = await getAllItems(sdk.transactions.query, {
      only: 'order',
      lastTransitions: [
        TRANSITION_ACCEPT,
        TRANSITION_RESCHEDULE_BY_PROVIDER,
        TRANSITION_RESCHEDULE_BY_CUSTOMER,
        TRANSITION_RESCHEDULE_BY_PROVIDER_AFTER_READY_TO_COMPLETE,
      ],
      include: ['provider', 'customer', 'booking', 'listing'],
    });
    const refs = responses.reduce((acc, res) => [...acc, ...entityRefs(res.data.data)], []);

    dispatch(fetchTransactionsSuccess(refs));
    responses.forEach((res) => {
      dispatch(addMarketplaceEntities(res));
    });
  } catch (e) {
    dispatch(fetchTransactionsError(storableError(e)));
  }
};

export const fetchRecentTransactions = page => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchRecentTransactionsRequest());
    dispatch(resetMarketplaceEntities());

    // const response = await getAllItems(sdk.transactions.query, {
    //   only: 'sale',
    //   lastTransitions: COMPLETE_TRANSITIONS,
    //   include: ['provider', 'customer', 'booking', 'listing'],
    // });
    const response = await sdk.transactions.query({
      only: 'sale',
      page,
      per_page: 100,
      lastTransitions: undefined,
      include: [
        'provider',
        'customer',
        'booking',
        'listing',
        'provider.profileImage',
        'customer.profileImage',
      ],
      'fields.image': [
        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    });

    const refs = entityRefs(response.data.data);
    const stateEntities = updatedEntities({}, response.data);
    const txs = denormalisedEntities(stateEntities, refs);
    const completedTxs = txs.filter(tx => COMPLETE_TRANSITIONS.includes(tx.attributes.lastTransition))

    const transformedTxs = transformRecentTransactions(completedTxs);

    dispatch(
      fetchRecentTransactionsSuccess({
        recentTransactions: transformedTxs,
        pagination: response.data.meta,
      })
    );
  } catch (e) {
    dispatch(fetchTransactionsError(storableError(e)));
  }
};

export const sendMessage = (txId, message) => (dispatch, getState, sdk) => {
  dispatch(sendMessageRequest());

  return sdk.messages
    .send({ transactionId: txId, content: message })
    .then(response => {
      dispatch(sendMessageSuccess());
    })
    .catch(e => {
      dispatch(sendMessageError(storableError(e)));
      // Rethrow so the page can track whether the sending failed, and
      // keep the message in the form for a retry.
      throw e;
    });
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  let { tab, state = 'all', action = 'all' } = params;
  const { page = 1, query } = parse(search);

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
    transactions: 'transactions',
    myClients: 'myClients',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  const stateData = getState();
  if (
    stateData.InboxPage.incomeTransactions.length === 0 &&
    stateData.InboxPage.incomeTransactionsLoading === false
  ) {
    dispatch(fetchIncomeTransactions());
  }

  if (onlyFilter === 'transactions') {
    return dispatch(fetchIncomeTransactions());
  } else if (onlyFilter === 'myClients') {
    return dispatch(fetchRecentTransactions(page));
  } else {
    dispatch(fetchOrdersOrSalesRequest());


    /** Filter transitions by state.
     * Also inside of getTransitionsByState() ENQUIRY transactions are filtered out
     * because they have a separate page
     **/
    let transitionsByState = ['transition/none'];

    switch (action) {
      case 'action-required':
        if (state === filterState.ALL) {
          if (onlyFilter === onlyFilterValues.sales) {
            transitionsByState = [
              TRANSITION_ENQUIRE,
              TRANSITION_CONFIRM_PAYMENT,
              // for processAlias release-1
              TRANSITION_CHARGE_CUSTOMER,
              TRANSITION_ACCEPT,
              TRANSITION_REQUEST_CHANGES,
              TRANSITION_OP_REQUEST_CHANGES,
            ];
          } else {
            transitionsByState = [
              TRANSITION_SET_PRICE,
              TRANSITION_UPDATE_PRICE,
              TRANSITION_REQUEST_PAYMENT,
              // for processAlias release-1
              TRANSITION_SET_DELIVERED,
              TRANSITION_OP_SET_DELIVERED,
              TRANSITION_MARK_DELIVERED_CHANGES,
              TRANSITION_OP_MARK_DELIVERED_CHANGES,
              TRANSITION_COMPLETE_MANUAL,
              TRANSITION_COMPLETE,
              TRANSITION_OP_COMPLETE,
            ];
          }
        } else if (state === filterState.ENQUIRY) {
          if (onlyFilter === onlyFilterValues.sales) {
            transitionsByState = [
              TRANSITION_ENQUIRE,
            ];
          } else {
            transitionsByState = [
              TRANSITION_SET_PRICE,
              TRANSITION_UPDATE_PRICE,
              TRANSITION_REQUEST_PAYMENT,
            ];
          }
        } else if (state === filterState.WORKSTARTED) {
          if (onlyFilter === onlyFilterValues.sales) {
            transitionsByState = [
              TRANSITION_CONFIRM_PAYMENT,
              // for processAlias release-1
              TRANSITION_ACCEPT,
              TRANSITION_REQUEST_EXTEND_DELIVERY_PERIOD,
              TRANSITION_AUTO_REQUEST_EXTEND_DELIVERY_PERIOD,
              TRANSITION_OP_REQUEST_EXTEND_DELIVERY_PERIOD,
              TRANSITION_ACCEPT_EXTEND_DELIVERY_PERIOD,
              TRANSITION_OP_ACCEPT_EXTEND_DELIVERY_PERIOD,
              TRANSITION_REQUEST_CHANGES,
              TRANSITION_OP_REQUEST_CHANGES,
              TRANSITION_SET_DELIVERED,
              TRANSITION_OP_SET_DELIVERED,
              TRANSITION_MARK_DELIVERED_CHANGES,
              TRANSITION_OP_MARK_DELIVERED_CHANGES,
            ];
          }
        } else if (state === filterState.DELIVERED) {
          if (onlyFilter === onlyFilterValues.sales) {
            transitionsByState = [
              TRANSITION_CONFIRM_PAYMENT,
              TRANSITION_OP_REVIEW_1_BY_CUSTOMER,
            ]
          } else {
            transitionsByState = [
              TRANSITION_CONFIRM_PAYMENT,
              TRANSITION_OP_REVIEW_1_BY_PROVIDER,
              // for processAlias release-1
              TRANSITION_COMPLETE_MANUAL,
              TRANSITION_COMPLETE,
              TRANSITION_OP_COMPLETE,
            ];
          }
        } else {
          break;
        }
        break;
      case 'all':
        transitionsByState = getTransitionsByState(state);
        if (state === 'all') transitionsByState = undefined;
        break;
      default:
        break;
    }

    // Exclude transaction that have not reached the "preauthorized" state.
    // transitionsByState = transitionsByState
    //   .filter((t) => (
    //     ![TRANSITION_REQUEST_PAYMENT, TRANSITION_EXPIRE_PAYMENT].includes(t)
    //   ));

    const apiQueryParams = {
      only: onlyFilter,
      lastTransitions: transitionsByState,
      include: [
        'provider',
        'provider.profileImage',
        'customer',
        'customer.profileImage',
        'booking',
        'listing',
      ],
      'fields.transaction': [
        'lastTransition',
        'lastTransitionedAt',
        'transitions',
        'payinTotal',
        'payoutTotal',
        'metadata',
        'protectedData',
      ],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
      page,
      per_page:
        state === 'ready-to-complete' ? INBOX_PAGE_SIZE_FOR_READY_TO_COMPLETE : INBOX_PAGE_SIZE,
    };

    // Special case of frontend filtering
    if (state === filterState.WORKSTARTED || state === filterState.DELIVERED) {
      return fetchFrontendFilteredTransaction(dispatch, sdk, apiQueryParams, page, state);
    }

    return sdk.transactions
      .query(apiQueryParams)
      .then(response => {
        response.data.data = response.data.data.map(tx => compatibleTransaction(tx));
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchOrdersOrSalesSuccess(response));
        return response;
      })
      .catch(e => {
        dispatch(fetchOrdersOrSalesError(storableError(e)));
        throw e;
      });
  }
};

async function fetchFrontendFilteredTransaction(dispatch, sdk, apiQueryParams, page, state) {
  try {
    let filteredTransactionList = [];
    const queryResponses = [];
    let filteredPage = 0;
    let realFetchedPage = 0;
    let noMoreData = false;
    const LOOP_LIMIT = 10;

    do {
      // Fetch a new raw page of data.
      realFetchedPage += 1;
      const modifiedParams = {...apiQueryParams};
      modifiedParams.page = realFetchedPage;
      modifiedParams.per_page = 100;
      delete modifiedParams.lastTransitions;

      const loopRes = await sdk.transactions
        .query(modifiedParams)
        .then(response => {
          response.data.data = response.data.data.map(tx => compatibleTransaction(tx));
          return response
        });
      queryResponses.push(loopRes);

      // Filter out non-matching transactions.
      let filteredData = loopRes;
      if (state === filterState.DELIVERED) {
        filteredData = loopRes.data.data.filter(tx => tx?.attributes?.metadata?.workflowStatus === 'delivered')
      } else if (state === filterState.WORKSTARTED) {
        const transitionsByCancelState = getTransitionsByState(filterState.CANCELED)
        filteredData = loopRes.data.data.filter(tx => [
          undefined,
          'started',
          'revealed'
        ].includes(tx?.attributes?.metadata?.workflowStatus)
          && tx?.attributes?.transitions.some(transition =>
            transition.transition === TRANSITION_CONFIRM_PAYMENT)
          && !tx?.attributes?.transitions.find(transition =>
            transitionsByCancelState.includes(transition.transition))
        )
      }

      filteredTransactionList = filteredTransactionList.concat(filteredData);

      // Check if the target page has been reached.
      filteredPage = Math.ceil(filteredTransactionList.length / INBOX_PAGE_SIZE);

      // Check if there is no more data to fetch.
      noMoreData = (realFetchedPage >= loopRes.data.meta.totalPages || realFetchedPage >= loopRes.data.meta.paginationLimit);

    } while(!noMoreData && (filteredPage <= page) && realFetchedPage < LOOP_LIMIT);

    // Create a result object in a format compatible with a Sharetribe API query
    // result.
    const result = {
      status: 200,
      statusText: "",
      data : {
        data: filteredTransactionList.slice(
          (page - 1) * INBOX_PAGE_SIZE,
          page * INBOX_PAGE_SIZE
        ),
        included: queryResponses.reduce(
          (accum, val) => {
            return accum.concat(val.data.included);
          },
          []
        ),
        meta: {
          "totalItems": filteredTransactionList.length,
          "totalPages": filteredPage,
          "page": page,
          // "paginationLimit": 10, //FIXME
          "perPage": INBOX_PAGE_SIZE
        }
      },
    };

    dispatch(addMarketplaceEntities(result));
    dispatch(fetchOrdersOrSalesSuccess(result));
    return result;
  } catch(e) {
    console.log('error detected', e);
    dispatch(fetchOrdersOrSalesError(storableError(e)));
    throw e;
  }
}
