import React from 'react';
import PropTypes from 'prop-types';

import css from './ProgressReportModal.css';

import {
  Modal,
  SecondaryButton,
  PrimaryButton
} from '../../components';

import { FormattedMessage } from '../../util/reactIntl';

const ProgressReportModal = props => {
  const { 
    isAnyPendingProgressReport,
    removePendingProgressReports,
    proceedToProgressReportHandler,
    onManageDisableScrolling,
  } = props;


  return (
    <Modal
      id="ProgressReportModal"
      isOpen={isAnyPendingProgressReport}
      onClose={() => removePendingProgressReports()}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={css.progressReportWrapper}>
        <h2 className={css.progressReportHeading}>
          <FormattedMessage id="ProgressReportModal.progressReportHeading" />
        </h2>

        <div>
          <div className={css.banner} />
          <p>
            <FormattedMessage id={'ProgressReportModal.addProgressReport'} />
          </p>

          <div className={css.buttons}>
            <PrimaryButton onClick={() => proceedToProgressReportHandler()}>
              <FormattedMessage id={'ProgressReportModal.proceedToProgressReport'} />
            </PrimaryButton>
            <SecondaryButton onClick={() => removePendingProgressReports()}>
              <FormattedMessage id={'ProgressReportModal.skipProgressReport'} />
            </SecondaryButton>
          </div>
        </div>
        
      </div>
    </Modal>
  );
};

ProgressReportModal.defaultProps = {
  rootClassName: null,
  className: null,
  isAnyPendingProgressReport: null,
  removePendingProgressReports: null
};

const { string } = PropTypes;

ProgressReportModal.propTypes = {
  rootClassName: string,
  className: string,
};

export default ProgressReportModal;
