import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import css from './MultiSelectionFilter.css';
import { convertMultipleSelectQueryStringToArray } from '../../utils';
import { convertArrayToSearchString } from 'util/urlHelpers';
import styled from 'styled-components';
import { Badge, Checkbox, FormControlLabel } from '@mui/material';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 6,
    top: 6,
    border: `1px solid #fff`,
    padding: '0 4px',
  },
}));

const MultiSelectionFilter = (props) => {
  const {
    handleFiltersChange,
    currentQueryParams,
    id,
    searchKey,
    options,
    type,
    name,
  } = props;

  const wrapperRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);

  const valueFromQueryString =
    currentQueryParams && convertMultipleSelectQueryStringToArray(currentQueryParams[searchKey]);
  const selectedOptions = valueFromQueryString ? valueFromQueryString : [];

  //popup logic
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);
  // ---

  const triggerOptions = (options) => {
    const searchValue = convertArrayToSearchString(options, type);
    const isSearchValueEmpty = searchValue === type + ':';
    handleFiltersChange({ [searchKey]: isSearchValueEmpty ? null : searchValue });
  };

  return (
    <div className={classNames(css.wrapper, css.disableSelect)} ref={wrapperRef}>
      <StyledBadge className={css.badgeWrapperClass} badgeContent={valueFromQueryString?.length} color="success" max={9}>
        <div className={(showPopup || valueFromQueryString) ? css.filterLabelSelected : css.filterLabel} onClick={() => setShowPopup(!showPopup)}>
          <span>{name}</span>
        </div>
      </StyledBadge>
      {showPopup && (
        <div className={css.popupWrapper}>
          {options.map(option => {
            const isChecked = selectedOptions.some(item => item == option.key);

            return <FormControlLabel
              control={
                <Checkbox
                  size='sm'
                  value={option.key}
                  checked={isChecked}
                  onClick={(event) => {
                    if (!selectedOptions.includes(event.target.value)) {
                      selectedOptions.push(event.target.value);
                      triggerOptions(selectedOptions);
                    } else {
                      const newSelectedOptions = selectedOptions.filter(option => option != event.target.value);
                      triggerOptions(newSelectedOptions);
                    }
                  }}
                />
              }
              label={option.label}
            />
          })}
        </div>
      )}
    </div>
  );
}

export default MultiSelectionFilter;
