import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  {
    id: 'default-anywhere',
    predictionPlace: {
      address: 'Anywhere',
    },
  },
  {
    id: 'default-asia',
    predictionPlace: {
      address: 'Asia',
      bounds: new LatLngBounds(
        new LatLng(66.5395535, 153.41559426),
        new LatLng(-27.44746443, 52.03631173)
      ),
      origin: new LatLng((-27.44746443 + 66.5395535) / 2, (153.41559426 + 52.03631173) / 2),
    },
  },
  {
    id: 'default-africa',
    predictionPlace: {
      address: 'Africa',
      bounds: new LatLngBounds(
        new LatLng(37.39778623, 67.088265),
        new LatLng(-61.36832882, -34.29101753)
      ),
      origin: new LatLng((-61.36832882 + 37.39778623) / 2, (67.088265 + -34.29101753) / 2),
    },
  },
  {
    id: 'default-antarctica',
    predictionPlace: {
      address: 'Antarctica',
      bounds: new LatLngBounds(
        new LatLng(-42.56459707, 127.85254996),
        new LatLng(-85.051129, 14.29766053)
      ),
      origin: new LatLng((-42.56459707 + -85.051129) / 2, (127.85254996 + 14.29766053) / 2),
    },
  },
  {
    id: 'default-australasia',
    predictionPlace: {
      address: 'Australasia / Oceania',
      bounds: new LatLngBounds(
        new LatLng(17.858276, -153.9076012),
        new LatLng(-74.61956637, 92.53750937)
      ),
      origin: new LatLng((17.858276 + -74.61956637) / 2, (-153.9076012 + 92.53750937) / 2),
    },
  },
  {
    id: 'default-europe',
    predictionPlace: {
      address: 'Europe',
      bounds: new LatLngBounds(
        new LatLng(69.65826288, 44.15451716),
        new LatLng(30.45832948, -12.62292756)
      ),
      origin: new LatLng((69.65826288 + 30.45832948) / 2, (44.15451716 + -12.62292756) / 2),
    },
  },
  {
    id: 'default-northAmerica',
    predictionPlace: {
      address: 'North America',
      bounds: new LatLngBounds(
        new LatLng(78.23717402, -51.62838263),
        new LatLng(-0.83931096, -163.63853013)
      ),
      origin: new LatLng((69.65826288 + -0.83931096) / 2, (-51.62838263 + -163.63853013) / 2),
    },
  },
  {
    id: 'default-southAmerica',
    predictionPlace: {
      address: 'South America',
      bounds: new LatLngBounds(
        new LatLng(16.38310346, -21.86297953),
        new LatLng(-74.64630204, -134.18490819)
      ),
      origin: new LatLng((16.38310346 + -74.64630204) / 2, (-21.86297953 + -134.18490819) / 2),
    },
  },
];
