import React from 'react';
import AddressLinkMaybe from './AddressLinkMaybe';
import Lyfshort_icon from '../../assets/Listing_Page/Lyfshort_icon.svg';

import css from './TransactionPanel.css';

// Functional component as a helper to build detail card headings
const DetailCardHeadingsMaybe = props => {
  const {
    showDetailCardHeadings,
    listingTitle,
    subTitle,
    location,
    geolocation,
    showAddress,
    otherUserDisplayName,
    userName,
    isOnlyDesktop,
  } = props;

  return showDetailCardHeadings ? (
    <div className={isOnlyDesktop ? css.detailCardHeadings : css.detailsCardHeadingsMobile}>
      <h2 className={css.detailCardTitle}>{otherUserDisplayName}</h2>
      <div className={css.userNameWrapper}>
        <img src={Lyfshort_icon} alt="Lyfshort icon" />
        <p>{userName}</p>
      </div>
      <AddressLinkMaybe location={location} geolocation={geolocation} showAddress={showAddress} />
    </div>
  ) : null;
};

export default DetailCardHeadingsMaybe;
