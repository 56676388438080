import React, { Component } from 'react';
import PropTypes, { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { calculateQuantityFromHours, timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Form,
  PrimaryButton,
  FieldCheckbox,
  FieldTextInput,
  SimplePackages,
  FieldSelect,
} from '../../components';
import { formatMoney } from '../../util/currency';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
// import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { isSeatsValueValid, composeValidators } from 'util/validators';

import css from './BookingTimeForm.css';
import moment from 'moment';
import { LISTING_TYPES } from 'util/constants';
import ClassBookingFormFields from 'forms/BookingTimeForm/ClassBookingFormFields';

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);
    const { currentUser, listing } = this.props;
    const credits =
      currentUser &&
        currentUser.attributes &&
        currentUser.attributes.profile.privateData.sessions &&
        currentUser.attributes.profile.privateData.sessions[listing.id.uuid]
        ? currentUser.attributes.profile.privateData.sessions[listing.id.uuid]
        : 0;
    const hasCredits = credits && credits > 0;

    this.directPaymentTabRef = React.createRef();
    this.periodRef = React.createRef();
    this.state = {
      invalidDates: false,
      selectedNumberOfHours: null,
      activeTab: hasCredits ? 'packages' : 'direct-payment',
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onInvalidDateTime = this.onInvalidDateTime.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onPeriodChange = this.onPeriodChange.bind(this);
  }

  handleFormSubmit(e) {
    const { currentUser, listing } = this.props;
    const credits =
      currentUser &&
        currentUser.attributes &&
        currentUser.attributes.profile.privateData.sessions &&
        currentUser.attributes.profile.privateData.sessions[listing.id.uuid]
        ? currentUser.attributes.profile.privateData.sessions[listing.id.uuid]
        : 0;
    const hasCredits = credits && credits > 0;

    const sartDateTime = timestampToDate(e.bookingStartTime);
    const endDateTime = timestampToDate(e.bookingEndTime);
    if (this.state.activeTab === 'packages' && hasCredits) {
      const numberOfhours =
        endDateTime && sartDateTime
          ? moment.duration(moment(endDateTime).diff(moment(sartDateTime))).asHours()
          : null;
      const periodValue = e.period || 1;
      if (numberOfhours * Number(periodValue) > credits) {
        this.onInvalidDateTime(numberOfhours);
      } else {
        this.props.onSubmit(e);
      }
    } else {
      this.props.onSubmit(e);
    }
  }

  onInvalidDateTime(numberOfHours) {
    const { currentUser, listing } = this.props;
    const credits =
      currentUser &&
        currentUser.attributes &&
        currentUser.attributes.profile.privateData.sessions &&
        currentUser.attributes.profile.privateData.sessions[listing.id.uuid]
        ? currentUser.attributes.profile.privateData.sessions[listing.id.uuid]
        : 0;

    const periodValue =
      this.periodRef && this.periodRef.current ? this.periodRef.current.value : null;

    const invalid = periodValue ? numberOfHours * Number(periodValue) > credits : true;

    if (invalid) {
      this.setState({
        invalidDates: true,
        selectedNumberOfHours: numberOfHours,
      });
    } else {
      this.setState({
        invalidDates: false,
        selectedNumberOfHours: numberOfHours,
      });
    }
  }

  onPeriodChange(value) {
    const { selectedNumberOfHours } = this.state;
    this.onInvalidDateTime(selectedNumberOfHours);
  }

  onSelect(index) {
    if (index === 1) {
      this.setState({
        activeTab: 'packages',
      });
    } else {
      this.setState({
        invalidDates: false,
        activeTab: 'direct-payment',
      });
    }
  }

  classTypeBookingValidation = values => {
    const errors = {};
    if (!values.bookingStartDate) {
      errors.bookingStartDate = 'Required';
    }
    return errors;
  };

  getFormInitData = () => {
    const { monthlyTimeSlots, isProduct } = this.props;

    if (!isProduct) {
      return {};
    }

    const initData = monthlyTimeSlots ? Object.entries(monthlyTimeSlots) : [];
    const initDate = initData.length ? initData[0][1] : {};
    const startTime = initDate.timeSlots ? initDate.timeSlots[0].attributes.start : null;
    const endTime = initDate.timeSlots ? initDate.timeSlots[0].attributes.end : null;

    return {
      bookingStartDate: { date: startTime },
      bookingEndDate: { date: endTime },
      bookingStartTime: moment(startTime).valueOf(),
      bookingEndTime: moment(startTime)
        .add(1, 'hours')
        .valueOf(),
      timeSlotsOnSelectedDate: initDate.timeSlots,
      seats: '1',
      period: '1',
    };
  };

  render() {
    const {
      rootClassName,
      className,
      handlePackagesModalOpen,
      showTopup,
      price: unitPrice,
      isProduct,
      onManageDisableScrolling,
      ComponentOverlap,
      onSkip,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const listingType = rest.listing.attributes.publicData.listing_type;

    if (!unitPrice && listingType === LISTING_TYPES.ENQUIRY) {
      return "";
    }
    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        initialValues={this.getFormInitData()}
        validate={listingType === LISTING_TYPES.CLASS ? this.classTypeBookingValidation : undefined}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            listing,
            currentUser,
            submitButtonWrapperClassName,
            unitPrice,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            priceAddons,
          } = fieldRenderProps;

          const listingType = listing.attributes.publicData.listing_type;
          const timeSlotsOnSelectedDate = values.timeSlotsOnSelectedDate;
          const credits =
            currentUser &&
              currentUser.attributes &&
              currentUser.attributes.profile.privateData.sessions &&
              currentUser.attributes.profile.privateData.sessions[listing.id.uuid]
              ? currentUser.attributes.profile.privateData.sessions[listing.id.uuid]
              : 0;
          const hasCredits = credits && credits > 0;

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const { invalidDates, selectedNumberOfHours } = this.state;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;

          const selectedPriceAddons =
            values &&
            values.additionalItems &&
            priceAddons.filter(p => values.additionalItems.find(i => i === p.label));

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                unitType,
                unitPrice,
                startDate,
                endDate,
                // Calculate the quantity as hours between the booking start and booking end
                quantity: calculateQuantityFromHours(startDate, endDate),
                timeZone,
                priceAddons: selectedPriceAddons,
                seats: Number(values.seats),
                period: Number(values.period),
              }
              : null;
          const bookingInfo = bookingData ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} />
            </div>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const { packages } = listing.attributes.publicData;
          const isPackagesTab = this.state.activeTab === 'packages';
          const defaultIndex = isPackagesTab ? 1 : 0;
          const areFieldsVisible = !isPackagesTab || (isPackagesTab && hasCredits);
          const periodValue =
            this.periodRef && this.periodRef.current ? this.periodRef.current.value : 0;
          return (
            <Form onSubmit={handleSubmit} className={classes}>
              <>
                {!!areFieldsVisible && (
                  <div>
                    {!isProduct && monthlyTimeSlots && timeZone ? (
                      listingType === LISTING_TYPES.CLASS ? (
                        <ClassBookingFormFields
                          monthlyTimeSlots={monthlyTimeSlots}
                          timeZone={timeZone}
                          areFieldsVisible={areFieldsVisible}
                          isProduct={isProduct}
                          form={form}
                          listing={listing}
                          onManageDisableScrolling={onManageDisableScrolling}
                        />
                      ) : (
                        <FieldDateAndTimeInput
                          {...dateInputProps}
                          className={css.bookingDates}
                          listingId={listingId}
                          bookingStartLabel={bookingStartLabel}
                          onFetchTimeSlots={onFetchTimeSlots}
                          monthlyTimeSlots={monthlyTimeSlots}
                          values={values}
                          intl={intl}
                          form={form}
                          pristine={pristine}
                          timeZone={timeZone}
                          credits={credits}
                          onInvalidDateTime={this.onInvalidDateTime}
                          isPackagesTab={isPackagesTab}
                        />
                      )
                    ) : null}

                    {isPackagesTab && hasCredits && (
                      <div className={css.periodSelect}>
                        <FieldSelect
                          id="period"
                          initialValue={ComponentOverlap ? '6' : '1'}
                          name="period"
                          label={'Select period'}
                          onChange={this.onPeriodChange}
                          inputRef={this.periodRef}
                        >
                          <option value="1">1 week</option>
                          <option value="2">2 weeks</option>
                          <option value="4">4 weeks</option>
                          <option value="6">6 weeks</option>
                          <option value="8">8 weeks</option>
                          <option value="10">10 weeks</option>
                        </FieldSelect>
                      </div>
                    )}

                    {invalidDates ? (
                      <span className={css.invalidDates}>
                        <FormattedMessage
                          id="BookingTimeForm.invalidDates"
                          values={{ credits, selectedNumberOfHours, periodValue }}
                        />
                      </span>
                    ) : null}

                    {/* {!isProduct && timeSlotsOnSelectedDate && timeSlotsOnSelectedDate[0] && defaultIndex === 0 && (
                      <div className={css.darkBorder}><FieldTextInput
                        className={css.seatsField}
                        id={`seats`}
                        name={`seats`}
                        type="number"
                        label={intl.formatMessage({ id: 'BookingTimeForm.seatsLabel' })}
                        initialValue={'1'}
                        min={1}
                        max={timeSlotsOnSelectedDate[0].attributes.seats}
                        validate={composeValidators(isSeatsValueValid, value =>
                          value <= timeSlotsOnSelectedDate[0].attributes.seats && value > 0
                            ? undefined
                            : `Must be more then 0 and less then ${timeSlotsOnSelectedDate[0].attributes.seats}`
                        )}
                      />
                      </div>)} */}

                    {!isPackagesTab &&
                      priceAddons &&
                      !!priceAddons.length &&
                      priceAddons.map((addon, index) => (
                        <div key={`addon${index}`} className={css.priceAddon}>
                          <FieldCheckbox
                            id={`${addon.label}`}
                            label={addon.label}
                            name={'additionalItems'}
                            value={addon.label}
                          />
                          <span className={css.priceAddonAmount}>
                            {formatMoney(intl, addon.price)}
                          </span>
                        </div>
                      ))}
                  </div>
                )}
              </>

              {!isPackagesTab ? bookingInfo : null}

              {/* <div className={submitButtonClasses}>
                {!ComponentOverlap && <p>
                  <input type="radio" id={ComponentOverlap ? "test3" : "test1"} name="radio-group" onClick={() => {
                    this.onSelect(0);
                  }} defaultChecked={defaultIndex === 0 ? true : false} />
                  <label htmlFor={ComponentOverlap ? "test3" : "test1"}>Direct Payment</label>
                </p>}
                <p>
                  <input type="radio" id={ComponentOverlap ? "test4" : "test2"} name="radio-group" onClick={() => {
                    this.onSelect(1);
                  }} defaultChecked={defaultIndex === 1 ? true : false} />
                  <label htmlFor={ComponentOverlap ? "test4" : "test2"}>Packages</label>
                </p>

                {defaultIndex === 0 && <div className={css.submitButtonPanel}>
                  <PrimaryButton
                    type="submit"
                    onClick={() => {
                      form.change('withPayment', true);
                      form.change('withCredits', false);
                    }}
                  >
                    <FormattedMessage id="BookingTimeForm.continue" />
                  </PrimaryButton>
                </div>}

                {defaultIndex === 1 && <div className={css.submitButtonPanel}>
                  {!ComponentOverlap && <SimplePackages listing={listing} intl={intl} />}
                  {hasCredits ? (
                    <div>
                      <PrimaryButton
                        type="submit"
                        className={css.requestToBookWithCredits}
                        onClick={() => {
                          form.change('withPayment', false);
                          form.change('withCredits', true);
                        }}
                        disabled={invalidDates}
                      >
                        {ComponentOverlap ?
                          <FormattedMessage id="BookingTimeForm.bookYourRecurringTimes" /> :
                          <FormattedMessage id="BookingTimeForm.requestToBookWithCredits" />}
                      </PrimaryButton>
                    </div>
                  ) : null}
                  {!isOwnListing && packages && packages.length > 0 && showTopup ? (
                    <div className={css.buyPackage}>
                      <PrimaryButton
                        onClick={e => {
                          e.preventDefault();
                          handlePackagesModalOpen();
                        }}
                      >
                        {
                          hasCredits ?
                            <FormattedMessage id="BookingTimeForm.topup" /> :
                            <FormattedMessage id="BookingTimeForm.proceed" />
                        }
                      </PrimaryButton>
                    </div>
                  ) : null}

                  {listing.attributes.publicData.packages.length === 0 && isPackagesTab && (
                    <p className={css.smallPrint}>
                      <FormattedMessage id="BookingTimeForm.noPackages" />
                    </p>)}

                  <p className={css.smallPrint}>
                    <FormattedMessage
                      id={
                        isOwnListing
                          ? 'BookingTimeForm.ownListing'
                          : 'BookingTimeForm.youWontBeChargedInfo'
                      }
                    />
                  </p>
                </div>}
              </div> */}


              <div className={submitButtonClasses}>
                <PrimaryButton
                  type="submit"
                  rootClassName={css.checkAvailabilityButton}
                  onClick={() => {
                    form.change('withPayment', true);
                    form.change('withCredits', false);
                    form.change('seats', 1);
                  }}
                >
                  <FormattedMessage id="BookingTimeForm.checkAvailability" />
                </PrimaryButton>
              </div>


              {/* {showContinueAndSkipButton === true && (
                <div className={css.continueAndSkipContainer}>
                  <PrimaryButton
                    type="submit"
                    onClick={() => {
                      form.change('withPayment', true);
                      form.change('withCredits', false);
                      form.change("payment_method", this.props.listing.attributes.publicData.payment_method || []);
                    }}
                  >
                    <FormattedMessage id="BookingTimeForm.continue" />
                  </PrimaryButton>
                  <SecondaryButton
                    type="button"
                    onClick={onSkip}
                  >
                    <FormattedMessage id="BookingTimeForm.skip" />
                  </SecondaryButton>
                </div>
              )} */}
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  priceAddons: PropTypes.array,
  isProduct: bool,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,

  onManageDisableScrolling: func,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
