import React from 'react';
import css from './TransactionStateButton.css';
import PropTypes from 'prop-types';
import cns from 'classnames';

export const TransactionStateButton = ({ isActive, state, label, onClick }) => {
  return (
    <div
      key={state}
      className={cns({ [css.stateBtn]: true, [css.stateBtnActive]: isActive })}
      onClick={onClick}
    >
      <span>{label}</span>
    </div>
  );
};

TransactionStateButton.propTypes = {
  isActive: PropTypes.bool,
  state: PropTypes.string,
  label: PropTypes.element,
  onClick: PropTypes.func,
};

export default TransactionStateButton;
