import React from 'react';
import {
  IconMasterVerified,
  IconNew, IconLevel1,
  IconProVerified,
  IconNewSmall,
  IconLevel1Small,
  IconMasterVerifiedSmall,
  IconProVerifiedSmall
} from './../../components/IconLevel/IconLevel'

const SectionLevel = props => {
  const {
    metadata,
    bigIcon = false,
  } = props;

  if (bigIcon) {
    switch (metadata && metadata.level) {
      case 'one':
      case 'two':
      case 'three':
        return <IconLevel1 />
      case 'pro':
        return <IconProVerified />
      case 'master_verified':
        return <IconMasterVerified />
      default:
        return <IconNew />
    }
  }
  else {
    switch (metadata && metadata.level) {
      case 'one':
      case 'two':
      case 'three':
        return <IconLevel1Small />
      case 'pro':
        return <IconProVerifiedSmall />
      case 'master_verified':
        return <IconMasterVerifiedSmall />
      default:
        return <IconNewSmall />
    }
  }
};

export default SectionLevel;
