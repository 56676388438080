import React, { useState, useCallback } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { currencyConfiguration } from '../../currency-config';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  Button,
  Form,
  FieldCurrencyInput,
  FieldTextInput,
  FieldRangeSlider,
  FieldMultipleSelection,
  HeadingFormFieldWrapper,
} from '../../components';
import css from './EditListingPricingForm.css';
import { PRICING } from 'util/constants';
import Collapsible from 'react-collapsible';
import ImageViewer from 'react-simple-image-viewer';
import priceSensitivityImage from '../../assets/price_sensitivity.png';
import { PlainTabs } from 'components';
import { CustomBookingSimulatorForm } from 'forms';
import { NavLink } from 'react-router-dom';

const { Money } = sdkTypes;
const MAX_FIELDS = 5;

const textRequired = value => (value ? undefined : 'Required');

const DESCRIPTION_MAX_LENGTH = 250;

export const EditListingPricingFormComponent = props => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);
  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          form,
          values,
        } = formRenderProps;

        const {
          mutators: { push, pop },
        } = form;

        const priceRequired = validators.required(
          intl.formatMessage({
            id: 'EditListingPricingForm.priceRequired',
          })
        );

        const minPrice = new Money(
          config.listingMinimumPriceSubUnits,
          values.base_currency || config.currency
        );
        const minPriceRequired = validators.moneySubUnitAmountAtLeast(
          intl.formatMessage(
            {
              id: 'EditListingPricingForm.priceTooLow',
            },
            {
              minPrice: formatMoney(intl, minPrice),
            }
          ),
          config.listingMinimumPriceSubUnits
        );
        const priceValidators = config.listingMinimumPriceSubUnits
          ? validators.composeValidators(priceRequired, minPriceRequired)
          : priceRequired;

        //Introduction - Custom
        const introductionCustomLabel = intl.formatMessage({
          id: 'EditListingPricingForm.introductionCustomLabel',
        });
        const introductionCustomSupportMessage = intl.formatMessage({
          id: 'EditListingPricingForm.introductionCustomSupport',
        });

        //Price
        const pricePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPricingForm.pricePlaceholder',
        });
        const priceSupportMessage = intl.formatMessage({
          id: 'EditListingPricingForm.priceSupport',
        });

        const priceLabel = intl.formatMessage({
          id: 'EditListingPricingForm.package1PriceLabel',
        });

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress || pristine;
        const { updateListingError, showListingsError } = fetchErrors || {};

        return (
          <Form onSubmit={handleSubmit} className={classes}>
            <div className={css.btnWrapper}>
              <div>
                <h1 className={css.headingTitle}>{PRICING}</h1>
              </div>
              <Button
                rootClassName={css.topSaveButton}
                type="submit"
                inProgress={false}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </div>
            <div className={css.formGroup}>
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingPricingForm.updateFailed" />
                </p>
              ) : null}
              {showListingsError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingPricingForm.showListingFailed" />
                </p>
              ) : null}
              <p className={css.pricingInfo}>
                <FormattedMessage
                  id="EditListingPricingForm.generalDescription"
                  values={{
                    title: header => <span className={css.titleText}>{header}</span>,
                    br: <br />
                  }}
                />
              </p>
              <p className={css.pricingInfo}>
                <FormattedMessage
                  id="EditListingPricingForm.inclusionDescription"
                  values={{
                    bold: header => <span className={css.boldText}>{header}</span>,
                    br: <br />
                  }}
                />
              </p>
              <p className={css.pricingInfo}>
                <FormattedMessage
                  id="EditListingPricingForm.exclusionDescription"
                  values={{
                    bold: header => <span className={css.boldText}>{header}</span>,
                    br: <br />
                  }}
                />
              </p>
              <p className={css.pricingInfo}>
                <FormattedMessage
                  id="EditListingPricingForm.minPriceDescription"
                  values={{
                    bold: header => <span className={css.boldText}>{header}</span>,
                    br: <br />
                  }}
                />
              </p>

              <HeadingFormFieldWrapper
                label={priceLabel}
                supportText={priceSupportMessage}
              >
                <FieldCurrencyInput
                  id="price"
                  name="price"
                  className={css.priceInput}
                  placeholder={pricePlaceholderMessage}
                  currencyConfig={
                    currencyConfiguration(values.base_currency || 'USD') || config.currencyConfig
                  }
                  validate={priceValidators}
                />
              </HeadingFormFieldWrapper>
              {isViewerOpen && (
                <ImageViewer
                  src={[priceSensitivityImage]}
                  currentIndex={0}
                  onClose={closeImageViewer}
                  backgroundStyle={{
                    zIndex: '90000',
                    backgroundColor: 'rgba(0,0,0,0.7)',
                  }}
                  closeOnClickOutside={true}
                />
              )}

              <Button
                rootClassName={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
