import React, { useRef, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import css from './PriceFilter.module.css';
import styled from 'styled-components';
import { Badge } from '@mui/material';
import { RangeSlider } from 'components';
import { debounce } from 'lodash';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 6,
    top: 6,
    border: `1px solid #fff`,
    padding: '0 4px',
  },
}));

const PriceFilter = props => {
  const { handleFiltersChange, currentQueryParams } = props;

  //popup logic
  const wrapperRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);

  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);
  // ---

  //slider logic
  const priceFromQueryString = currentQueryParams?.price;
  const rangeInitialValue = priceFromQueryString
    ? priceFromQueryString.split(',').map(Number)
    : [10, 500];
  const [range, setRange] = useState(rangeInitialValue);

  const handleDebouncedChange = useCallback(
    debounce(value => {
      handleSubmit(value);
    }, 500),
    [handleFiltersChange]
  );

  const handleChange = value => {
    setRange(value);
    handleDebouncedChange(value);
  };

  const handleSubmit = value => {
    const newValue = {
      price: `${value[0]},${value[1]}`,
    };
    handleFiltersChange(newValue);
  };

  //---

  return (
    <div className={classNames(css.wrapper, css.disableSelect)} ref={wrapperRef}>
      <StyledBadge className={css.badgeWrapperClass} badgeContent={priceFromQueryString ? 1 : 0} color="success" max={9}>
        <div className={(showPopup || priceFromQueryString) ? css.filterLabelSelected : css.filterLabel} onClick={() => setShowPopup(!showPopup)}>
          <span>Price</span>
        </div>
      </StyledBadge>
      {showPopup && (
        <div className={css.popupWrapper}>
          <p className={css.popupLabel}>
            Price range{' '}
            <span className={css.rangeValue}>
              ${range[0]} - ${range[1]}
            </span>
          </p>
          <div className={css.sliderWrapper}>
            <RangeSlider handles={range} min={10} max={500} onChange={handleChange} />
          </div>
        </div>
      )}
    </div>
  );
};

export default PriceFilter;
