import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconProTrainers.css';

const IconProTrainers = props => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={classes} width="66" height="66" viewBox="0 0 66 66" fill="none">
            <path d="M1.93359 66H19.4648V60.1992C19.4648 54.8597 15.1364 50.4023 9.79688 50.4023C4.45734 50.4023 0 54.8597 0 60.1992V64.0664C0 65.1344 0.865585 66 1.93359 66Z" fill="#0095B3" />
            <path d="M15.5977 44.6016C15.5977 41.398 13.0004 38.8008 9.79688 38.8008C6.59335 38.8008 3.86719 41.398 3.86719 44.6016C3.86719 47.8051 6.59335 50.4023 9.79688 50.4023C13.0004 50.4023 15.5977 47.8051 15.5977 44.6016Z" fill="#0095B3" />
            <path d="M23.332 60.1992V66H42.7969V60.1992C42.7969 54.8597 38.3395 50.4023 33 50.4023C27.6605 50.4023 23.332 54.8597 23.332 60.1992Z" fill="#0095B3" />
            <path d="M38.9297 44.6016C38.9297 41.398 36.2035 38.8008 33 38.8008C29.7965 38.8008 27.1992 41.398 27.1992 44.6016C27.1992 47.8051 29.7965 50.4023 33 50.4023C36.2035 50.4023 38.9297 47.8051 38.9297 44.6016Z" fill="#0095B3" />
            <path d="M56.332 50.4023C50.9925 50.4023 46.6641 54.8597 46.6641 60.1992V66H64.0664C65.1344 66 66 65.1344 66 64.0664V60.1992C66 54.8597 61.6716 50.4023 56.332 50.4023Z" fill="#0095B3" />
            <path d="M62.1328 44.6016C62.1328 47.8051 59.5356 50.4023 56.332 50.4023C53.1285 50.4023 50.5312 47.8051 50.5312 44.6016C50.5312 41.398 53.1285 38.8008 56.332 38.8008C59.5356 38.8008 62.1328 41.398 62.1328 44.6016Z" fill="#0095B3" />
            <path d="M62.1328 13.6641C62.1328 16.8676 59.5356 19.4648 56.332 19.4648C53.1285 19.4648 50.5312 16.8676 50.5312 13.6641C50.5312 10.4605 53.1285 7.86328 56.332 7.86328C59.5356 7.86328 62.1328 10.4605 62.1328 13.6641Z" fill="#0095B3" />
            <path d="M56.332 19.4648C50.9925 19.4648 46.6641 23.7933 46.6641 29.1328V44.6016C46.6641 39.2711 51.0016 34.9336 56.332 34.9336C61.6625 34.9336 66 39.2711 66 44.6016V29.1328C66 23.7933 61.6716 19.4648 56.332 19.4648Z" fill="#0095B3" />
            <path d="M1.93359 31.0664H42.7969V29.1328C42.7969 25.459 44.2793 22.1316 46.6641 19.6904V1.93359C46.6641 0.865082 45.799 0 44.7305 0H1.93359C0.865082 0 0 0.865082 0 1.93359V29.1328C0 30.2013 0.865082 31.0664 1.93359 31.0664ZM23.332 9.79688C23.332 8.72836 24.1971 7.86328 25.2656 7.86328C26.3341 7.86328 27.1992 8.72836 27.1992 9.79688V21.3984C27.1992 22.4669 26.3341 23.332 25.2656 23.332C24.1971 23.332 23.332 22.4669 23.332 21.3984V9.79688ZM15.5977 13.6641C15.5977 12.5956 16.4627 11.7305 17.5312 11.7305C18.5998 11.7305 19.4648 12.5956 19.4648 13.6641V21.3984C19.4648 22.4669 18.5998 23.332 17.5312 23.332C16.4627 23.332 15.5977 22.4669 15.5977 21.3984V13.6641ZM7.86328 17.5312C7.86328 16.4627 8.72836 15.5977 9.79688 15.5977C10.8654 15.5977 11.7305 16.4627 11.7305 17.5312V21.3984C11.7305 22.4669 10.8654 23.332 9.79688 23.332C8.72836 23.332 7.86328 22.4669 7.86328 21.3984V17.5312Z" fill="#0095B3" />
        </svg>
    );
};

IconProTrainers.defaultProps = {
    rootClassName: null,
    className: null,
};

const { string } = PropTypes;

IconProTrainers.propTypes = {
    rootClassName: string,
    className: string,
};

export default IconProTrainers;
