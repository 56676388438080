import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditBusinessAdminForm } from '../../forms';
import config from '../../config';
import { CurrentUserContext } from 'contexts/currentUserContext';

import css from '../EditListingDescriptionPanel/EditListingDescriptionPanel.css';


const EditBusinessAdminPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    listingType,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData, privateData } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const {
    Contact_1_first_name,
    Contact_1_last_name,
    Contact_1_business_role,
    Contact_1_email,
    Contact_1_country_code,
    Contact_1_phone_no,
    Contact_2_first_name,
    Contact_2_last_name,
    Contact_2_business_role,
    Contact_2_email,
    Contact_2_country_code,
    Contact_2_phone_no,
  } = privateData;

  return (
    // <div className={classes}>
    <div className={css.scope}>
      <CurrentUserContext.Consumer>
        {currentUser => {
          return (
            <EditBusinessAdminForm
              className={css.form}
              initialValues={{
                Contact_1_first_name,
                Contact_1_last_name,
                Contact_1_business_role,
                Contact_1_email,
                Contact_1_country_code,
                Contact_1_phone_no,
                Contact_2_first_name,
                Contact_2_last_name,
                Contact_2_business_role,
                Contact_2_email,
                Contact_2_country_code,
                Contact_2_phone_no,
              }}
              saveActionMsg={submitButtonText}
              onSubmit={values => {
                const {
                  Contact_1_first_name,
                  Contact_1_last_name,
                  Contact_1_business_role,
                  Contact_1_email,
                  Contact_1_country_code,
                  Contact_1_phone_no,
                  Contact_2_first_name,
                  Contact_2_last_name,
                  Contact_2_business_role,
                  Contact_2_email,
                  Contact_2_country_code,
                  Contact_2_phone_no,
                } = values;

                const updateValues = {
                  privateData: {
                    Contact_1_first_name,
                    Contact_1_last_name,
                    Contact_1_business_role,
                    Contact_1_email,
                    Contact_1_country_code,
                    Contact_1_phone_no,
                    Contact_2_first_name,
                    Contact_2_last_name,
                    Contact_2_business_role,
                    Contact_2_email,
                    Contact_2_country_code,
                    Contact_2_phone_no,
                  },
                };

                onSubmit(updateValues);
              }}
              onChange={onChange}
              disabled={disabled}
              ready={ready}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              category={publicData.category}
              subCategory={publicData.subCategory}
              activityLevel={config.custom.activityLevel}
              currentUser={currentUser}
              listingType={listingType}
            />
          );
        }}
      </CurrentUserContext.Consumer>
    </div>
  );
};

EditBusinessAdminPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditBusinessAdminPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  listingType: string,
};

export default EditBusinessAdminPanel;
