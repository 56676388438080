import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from 'components';
import { TopbarContainer, StaticPage } from 'containers';
import React from 'react';
import css from './TripExpertGettingStartedPage.css';
import outline from 'assets/GettingStartedPage/Trip Expert Getting Started thumbnail.png';

const content = {
  outline: {
    img: outline,
    url:
      'https://docs.google.com/presentation/d/e/2PACX-1vReqQsoCXebn2O4dOd8G5cF-rHE1cHHiQut-28G5EUyH0xhlCo2DjD269OHkez66HV-FbNPc60ZGkso/pub?start=false&loop=false&delayms=3000',
    title: 'create a listing',
    list: [
      'Process',
      'Invitation Link',
      'Login',
      'Link Partner Travel Agency',
      'Overview Section',
      'Location Section',
      'Gallery Section',
      'Pricing Section',
      'Business Link',
      'Publish Listing',
      'Search',
      'Share Listing Link',
      'Edit Listing',
      'Hide Listing',
    ],
  },
};

function TripExpertGettingStartedPage() {
  return (
    <StaticPage title="Trip Expert Getting Started Page">
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer isDarkMode={true} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.container}>
            <div className={css.contentWrapper}>
              {Object.keys(content).map(section => {
                const { img, title, list, url } = content[section];
                return (
                  <div className={css.section}>
                    <div className={css.imageContainer}>
                      <a href={url}>
                        <img src={img} alt={title} width="100%" />
                      </a>
                    </div>
                    <div className={css.processDescription}>
                      <h2>
                        <a href={url}>{title}</a>
                      </h2>
                      <ul>
                        {list.map(item => (
                          <li>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
}

export default TripExpertGettingStartedPage;
