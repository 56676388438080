import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import ListingResults from 'components/SearchResultsPanel/components/ListingResults/ListingResults';
import css from './FavListingsPage.module.css';
import { injectIntl } from 'react-intl';

const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const FavListingsPageComponent = (props) => {
  const {currencyConversionRate} = props;
  const [favListings, setFavListings] = useState([]);
  const [favListingsChanged, setFavListingsChanged] = useState(true);

  useEffect(() => {
    sdk.currentUser
      .show()
      .then(res => {
        const userPrivateData = res.data.data.attributes.profile.privateData;
        if (userPrivateData.favListingsArray) {
          const favListingsIdsArray = userPrivateData.favListingsArray;

          const promisesArray = favListingsIdsArray.map(listingId => {
            return sdk.listings
              .show({
                id: listingId,
                include: ['author', 'images'],
                'fields.image': ['variants.landscape-crop', 'landscape-crop2x'],
                //  "imageVariant.my-variant": sdkUtil.objectQueryString({
                //    w: 320,
                //    h: 640,
                //    fit: 'scale'
                //  }),
              })
              .then(resp => {
                let rowListing = resp.data.data;

                const imagesArray = resp.data.included.filter(i => {
                  return i.type === 'image';
                });
                const finalImagesArray = imagesArray.slice(0, 3);

                const usersArray = resp.data.included.filter(i => {
                  return i.type === 'user';
                });
                const firstUserId = resp.data.data.relationships.author.data.id.uuid;
                const finalAuthorObj = usersArray.find(i => {
                  return i.id.uuid === firstUserId;
                });

                rowListing.images = finalImagesArray;
                rowListing.author = finalAuthorObj;
                return rowListing;
              })
              .catch(error => console.log(error.stack));
          });

          Promise.all(promisesArray)
            .then(resp => {
              // console.log("3#")
              // console.log("array with all the closed dates that we just fetched from external source")
              // console.log(resp.flat())
              const flattenJustFetchedDatesArray = resp.flat();
              return setFavListings(flattenJustFetchedDatesArray);
            })
            .catch(error => {
              console.log(error.stack);
            });
        }
      })
      .catch(e => console.log(e));
  }, [favListings.length, favListingsChanged]);

  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');
  // prettier-ignore
  return (
    <StaticPage
      title="Favorite"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'FavListingsPage',
        description: 'Favorite',
        name: 'Favorite listings page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>My Favorite Trip Experts</h1>

          <div className={css.contentWrapper}>
            {favListings.length === 0 ? (
              <p className={css.noListings}>You don't have any favorite trip experts</p>
            ) : (
              <div className={css.listingsWrapper}>
                <ListingResults
                  listings={favListings}
                  favListings={favListings}
                  setFavListingsChanged={setFavListingsChanged}
                  setActiveListing={() => {}}
                  allListingReview={{}}
                  currencyConversionRate={currencyConversionRate}
                />
              </div>
            )}
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {
  const {currencyConversionRate} = state.user;

  return {
    currencyConversionRate,
  }
}

const FavListingsPage = compose(
  connect(mapStateToProps),
  injectIntl,
)(FavListingsPageComponent);

export default FavListingsPage;
