import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { func, object, shape, string } from 'prop-types';
import Slider from 'react-slick';

import SectionSearchForHomepage from '../../components/SectionSearch/SectionSearchForHomepage';
import css from './SectionHero.css';
import HomePage_Banner_1 from '../../assets/Landing_Pages/Banners/HomePage_Banner_1.jpg';
import HomePage_Banner_2 from '../../assets/Landing_Pages/Banners/HomePage_Banner_2.jpg';
import HomePage_Banner_3 from '../../assets/Landing_Pages/Banners/HomePage_Banner_3.jpg';
import HomePage_Banner_4 from '../../assets/Landing_Pages/Banners/HomePage_Banner_4.jpg';

const SectionHero = props => {
  const { rootClassName, className, suggestedListings, handleProviderQueryChange } = props;
  const [isRedirect, setRedirect] = useState(false);
  const [isZoomIn, setZoomIn] = useState(false);
  const sliderRef = useRef(null);

  const sliderSettings = {
    fade: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    swipe: false,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 3000,

    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToScroll: 0,
          arrows: false,
        },
      },
    ],
  };

  const classes = classNames(rootClassName || css.root, className);

  const images = [
    { id: 'Banner1', src: HomePage_Banner_1, title: 'Connecting Travellers & Experts' },
    { id: 'Banner2', src: HomePage_Banner_2, title: 'Connecting Travellers & Experts' },
    { id: 'Banner3', src: HomePage_Banner_3, title: 'Connecting Travellers & Experts' },
    { id: 'Banner4', src: HomePage_Banner_4, title: 'Connecting Travellers & Experts' },
  ];

  const onCardClickHandler = e => {
    setRedirect(true);
  };

  return (
    <div className={classes}>
      <div
        className={css.popular}
        onMouseOver={() => {
          sliderRef.current.slickPlay();
          setZoomIn(true);
        }}
        onMouseOut={() => {
          sliderRef.current.slickPause();
          setZoomIn(false);
        }}
        onClick={onCardClickHandler}
      >
        <div className={css.sectionSearchWrapper}>
          <div className={css.sectionSearchContent}>
            <h1 className={css.findProvidersTitle}>
              {'Plan My Trip'}{' '}
              <p style={{ fontWeight: '100', fontSize: '31px' }}>Connecting Travellers & Trip Experts</p>
            </h1>
            <SectionSearchForHomepage
              intl={props.intl}
              history={props.history}
              suggestedListings={suggestedListings}
              handleProviderQueryChange={handleProviderQueryChange}
              title={''}
              location={props.location}
              isMobile
            />
          </div>
        </div>
        {images ? (
          <Slider ref={sliderRef} {...sliderSettings} autoplay={true} className={css.heroSlider}>
            {images.map(image => (
              <div key={image.id} className={css.heroImageWrapper}>
                <img src={image.src} alt={image.id} className={css.bannerImages} />
                <div className={css.heroContent}>
                </div>
              </div>
            ))}
          </Slider>
        ) : null}
      </div>
    </div>
  );
};

SectionHero.defaultProps = {
  rootClassName: null,
  className: null,
  location: shape({ state: object }).isRequired,
};

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  handleSuggestionChange: func,
};

export default SectionHero;
