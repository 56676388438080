import React from 'react';
import { capitalize } from 'lodash/string';
import { NamedLink } from 'components/index';
import { ensureListing } from 'util/data';
import { createSlug } from 'util/urlHelpers';
import css from './EnquiryCard.css';
import { FormattedMessage } from 'util/reactIntl';

function EnquiryCard(props) {
  const { listing, setActiveListing } = props;
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', publicData = {}, description } = currentListing.attributes;
  const slug = createSlug(title);
  const { category, sub_category } = publicData;

  return (
    <NamedLink name="ListingPage" params={{ id, slug }} className={css.listingLink}>
      <div className={css.popular}>
        <div
          className={css.threeToTwoWrapper}
          onMouseEnter={() => setActiveListing(currentListing.id)}
          onMouseLeave={() => setActiveListing(null)}
        >
          <div className={css.info}>
            <div className={css.linkText}>
              <p className={css.headline}>{title}</p>
              <div className={css.section}>
                <p className={css.category}>
                  <span>
                    <FormattedMessage id="EnquiryCard.category" />
                  </span>
                  {capitalize(category)}
                </p>
                <p className={css.subCategory}>
                  <span>
                    <FormattedMessage id="EnquiryCard.subcategory" />
                  </span>
                  {capitalize(sub_category)}
                </p>
              </div>

              <p className={css.description}>{description}</p>
            </div>
          </div>
        </div>
      </div>
    </NamedLink>
  );
}

export default EnquiryCard;
