import React, { Component, useState, useRef } from 'react';
import css from '../../containers/LandingPage/LandingPagePromotedListings.css';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { NamedLink, NamedRedirect } from '..';
import { ResponsiveImage, UserDisplayName } from '../../components';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import { SectionLevel } from '../../components';
import StarRatings from 'react-star-ratings';
import { createSlug } from '../../util/urlHelpers';
import { getRatingFromMetadata } from 'containers/ListingPage/helper';
import Slider from 'react-slick';
import classNames from 'classnames';
import { ensurePrice } from 'util/data';
import map_Icon from '../../assets/Landing_Pages/Map_Icon_Red.svg';

class SimpleImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const PopularImage = lazyLoadWithDimensions(SimpleImage, { loadAfterInitialRendering: 3000 });

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price, 0, 0);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const PromotedListing = ({ pl, intl, reviews }) => {
  const [isRedirect, setRedirect] = useState(false);
  const [isZoomIn, setZoomIn] = useState(false);
  const sliderRef = useRef(null);
  const title = pl.attributes.title;
  const metadata = pl.attributes.metadata;
  const description = pl.attributes.description;
  const location =
    pl.attributes.publicData.location?.address ||
    pl.attributes.publicData?.Travel_business_address?.search;
  const author = pl.author;
  const images = pl.images ? pl.images.slice(1, pl.images.length) : [];
  const id = pl.id.uuid;
  const slug = createSlug(pl.attributes.title);
  const rating = getRatingFromMetadata(metadata);
  const reviewcount = rating;
  const totalRating = metadata?.totalRating || 0;
  const price = pl.attributes.price;

  const sliderSettings = {
    fade: true,
    infinite: true,
    speed: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    swipe: false,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 2100,

    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesToScroll: 0,
          arrows: false,
        },
      },
    ],
  };

  const onCardClickHandler = e => {
    setRedirect(true);
  };

  const publicData = (author && author.attributes.profile.publicData) || {};
  const contactNumber = (publicData && publicData.phoneNumber) || '';

  const currentPrice = ensurePrice(price);
  const { formattedPrice } = priceData(currentPrice, intl);
  return isRedirect ? (
    <NamedRedirect name="ListingPage" params={{ id, slug }} />
  ) : (
    <div
      className={css.popular}
      onMouseOver={() => {
        sliderRef.current.slickPlay();
        setZoomIn(true);
      }}
      onMouseOut={() => {
        sliderRef.current.slickPause();
        setZoomIn(false);
      }}
      onClick={onCardClickHandler}
    >
      {images ? (
        <Slider ref={sliderRef} {...sliderSettings} autoplay={isZoomIn} className={css.slider}>
          {images.map(image => (
            <div key={image.id.uuid} className={css.imageWrapper}>
              <div className={css.aspectWrapper}>
                <PopularImage
                  src={image}
                  alt={title}
                  className={classNames(css.popularImage, { [css.popularImageHover]: isZoomIn })}
                  image={image}
                  variants={['scaled-small', 'square-small2x']}
                />
              </div>
            </div>
          ))}
        </Slider>
      ) : null}

      <div className={css.mainWrapper}>
        <div className={css.startingAt}>
          from <span>{formattedPrice || 'USD 0'}</span>
        </div>
        <div className={css.linkText}>
          <div className={css.avatarWrapper}>
            {author && (
              <a name="ProfilePage" params={{ id: author.id.uuid }}>
                <UserDisplayName className={css.name} user={author} intl={intl} />
              </a>
            )}
          </div>

          {/* <div className={css.listingLink}>
            <p className={css.headline}>{title}</p>
          </div>*/}
          {location && (
            <div className={css.locationContainer}>
              <img src={map_Icon} alt="map" />
              <p className={css.locationLine}>{location}</p>
            </div>
          )}

          <div className={css.star_Wrapper}>
            <div className={css.starWrapper}>
              <div className={css.starContent}>
                <span className={classNames(css.rating, { [css.filledRating]: rating })}>
                  <StarRatings
                    rating={rating}
                    starRatedColor="#00bfe6"
                    starEmptyColor="#fff"
                    numberOfStars={1}
                    starDimension="18px"
                    starSpacing="0px"
                  />
                  <span className={css.reviewCount}>{reviewcount}</span>
                </span>
                <span className={css.reviewCountWrapper}>({totalRating})</span>
              </div>
            </div>
          </div>
          <div className={css.descriptionContainer}>
            <p className={css.description}>{description}</p>
          </div>
        </div>
        <div className={css.whatsappSection}>
          <button className={css.btnView}>View</button>
        </div>
      </div>
    </div>
  );
};

export default PromotedListing;
