import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconLocation.css';

const IconLocation = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18" fill="none">
      <path d="M6.4719 0.330078C3.08798 0.330078 0.334961 3.08309 0.334961 6.46699C0.334961 10.6665 5.82692 16.8317 6.06075 17.0921C6.28038 17.3367 6.66383 17.3362 6.88306 17.0921C7.11688 16.8317 12.6088 10.6665 12.6088 6.46699C12.6088 3.08309 9.8558 0.330078 6.4719 0.330078ZM6.4719 9.55464C4.76936 9.55464 3.38429 8.16953 3.38429 6.46699C3.38429 4.76445 4.7694 3.37937 6.4719 3.37937C8.17441 3.37937 9.55949 4.76448 9.55949 6.46702C9.55949 8.16956 8.17441 9.55464 6.4719 9.55464Z" fill="#0095B3" />
    </svg>
  );
};

IconLocation.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconLocation.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconLocation;
