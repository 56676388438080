import React from 'react';

import css from './PopularRequest.css';

function PopularRequestItem({ children, url }) {
  return (
    <li className={css.popularItem}>
      <a className={css.popularLink} href={url}>
        {children}
      </a>
    </li>
  );
}

function PopularRequest({ items }) {
  if (!items || !items.length) return null;

  return (
    <div className={css.root}>
      <span className={css.popularHeading}>Popular: </span>
      <ul className={css.popularList}>
        {items.map(i => (
          <PopularRequestItem key={i.label} url={i.url}>
            {i.label}
          </PopularRequestItem>
        ))}
      </ul>
    </div>
  );
}

export default PopularRequest;
