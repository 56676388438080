import React, { Component } from 'react';
import { memoize } from 'lodash/function';
import { uid } from 'react-uid';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { types as sdkTypes } from '../../util/sdkLoader';
import { REVIEW_TYPE_OF_PROVIDER, REVIEW_TYPE_OF_CUSTOMER, propTypes } from '../../util/types';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import { withViewport } from '../../util/contextHelpers';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  Page,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  AvatarLarge,
  NamedLink,
  Reviews,
  ButtonTabNavHorizontal,
  LayoutSingleColumn,
  ShareButton,
  Modal,
  SecondaryButton,
  ListingsByCategory,
  WhatsappButton,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
import { loadData, sendEnquiry, fetchBookingsFromCurrentUser } from './ProfilePage.duck';
import config from '../../config';
import css from './ProfilePage.css';
import { getCurrentPageUrl, getExtensionFromUrl } from 'util/urlHelpers';
import SectionHeadImagesSlider from 'components/SectionHeadImagesSlider/SectionHeadImagesSlider';
import { EnquiryForm } from 'forms';
import { MAX_MOBILE_SCREEN_WIDTH } from 'util/constants';
import { filterListingsByCategory } from 'util/data';
import BookingsFromCurrentSection from 'containers/ProfilePage/BookingsFromCurrentUserSection/BookingsFromCurrentUserSection';
import { getMarketplaceEntitiesMemo } from 'containers/BookingPlanPage/helpers';
import { rescheduleBooking, transitionTransaction } from 'containers/InboxPage/InboxPage.duck';
import { fetchMonthlyTimeSlots, fetchTimeSlots } from 'containers/ListingPage/ListingPage.duck';

const { UUID } = sdkTypes;

const filterListingsByCategoryMemo = memoize(filterListingsByCategory);

export class ProfilePageComponent extends Component {
  constructor(props) {
    super(props);

    const { currentUser } = props;
    const hasAdminId = currentUser?.attributes?.profile?.publicData?.adminId;
    console.log("hasAdminId", hasAdminId);


    this.state = {
      // keep track of which reviews tab to show in desktop viewport
      showReviewsType: hasAdminId ? REVIEW_TYPE_OF_PROVIDER : REVIEW_TYPE_OF_CUSTOMER,
      enquiryModalOpen: false,
    };

    this.showOfProviderReviews = this.showOfProviderReviews.bind(this);
    this.showOfCustomerReviews = this.showOfCustomerReviews.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { currentUser } = this.props;
    const { currentUser: prevCurrentUser } = prevProps;
    const hasAdminId = currentUser?.attributes?.profile?.publicData?.adminId;
    const prevHasAdminId = prevCurrentUser?.attributes?.profile?.publicData?.adminId;

    if (hasAdminId !== prevHasAdminId) {
      this.setState({
        showReviewsType: hasAdminId ? REVIEW_TYPE_OF_PROVIDER : REVIEW_TYPE_OF_CUSTOMER,
      });
    }
  }

  showOfProviderReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_PROVIDER,
    });
  }

  showOfCustomerReviews() {
    this.setState({
      showReviewsType: REVIEW_TYPE_OF_CUSTOMER,
    });
  }

  onSubmitEnquiry = values => {
    const { onSendEnquiry } = this.props;
    const { message, listing } = values;

    onSendEnquiry(listing, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  };

  onContactUser = () => {
    this.setState({ enquiryModalOpen: true });
  };

  onVideoLoadStart = e => {
    const { viewport } = this.props;
    const videoElem = e.target;
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    if (isMobileLayout) {
      videoElem.volume = 0;
      videoElem.autoplay = true;
    }
  };

  render() {
    const {
      scrollingDisabled,
      currentUser,
      user,
      userShowError,
      userListings,
      reviews,
      queryReviewsError,
      viewport,
      intl,
      params,
      onManageDisableScrolling,
      sendEnquiryInProgress,
      sendEnquiryError,
      bookingsFromCurrentUser,
      bookingsFromCurrentUserIsLoading,
      fetchBookingsFromCurrentUser,
      transitionTransaction,
      onRescheduleBooking,
      rescheduleRequestInProgress,
      rescheduleError,
      rescheduledsuccessfully,
      onFetchTimeSlots,
      onFetchMonthlyTimeSlots,
      monthlyTimeSlots
    } = this.props;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const profileUser = ensureUser(user);
    const isCurrentUser =
      ensuredCurrentUser.id && profileUser.id && ensuredCurrentUser.id.uuid === profileUser.id.uuid;
    const { displayName, publicData } = profileUser.attributes.profile;
    const { promoVideo, promoImages } = publicData || {};
    const bio = profileUser.attributes.profile.bio;
    const hasBio = !!bio;
    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const shareUrl = getCurrentPageUrl();
    // const ensuredAuthor = ensureUser(currentAuthor);
    const editLinkMobile = isCurrentUser ? (
      <NamedLink className={css.editLinkMobile} name="ProfileSettingsPage">
        <FormattedMessage id="ProfilePage.editProfileLinkMobile" />
      </NamedLink>
    ) : null;
    const editLinkDesktop = isCurrentUser ? (
      <NamedLink className={css.editLinkDesktop} name="ProfileSettingsPage">
        <FormattedMessage id="ProfilePage.editProfileLinkDesktop" />
      </NamedLink>
    ) : null;

    const asideContent = (
      <div className={css.asideContent}>
        <AvatarLarge className={css.avatar} user={user} disableProfileLink />
        <h1 className={css.mobileHeading}>
          {displayName ? (
            <FormattedMessage id="ProfilePage.mobileHeading" values={{ name: displayName }} />
          ) : null}
        </h1>
        {editLinkMobile}
        {editLinkDesktop}
      </div>
    );

    const reviewsError = (
      <p className={css.error}>
        <FormattedMessage id="ProfilePage.loadingReviewsFailed" />
      </p>
    );

    const reviewsOfProvider = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER);

    const reviewsOfCustomer = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_CUSTOMER);

    const hasAdminId = currentUser?.attributes?.profile?.publicData?.adminId;

    const mobileReviews = (
      <div className={css.mobileReviews}>
        {hasAdminId ? (<>
          <h2 className={css.mobileReviewsTitle}>
            <FormattedMessage
              id="ProfilePage.reviewsOfProviderTitle"
              values={{ count: reviewsOfProvider.length }}
            />
          </h2>
          {queryReviewsError ? reviewsError : null}
          <Reviews reviews={reviewsOfProvider} />
        </>) : null}
        <h2 className={css.mobileReviewsTitle}>
          <FormattedMessage
            id="ProfilePage.reviewsOfCustomerTitle"
            values={{ count: reviewsOfCustomer.length }}
          />
        </h2>
        {queryReviewsError ? reviewsError : null}
        <Reviews reviews={reviewsOfCustomer} />
      </div>
    );

    const desktopReviewTabs = hasAdminId ? [
      {
        text: (
          <h3 className={css.desktopReviewsTitle}>
            <FormattedMessage
              id="ProfilePage.reviewsOfProviderTitle"
              values={{ count: reviewsOfProvider.length }}
            />
          </h3>
        ),
        selected: this.state.showReviewsType === REVIEW_TYPE_OF_PROVIDER,
        onClick: this.showOfProviderReviews,
      },
      {
        text: (
          <h3 className={css.desktopReviewsTitle}>
            <FormattedMessage
              id="ProfilePage.reviewsOfCustomerTitle"
              values={{ count: reviewsOfCustomer.length }}
            />
          </h3>
        ),
        selected: this.state.showReviewsType === REVIEW_TYPE_OF_CUSTOMER,
        onClick: this.showOfCustomerReviews,
      },
    ] : [
      {
        text: (
          <h3 className={css.desktopReviewsTitle}>
            <FormattedMessage
              id="ProfilePage.reviewsOfCustomerTitle"
              values={{ count: reviewsOfCustomer.length }}
            />
          </h3>
        ),
        selected: this.state.showReviewsType === REVIEW_TYPE_OF_CUSTOMER,
        onClick: this.showOfCustomerReviews,
      },
    ];

    const desktopReviews = (
      <div className={css.desktopReviews}>
        <ButtonTabNavHorizontal className={css.desktopReviewsTabNav} tabs={desktopReviewTabs} />

        {queryReviewsError ? reviewsError : null}

        {this.state.showReviewsType === REVIEW_TYPE_OF_PROVIDER ? (
          reviewsOfProvider.length > 0 ? <Reviews reviews={reviewsOfProvider} /> : <div className={css.noReviewsWrapper}>No reviews yet</div>
        ) : (
          reviewsOfCustomer.length > 0 ? <Reviews reviews={reviewsOfCustomer} /> : <div className={css.noReviewsWrapper}>No reviews yet</div>
        )}
      </div>
    );

    const categoryFilteredListings = filterListingsByCategoryMemo(userListings);
    const mainContent = (
      <div>
        {/* <h1 className={css.desktopHeading}>
          <FormattedMessage id="ProfilePage.desktopHeading" values={{ name: displayName }} />
        </h1>

        <div className={css.btnsWrap}>
          <ShareButton
            className={css.share}
            viewport={viewport}
            shareData={{
              url: shareUrl,
              title: displayName,
            }}
          >
            <FormattedMessage id={'ProfilePage.shareButton'} />
          </ShareButton>
          {
            profileUser ?
              <div className={css.whatsapp}>
                <WhatsappButton user={profileUser} className={css.whatsappButton} />
              </div> : null
          }
          {!isCurrentUser && userListings && (
            <SecondaryButton className={css.contactLink} onClick={this.onContactUser}>
              <FormattedMessage id="ProfilePage.enquiryButtonLabel" />
            </SecondaryButton>
          )}
        </div>

        <BookingsFromCurrentSection
          intl={intl}
          params={params}
          onStateChange={fetchBookingsFromCurrentUser}
          transactions={bookingsFromCurrentUser}
          isLoading={bookingsFromCurrentUserIsLoading}
          profileUser={profileUser}
          transitionTransaction={transitionTransaction}
          onManageDisableScrolling={onManageDisableScrolling}
          onRescheduleBooking={onRescheduleBooking}
          rescheduleRequestInProgress={rescheduleRequestInProgress}
          rescheduleError={rescheduleError}
          rescheduledsuccessfully={rescheduledsuccessfully}
          currentUser={ensuredCurrentUser}
          onFetchTimeSlots={onFetchTimeSlots}
          onFetchMonthlyTimeSlots={onFetchMonthlyTimeSlots}
          monthlyTimeSlots={monthlyTimeSlots}
        />

        {hasBio ? <p className={css.bio}>{bio}</p> : null}

        {promoVideo && (
          <div className={css.promoVideo}>
            <video onLoadStart={this.onVideoLoadStart} src={promoVideo} controls width="100%">
              <source src={promoVideo} type={`video/${getExtensionFromUrl(promoVideo)}`} />
            </video>
          </div>
        )}

        <div className={css.listingCategories}>
          <ListingsByCategory headRequired={true} intl={intl} listingsByCategory={categoryFilteredListings} />
        </div> */}

        {/* {isMobileLayout ? mobileReviews : desktopReviews} */}
        {desktopReviews}
      </div>
    );

    let content;

    if (userShowError && userShowError.status === 404) {
      return <NotFoundPage />;
    } else if (userShowError) {
      content = (
        <p className={css.error}>
          <FormattedMessage id="ProfilePage.loadingDataFailed" />
        </p>
      );
    } else {
      content = mainContent;
    }

    const schemaTitle = intl.formatMessage(
      {
        id: 'ProfilePage.schemaTitle',
      },
      {
        name: displayName,
        siteTitle: config.siteTitle,
      }
    );

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        title={schemaTitle}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ProfilePage',
          name: schemaTitle,
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ProfilePage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              {promoImages && !!promoImages.length && (
                <SectionHeadImagesSlider images={promoImages}>
                  {promoImages.map((image, i) => (
                    <div key={uid(image, i)} className={css.singleImageContainer}>
                      <img className={css.image} src={image} alt="" />
                    </div>
                  ))}
                </SectionHeadImagesSlider>
              )}

              <div className={css.contentContainer}>
                {/* <aside className={css.aside}>{asideContent}</aside> */}
                <div className={css.main}>{content}</div>
              </div>
            </div>

            <Modal
              id="ProfilePage.enquiry"
              contentClassName={css.enquiryModalContent}
              isOpen={this.state.enquiryModalOpen}
              onClose={() => this.setState({ enquiryModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <EnquiryForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={<FormattedMessage id="ProfilePage.enquiryHeader" />}
                authorDisplayName={displayName}
                sendEnquiryError={sendEnquiryError}
                onSubmit={this.onSubmitEnquiry}
                inProgress={sendEnquiryInProgress}
                listings={userListings}
              />
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ProfilePageComponent.defaultProps = {
  currentUser: null,
  user: null,
  userShowError: null,
  reviews: [],
  queryReviewsError: null,
};

const { bool, arrayOf, number, shape } = PropTypes;

ProfilePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  user: propTypes.user,
  userShowError: propTypes.error,
  reviews: arrayOf(propTypes.review),
  queryReviewsError: propTypes.error,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    userId,
    userShowError,
    reviews,
    queryReviewsError,
    userListingRefs,
    companyListingRefs,
    sendEnquiryInProgress,
    sendEnquiryError,
    bookingsFromCurrentUser: bookingsFromCurrentUserRefs,
    bookingsFromCurrentUserIsLoading,
    bookingsFromCurrentUserError,
  } = state.ProfilePage;
  const { rescheduleRequestInProgress, rescheduleError, rescheduledsuccessfully } = state.InboxPage;
  const { monthlyTimeSlots } = state.ListingPage;

  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const userListings = getMarketplaceEntities(state, userListingRefs);
  const companyListings = getMarketplaceEntities(state, companyListingRefs);
  const nonUserCompanyListings = companyListings.filter(
    cl => !userListings.some(ul => ul.id.uuid === cl.id.uuid)
  );
  const bookingsFromCurrentUser = getMarketplaceEntitiesMemo(state, bookingsFromCurrentUserRefs);
  const user = userMatches.length === 1 ? userMatches[0] : null;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    user,
    userShowError,
    reviews,
    queryReviewsError,
    userListings: [...userListings, ...nonUserCompanyListings],
    sendEnquiryInProgress,
    sendEnquiryError,
    bookingsFromCurrentUser,
    bookingsFromCurrentUserIsLoading,
    bookingsFromCurrentUserError,
    rescheduleRequestInProgress,
    rescheduleError,
    rescheduledsuccessfully,
    monthlyTimeSlots
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  fetchBookingsFromCurrentUser: (userId, status) =>
    dispatch(fetchBookingsFromCurrentUser(userId, status)),
  transitionTransaction: params => dispatch(transitionTransaction(params)),
  onRescheduleBooking: params => dispatch(rescheduleBooking(params)),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onFetchMonthlyTimeSlots: listing => dispatch(fetchMonthlyTimeSlots(listing)),
});

const ProfilePage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(ProfilePageComponent);

ProfilePage.loadData = params => {
  const id = new UUID(params.id);
  return loadData(id);
};

export default ProfilePage;
