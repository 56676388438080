import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, ReviewRating } from '../../components';
import { propTypes } from '../../util/types';
import ShowMore from 'react-show-more';
import css from './Reviews.css';
import config from '../../config'

const Review = props => {
  const { review, intl } = props;

  const date = review.attributes.createdAt;
  const dateString = intl.formatDate(date, { month: 'long', year: 'numeric' });

  return (
    <div className={css.review}>
      <div className={css.reviewInfo}>
        {/* <UserDisplayName user={review.author} intl={intl} /> */}
        <Avatar className={css.avatar} user={review.author} disableProfileLink />

        <div className={css.reviewHeaderContent}>
          <div className={css.reviewHeader}>
            <p className={css.author}>
              {review.author ? review.author.attributes.profile.displayName : 'Name is absent'}
            </p>
            <span className={css.separator}>•</span>
            {dateString}
          </div>
          <ReviewRating
            rating={review.attributes.rating}
            className={css.mobileReviewRating}
            reviewStarClassName={css.reviewRatingStar}
          />
        </div>
      </div>
      {/* <span className={css.desktopReviewRatingWrapper}>
            <ReviewRating
              rating={review.attributes.rating}
              className={css.desktopReviewRating}
              reviewStarClassName={css.reviewRatingStar}
            />
          </span> */}
      <ShowMore className={css.reviewContent} lines={5} more="+More" less="Less" anchorClass="">
        {review.attributes.content !== config.dummyReviewContent && review.attributes.content}
      </ShowMore>
    </div>
  );
};

Review.propTypes = {
  review: propTypes.review.isRequired,
  intl: intlShape.isRequired,
};

const ReviewsComponent = props => {
  const { className, rootClassName, reviews, intl } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <ul className={classes}>
      {reviews.map(r => {
        return (
          <li key={`Review_${r.id.uuid}`} className={css.reviewItem}>
            <Review review={r} intl={intl} />
          </li>
        );
      })}
    </ul>
  );
};

ReviewsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  reviews: [],
};

ReviewsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  reviews: arrayOf(propTypes.review),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Reviews = injectIntl(ReviewsComponent);

export default Reviews;
