import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconDownload.css';

const IconDownload = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5416 7.81493C15.2869 7.81493 15.0832 8.01866 15.0832 8.27333V12.4431C15.0832 13.5772 14.1596 14.4974 13.0289 14.4974H2.97114C1.83701 14.4974 0.916808 13.5738 0.916808 12.4431V8.20542C0.916808 7.95075 0.713073 7.74701 0.458404 7.74701C0.203735 7.74701 0 7.95075 0 8.20542V12.4431C0 14.0832 1.33447 15.4142 2.97114 15.4142H13.0289C14.6689 15.4142 16 14.0798 16 12.4431V8.27333C16 8.02206 15.7963 7.81493 15.5416 7.81493Z"
        fill="#006A80"
      />
      <path
        d="M7.67743 11.7742C7.76572 11.8625 7.88456 11.91 8.00001 11.91C8.11546 11.91 8.23431 11.8659 8.32259 11.7742L11.236 8.86077C11.416 8.6808 11.416 8.39218 11.236 8.21221C11.056 8.03224 10.7674 8.03224 10.5875 8.21221L8.45842 10.3446V1.04413C8.45842 0.789459 8.25468 0.585724 8.00001 0.585724C7.74535 0.585724 7.54161 0.789459 7.54161 1.04413V10.3446L5.40918 8.21221C5.22922 8.03224 4.94059 8.03224 4.76063 8.21221C4.58066 8.39218 4.58066 8.6808 4.76063 8.86077L7.67743 11.7742Z"
        fill="#006A80"
      />
    </svg>
  );
};

IconDownload.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconDownload.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconDownload;
