import React, { Component, useCallback } from 'react';
import PropTypes, { object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  PrimaryButton,
  CompanyListingCard,
} from 'components';
import TopbarContainer from 'containers/TopbarContainer/TopbarContainer';
import { injectIntl, intlShape } from 'util/reactIntl';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import { LISTING_PAGE_PARAM_TYPE_DRAFT, createSlug, getValuesFromQueryString, pushToPath } from 'util/urlHelpers';
import css from './JoinPartnerPage.css';
import { ensureCurrentUser } from 'util/data';
import { routeConfiguration } from 'index';
import { createResourceLocatorString } from 'util/routes';
import { getInviteInfo, redeemInvitation } from './JoinPartnerPage.duck';
import { CircularProgress } from '@mui/material';
import { OVERVIEW } from 'util/constants';

const queryStringValues = getValuesFromQueryString();
const invitationId = queryStringValues && queryStringValues.id;

class JoinPartnerPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authError: Cookies.get('st-autherror')
        ? JSON.parse(Cookies.get('st-autherror').replace('j:', ''))
        : null,
      authInfo: Cookies.get('st-authinfo')
        ? JSON.parse(Cookies.get('st-authinfo').replace('j:', ''))
        : null,
      editListingURL: '/',
    };
  }

  componentDidMount() {
    // Remove the autherror cookie once the content is saved to state
    // because we don't want to show the error message e.g. after page refresh
    Cookies.remove('st-autherror');

    const { onGetInviteInfo } = this.props;
    onGetInviteInfo(invitationId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.invitationId !== invitationId) {
      const { onGetInviteInfo } = this.props;
      onGetInviteInfo(invitationId);
    }
  }

  render() {
    const {
      currentUser,
      isAuthenticated,
      location,
      intl,
      businessAccountListing,
      adminUserId,
      inviteInfoInProgress,
      redeemInProgress,
      redeemSuccess,
      onRedeemInvitation,
      newProviderUser,
      redeemError,
    } = this.props;
    const user = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!user.id;
    const businessName = businessAccountListing?.attributes?.title;

    const isNotYetTE = !currentUser?.attributes.profile.publicData?.adminId;
    const isAlreadyJoined = currentUser?.attributes.profile.publicData?.adminId === adminUserId;

    // const locationFrom = location.state && location.state.from ? location.state.from : null;
    // const authinfoFrom =
    //   this.state.authInfo && this.state.authInfo.from ? this.state.authInfo.from : null;

    const ableToJoinTitle = intl.formatMessage({ id: 'JoinPartnerPage.ableToJoinTitle' });
    const alreadyJoinedTitle = intl.formatMessage({ id: 'JoinPartnerPage.alreadyJoinedTitle' });
    const unableToJoinAnotherTitle = intl.formatMessage({
      id: 'JoinPartnerPage.unableToJoinAnotherTitle',
    });
    const joinButtonLabel = intl.formatMessage({ id: 'JoinPartnerPage.joinButtonLabel' });
    const confirmJoinButtonLabel = intl.formatMessage({
      id: 'JoinPartnerPage.confirmJoinButtonLabel',
    });
    const confirmTitle = intl.formatMessage(
      {
        id: 'JoinPartnerPage.confirmTitle',
      },
      {
        businessName,
        br: <br />,
        b: (...chunks) => <b>{chunks}</b>,
        red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
      }
    );

    const onPressJoin = async () => {
        try {
          if (isAuthenticated && adminUserId && currentUserLoaded) {
            const { newListingObj } = await onRedeemInvitation(invitationId);
            const id = newListingObj.id;
            const slug = createSlug(newListingObj.title);
            const routes = routeConfiguration();
            const type = LISTING_PAGE_PARAM_TYPE_DRAFT;
            const tab = OVERVIEW;
            const editListingURL = createResourceLocatorString(
              'EditListingPage',
              routes,
              { id, slug, type, tab },
              {}
            );
            this.setState(prevState => ({
              editListingURL: editListingURL,
            }));
          }
        } catch (error) {
          alert('Error: ' + error);
          return pushToPath('/');
        }
      };

    const content =
      inviteInfoInProgress || redeemInProgress || !businessAccountListing ? (
        <CircularProgress />
      ) : redeemSuccess ? (
        <>
          <div className={css.descriptionWrapper}>{confirmTitle}</div>
          <div className={css.subContainer}>
            <div className={css.buttonWrapper}>
              <PrimaryButton
                onClick={() => {
                  pushToPath(this.state.editListingURL);
                }}
              >
                {confirmJoinButtonLabel}
              </PrimaryButton>
            </div>
          </div>
        </>
      ) : isNotYetTE ? (
        <>
          <div className={css.descriptionWrapper}>{ableToJoinTitle}</div>
          <div className={css.subContainer}>
            <div className={css.cardWrapper}>
              <CompanyListingCard listing={businessAccountListing} setActiveListing={() => {}} />
            </div>
            <div className={css.buttonWrapper}>
              <PrimaryButton onClick={onPressJoin}>{joinButtonLabel}</PrimaryButton>
            </div>
          </div>
        </>
      ) : isAlreadyJoined ? (
        alreadyJoinedTitle
      ) : (
        unableToJoinAnotherTitle
      );

    return (
      <Page title="Join Partner" scrollingDisabled={true}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.container}>
              {content}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

const { bool } = PropTypes;

JoinPartnerPageComponent.propTypes = {
  isAuthenticated: bool.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  const {
    invitationId,
    businessAccountListing,
    adminUserId,
    inviteInfoInProgress,
    inviteInfoError,
    redeemInProgress,
    redeemSuccess,
    newProviderUser,
    redeemError,
  } = state.JoinPartnerPage;
  return {
    currentUser,
    isAuthenticated,
    invitationId,
    businessAccountListing,
    adminUserId,
    inviteInfoInProgress,
    inviteInfoError,
    redeemInProgress,
    redeemSuccess,
    newProviderUser,
    redeemError,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetInviteInfo: value => dispatch(getInviteInfo(value)),
  onRedeemInvitation: (value1, value2) => dispatch(redeemInvitation(value1, value2)),
});

const JoinPartnerPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(JoinPartnerPageComponent);
export default JoinPartnerPage;
