import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconFacility.css';

const IconFacility = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={classes} width="70" height="69" viewBox="0 0 70 69" fill="none">
      <rect width="26.3984" height="26.3984" rx="5" fill="#0095B3" />
      <rect y="36.6654" width="26.3984" height="26.3984" rx="5" fill="#0095B3" />
      <rect x="37.4653" width="26.3984" height="26.3984" rx="5" fill="#0095B3" />
      <rect x="50.6646" y="31.1981" width="26.3984" height="26.3984" rx="5" transform="rotate(45 50.6646 31.1981)" fill="#0095B3" />
    </svg>
  );
};

IconFacility.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconFacility.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconFacility;
