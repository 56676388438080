import { fetchCurrentUser } from './user.duck';
import { trackGAEvent } from '../analytics/utils';

// ================ Reducer ================ //

// Default export reducer is just for "ducks" compatibility.
const initialState = {};
export default function reducer(state = initialState, action = {}) {
  return state;
}

// ================ Thunks ================ //

/**
 * Wait for details to be fetched before tracking the analytics event.
 *
 * Currently only waits for currentUser.
 */
export const trackAnalyticsEventWithContext = (action, params) => (
  async (dispatch, getState, sdk) => {
    try {
      // Note: fetchCurrentUser will set the user id in Google Analytics.
      if (!getState().user.currentUserLoaded) {
        await dispatch(fetchCurrentUser());
      }
      trackGAEvent(action, params)
    } catch (error) {
      console.log(error);
    }
  }
);
