import React, { useState, useEffect, useCallback } from 'react';
import { StaticPage, TopbarContainer } from '..';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import dots from 'assets/dots.svg'

import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Button,
  ResponsiveImage,
  NamedLink,
} from 'components';
import { post, get } from '../../util/api';
import css from './AssociatedAccountsPage.module.css';
import moment from 'moment';
import { CircularProgress } from '@mui/material';
import { LISTING_PAGE_PARAM_TYPE_DRAFT, createSlug, pushToPath, stringify } from 'util/urlHelpers';
import { OVERVIEW } from 'util/constants';
import { createResourceLocatorString } from 'util/routes';
import { routeConfiguration } from 'index';

const baseUrl = typeof window !== 'undefined' && window.location.origin;

const avatarColors = [
  { background: 'rgb(6,150,104)', color: '#ffffff' },
  { background: 'rgb(154,211,179)', color: '#000000' },
  { background: 'rgb(32,80,46)', color: '#ffffff' },
  { background: 'rgb(17,227,140)', color: '#000000' },
  { background: 'rgb(42,83,138)', color: '#ffffff' },
  { background: 'rgb(179,181,230)', color: '#000000' },
  { background: 'rgb(49,94,234)', color: '#ffffff' },
  { background: 'rgb(254,92,222)', color: '#000000' },
  { background: 'rgb(157,13,108)', color: '#ffffff' },
  { background: 'rgb(63,198,248)', color: '#000000' },
  { background: 'rgb(65,21,208)', color: '#ffffff' },
  { background: 'rgb(251,172,246)', color: '#000000' },
  { background: 'rgb(246,187,134)', color: '#000000' },
  { background: 'rgb(175,48,20)', color: '#ffffff' },
  { background: 'rgb(167,232,49)', color: '#000000' },
  { background: 'rgb(121,157,16)', color: '#ffffff' },
  { background: 'rgb(52,245,14)', color: '#000000' },
  { background: 'rgb(174,120,62)', color: '#ffffff' },
  { background: 'rgb(244,212,3)', color: '#000000' },
  { background: 'rgb(251,120,16)', color: '#000000' },
  { background: 'rgb(255,136,137)', color: '#000000' },
];

const stringToUniqueNumber = str => {
  const caculatedValue = str.slice(-4);
  const id = parseInt(caculatedValue, 16);
  return id;
};

const AVATAR_IMAGE_VARIANTS = [
  // 240x240
  'square-small',

  // 480x480
  'square-small2x',
];

const AVATAR_SIZES = '40px';
const AVATAR_SIZES_MEDIUM = '60px';
const AVATAR_SIZES_LARGE = '96px';

const AssociatedAccountsPageComponent = props => {
  const { currentUser, intl } = props;
  const [associatedUsers, setAssociatedUsers] = useState([]);
  const [businessId, setBusinessId] = useState(null);
  const [showSupport, setShowSupport] = useState(false);
  const [invitationData, setInvitationData] = useState(null);
  const [isGeneratingLink, setIsGeneratingLink] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingOwn, setIsAddingOwn] = useState(false);
  const isApproved = currentUser?.attributes?.profile?.protectedData?.approved === 'true';
  const userId = currentUser?.id?.uuid;
  const hasOwnListing = !!currentUser?.attributes?.profile?.publicData?.adminId;

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      get('/api/provider-business/get-public', { userId })
        .then(async res => {
          const { id } = res.providerBusiness;
          const providerUsers = await get('/api/provider-business/get-users', {
            businessId: id,
          });
          setBusinessId(id);
          setAssociatedUsers(providerUsers.providerUsers);
          setIsLoading(false);
        })
        .catch(e => {
          setIsLoading(false);
          console.log(e);
        });
    }
  }, [userId]);

  const handleCopyInviteLink = () => {
    if (typeof window !== 'undefined' && invitationData) {
      const baseUrl = window.location.origin;
      navigator.clipboard.writeText(`${baseUrl}/join-partner?id=${invitationData.id}`).then(
        () => {
          alert('Link copied');
        },
        () => {
          console.error('Failed to copy to clipboard:');
        }
      );
    }
  };

  const handleGenerateInviteLink = useCallback(() => {
    setIsGeneratingLink(true);
    if (businessId) {
      const body = {
        businessId,
        type: 1,
      };
      post('/api/provider-business/invite-create', body)
        .then(res => {
          setInvitationData(res.invitation);
          setIsGeneratingLink(false);
        })
        .catch(e => console.log(e));
    } else {
      setIsGeneratingLink(false);
      alert('businessId Not Found');
    }
  }, [businessId]);

  const handleAddOwnListing = useCallback(() => {
    setIsAddingOwn(true);
    const body = { businessId };
    post('/api/provider-business/add-trip-expert-role', body)
      .then(res => {
        setIsAddingOwn(false);
        const { id, title } = res.newListingObj;
        const slug = createSlug(title);
        const routes = routeConfiguration();
        const type = LISTING_PAGE_PARAM_TYPE_DRAFT;
        const tab = OVERVIEW;
        const editListingURL = createResourceLocatorString(
          'EditListingPage',
          routes,
          { id, slug, type, tab },
          {}
        );
        pushToPath(editListingURL);
      })
      .catch(e => {
        setIsAddingOwn(false);
        alert('Error: ' + e);
      });
  }, [businessId]);

  const supportText = intl.formatMessage({ id: 'AssociatedAccountsPage.supportText' });

  const content = isLoading ? (
    <CircularProgress />
  ) : (
    <>
      {invitationData ? (
        <center className={css.inviteLinkContainer}>
          <p className={css.inviteLink}>{`${baseUrl}/join-partner?id=${invitationData?.id}`}</p>
          <div className={css.copyButtonContainer}>
            <Button className={css.inviteButton} onClick={handleCopyInviteLink}>
              Copy Link
            </Button>
            <div onMouseLeave={() => setShowSupport(false)} className={css.support}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setShowSupport(prevState => !prevState)}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clip-path="url(#clip0_906_753)">
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.08984 8.99959C9.32495 8.33126 9.789 7.7677 10.3998 7.40873C11.0106 7.04975 11.7287 6.91853 12.427 7.0383C13.1253 7.15808 13.7587 7.52112 14.2149 8.06312C14.6712 8.60512 14.9209 9.29112 14.9198 9.99959C14.9198 11.9996 11.9198 12.9996 11.9198 12.9996"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <circle cx="12" cy="17" r="1" fill="black" />
                </g>
                <defs>
                  <clipPath id="clip0_906_753">
                    <rect width="24" height="24" fill="transparent" />
                  </clipPath>
                </defs>
              </svg>
              <span
                className={classNames(css.supportText, { [css.hideSupportText]: !showSupport })}
              >
                {supportText}
              </span>
            </div>
          </div>
          <p>
            {intl.formatMessage(
              { id: 'AssociatedAccountsPage.copyLinkDescription' },
              {
                rem: chunks => (
                  <b>
                    <em className={css.redText}>{chunks}</em>
                  </b>
                ),
                b: chunks => <strong>{chunks}</strong>,
                br: <br />,
              }
            )}
          </p>
        </center>
      ) : null}
      {isApproved && businessId ? (
        <div className={css.buttonsWrapper}>
          <Button
            className={css.inviteButton}
            inProgress={isGeneratingLink}
            onClick={handleGenerateInviteLink}
          >
            Generate Invite Link
          </Button>
          <Button
            className={css.inviteButton}
            onClick={handleAddOwnListing}
            disabled={hasOwnListing}
            inProgress={isAddingOwn}
          >
            Add Own Listing
          </Button>
        </div>
      ) : (
        <center>
          <p>
            <strong>
              Your account needs to be approved by the marketplace before you can invite Trip
              Experts
            </strong>
          </p>
        </center>
      )}

      <div className={css.associatedUsersWrapper}>
        {associatedUsers && associatedUsers.length > 0 ? (
          associatedUsers.map(user => {
            const roles = [
              ...(user?.isAdmin ? ['Admin'] : []),
              ...(user?.isTripExpert ? ['Trip Expert'] : []),
            ];
            const roleText = [...roles].join(' & ');
            const addedDate = moment(user.createdDate).format('DD/MM/YY');
            const { email, firstName, lastName, included } = user.userObj;
            const userName = [firstName, lastName].join(' ');
            const avatarInitials = firstName && lastName && firstName[0] + lastName[0] || 'N/A';
            const userColorId = stringToUniqueNumber(user.userId);
            const avatarStyle = avatarColors[userColorId % avatarColors.length];

            const params = {id: user.userId};
            const searchParams = {state: 'all', action: 'all'};
            const to = {search: stringify(searchParams)};
            return (
              <NamedLink name="PartnerMemberSalesPage" params={params} to={to} className={css.rowContainer} key={email}>
                  <div className={css.avatarContainer}>
                    {included ? (
                      <ResponsiveImage
                        rootClassName={css.avatarImage}
                        alt={userName}
                        image={included}
                        variants={AVATAR_IMAGE_VARIANTS}
                        sizes={AVATAR_SIZES_MEDIUM}
                      />
                    ) : (
                      <div style={avatarStyle} className={css.avatarInitials}>
                        {avatarInitials}
                      </div>
                    )}
                  </div>
                  <div className={css.userInfoContainer}>
                    <div className={css.profileContainer}>
                      <div className={css.titleContainer}>
                        <div className={css.nameContainer}>{userName}</div>
                        <div className={css.idContainer}>{user.userId}</div>
                      </div>
                      <div className={css.roleInfoContainer}>{roleText}</div>
                    </div>
                    <div className={css.contextContainer}>
                      <div className={css.emailContainer}>{email}</div>
                      <div className={css.dateContainer}>
                        <div>Added:</div>
                        <div>{addedDate}</div>
                      </div>
                    </div>
                  </div>
                  <div className={css.dotsContainer}><img src={dots} /></div>
              </NamedLink>
            );
          })
        ) : (
          <p>{`You don't have any associated travel agents yet :(`}</p>
        )}
      </div>
    </>
  );

  return (
    <StaticPage
      title="AssociatedAccountsPage"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AssociatedAccountsPage',
        description: 'AssociatedAccountsPage',
        name: 'AssociatedAccountsPage',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.pageContainer}>
            <h1>Associated Travel Agents</h1>
            {content}
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({});

const AssociatedAccountsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AssociatedAccountsPageComponent);

export default AssociatedAccountsPage;
