import React, { Component } from 'react';
import { bool, func, number, object, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';

import { FieldDateRangeController, FieldSelect, FilterPopup, FilterPlain } from '../../components';
import { propTypes } from '../../util/types';
import css from './BookingDateRangeLengthFilter.css';

const printHourStrings = h => (h > 9 ? `${h}:00` : `0${h}:00`);
const HOURS = Array(24).fill();
const ALL_START_HOURS = [...HOURS].map((v, i) => printHourStrings(i));
const ALL_END_HOURS = [...HOURS].map((v, i) => printHourStrings(i + 1));

const formatSelectedLabel = (minDurationOptions, minDuration, startTime, endTime, startDate, endDate) => {
  // Only show the minimum duration label for options whose key
  // matches the given param and that have the short label defined.
  const minDurationOption =
    typeof minDuration === 'number'
      ? minDurationOptions.find(option => {
          return minDuration.toString() === option.key && option.shortLabel;
        })
      : null;
  return minDurationOption && startTime && endTime
    ? `${startDate} - ${endDate}, ${startTime} - ${endTime}, ${minDurationOption.shortLabel}`
    :  startTime && endTime
    ? `${startDate} - ${endDate}, ${startTime} - ${endTime}`
    : `${startDate} - ${endDate}`
};

export class BookingDateRangeLengthFilterComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // We need to sync the currently selected dates from the
      // datepicker so we can enable the min duration only when there
      // are dates selected.
      selectedDates: null,
      selectedStartTime: null,
      selectedEndTime: null,
    };

    this.popupControllerRef = null;
    this.plainControllerRef = null;
  }

  render() {
    const {
      className,
      rootClassName,
      dateRangeLengthFilter,
      showAsPopup,
      initialDateValues: initialDateValuesRaw,
      initialDurationValue,
      initialTimeValues,
      id,
      contentPlacementOffset,
      onSubmit,
      datesUrlParam,
      durationUrlParam,
      intl,
      isInput,
      isOutsideRange,
      ...rest
    } = this.props;

    const isDatesSelected = !!initialDateValuesRaw && !!initialDateValuesRaw.dates;
    const initialDateValues = isDatesSelected ? initialDateValuesRaw : { dates: null };

    const startDate = isDatesSelected ? initialDateValues.dates.startDate : null;
    const endDate = isDatesSelected ? initialDateValues.dates.endDate : null;

    const format = {
      month: 'short',
      day: 'numeric',
    };

    const formattedStartDate = isDatesSelected ? intl.formatDate(startDate, format) : null;
    const formattedEndDate = isDatesSelected ? intl.formatDate(endDate, format) : null;
    const selectedStartTime = initialTimeValues ? initialTimeValues.split(',')[0] : null;
    const selectedEndTime = initialTimeValues ? initialTimeValues.split(',')[1] : null;

    const labelForPlain = isDatesSelected
      ? intl.formatMessage(
          { id: 'BookingDateRangeLengthFilter.labelSelectedPlain' },
          {
            dates: formatSelectedLabel(
              dateRangeLengthFilter.config.options,
              initialDurationValue,
              selectedStartTime,
              selectedEndTime,
              formattedStartDate,
              formattedEndDate
            ),
          }
        )
      : isInput ? intl.formatMessage({ id: 'SectionSearch.availabilityQueryPlaceholder' }) : intl.formatMessage({ id: 'BookingDateRangeLengthFilter.labelPlain' });

    const labelForPopup = isDatesSelected
      ? intl.formatMessage(
          { id: 'BookingDateRangeLengthFilter.labelSelectedPopup' },
          {
            dates: formatSelectedLabel(
              dateRangeLengthFilter.config.options,
              initialDurationValue,
              selectedStartTime,
              selectedEndTime,
              formattedStartDate,
              formattedEndDate
            ),
          }
        )
      : isInput ? intl.formatMessage({ id: 'SectionSearch.availabilityQueryPlaceholder' }) : intl.formatMessage({ id: 'BookingDateRangeLengthFilter.labelPopup' });

    

    const onClearPopupMaybe =
      this.popupControllerRef && this.popupControllerRef.onReset
        ? {
            onClear: () => {
              this.setState({
                selectedDates: null,
                selectedStartTime: null,
                selectedEndTime: null,
               });
              this.popupControllerRef.onReset(null, null);
            },
          }
        : {};

    const onCancelPopupMaybe =
      this.popupControllerRef && this.popupControllerRef.onReset
        ? {
            onCancel: () => {
              this.setState({
                selectedDates: null,
                selectedStartTime: null,
                selectedEndTime: null,
              });
              this.popupControllerRef.onReset(startDate, endDate);
            },
          }
        : {};

    const onClearPlainMaybe =
      this.plainControllerRef && this.plainControllerRef.onReset
        ? {
            onClear: () => {
              this.setState({
                selectedDates: null,
                selectedStartTime: null,
                selectedEndTime: null,
              });
              this.plainControllerRef.onReset(null, null);
            },
          }
        : {};

    const handleSubmit = (param, values) => {
      this.setState({
        selectedDates: null,
        selectedStartTime: null,
        selectedEndTime: null,
      });
      onSubmit(values);
    };

    const handleChange = (param, values) => {
      this.setState({
        selectedDates: values[datesUrlParam],
        selectedStartTime: values['startTime'],
        selectedEndTime: values['endTime'],
      });
    };

    // const datesSelected = !!(initialDateValues.dates || this.state.selectedDates);

    const initialValues = {
      ...initialDateValues,
      startTime: selectedStartTime,
      endTime: selectedEndTime
    };

    const startTimePlaceholder = intl.formatMessage({
      id: 'BookingDateRangeLengthFilter.startTimePlaceholder',
    });
    const endTimePlaceholder = intl.formatMessage({
      id: 'BookingDateRangeLengthFilter.endTimePlaceholder',
    });
    const fields = (
      <>
        <FieldDateRangeController
          name={datesUrlParam}
          controllerRef={node => {
            this.popupControllerRef = node;
          }}
          isOutsideRange={isOutsideRange}
        />

        <div className={css.bookingTime}>
          <div className={css.startTime}>
            <FieldSelect
              id='startTime'
              name='startTime'
              selectClassName={css.fieldSelect}
            >
              <option disabled value="">
                {startTimePlaceholder}
              </option>
              {ALL_START_HOURS.map(
                s => {
                      return (selectedStartTime === s) ?
                        <option value={s} key={s} selected>
                          {s}
                        </option> :

                        <option value={s} key={s}>
                          {s}
                        </option>
                }
              )}
            </FieldSelect>
          </div>
          <div className={css.endTime}>
            <FieldSelect
              id='endTime'
              name='endTime'
              selectClassName={css.fieldSelect}
            >
              <option disabled value="">
                {endTimePlaceholder}
              </option>
              {ALL_END_HOURS.map(s => (
                <option value={s} key={s}>
                  {s}
                </option>
              ))}
            </FieldSelect>
          </div>
        </div>
      </>
    );

    return showAsPopup ? (
      <FilterPopup
        className={className}
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        label={labelForPopup}
        isSelected={isDatesSelected}
        id={`${id}.popup`}
        showAsPopup
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        onChange={handleChange}
        {...onClearPopupMaybe}
        {...onCancelPopupMaybe}
        initialValues={initialValues}
        urlParam={datesUrlParam}
        {...rest}
      >
      {fields}
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={labelForPlain}
        isSelected={isDatesSelected}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        {...onClearPlainMaybe}
        initialValues={initialValues}
        urlParam={datesUrlParam}
        {...rest}
      >
      {fields}
      </FilterPlain>
    );
  }
}

BookingDateRangeLengthFilterComponent.defaultProps = {
  rootClassName: null,
  className: null,
  dateRangeLengthFitler: null,
  showAsPopup: true,
  liveEdit: false,
  initialDateValues: null,
  initialDurationValue: null,
  contentPlacementOffset: 0,
};

BookingDateRangeLengthFilterComponent.propTypes = {
  rootClassName: string,
  className: string,
  dateRangeLengthFitler: propTypes.filterConfig,
  id: string.isRequired,
  showAsPopup: bool,
  liveEdit: bool,
  datesUrlParam: string.isRequired,
  durationUrlParam: string.isRequired,
  onSubmit: func.isRequired,
  initialDateValues: object,
  initialDurationValue: number,
  contentPlacementOffset: number,
  isOutsideRange: func,

  // form injectIntl
  intl: intlShape.isRequired,
};

const BookingDateRangeLengthFilter = injectIntl(BookingDateRangeLengthFilterComponent);

export default BookingDateRangeLengthFilter;
