import { storableError } from '../../util/errors';
import { types as sdkTypes } from '../../util/sdkLoader';
import { get, post } from 'util/api';
const { UUID } = sdkTypes;

// ================ Action types ================ //

export const INVITE_INFO_REQUEST = 'app/JoinPartnerPage/INVITE_INFO_REQUEST';
export const INVITE_INFO_SUCCESS = 'app/JoinPartnerPage/INVITE_INFO_SUCCESS';
export const INVITE_INFO_ERROR = 'app/JoinPartnerPage/INVITE_INFO_ERROR';

export const INVITE_REDEEM_REQUEST = 'app/JoinPartnerPage/INVITE_REDEEM_REQUEST';
export const INVITE_REDEEM_SUCCESS = 'app/JoinPartnerPage/INVITE_REDEEM_SUCCESS';
export const INVITE_REDEEM_ERROR = 'app/JoinPartnerPage/INVITE_REDEEM_ERROR';

// ================ Reducer ================ //

const initialState = {
  invitationId: null,
  businessAccountListing: null,
  adminUserId: null,
  inviteInfoInProgress: false,
  inviteInfoError: null,
  redeemInProgress: false,
  redeemSuccess: false,
  newProviderUser: null,
  redeemError: null,
};

const JoinPartnerPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case INVITE_INFO_REQUEST:
      return {
        ...state,
        invitationId: payload.invitationId,
        inviteInfoInProgress: true,
        inviteInfoError: null,
      };
    case INVITE_INFO_SUCCESS:
      return {
        ...state,
        inviteInfoInProgress: false,
        businessAccountListing: payload.businessAccountListing,
        adminUserId: payload.adminUserId,
      };
    case INVITE_INFO_ERROR:
      console.error(payload);
      return { ...state, inviteInfoInProgress: false, inviteInfoError: payload };

    case INVITE_REDEEM_REQUEST:
      return {
        ...state,
        redeemInProgress: true,
        redeemSuccess: false,
        redeemError: null,
      };
    case INVITE_REDEEM_SUCCESS: {
      return {
        ...state,
        redeemInProgress: false,
        redeemSuccess: true,
        newProviderUser: payload.newProviderUser,
      };
    }
    case INVITE_REDEEM_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, redeemError: payload, redeemInProgress: false, redeemSuccess: false };

    default:
      return state;
  }
};

export default JoinPartnerPageReducer;

// ================ Action creators ================ //

export const inviteInfoRequest = invitationId => ({
  type: INVITE_INFO_REQUEST,
  payload: { invitationId },
});

export const inviteInfoSuccess = response => ({
  type: INVITE_INFO_SUCCESS,
  payload: {
    businessAccountListing: response.businessAccountListing,
    adminUserId: response.adminUserId,
  },
});

export const inviteInfoError = e => ({
  type: INVITE_INFO_ERROR,
  error: true,
  payload: e,
});

export const inviteRedeemRequest = () => ({
  type: INVITE_REDEEM_REQUEST,
  payload: {},
});

export const inviteRedeemSuccess = response => ({
  type: INVITE_REDEEM_SUCCESS,
  payload: {
    newProviderUser: response,
  },
});

export const inviteRedeemError = e => ({
  type: INVITE_REDEEM_ERROR,
  error: true,
  payload: e,
});

export const getInviteInfo = invitationId => async (dispatch, getState, sdk) => {
  dispatch(inviteInfoRequest(invitationId));
  return get('/api/provider-business/invite-info', { id: invitationId })
    .then(async res => {
      const { sharetribeListingId, sharetribeUserId } = res.providerBusiness;
      const listingId = new UUID(sharetribeListingId);
      const params = {
        ids: listingId,
        include: ['images'],
        'fields.image': ['variants.scaled-small', 'variants.scaled-small2x'],
      };
      const result = await sdk.listings.query(params);
      const businessAccountListing = result.data.data[0];
      const filteredImages = result.data.included;

      const updatedBusinessAccountListing = {
        ...businessAccountListing,
        images: filteredImages,
      };
      const output = {
        businessAccountListing: updatedBusinessAccountListing,
        adminUserId: sharetribeUserId,
      };
      dispatch(inviteInfoSuccess(output));
      return res;
    })
    .catch(e => inviteInfoError(e));
};

export const redeemInvitation = invitationId => async (dispatch, getState, sdk) => {
  const body = { id: invitationId };
  dispatch(inviteRedeemRequest());
  return post('/api/provider-business/invite-redeem', body)
    .then(res => {
      dispatch(inviteRedeemSuccess(res.newProviderUser));
      return res;
    })
    .catch(e => inviteRedeemError(e));
};
