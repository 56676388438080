import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import config from '../../config';
import css from './Logo.css';
import PrivateLogoImage from '../../assets/Header/Lyfshort_logo_white.svg';
import mobilePrivateLogoImage from '../../assets/Header/Lyfshort_logo_white.svg';
import LogoImage from '../../assets/Header/Lyfshort_logo_white.svg';
import { DarkLogoIcon } from './LogoIcon';
import LazyLoadingImages from 'components/LazyLoadingImages/LazyLoadingImages';
import Logo_Image from '../../assets/Header/Lyfshort_logo_white.svg';

const Logo = props => {
  const { className, format, isDarkMode = false, isPrivateSite = false, ...rest } = props;
  const mobileClasses = classNames(css.logoMobile, className);

  if (format === 'desktop') {
    if (isPrivateSite) {
      return (
        <LazyLoadingImages
          className={className}
          src={PrivateLogoImage}
          alt={config.siteTitle}
          {...rest}
        />
      );
    } else if (isDarkMode) {
      return (
        <LazyLoadingImages
          className={className}
          src={Logo_Image}
          alt={config.siteTitle}
          {...rest}
        />
      );
    } else {
      return (
        <LazyLoadingImages
          className={className}
          src={Logo_Image}
          alt={config.siteTitle}
          {...rest}
        />
      );
    }
  }

  if (isPrivateSite) {
    return (
      <LazyLoadingImages
        className={className}
        src={mobilePrivateLogoImage}
        alt={config.siteTitle}
        {...rest}
      />
    );
  } else if (isDarkMode) {
    return <DarkLogoIcon className={mobileClasses} />;
  } else {
    return (
      <LazyLoadingImages className={className} src={LogoImage} alt={config.siteTitle} {...rest} />
    );
  }
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
