/**
 * Note: This form is using card from Stripe Elements https://stripe.com/docs/stripe-js#elements
 * Card is not a Final Form field so it's not available trough Final Form.
 * It's also handled separately in handleSubmit function.
 */
import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';

import { Form, PrimaryButton, FieldTextInput, IconClose } from '../../components';
import css from './WithoutPaymentForm.css';
import { PAYMENT_TYPES } from 'util/constants';
import * as validators from 'util/validators';
import { propTypes } from 'util/types';

/**
 * Translate a Stripe API error object.
 *
 * To keep up with possible keys from the Stripe API, see:
 *
 * https://stripe.com/docs/api#errors
 *
 * Note that at least at moment, the above link doesn't list all the
 * error codes that the API returns.
 *
 * @param {Object} intl - react-intl object from injectIntl
 * @param {Object} stripeError - error object from Stripe API
 *
 * @return {String} translation message for the specific Stripe error,
 * or the given error message (not translated) if the specific error
 * type/code is not defined in the translations
 *
 */

const initialState = {
  error: null,
  paymentProofSsUrls: [],
  paymentProofRequiredError: null,
};

/**
 * Payment form that asks for credit card info using Stripe Elements.
 *
 * When the card is valid and the user submits the form, a request is
 * sent to the Stripe API to handle payment. `stripe.handleCardPayment`
 * may ask more details from cardholder if 3D security steps are needed.
 *
 * See: https://stripe.com/docs/payments/payment-intents
 *      https://stripe.com/docs/elements
 */
class WithoutPaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.paymentForm = this.paymentForm.bind(this);
    this.uploadWidget = this.uploadWidget.bind(this);
    this.finalFormAPI = null;
  }


  handleSubmit(values) {
    const { onSubmit, inProgress, formId, paymentType } = this.props;
    const { initialMessage } = values;

    if (inProgress) {
      // Already submitting or card value incomplete/invalid
      return;
    }

    if (this.props.credits !== 'true') {
      if (this.state.paymentProofSsUrls.length <= 0 && paymentType === PAYMENT_TYPES.direct) {
        this.setState({
          paymentProofRequiredError: 'Payment proof is required, please upload payment screenshot',
        });
        return;
      }
    }

    const params = {
      message: initialMessage ? initialMessage.trim() : null,
      paymentProofSsUrls: this.state.paymentProofSsUrls.map(a => a.secure_url),
      formId,
      formValues: values,
    };
    onSubmit(params);
  }

  onDrop = (files) => {
    const url = 'https://api.cloudinary.com/v1_1/movementbuddy/auto/upload';
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      formData.append('file', file);
      formData.append('upload_preset', 'movementbuddy');

      fetch(url, { method: 'POST', body: formData })
        .then((response) => response.text())
        .then((data) => {
          this.setState({
            paymentProofSsUrls: [...this.state.paymentProofSsUrls, JSON.parse(data)],
          });
        });
    }
  };

  removeFile = (index) => (e) => {
    e.stopPropagation();

    const dt = new DataTransfer();
    const file = this.state.paymentProofSsUrls[index];
    const el = document.getElementById('payment-dropzone');
    for (let i = 0; i < el.files.length; i++) {
      const rowFile = el.files[i];
      let name = rowFile.name.split('.');
      name.splice(name.length - 1, 1);
      name = name.join('');
      if (name !== file.original_filename) dt.items.add(rowFile);
    }
    el.files = dt.files;

    this.state.paymentProofSsUrls.splice(index, 1);
    this.setState({
      paymentProofSsUrls: [...this.state.paymentProofSsUrls],
    });
  };

  renderFormFields = () => {
    const { paymentProofSsUrls } = this.state;
    const { credits, bankDetails, paymentType, intl } = this.props;

    const membershipLabel = <FormattedMessage id={'withoutPaymentForm.membershipLabel'} />;
    const membershipPlaceholder = intl.formatMessage({
      id: 'withoutPaymentForm.membershipPlaceholder',
    });

    switch (paymentType) {
      case PAYMENT_TYPES.direct:
        return (
          <>
            {credits !== 'true' ? (
              <div>
                {bankDetails ? (
                  <div>
                    <h2>
                      <FormattedMessage id='withoutPaymentForm.bankDetails' />
                    </h2>
                    <p className={css.bankDetails}>{bankDetails}</p>
                  </div>
                ) : null}
                {/*<button className={css.uploadPaymentProofBtn} onClick={this.uploadWidget}>*/}
                {/*  <FormattedMessage id="withoutPaymentForm.uploadPaymentProof" />*/}
                {/*</button>*/}

                <section className={css.shareReceiptContainer}>
                  <div className={css.shareReceiptWrapper}>
                    <h4>
                      <FormattedMessage id={'withoutPaymentForm.shareTransferReceipt'} />
                    </h4>
                    <span className={css.vendorRequiredInfo}>
                      <FormattedMessage id={'withoutPaymentForm.youShareVendorReceipt'} />
                    </span>
                    <Dropzone onDrop={this.onDrop} accept='image/*,application/pdf,.doc,.docx'>
                      {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: css.shareReceiptBtnWrapper })}>
                            <input {...getInputProps()} id='payment-dropzone' />
                            <div className={css.shareReceiptBtn}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="29" viewBox="0 0 26 29" fill="none">
                                <path d="M21.3536 20.2151C22.4674 20.2151 23.4098 20.622 24.1808 21.4359C24.9519 22.207 25.3374 23.128 25.3374 24.1989C25.3374 25.3126 24.9305 26.2765 24.1166 27.0904C23.3455 27.8614 22.4245 28.247 21.3536 28.247C20.2827 28.247 19.3403 27.8614 18.5264 27.0904C17.7553 26.2765 17.3698 25.3126 17.3698 24.1989C17.3698 23.7705 17.3912 23.4707 17.434 23.2993L7.73145 17.6448C6.91755 18.3731 5.97514 18.7372 4.90421 18.7372C3.79045 18.7372 2.82662 18.3302 2.01272 17.5163C1.19882 16.7024 0.791866 15.7386 0.791866 14.6248C0.791866 13.5111 1.19882 12.5472 2.01272 11.7333C2.82662 10.9194 3.79045 10.5125 4.90421 10.5125C5.97514 10.5125 6.91755 10.8766 7.73145 11.6048L17.3698 6.01461C17.2841 5.58624 17.2413 5.26496 17.2413 5.05078C17.2413 3.93701 17.6482 2.97318 18.4621 2.15928C19.276 1.34538 20.2398 0.938428 21.3536 0.938428C22.4674 0.938428 23.4312 1.34538 24.2451 2.15928C25.059 2.97318 25.4659 3.93701 25.4659 5.05078C25.4659 6.16454 25.059 7.12837 24.2451 7.94227C23.4312 8.75617 22.4674 9.16312 21.3536 9.16312C20.3255 9.16312 19.3831 8.77759 18.5264 8.00652L8.88805 13.661C8.97372 14.0894 9.01656 14.4106 9.01656 14.6248C9.01656 14.839 8.97372 15.1603 8.88805 15.5887L18.6549 21.2431C19.4259 20.5577 20.3255 20.2151 21.3536 20.2151Z" fill="#233045" />
                              </svg>
                            </div>
                          </div>
                      )}
                    </Dropzone>
                    <p className={css.receiptInfo}>
                    <FormattedMessage id={'withoutPaymentForm.saveProsessingFee’s'} />
                      </p>
                  </div>
                </section>

                {paymentProofSsUrls?.length > 0 ? (
                  <div className={css.paymentProofsContainer}>
                    <h2>
                      <FormattedMessage id='withoutPaymentForm.paymentScreenshots' />
                    </h2>
                    <div className={css.paymentProofSsContainer}>
                      {paymentProofSsUrls.map((ss, index) => {
                        return (
                          <div style={{ position: 'relative' }}>
                            <a
                              key={ss.public_id}
                              // eslint-disable-next-line
                              target='_blank'
                              href={ss?.secure_url}
                            >
                              <div className={css.paymentProofSs}>
                                {ss.resource_type === 'image' && (
                                  <img src={ss.secure_url} alt={ss.public_id} />
                                )}
                                {ss.resource_type === 'raw' && <p>{ss.public_id}</p>}
                              </div>
                            </a>
                            <div className={css.removeItem} onClick={this.removeFile(index)}>
                              <IconClose size='normal' className={css.removeIcon} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </>
        );
      case PAYMENT_TYPES.membership:
        const prevMembershipNumber = localStorage.getItem('membershipNumber');
        return (
          <FieldTextInput
            type='text'
            id='membership'
            name='membership'
            label={membershipLabel}
            placeholder={membershipPlaceholder}
            className={css.message}
            initialValue={prevMembershipNumber ? prevMembershipNumber : ""}
            validate={validators.required('This field is required')}
          />
        );
      default:
        return null;
    }
  };

  uploadWidget(e) {
    const _this = this;
    e.preventDefault();
    if (typeof window !== 'undefined') {
      window.cloudinary.openUploadWidget(
        { cloud_name: 'movementbuddy', upload_preset: 'movementbuddy' },
        function (error, result) {
          if (result && result.info && result.info.secure_url)
            _this.setState({
              paymentProofSsUrls: [..._this.state.paymentProofSsUrls, result.info.secure_url],
            });
        },
      );
    }
  }

  paymentForm(formRenderProps) {
    const {
      className,
      rootClassName,
      inProgress: submitInProgress,
      formId,
      paymentInfo,
      authorDisplayName,
      showInitialMessageInput,
      intl,
      initiateOrderError,
      invalid,
      handleSubmit,
      form,
      listing,
      paymentType,
    } = formRenderProps;

    this.finalFormAPI = form;

    const submitDisabled = invalid || submitInProgress;
    const classes = classNames(rootClassName || css.root, className);
    // const cardClasses = classNames(css.card);

    const messagePlaceholder = intl.formatMessage(
      { id: 'StripePaymentForm.messagePlaceholder' },
      { name: authorDisplayName },
    );

    const kidsNamePlaceholder = intl.formatMessage({ id: 'StripePaymentForm.kidsNamePlaceholder' });

    const messageOptionalText = intl.formatMessage({
      id: 'StripePaymentForm.messageOptionalText',
    });

    const initialMessageLabel = intl.formatMessage(
      { id: 'StripePaymentForm.messageLabel' },
      { messageOptionalText: messageOptionalText },
    );

    const { paymentProofRequiredError, paymentProofSsUrls } = this.state;
    const listingCategory = listing.attributes.publicData.category;

    return (
      <Form className={classes} onSubmit={handleSubmit}>
        {initiateOrderError ? (
          <span className={css.errorMessage}>{initiateOrderError.message}</span>
        ) : null}

        {showInitialMessageInput && (paymentType !== PAYMENT_TYPES.hitpay) ? (
          <div className={css.formSection}>
            <h3 className={css.messageHeading}>
              <FormattedMessage id='StripePaymentForm.messageHeading' />
            </h3>

            <FieldTextInput
              type='textarea'
              id={`${formId}-message`}
              name='initialMessage'
              label={initialMessageLabel}
              placeholder={messagePlaceholder}
              className={css.message}
            />
          </div>
        ) : null}

        {this.renderFormFields()}

        {listingCategory === 'kids' && (
          <div className={css.formSection}>
            <h3 className={css.messageHeading}>
              <FormattedMessage id='StripePaymentForm.kidsNameHeading' />
            </h3>

            <FieldTextInput
              type='textarea'
              id={`${formId}-kids-name`}
              name='kidsName'
              placeholder={kidsNamePlaceholder}
              className={css.message}
            />
          </div>
        )}

        {paymentProofRequiredError && paymentProofSsUrls.length <= 0 ? (
          <span className={css.errorMessage}>{paymentProofRequiredError}</span>
        ) : null}

        <div className={css.submitContainer}>
          {
            (paymentType === PAYMENT_TYPES.hitpay) ? null :
              <p className={css.paymentInfo}>{paymentInfo}</p>
          }
          <PrimaryButton
            className={css.submitButton}
            type='submit'
            inProgress={submitInProgress}
            disabled={submitDisabled}
          >
            {<FormattedMessage id='StripePaymentForm.submitConfirmPaymentInfo' />}
          </PrimaryButton>
        </div>
      </Form>
    );
  }

  render() {
    const { onSubmit, ...rest } = this.props;
    return <FinalForm onSubmit={this.handleSubmit} {...rest} render={this.paymentForm} />;
  }
}

WithoutPaymentForm.defaultProps = {
  className: null,
  rootClassName: null,
  inProgress: false,
  loadingData: false,
  showInitialMessageInput: true,
  initiateOrderError: null,
};

WithoutPaymentForm.propTypes = {
  className: string,
  rootClassName: string,
  inProgress: bool,
  loadingData: bool,
  initiateOrderError: object,
  formId: string.isRequired,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  paymentInfo: string.isRequired,
  authorDisplayName: string.isRequired,
  showInitialMessageInput: bool,
  paymentType: string.isRequired,
  listing: propTypes.listing,
};

export default injectIntl(WithoutPaymentForm);
