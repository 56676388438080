import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import css from './MultiSelectionFilter.css';
import { convertMultipleSelectQueryStringToArray } from '../../utils';
import { convertArrayToSearchString } from 'util/urlHelpers';
import { Badge, Checkbox, FormControlLabel } from '@mui/material';

const MultiSelectionFilterOnPopup = (props) => {
  const {
    handleFiltersChange,
    currentQueryParams,
    id,
    searchKey,
    options,
    type,
    name,
  } = props;

  const valueFromQueryString =
    currentQueryParams && convertMultipleSelectQueryStringToArray(currentQueryParams[searchKey]);
  const selectedOptions = valueFromQueryString ? valueFromQueryString : [];

  const triggerOptions = (options) => {
    const searchValue = convertArrayToSearchString(options, type);
    const isSearchValueEmpty = searchValue === type + ':';
    handleFiltersChange({ [searchKey]: isSearchValueEmpty ? null : searchValue });
  };

  return (
    <div className={classNames(css.wrapperOnPopup, css.disableSelect)}>
      {options.map(option => {
        const isChecked = selectedOptions.some(item => item == option.key);

        return <FormControlLabel
          control={
            <Checkbox
              size='sm'
              value={option.key}
              checked={isChecked}
              onClick={(event) => {
                if (!selectedOptions.includes(event.target.value)) {
                  selectedOptions.push(event.target.value);
                  triggerOptions(selectedOptions);
                } else {
                  const newSelectedOptions = selectedOptions.filter(option => option != event.target.value);
                  triggerOptions(newSelectedOptions);
                }
              }}
            />
          }
          label={option.label}
        />
      })}
    </div>
  );
}

export default MultiSelectionFilterOnPopup;
