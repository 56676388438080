import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconBank.css';

const IconBank = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="67" height="67" viewBox="0 0 67 67" fill="none">
      <path d="M51.6543 34.9661H59.4723V53.6851H51.6543V34.9661Z" fill="#232750" />
      <path d="M36.8496 34.9661H44.6676V53.6851H36.8496V34.9661Z" fill="#232750" />
      <path d="M22.0449 34.9661H29.8629V53.6851H22.0449V34.9661Z" fill="#232750" />
      <path d="M7.24121 34.9661H15.0632V53.6851H7.24121V34.9661Z" fill="#232750" />
      <path d="M62.0788 29.1041V27.1491H4.63477V29.1041C4.63463 29.3609 4.68511 29.6152 4.78331 29.8524C4.88152 30.0897 5.02552 30.3052 5.20708 30.4868C5.38865 30.6684 5.60421 30.8124 5.84147 30.9106C6.07872 31.0088 6.333 31.0592 6.58977 31.0591H60.1248C60.6431 31.0588 61.1401 30.8527 61.5065 30.4861C61.8729 30.1195 62.0788 29.6224 62.0788 29.1041Z" fill="#232750" />
      <path d="M64.759 57.5951H1.95598C1.69921 57.595 1.44493 57.6455 1.20768 57.7437C0.970424 57.8419 0.754855 57.9859 0.57329 58.1675C0.391725 58.349 0.247726 58.5646 0.149525 58.8018C0.0513232 59.0391 0.000845377 59.2933 0.000976819 59.5501V64.7621C0.000845377 65.0189 0.0513232 65.2732 0.149525 65.5104C0.247726 65.7477 0.391725 65.9632 0.57329 66.1448C0.754855 66.3264 0.970424 66.4704 1.20768 66.5686C1.44493 66.6668 1.69921 66.7173 1.95598 66.7171H64.756C65.0127 66.7173 65.267 66.6668 65.5043 66.5686C65.7415 66.4704 65.9571 66.3264 66.1387 66.1448C66.3202 65.9632 66.4642 65.7477 66.5624 65.5104C66.6606 65.2732 66.7111 65.0189 66.711 64.7621V59.5501C66.7112 59.0321 66.5058 58.5351 66.1397 58.1685C65.7737 57.8019 65.277 57.5957 64.759 57.5951Z" fill="#232750" />
      <path d="M1.95378 23.2391H64.7598C65.1852 23.2388 65.5989 23.0998 65.9381 22.8432C66.2774 22.5865 66.5236 22.2262 66.6396 21.8169C66.7555 21.4076 66.7348 20.9716 66.5805 20.5752C66.4262 20.1787 66.1469 19.8434 65.7848 19.6201L34.3818 0.290114C34.0737 0.100434 33.7191 0 33.3573 0C32.9955 0 32.6408 0.100434 32.3328 0.290114L0.92878 19.6201C0.566699 19.8434 0.287334 20.1787 0.13307 20.5752C-0.0211929 20.9716 -0.0419352 21.4076 0.0739904 21.8169C0.189916 22.2262 0.436183 22.5865 0.775427 22.8432C1.11467 23.0998 1.52838 23.2388 1.95378 23.2391ZM32.0288 11.5001H34.6858C34.9462 11.4942 35.2051 11.5404 35.4474 11.636C35.6897 11.7316 35.9104 11.8746 36.0967 12.0567C36.2829 12.2388 36.4309 12.4562 36.5319 12.6963C36.6329 12.9363 36.685 13.1942 36.685 13.4546C36.685 13.7151 36.6329 13.9729 36.5319 14.213C36.4309 14.453 36.2829 14.6705 36.0967 14.8526C35.9104 15.0346 35.6897 15.1777 35.4474 15.2732C35.2051 15.3688 34.9462 15.415 34.6858 15.4091H32.0288C31.5181 15.3976 31.0322 15.1866 30.6751 14.8213C30.3179 14.456 30.118 13.9655 30.118 13.4546C30.118 12.9438 30.3179 12.4532 30.6751 12.0879C31.0322 11.7227 31.5181 11.5117 32.0288 11.5001Z" fill="#232750" />
    </svg>
  );
};

IconBank.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconBank.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconBank;
