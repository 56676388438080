import {
  LISTING_TYPES,
  ADMIN,
  AGREEMENT,
  PAYMENTS,
  VIEWDRAFTLISTING,
  VIEWDRAFTBUSINESS,
  OVERVIEW,
  PROFILE,
  DESCRIPTION,
  LOCATION,
  PHOTOS,
  PRICING,
  INTRODUCTION,
} from 'util/constants';

// You can reorder these panels.
// Note 1: You need to change save button translations for new listing flow
// Note 2: Ensure that draft listing is created after the first panel
// and listing publishing happens after last panel.
// Note 3: in FTW-hourly template we don't use the POLICY tab so it's commented out.
// If you want to add a free text field to your listings you can enable the POLICY tab
export const TABS = [OVERVIEW, LOCATION, PHOTOS, PRICING, PROFILE, VIEWDRAFTLISTING];

export const COMPANY_TABS = [
  INTRODUCTION,
  DESCRIPTION,
  PHOTOS,
  AGREEMENT,
  ADMIN,
  PAYMENTS,
  VIEWDRAFTBUSINESS,
];
export const LISTING_TABS = [...TABS];

export const getTabs = listingType => {
  switch (listingType) {
    case LISTING_TYPES.LISTING:
      return LISTING_TABS;
    case LISTING_TYPES.COMPANY:
      return COMPANY_TABS;
    default:
      return TABS;
  }
};
