import React from 'react';
import css from './LandingPageIntroductionSection.css';

const LandingPageIntroductionSection = () => {
  return (
    <video
      id='intro'
      poster="https://storage.googleapis.com/lyfsho.appspot.com/public/marketplace-assisted-trip-planning-thumb.jpg"
      controls
      width="100%"
    >
      <source
        src="https://storage.googleapis.com/lyfsho.appspot.com/public/marketplace-assisted-trip-planning.mp4"
        type="video/mp4"
      />
    </video>
  );
};
export default LandingPageIntroductionSection;
