/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `OrderBreakdown` component.
 */
import React from 'react';
import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode } from '../../util/data';
import { LINE_ITEMS, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemUnknownItemsMaybe = props => {
  const { lineItems, isProvider, intl } = props;

  // resolve unknown non-reversal line items
  const allItems = lineItems.filter(item => LINE_ITEMS.indexOf(item.code) === -1 && !item.reversal);

  const items = isProvider
    ? allItems.filter(
      item => item.includeFor.includes('provider') && item.code !== 'line-item/agent-lead-fee'
    )
    : allItems.filter(
      item => item.includeFor.includes('customer') && item.code !== 'line-item/agent-lead-fee'
    );

  return items.length && items.length > 1 ? (
    <React.Fragment>
      <div className={css.lineItem}>
        <span>Research & Itinerary. <br /> Includes Up To:</span>
      </div>
      {items.map((item, i) => {
        const quantity = item.quantity;

        const label =
          quantity && quantity > 1
            ? `${humanizeLineItemCode(item.code)} x ${quantity}`
            : humanizeLineItemCode(item.code);

        const formattedTotal = formatMoney(intl, item.lineTotal);
        return (
          <div key={`${i}-item.code`} className={css.lineItem}>
            <span className={css.itemLabel}>{label}</span>
            <span className={css.itemValue}>{formattedTotal}</span>
          </div>
        );
      })}
    </React.Fragment>
  ) : <React.Fragment>
    {items.map((item, i) => {
      const quantity = item.quantity;

      const label =
        quantity && quantity > 1
          ? `${humanizeLineItemCode(item.code)} x ${quantity}`
          : humanizeLineItemCode(item.code);

      const formattedTotal = formatMoney(intl, item.lineTotal);
      return (
        <div key={`${i}-item.code`} className={css.lineItem}>
          <span className={css.itemLabel}>
            Personalised trip plan. 
            <br />Includes:
            <br /> • Interactive Itinerary
            <br /> • Online Collaboration
            <br /> • Supplier Quotes
            <br /> • Group Messaging
          </span>
          <span className={css.itemValue}>{formattedTotal}</span>
        </div>
      );
    })}
  </React.Fragment>
    ;
};

// LineItemUnknownItemsMaybe.propTypes = {
//   lineItems: propTypes.lineItems.isRequired,
//   intl: intlShape.isRequired,
// };

export default LineItemUnknownItemsMaybe;
