import React, { Component } from 'react';
import cns from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { ensureCurrentUser } from '../../util/data';
import { withRouter } from 'react-router-dom';
import { updateProfile, uploadImage } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { saveContactDetails } from '../ContactDetailsPage/ContactDetailsPage.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  GenericMessage,
  IconSpinner,
  Avatar,
  PrimaryButton,
} from '../../components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { TopbarContainer } from '../../containers';
import css from './ProfileAvatarPage.css';
import axios from 'axios';

const userInitialsBaseURI = "https://ui-avatars.com/api/?name="

const avatarColors = [
  { background: "rgb(6,150,104)", textColor: 'ffffff' },
  { background: "rgb(154,211,179)", textColor: '000000' },
  { background: "rgb(32,80,46)", textColor: 'ffffff' },
  { background: "rgb(17,227,140)", textColor: '000000' },
  { background: "rgb(42,83,138)", textColor: 'ffffff' },
  { background: "rgb(179,181,230)", textColor: '000000' },
  { background: "rgb(49,94,234)", textColor: 'ffffff' },
  { background: "rgb(254,92,222)", textColor: '000000' },
  { background: "rgb(157,13,108)", textColor: 'ffffff' },
  { background: "rgb(63,198,248)", textColor: '000000' },
  { background: "rgb(65,21,208)", textColor: 'ffffff' },
  { background: "rgb(251,172,246)", textColor: '000000' },
  { background: "rgb(246,187,134)", textColor: '000000' },
  { background: "rgb(175,48,20)", textColor: 'ffffff' },
  { background: "rgb(167,232,49)", textColor: '000000' },
  { background: "rgb(121,157,16)", textColor: 'ffffff' },
  { background: "rgb(52,245,14)", textColor: '000000' },
  { background: "rgb(174,120,62)", textColor: 'ffffff' },
  { background: "rgb(244,212,3)", textColor: '000000' },
  { background: "rgb(251,120,16)", textColor: '000000' },
  { background: "rgb(255,136,137)", textColor: '000000' },
]

const rgbToHex = (rgbString) => {
  // Extract the individual RGB values
  const rgbValues = rgbString.substring(4, rgbString.length - 1).split(',');

  // Convert each RGB value to its hexadecimal equivalent
  const red = parseInt(rgbValues[0]);
  const green = parseInt(rgbValues[1]);
  const blue = parseInt(rgbValues[2]);

  // Convert the RGB values to a hex color code
  const hexColor = ((1 << 24) | (red << 16) | (green << 8) | blue).toString(16).slice(1);

  return hexColor;
}

const stringToUniqueNumber = (str) => {
  const caculatedValue = str.slice(-4);
  const id = parseInt(caculatedValue, 16);
  return id;
}

export class ProfileAvatarPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuccessMessage: false,
      isNextDisabled: false,
      isImageUploaded: false,
      isCountryCodeValid: true,
      isPhoneNumberValid: true,
      isCountryCodetouched: false,
      isPhoneNumbertouched: false,
    };

    this.countryCode = React.createRef();
    this.phoneNumber = React.createRef();
  }

  onUpdateChanges = () => {
    const uploadedImage = this.props.image;
    const updatedValues =
      uploadedImage && uploadedImage.imageId && uploadedImage.file
        ? { profileImageId: uploadedImage.imageId }
        : null;
    if (updatedValues) {
      this.props.onUpdateProfile(updatedValues);
      this.onChangeCountryCode();
      this.onChangePhoneNumber();
    }
  };

  onChangeCountryCode = () => {
    if (this.countryCode.current && this.countryCode.current.value.length > 1) {
      this.setState({ isCountryCodeValid: true });
    } else {
      this.setState({ isCountryCodeValid: false, isNextDisabled: true });
    }
  };

  onChangePhoneNumber = () => {
    if (this.phoneNumber.current && this.phoneNumber.current.value.length > 6) {
      this.setState({ isPhoneNumberValid: true });
    } else {
      this.setState({ isPhoneNumberValid: false, isNextDisabled: true });
    }
  };

  componentDidUpdate() {
    const uploadedImage = this.props.image;
    const {
      showSuccessMessage,
      isNextDisabled,
      isImageUploaded,
      isCountryCodeValid,
      isPhoneNumberValid,
    } = this.state;
    if (
      uploadedImage &&
      uploadedImage.imageId &&
      uploadedImage.file &&
      showSuccessMessage !== true &&
      isImageUploaded !== true
    ) {
      this.onUpdateChanges();
      this.setState({ showSuccessMessage: true, isImageUploaded: true });
    }
    if (isImageUploaded && isCountryCodeValid && isPhoneNumberValid && isNextDisabled) {
      this.setState({ isNextDisabled: false });
    }
  }

  onCloseSuccessMessage = () => {
    this.setState({ showSuccessMessage: false });
  };

  render() {
    const {
      image,
      onImageUpload,
      updateInProgress,
      updateProfileError,
      uploadImageError,
      uploadInProgress,
      currentUser,
      onSubmitContactDetails,
      history,
    } = this.props;

    const adminId = currentUser?.attributes?.profile?.publicData?.adminId;

    const abbreviatedName = currentUser?.attributes.profile.abbreviatedName;

    const {
      isPhoneNumberValid,
      isCountryCodeValid,
      isPhoneNumbertouched,
      isCountryCodetouched,
    } = this.state;
    const onChangeImage = e => {
      const file = e.target.files[0];
      if (file != null) {
        const tempId = `${file.name}_${Date.now()}`;
        onImageUpload({ id: tempId, file });
      }
      this.onChangeCountryCode();
      this.onChangePhoneNumber();
    };

    const onClickImageAvatar = async avatar => {
      try {
        const response = await axios({
          url: `/static/avatar/${avatar}.png`,
          method: 'GET',
          responseType: 'blob', // important
        });
        const fileBlob = new Blob([response.data]);
        var file = new File([fileBlob], 'avatar1');
        if (file != null) {
          const tempId = `${file.name}_${Date.now()}`;
          onImageUpload({ id: tempId, file });
        }
      } catch (error) {
        console.log('error', error);
      }
    };

    const onGenerateIntialAvatar = async (id) => {
      const userId = stringToUniqueNumber(id.uuid);
      const userColor = avatarColors[userId % avatarColors.length];
      const backgroundColor = rgbToHex(userColor.background);

      const url = userInitialsBaseURI.concat(abbreviatedName, `&background=${backgroundColor}`, "&size=512").concat(`&color=${userColor.textColor}`);
      const response = await axios({
        url: url,
        method: 'GET',
        responseType: 'blob'
      });

      const fileBlob = new Blob([response.data]);
      var file = new File([fileBlob], 'avatar1');
      if (file != null) {
        const tempId = `${file.name}_${Date.now()}`;
        onImageUpload({ id: tempId, file });
      }
      this.onChangeCountryCode();
      this.onChangePhoneNumber();
    }

    const onClickNextButton = () => {
      // const result = {
      //   countryCode: this.countryCode.current.value,
      //   phoneNumber: this.phoneNumber.current.value,
      // };
      // onSubmitContactDetails(result);

      if (!this.state.isImageUploaded) {
        onGenerateIntialAvatar(user.id);
      }

      if (adminId) {
        history.push('/l/draft/00000000-0000-0000-0000-000000000000/new/overview');
      } else {
        history.push('/');
      }
    };

    const user = ensureCurrentUser(currentUser);
    const transientUserProfileImage = (image && image.uploadedImage) || (user && user.profileImage);
    const transientUser = { ...user, profileImage: transientUserProfileImage };

    let error = null;
    if (uploadImageError) {
      error = (
        <div className={css.error}>
          <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
        </div>
      );
    } else if (updateProfileError) {
      error = (
        <div className={css.error}>
          <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
        </div>
      );
    }

    let content;

    if (updateInProgress || uploadInProgress) {
      content = <IconSpinner rootClassName={css.spinner} />;
    } else {
      content = (
        <div className={css.addAvatarSection}>
          <div className={css.chooseLeftSection}>
            <h3 className={css.addAvatarTitle}>
              <FormattedMessage id="ProfileAvatarPage.addAvatar" />
            </h3>
            <div>
              <input
                type="file"
                accept="image/*"
                onChange={onChangeImage}
                capture="camera"
                id="file1"
              />
              <label
                htmlFor="file1"
                className={cns({ [css.removedBorder]: transientUser.profileImage !== null })}
              >
                {transientUser.profileImage ? (
                  <Avatar rootClassName={css.avatar} user={transientUser} disableProfileLink />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="63"
                    height="63"
                    viewBox="0 0 63 63"
                    fill="none"
                  >
                    <path
                      d="M26 34H32M32 34H38M32 34V28M32 34L32 40"
                      stroke="#0095B3"
                      strokeWidth="3"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M48.8935 15.065H55.4674C59.6446 15.065 63 18.4205 63 22.5976V49.1672C63 53.3444 59.6446 56.6998 55.4674 56.6998H7.53261C3.35543 56.6998 0 53.3444 0 49.1672V22.5976C0 18.4205 3.35543 15.065 7.53261 15.065H14.1065L17.7359 9.58676C19.1054 7.53241 21.5022 6.2998 23.9674 6.2998H39.0326C41.4978 6.2998 43.8946 7.53241 45.2641 9.58676L48.8935 15.065ZM54.2348 25.6792H54.5087C55.7413 25.6107 56.6315 24.5835 56.563 23.3509C56.4946 22.1183 55.4674 21.1596 54.2348 21.2281H51.4956C50.3315 21.2965 49.4413 22.1868 49.3728 23.3509C49.3043 24.5835 50.263 25.6107 51.4956 25.6792H54.2348ZM32 47C39.1797 47 45 41.1797 45 34C45 26.8203 39.1797 21 32 21C24.8203 21 19 26.8203 19 34C19 41.1797 24.8203 47 32 47Z"
                      fill="#0095B3"
                    />
                  </svg>
                )}
              </label>
            </div>
          </div>
          <div className={css.chooseSection}>
            <PrimaryButton disabled={false} className={css.nextButton} onClick={onClickNextButton}>
              <FormattedMessage id="ProfileAvatarPage.nextBtn" />
            </PrimaryButton>
            <div>
              <input type="file" id="file" onChange={onChangeImage} />
              <label htmlFor="file">
                <FormattedMessage id="ProfileAvatarPage.chooseImage" />
              </label>
            </div>
            {/* <span>
              <FormattedMessage id="ProfileAvatarPage.OrChooseRobot" />
            </span>
            <div className={css.robotImageContainer}>
              <img
                onClick={() => onClickImageAvatar('avatar1')}
                src={'/static/avatar/avatar1.png'}
                alt="avatar1"
              />
              <img
                onClick={() => onClickImageAvatar('avatar2')}
                src={'/static/avatar/avatar2.png'}
                alt="avatar2"
              />
              <img
                onClick={() => onClickImageAvatar('avatar3')}
                src={'/static/avatar/avatar3.png'}
                alt="avatar3"
              />
              <img
                onClick={() => onClickImageAvatar('avatar4')}
                src={'/static/avatar/avatar4.png'}
                alt="avatar4"
              />
              <img
                onClick={() => onClickImageAvatar('avatar5')}
                src={'/static/avatar/avatar5.png'}
                alt="avatar5"
              />
              <img
                onClick={() => onClickImageAvatar('avatar6')}
                src={'/static/avatar/avatar6.png'}
                alt="avatar6"
              />
              <img
                onClick={() => onClickImageAvatar('avatar7')}
                src={'/static/avatar/avatar7.png'}
                alt="avatar7"
              />
              <img
                onClick={() => onClickImageAvatar('avatar8')}
                src={'/static/avatar/avatar8.png'}
                alt="avatar8"
              />
              <img
                onClick={() => onClickImageAvatar('avatar9')}
                src={'/static/avatar/avatar9.png'}
                alt="avatar9"
              />
              <img
                onClick={() => onClickImageAvatar('avatar10')}
                src={'/static/avatar/avatar10.png'}
                alt="avatar10"
              />
              <img
                onClick={() => onClickImageAvatar('avatar11')}
                src={'/static/avatar/avatar11.png'}
                alt="avatar11"
              />
              <img
                onClick={() => onClickImageAvatar('avatar12')}
                src={'/static/avatar/avatar12.png'}
                alt="avatar12"
              />
              <img
                onClick={() => onClickImageAvatar('avatar13')}
                src={'/static/avatar/avatar13.png'}
                alt="avatar13"
              />
              <img
                onClick={() => onClickImageAvatar('avatar14')}
                src={'/static/avatar/avatar14.png'}
                alt="avatar14"
              />
              <img
                onClick={() => onClickImageAvatar('avatar15')}
                src={'/static/avatar/avatar15.png'}
                alt="avatar15"
              />
              <img
                onClick={() => onClickImageAvatar('avatar16')}
                src={'/static/avatar/avatar16.png'}
                alt="avatar16"
              />
              <img
                onClick={() => onClickImageAvatar('avatar17')}
                src={'/static/avatar/avatar17.png'}
                alt="avatar17"
              />
              <img
                onClick={() => onClickImageAvatar('avatar18')}
                src={'/static/avatar/avatar18.png'}
                alt="avatar18"
              />
            </div> */}
          </div>
        </div>
      );
    }

    return (
      <Page className={css.root}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.mainContainer}>
              <GenericMessage
                show={this.state.showSuccessMessage}
                onClose={this.onCloseSuccessMessage}
                message={'You successfully updated your profile pic'}
              />
              <div className={css.leftContainer}>
                <img src={'/static/avatar/ProfileAvatarPage.png'} alt="ProfileAvatarPage" />
                {/* <div className={css.phoneNumberContainer}>
                  <label>
                    <FormattedMessage id="ProfileAvatarPage.contactNumber" />
                  </label>
                  <span className={css.contactNumberInfo}>
                    <FormattedMessage id="ProfileAvatarPage.contactNumberInfo" />
                  </span>
                  <div className={css.PhoneInputWrapper}>
                    <div className={css.leftInput}>
                      <input
                        ref={this.countryCode}
                        className={cns(css.countryCode, {
                          [css.inputSuccess]: isCountryCodeValid,
                          [css.inputError]: !isCountryCodeValid && isCountryCodetouched === true,
                        })}
                        placeholder="Country code"
                        onClick={() => this.setState({ isCountryCodetouched: true })}
                        onChange={this.onChangeCountryCode}
                        id={'countryCode'}
                        type="Number"
                        required
                      />
                      {!isCountryCodeValid && isCountryCodetouched === true && (
                        <span className={css.error}>
                          <FormattedMessage id="ProfileAvatarPage.countryInvalid" />
                        </span>
                      )}
                    </div>
                    <div className={css.rightInput}>
                      <input
                        ref={this.phoneNumber}
                        className={cns(css.phoneNumber, {
                          [css.inputSuccess]: isPhoneNumberValid,
                          [css.inputError]: !isPhoneNumberValid && isPhoneNumbertouched === true,
                        })}
                        placeholder="Enter your phone number"
                        onClick={() => this.setState({ isPhoneNumbertouched: true })}
                        onChange={this.onChangePhoneNumber}
                        id={'phoneNumber'}
                        type="Number"
                        required
                      />
                      {!isPhoneNumberValid && isPhoneNumbertouched === true && (
                        <span className={css.error}>
                          <FormattedMessage id="ContactDetailsForm.phoneInvalid" />
                        </span>
                      )}
                    </div>
                  </div>
                </div> */}
              </div>
              <div className={css.rightContainer}>
                <div className={css.heading}>
                  <h2>
                    <FormattedMessage id="ProfileAvatarPage.welcomeYourProfile" />
                  </h2>
                  <span>
                    <FormattedMessage id="ProfileAvatarPage.betterYou" />
                  </span>
                </div>
                {error}
                {content}
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;
  return {
    currentUser,
    image,
    scrollingDisabled: isScrollingDisabled(state),
    updateInProgress,
    updateProfileError,
    uploadImageError,
    uploadInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onImageUpload: data => dispatch(uploadImage(data)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
  onSubmitContactDetails: values => dispatch(saveContactDetails(values)),
});

const ProfileAvatarPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ProfileAvatarPageComponent);

export default ProfileAvatarPage;
