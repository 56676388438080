import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { createSlug, stringify } from '../../util/urlHelpers';
import { NamedLink, InlineTextButton, PrimaryButton } from '../../components';
import QRcode from 'assets/QR_code_play_app_store_badges.png';

import css from './TransactionPanel.css';
import { LINE_ITEM_BASIC_PACKAGE_FEE } from 'util/types';
import { LINE_ITEM_DETAILED_PACKAGE_FEE } from 'util/types';
import Timer from './components/Timer/Timer';
import { convertMoneyToNumber } from '../../util/currency';
import { isMoneyType } from 'util/validators';
import { txIsPriceOffered } from 'util/transaction';

export const HEADING_ENQUIRED = 'enquired';
export const HEADING_PAYMENT_PENDING = 'pending-payment';
export const HEADING_PAYMENT_EXPIRED = 'payment-expired';
export const HEADING_PRICE_OFFERED = 'price-offered';
export const HEADING_WORK_STARTED = 'work-started';
export const HEADING_REQUESTED = 'requested';
export const HEADING_ACCEPTED = 'accepted';
export const HEADING_PAYMENT_CAPTURING = 'payment-capturing';
export const HEADING_REQUESTED_EXTEND = 'requested-extend';
export const HEADING_DECLINED = 'declined';
export const HEADING_PAYMENT_CAPTURE_FAILED = 'payment-capture-failed';
export const HEADING_CANCELED = 'canceled';
export const HEADING_DELIVERED = 'delivered';
export const HEADING_COLLABORATE = 'collaborate';
export const HEADING_COMPLETED = 'completed';

const createListingLink = (listingId, label, listingDeleted, searchParams = {}, className = '') => {
  if (!listingDeleted) {
    const params = { id: listingId, slug: createSlug(label) };
    const to = { search: stringify(searchParams) };
    return (
      <NamedLink className={className} name="ListingPage" params={params} to={to}>
        {label}
      </NamedLink>
    );
  } else {
    return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
  }
};

const createEditListingLink = (listingId, label, listingDeleted, linkTitle, className = '') => {
  if (!listingDeleted) {
    const params = {
      id: listingId,
      slug: createSlug(label),
      type: 'edit',
      tab: 'view_draft_listing',
    };
    return (
      <NamedLink className={className} name="EditListingPage" params={params}>
        {linkTitle}
      </NamedLink>
    );
  } else {
    return <a href="">{linkTitle}</a>;
  }
};

const ListingDeletedInfoMaybe = props => {
  return props.listingDeleted ? (
    <p className={css.transactionInfoMessage}>
      <FormattedMessage id="TransactionPanel.messageDeletedListing" />
    </p>
  ) : null;
};

const HeadingCustomer = props => {
  const { className, id, values, listingDeleted } = props;
  return (
    <React.Fragment>
      <h1 className={className}>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} values={values} />
        </span>
      </h1>
      <ListingDeletedInfoMaybe listingDeleted={listingDeleted} />
    </React.Fragment>
  );
};

const HeadingCustomerWithSubtitle = props => {
  const { className, id, values, subtitleId, subtitleValues, children, listingDeleted } = props;
  return (
    <React.Fragment>
      <h1 className={className}>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} values={values} />
        </span>
        <FormattedMessage id={subtitleId} values={subtitleValues} />
      </h1>
      {children}
      <ListingDeletedInfoMaybe listingDeleted={listingDeleted} />
    </React.Fragment>
  );
};

const CustomerBannedInfoMaybe = props => {
  return props.isCustomerBanned ? (
    <p className={css.transactionInfoMessage}>
      <FormattedMessage id="TransactionPanel.customerBannedStatus" />
    </p>
  ) : null;
};

const HeadingProvider = props => {
  const { className, id, values, isCustomerBanned, children } = props;
  return (
    <React.Fragment>
      <h1 className={className}>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} values={values} />
        </span>
      </h1>
      {children}
      <CustomerBannedInfoMaybe isCustomerBanned={isCustomerBanned} />
    </React.Fragment>
  );
};

// Functional component as a helper to choose and show Order or Sale heading info:
// title, subtitle, and message
const PanelHeading = props => {
  const {
    className,
    rootClassName,
    panelHeadingState,
    customerName,
    providerName,
    listingId,
    listingTitle,
    listingDeleted,
    isCustomerBanned,
    currentTransaction,
    listing,
    currentUser,
    lastTransitionedAt,
    deliveryTime,
    collaborationTime,
    isDeliveryExtended,
    onOpenReviewModal,
    travelAgentServiceRequested,
  } = props;

  let offerPriceAmount;
  const offeredPrice = currentTransaction?.attributes?.payinTotal;

  if (isMoneyType(offeredPrice) && txIsPriceOffered(currentTransaction)) {
    offerPriceAmount = convertMoneyToNumber(offeredPrice);
  }

  const saleCompletedTitle = travelAgentServiceRequested === undefined ? "TransactionPanel.saleCompletedTitle" : travelAgentServiceRequested ? "TransactionPanel.saleCompletedTitleOnTravelAgencyYes" : "TransactionPanel.saleCompletedTitleOnTravelAgencyNo";

  const occasionalDeliveryTime = isDeliveryExtended ? Number(deliveryTime) + 24 : deliveryTime;
  const customerReviewed = currentTransaction.reviews.some(
    review => review.attributes.type === 'ofProvider'
  );
  const providerReviewed = currentTransaction.reviews.some(
    review => review.attributes.type === 'ofCustomer'
  );

  const userName = props.customerName.props.user.attributes.profile.publicData.userName;

  const isCustomer = props.transactionRole === 'customer';

  const defaultRootClassName = isCustomer ? css.headingOrder : css.headingSale;
  const titleClasses = classNames(rootClassName || defaultRootClassName, className);
  const listingLink = createListingLink(listingId, listingTitle, listingDeleted);
  const withHitpay =
    currentTransaction &&
    currentTransaction.attributes &&
    currentTransaction.attributes.protectedData &&
    currentTransaction.attributes.protectedData.withHitpay;

  const protectedData = currentTransaction.attributes.protectedData.tripDetails;
  const tripName = protectedData?.tripName;

  const lineItems = currentTransaction?.attributes?.lineItems || [];

  const { packages } = listing.attributes.publicData;
  const hasPackage = lineItems.find(
    item =>
      item.code === LINE_ITEM_BASIC_PACKAGE_FEE || item.code === LINE_ITEM_DETAILED_PACKAGE_FEE
  );
  const selectedPackage = hasPackage
    ? hasPackage.code === LINE_ITEM_BASIC_PACKAGE_FEE
      ? packages[0]
      : hasPackage.code === LINE_ITEM_DETAILED_PACKAGE_FEE
        ? packages[1]
        : null
    : null;

  switch (panelHeadingState) {
    case HEADING_ENQUIRED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderEnquiredTitle"
          values={{
            providerName,
            tripName,
            br: <br />,
            red: (...chunks) => <b style={{color: '#C13750'}}>{chunks}</b>,
            bold: (...chunks) => <b>{chunks}</b>,
            title: (...chunks) => <span style={{ fontSize: 22, fontWeight: 700 }}>{chunks}</span>,
            listingLink
          }}
          listingDeleted={listingDeleted}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleEnquiredTitle"
          values={{
            title: (...chunks) => <span style={{ fontSize: 22, fontWeight: 700 }}>{chunks}</span>,
            customerName,
            listingLink,
            br: <br />,
          }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_PRICE_OFFERED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderPriceOfferedTitle"
          values={{
            providerName,
            customerName,
            br: <br />,
            title: (...chunks) => <span style={{ fontSize: 22, fontWeight: 700 }}>{chunks}</span>,
            amount: offerPriceAmount,
            currency: offeredPrice?.currency || 'USD',
            bold: (...chunks) => <b>{chunks}</b>,
            listingLink
          }}
          listingDeleted={listingDeleted}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.salePriceOfferedTitle"
          values={{
            title: (...chunks) => <span style={{ fontSize: 22, fontWeight: 700 }}>{chunks}</span>,
            customerName,
            amount: offerPriceAmount,
            currency: offeredPrice?.currency || 'USD',
            listingLink,
            br: <br />,
            bold: (...chunks) => <b>{chunks}</b>,
          }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_WORK_STARTED:
      return isCustomer ? (
        <HeadingCustomerWithSubtitle
          className={titleClasses}
          id="TransactionPanel.orderAcceptedTitle"
          values={{
            providerName,
            deliveryTime: occasionalDeliveryTime,
            br: <br />,
            b: (...chunks) => <b>{chunks}</b>,
            em: (...chunks) => <em>{chunks}</em>,
            red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
            a: (...chunks) => (
              <a href="https://l.lyfshort.com/mkp_open_org_app" target="_blank">
                {chunks}
              </a>
            ),
          }}
          subtitleId="TransactionPanel.orderAcceptedSubtitle"
          subtitleValues={{ listingLink }}
        >
          <div className={css.qrCodeContainer}>
            <a href='https://l.lyfshort.com/mkp_open_org_app' target='_blank'><img src={QRcode} /></a>
          </div>
        </HeadingCustomerWithSubtitle>
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleAcceptedTitle"
          values={{
            customerName,
            userName,
            b: (...chunks) => <b>{chunks}</b>,
            br: <br />,
            red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
            a: (...chunks) => (
              <a href="https://l.lyfshort.com/mkp_open_org_app" target="_blank">
                {chunks}
              </a>
            ),
          }}
        >
          <div className={css.qrCodeContainer}>
            <a href='https://l.lyfshort.com/mkp_open_org_app' target='_blank'><img src={QRcode} /></a>
          </div>
        </HeadingProvider>
      );
    case HEADING_PAYMENT_PENDING:
      const headingPendingPaymentId = withHitpay
        ? 'TransactionPanel.orderPaymentPendingTitleHitPay'
        : 'TransactionPanel.orderPaymentPendingTitle';
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id={headingPendingPaymentId}
          values={{ listingLink }}
          listingDeleted={listingDeleted}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.salePaymentPendingTitle"
          values={{ customerName, listingLink }}
          isCustomerBanned={isCustomerBanned}
        >
          <p className={css.transactionInfoMessage}>
            <FormattedMessage
              id="TransactionPanel.salePaymentPendingInfo"
              values={{ customerName }}
            />
          </p>
        </HeadingProvider>
      );
    case HEADING_PAYMENT_EXPIRED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderPaymentExpiredTitle"
          values={{ listingLink }}
          listingDeleted={listingDeleted}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.salePaymentExpiredTitle"
          values={{ customerName, listingLink }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_REQUESTED:
      return isCustomer ? (
        <HeadingCustomerWithSubtitle
          className={titleClasses}
          id="TransactionPanel.orderPreauthorizedTitle"
          values={{
            customerName,
            providerName,
            br: <br />,
            b: (...chunks) => <b>{chunks}</b>,
            red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
          }}
          subtitleId="TransactionPanel.orderPreauthorizedSubtitle"
          subtitleValues={{ listingLink }}
        >
          {!listingDeleted ? (
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id="TransactionPanel.orderPreauthorizedInfo"
                values={{
                  providerName,
                  br: <br />,
                  b: (...chunks) => <b>{chunks}</b>,
                  red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
                  a: (...chunks) => (
                    <a href="https://l.lyfshort.com/mkp_open_org_app" target="_blank">
                      {chunks}
                    </a>
                  ),
                  a1: (...chunks) => (
                    <a href="https://l.lyfshort.com/TripExpertOrderEmail" target="_blank">
                      {chunks}
                    </a>
                  ),
                  currentUserEmail: currentUser?.attributes?.email,
                }}
              />
              <div className={css.qrCodeContainer}>
                <a href='https://l.lyfshort.com/mkp_open_org_app' target='_blank'><img src={QRcode} /></a>
              </div>
            </p>
          ) : null}
        </HeadingCustomerWithSubtitle>
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleRequestedTitle"
          values={{ customerName, listingLink, br: <br /> }}
        >
          {!isCustomerBanned ? (
            <p className={titleClasses}>
              <FormattedMessage
                id="TransactionPanel.saleRequestedInfo"
                values={{
                  userName,
                  deliveryTime,
                  br: <br />,
                  b: (...chunks) => <b>{chunks}</b>,
                  em: (...chunks) => <em>{chunks}</em>,
                  red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
                  a: (...chunks) => (
                    <a href="https://l.lyfshort.com/mkp_open_org_app" target="_blank">
                      {chunks}
                    </a>
                  ),
                }}
              />
            </p>
          ) : null}
        </HeadingProvider>
      );
    case HEADING_PAYMENT_CAPTURING:
      return isCustomer ? (
        <HeadingCustomerWithSubtitle
          className={titleClasses}
          id="TransactionPanel.orderPreauthorizedTitle"
          values={{
            customerName,
            providerName,
            br: <br />,
            b: (...chunks) => <b>{chunks}</b>,
            red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
          }}
          subtitleId="TransactionPanel.orderPreauthorizedSubtitle"
          subtitleValues={{ listingLink }}
        >
          {!listingDeleted ? (
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id="TransactionPanel.orderPreauthorizedInfo"
                values={{
                  providerName,
                  br: <br />,
                  b: (...chunks) => <b>{chunks}</b>,
                  red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
                  a: (...chunks) => (
                    <a href="https://l.lyfshort.com/mkp_open_org_app" target="_blank">
                      {chunks}
                    </a>
                  ),
                  a1: (...chunks) => (
                    <a href="https://l.lyfshort.com/TripExpertOrderEmail" target="_blank">
                      {chunks}
                    </a>
                  ),
                  currentUserEmail: currentUser?.attributes?.email,
                }}
              />
              <div className={css.qrCodeContainer}>
                <a href='https://l.lyfshort.com/mkp_open_org_app' target='_blank'><img src={QRcode} /></a>
              </div>
            </p>
          ) : null}
        </HeadingCustomerWithSubtitle>
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.salePaymentCapturingTitle"
          values={{ customerName, listingLink, br: <br /> }}
        />
      );
    case HEADING_ACCEPTED:
      return isCustomer ? (
        <HeadingCustomerWithSubtitle
          className={titleClasses}
          id="TransactionPanel.orderAcceptedTitle"
          values={{
            providerName,
            deliveryTime: occasionalDeliveryTime,
            br: <br />,
            b: (...chunks) => <b>{chunks}</b>,
            em: (...chunks) => <em>{chunks}</em>,
            red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
            a: (...chunks) => (
              <a href="https://l.lyfshort.com/mkp_open_org_app" target="_blank">
                {chunks}
              </a>
            ),
            timeLeft: (
              <p className={css.timeLeftLabel}>
                <Timer date={lastTransitionedAt} hoursToAdd={occasionalDeliveryTime} />
              </p>
            ),
          }}
          subtitleId="TransactionPanel.orderAcceptedSubtitle"
          subtitleValues={{ listingLink }}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleAcceptedTitle"
          values={{
            userName,
            customerName,
            b: (...chunks) => <b>{chunks}</b>,
            br: <br />,
            red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
            a: (...chunks) => (
              <a href="https://l.lyfshort.com/mkp_open_org_app" target="_blank">
                {chunks}
              </a>
            ),
          }}
        >
          <div className={css.qrCodeContainer}>
            <a href='https://l.lyfshort.com/mkp_open_org_app' target='_blank'><img src={QRcode} /></a>
          </div>
        </HeadingProvider>
      );
    case HEADING_REQUESTED_EXTEND:
      return isCustomer ? (
        <HeadingCustomerWithSubtitle
          className={titleClasses}
          id="TransactionPanel.orderRequestedExtendTitle"
          values={{
            providerName,
            deliveryTime: occasionalDeliveryTime,
            br: <br />,
            b: (...chunks) => <b>{chunks}</b>,
            red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
            a: (...chunks) => (
              <a href="https://l.lyfshort.com/mkp_open_org_app" target="_blank">
                {chunks}
              </a>
            ),
          }}
          subtitleId="TransactionPanel.orderAcceptedSubtitle"
          subtitleValues={{ listingLink }}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleAcceptedTitle"
          values={{
            customerName,
            userName,
            b: (...chunks) => <b>{chunks}</b>,
            br: <br />,
            red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
            a: (...chunks) => (
              <a href="https://l.lyfshort.com/mkp_open_org_app" target="_blank">
                {chunks}
              </a>
            ),
          }}
        />
      );
    case HEADING_DECLINED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderDeclinedTitle"
          values={{
            providerName,
            listingLink,
            br: <br />,
            b: (...chunks) => <b>{chunks}</b>,
            red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
          }}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleDeclinedTitle"
          values={{
            customerName,
            listingLink,
            br: <br />,
            b: (...chunks) => <b>{chunks}</b>,
            red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
            a: (...chunks) => (
              <a href="https://l.lyfshort.com/mkp_open_org_app" target="_blank">
                {chunks}
              </a>
            ),
          }}
          isCustomerBanned={isCustomerBanned}
        >
          <p className={titleClasses}>
            <FormattedMessage
              id="TransactionPanel.saleDeclinedInfo"
              values={{
                userName,
                br: <br />,
                b: (...chunks) => <b>{chunks}</b>,
                red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
                a: (...chunks) =>
                  createEditListingLink(listingId, listingTitle, listingDeleted, chunks),
              }}
            />
          </p>
        </HeadingProvider>
      );
    case HEADING_PAYMENT_CAPTURE_FAILED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderPaymentCaptureFailedTitle"
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.salePaymentCaptureFailedTitle"
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_CANCELED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderCancelledTitle"
          values={{ customerName, tripName }}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleCancelledTitle"
          values={{ customerName }}
        />
      );
    case HEADING_DELIVERED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderDeliveredTitle"
          values={{
            providerName,
            listingLink,
            collaborationTime,
            br: <br />,
            b: (...chunks) => <b>{chunks}</b>,
            red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
          }}
          isCustomerBanned={isCustomerBanned}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleDeliveredTitle"
          values={{
            customerName,
            listingLink,
            collaborationTime,
            br: <br />,
            b: (...chunks) => <b>{chunks}</b>,
            em: (...chunks) => <em>{chunks}</em>,
            red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
            a: (...chunks) => (
              <a href="https://l.lyfshort.com/mkp_open_org_app" target="_blank">
                {chunks}
              </a>
            ),
            timeLeft: (
              <p className={css.timeLeftLabel}>
                <Timer date={lastTransitionedAt} hoursToAdd={collaborationTime} />
              </p>
            ),
          }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_COLLABORATE:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderCollaborateTitle"
          values={{
            providerName,
            collaborationTime,
            br: <br />,
            b: (...chunks) => <b>{chunks}</b>,
            red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
          }}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleCollaborateTitle"
          values={{
            customerName,
            collaborationTime,
            br: <br />,
            b: (...chunks) => <b>{chunks}</b>,
            em: (...chunks) => <em>{chunks}</em>,
            red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
            timeLeft: (
              <p className={css.timeLeftLabel}>
                <Timer date={lastTransitionedAt} hoursToAdd={collaborationTime} />
              </p>
            ),
          }}
        />
      );
    case HEADING_COMPLETED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderCompletedTitle"
          values={{
            tripName,
            providerName,
            br: <br />,
            b: (...chunks) => <b>{chunks}</b>,
            red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
            reviewLink: !customerReviewed ? (
              <>
                Please now rate and review {providerName}.
                <br />
                <br />
                <PrimaryButton onClick={onOpenReviewModal}>{'Review Trip Expert'}</PrimaryButton>
              </>
            ) : null,
          }}
        ></HeadingCustomer>
      ) : (
        <HeadingProvider
          className={titleClasses}
          id={saleCompletedTitle}
          values={{
            customerName,
            br: <br />,
            b: (...chunks) => <b>{chunks}</b>,
            em: (...chunks) => <em>{chunks}</em>,
            red: (...chunks) => <span style={{ color: '#C13750' }}>{chunks}</span>,
            reviewLink: !providerReviewed ? (
              <>
                Please now rate and review {customerName}.
                <br />
                <br />
                <PrimaryButton onClick={onOpenReviewModal}>{'Review Customer'}</PrimaryButton>
              </>
            ) : null,
          }}
        />
      );
    default:
      console.warn('Unknown state given to panel heading.');
      return null;
  }
};

export default PanelHeading;
