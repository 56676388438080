import React from 'react';
import PropTypes from 'prop-types';
import cns from 'classnames';

import css from './IconFavourite.css';

const IconFavourite = props => {
  const classes = cns({
    [css.iconFavouriteWrapper]: true,
    [props.className]: props.className,
  });
  return (
    <div className={classes}>
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="heart"
        className={css.iconFavourite}
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z"
        ></path>
      </svg>
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="plus"
        className={css.iconFavouritePlus}
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
      >
        <path
          fill="currentColor"
          d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
        ></path>
      </svg>
    </div>
  );
};

IconFavourite.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconFavourite.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconFavourite;
