import React, { useState, useEffect } from 'react';
import { SecondaryButton, NamedLink } from 'components';
import classNames from 'classnames';
import css from './BusinessStatusBar.css';
import view_profile_icon from 'assets/external-link.svg';
import { createSlug } from 'util/urlHelpers';

const actionTypes = {
  APPROVE: 'approve',
  DECLINE: 'deny',
};

const approvalTypes = [
  { value: 0, label: '' },
  { value: 1, label: 'Approved' },
  { value: 2, label: 'Declined' },
];

const getApprovalLabel = value => {
  const found = approvalTypes.find(type => type.value == value);
  return found ? found.label : null;
};

const getStatusClass = status => {
  if (status == 1) return css.approvedStatus;
  if (status == 2) return css.declinedStatus;
  return null;
};

const BusinessStatusBar = props => {
  const {
    id,
    title,
    listingId,
    status,
    requested,
    date,
    handleAction,
    inProgress,
    setInProgress,
  } = props;

  const [approving, setApproving] = useState(false);
  const [declining, setDeclining] = useState(false);

  const slug = createSlug(title);

  useEffect(() => {
    if (!inProgress) {
      setApproving(false);
      setDeclining(false);
    }
  }, [inProgress]);

  const handleApprove = () => {
    setApproving(true);
    setInProgress(true);
    handleAction(id, actionTypes.APPROVE);
  };

  const handleDecline = () => {
    setDeclining(true);
    setInProgress(true);
    handleAction(id, actionTypes.DECLINE);
  };

  return (
    <div className={css.itemWrapper} key={id}>
      <div className={css.actionsWrapper}>
        <div className={css.titleWrapper}>
          <div className={css.nameWrapper}>
            {title}
            {status === 1 ? (
              <NamedLink className={css.linkIconClass} name="ListingPage" params={{ id: listingId, slug }} target="_blank">
                <img src={view_profile_icon} alt="business profile view" />
              </NamedLink>
            ) : null}
          </div>
          <div className={css.idWrapper}>{listingId}</div>
        </div>
        <div className={css.buttonsWrapper}>
          <SecondaryButton
            className={classNames(css.actionBtnClass, css.declineBtn)}
            onClick={handleDecline}
            disabled={!requested}
            inProgress={declining}
          >
            Decline
          </SecondaryButton>
          <SecondaryButton
            className={classNames(css.actionBtnClass, css.acceptBtn)}
            onClick={handleApprove}
            disabled={!requested}
            inProgress={approving}
          >
            Accept
          </SecondaryButton>
        </div>
      </div>
      <div className={css.detailsWrapper}>
        <div className={css.statusWrapper}>
          <div>Added:</div>
          <div className={css.dateWrapper}>{date}</div>
          <div>Status: </div>
          <div className={getStatusClass(status)}>{getApprovalLabel(status)}</div>
        </div>
        {requested && <div className={css.reqStatusWrapper}>Requested</div>}
      </div>
    </div>
  );
};

export default BusinessStatusBar;
