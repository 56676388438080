import React, { Component } from 'react';
import {
  ListingTravelAgentService,
  SectionHeadImagesSlider,
  ResponsiveImage,
} from 'components';
import css from './CompanyPage.css';
import Identity_Verified_Icon from '../../assets/Listing_Page/Identity_Verified_Icon.png';
import Triangle_Orange from '../../assets/Listing_Page/Triangle_Orange.png';
import SectionMapMaybe from './SectionMapMaybe';
import { isMobile } from 'util/userAgent';

class CompanyPageCommonSection extends Component {
  render() {
    const {
      currentListing,
      backgroundImage,
      profileImage,
      title,
      intl,
      listingVariant,
      currencyConversionRate,
    } = this.props;

    const { publicData } = currentListing.attributes;

    const profileList = [
      {
        id: 'travel_business',
        title: 'Travel Business',
        details: [currentListing?.attributes?.title],
      },
      {
        id: 'business_number',
        title: 'Business Number',
        details: [publicData?.Travel_business_busnumber],
      },
      {
        id: 'years_in_business',
        title: 'Years in Business',
        details: [publicData?.Travel_business_busyears],
      },
      {
        id: 'business_location',
        title: 'Business Location',
        details: [publicData?.Travel_business_location?.search],
      },
      {
        id: 'accreditation',
        title: 'Accreditations',
        details: publicData?.Travel_business_acreditation,
      },
      {
        id: 'payments_accepted',
        title: 'Payments Accepted',
        details: publicData?.Travel_business_payment_method,
      },
    ];

    if (typeof window === 'undefined') {
      return null;
    }

    return (
      <>
        <div className={css.bannerContainer}>
          <div className={css.banner}>
            <ResponsiveImage
              image={backgroundImage}
              variants={[
                'scaled-medium',
                'scaled-large',
                'default',
                'landscape-background-big-crop',
                'landscape-background-crop',
              ]}
              alt={'title'}
            />
            <div className={css.titleContainer}>{title}</div>
            <div className={css.sliderContainer}>
              <SectionHeadImagesSlider rootClassName={css.imageSlider} images={[profileImage]}>
                {[profileImage] &&
                  [profileImage].map(image => {
                    return (
                      <div className={css.singleImageContainer}>
                        <div style={{ border: '5px solid white', borderRadius: 15 }}>
                          <ResponsiveImage
                            className={css.image}
                            image={image}
                            variants={['scaled-medium', 'default']}
                            alt={'title'}
                          />
                        </div>
                      </div>
                    );
                  })}
                <div className={css.singleImageContainer}>
                  <SectionMapMaybe
                    geolocation={publicData.geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
                    className={css.mapImage}
                  />
                </div>
              </SectionHeadImagesSlider>
            </div>
          </div>
        </div>
        <div className={css.contentContainer}>
          <div className={css.mainContent}>
            <div className={css.specializationWrapper}>
              <h2>
                TRAVEL BUSINESS{' '}
                <div className={css.profileIdentityWrapper}>
                  <img
                    src={Identity_Verified_Icon}
                    alt="Identity_Verified_Icon"
                    width="28px"
                    height="28px"
                  />
                  <span>IDENTITY VERIFIED</span>
                </div>
              </h2>
              {profileList &&
                profileList.map(list => {
                  let count = list.details.length;
                  return (
                    count > 0 && (
                      <li
                        className={
                          count > 1 || (list.id == 'business_location' && isMobile())
                            ? css.columnDirection
                            : css.rowDirection
                        }
                        key={list.id}
                      >
                        <div className={css.topAlignedWrapper}>
                          <div className={css.verticalAlignCenter}>
                            <img src={Triangle_Orange} alt={list.id} />
                          </div>
                          <h5>{list.title} : &nbsp;</h5>
                        </div>
                        <div className={css.wrapper}>
                          {list.details.length > 1 ? (
                            <>
                              {list.id === 'payments_accepted' &&
                                list.details.map((c, index) => (
                                  <div className={css.chip} key={index}>
                                    {c}
                                  </div>
                                ))}
                              {list.id === 'accreditation' && (
                                <ul className={css.accreditationContainer}>
                                  {list.details.map(c => (
                                    <li>{c}</li>
                                  ))}
                                </ul>
                              )}
                            </>
                          ) : (
                            <p>{list.details}</p>
                          )}
                        </div>
                      </li>
                    )
                  );
                })}
            </div>
          </div>
        </div>
        <ListingTravelAgentService
          rootClassName={css.container}
          currentListing={currentListing}
          intl={intl}
          listingVariant={listingVariant}
          currencyConversionRate={currencyConversionRate}
        />
      </>
    );
  }
}

export default CompanyPageCommonSection;
