import React, { PureComponent } from 'react';
import { array, bool, func, number, object, objectOf, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { SearchResultsPanel, SearchFiltersPanel } from '../../components';
import { LISTING_TYPES } from '../../util/constants';
import css from './SearchPage.css';

class MainPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isSearchFiltersPanelOpen: false };
  }

  render() {
    const {
      className,
      rootClassName,
      urlQueryParams,
      sort,
      listings,
      searchInProgress,
      fetchReviewsInProgress,
      searchListingsError,
      searchParamsAreInSync,
      onActivateListing,
      pagination,
      searchParamsForPagination,
      secondaryFilters,
      allListingReview,
      allAuthorNumberOfListings,
      otherListings,
      currencyConversionRate,
    } = this.props;

    const listingIds = listings.map(listing => listing.id.uuid);
    const otherListingIds = otherListings.map(listing => listing.id.uuid);

    const notInListings = listing => {
      return !listingIds.includes(listing);
    };

    const extraIds = otherListingIds.filter(notInListings);
    const extraListings = otherListings.filter(listing => extraIds.includes(listing.id.uuid));

    const isSearchFiltersPanelOpen = !!secondaryFilters && this.state.isSearchFiltersPanelOpen;
    const searchListingType = urlQueryParams.pub_listing_type;

    // With time-based availability filtering, pagination is NOT
    // supported. In these cases we get the pagination support info in
    // the response meta object, and we can use the count of listings
    // as the result count.
    //
    // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering

    const hasPaginationInfo = !!pagination && !pagination.paginationUnsupported;
    const listingsLength = listings ? listings.length : 0;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : listingsLength;

    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync;

    const classes = classNames(rootClassName || css.searchResultContainer, className);

    const secondaryFilterParamNames = secondaryFilters
      ? Object.values(secondaryFilters).map(f => f.paramName)
      : [];

    return (
      <div className={classes}>
        <div className={css.resultHead}>
          {searchListingType === LISTING_TYPES.COMPANY ? (
            <div className={css.modeHeadingWrap}>
              <h2 className={css.staysLocation}>
                <span>Partners</span>
              </h2>
            </div>
          ) : null}
          <div className={css.modeHeadingWrap}>
            <h2 className={css.staysLocation}>
              <span>
                {searchListingType === LISTING_TYPES.COMPANY
                  ? 'Travel Business Listings'
                  : 'Hire Your Trip Expert'}
              </span>
              <p>
                {searchListingType === LISTING_TYPES.LISTING &&
                  'Personalised research, Itinerary design and online collaboration service'}
              </p>
            </h2>
          </div>
          <span className={css.totalStays}>{searchInProgress || fetchReviewsInProgress ? 'Search Listings...' : `${listingsLength} Listings found`}</span>
        </div>
        <div
          className={classNames(css.listings, {
            [css.newSearchInProgress]: !listingsAreLoaded,
          })}
        >
          {searchListingsError ? (
            <h2 className={css.error}>
              <FormattedMessage id="SearchPage.searchError" />
            </h2>
          ) : null}
          {!fetchReviewsInProgress && <SearchResultsPanel
            className={css.searchListingsPanel}
            listings={listings}
            pagination={listingsAreLoaded ? pagination : null}
            search={searchParamsForPagination}
            setActiveListing={onActivateListing}
            allListingReview={allListingReview}
            allAuthorNumberOfListings={allAuthorNumberOfListings}
            currencyConversionRate={currencyConversionRate}
          />}
          {!fetchReviewsInProgress && listings?.length < 6 &&
            extraListings.length > 0 &&
            searchListingType !== LISTING_TYPES.COMPANY && (
              <>
                <h2>Other experts</h2>
                <SearchResultsPanel
                  className={css.searchListingsPanel}
                  // listings={otherListings}
                  listings={extraListings}
                  pagination={listingsAreLoaded ? pagination : null}
                  search={searchParamsForPagination}
                  setActiveListing={onActivateListing}
                  allListingReview={allListingReview}
                  allAuthorNumberOfListings={allAuthorNumberOfListings}
                  currencyConversionRate={currencyConversionRate}
                />
              </>
            )}
        </div>
      </div>
    );
  }
}

MainPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  resultsCount: 0,
  pagination: null,
  searchParamsForPagination: {},
  primaryFilters: null,
  secondaryFilters: null,
  allListingReview: {},
};

MainPanel.propTypes = {
  className: string,
  rootClassName: string,

  urlQueryParams: object.isRequired,
  listings: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  pagination: propTypes.pagination,
  searchParamsForPagination: object,
  showAsModalMaxWidth: number.isRequired,
  primaryFilters: objectOf(propTypes.filterConfig),
  secondaryFilters: objectOf(propTypes.filterConfig),
  allListingReview: object,
};

export default MainPanel;
