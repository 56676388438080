import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditBusinessVerificationForm } from '../../forms';
import config from '../../config';
import { CurrentUserContext } from 'contexts/currentUserContext';

import css from '../EditListingDescriptionPanel/EditListingDescriptionPanel.css';

const EditBusinessVerificationPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    listingType,
    handlePaymentVerification,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  return (
    <div className={css.scope}>
      <CurrentUserContext.Consumer>
        {currentUser => {
          return (
            <EditBusinessVerificationForm
              className={css.form}
              saveActionMsg={submitButtonText}
              onSubmit={(values) => {
                onSubmit(values)
              }}
              onChange={onChange}
              disabled={disabled}
              ready={ready}
              isPublished={isPublished}
              handlePaymentVerification={handlePaymentVerification}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              category={publicData.category}
              subCategory={publicData.subCategory}
              activityLevel={config.custom.activityLevel}
              currentUser={currentUser}
              listingType={listingType}
            />
          );
        }}
      </CurrentUserContext.Consumer>
    </div>
  );
};

EditBusinessVerificationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditBusinessVerificationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  listingType: string,
};

export default EditBusinessVerificationPanel;
